import React from "react";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}
const OfflineOutline: React.FC<IconProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 24" fill="none" {...props}>
      <path
        d="M20.833 15.6V18c3.348 0 6.061-2.686 6.061-6 0-3.018-2.25-5.516-5.181-5.937C20.682 2.527 17.389 0 13.56 0a8.502 8.502 0 00-7.006 3.66C2.974 4.12.226 7.15.226 10.8c0 3.568 2.621 6.53 6.061 7.1V15.45c-2.091-.533-3.636-2.412-3.636-4.649 0-2.58 2.06-4.695 4.654-4.796l.668-.026.334-.573A6.068 6.068 0 0113.56 2.4c2.97 0 5.491 2.132 5.977 5l.173 1.022 1.047-.021a5.987 5.987 0 01.075-.001c2.009 0 3.637 1.612 3.637 3.6s-1.628 3.6-3.637 3.6z"
        fill="#fff"
      />
      <path
        d="M12.349 12v7.504l-2.78-2.693-1.714 1.661L13.561 24l5.705-5.528-1.714-1.66-2.78 2.692V12H12.35z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoOfflineOutline = React.memo(OfflineOutline);
export default MemoOfflineOutline;
