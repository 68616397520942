import { createContext, useContext, useEffect, useState } from "react";
import { db, OfflineChapter } from "app/utils/db";
import { useLiveQuery } from "dexie-react-hooks";
import { useFeaturesContext } from "./features.context";
import * as Sentry from "@sentry/nextjs";
export const LOCAL_STORAGE_COUNTRY_KEY = "countryCode";
export const LOCAL_STORAGE_LOCALE_KEY = "locale";

interface OfflineContextValue {
  offlineChapters: OfflineChapter[] | undefined;
}
const OfflineContext = createContext<OfflineContextValue | null>(null);

export const OfflineContextProvider: React.FC = ({ children }) => {
  const { setDownloadEnabled, downloadEnabled } = useFeaturesContext();
  useEffect(() => {
    if (downloadEnabled) {
      db.open();
    } else {
      if (db.isOpen()) {
        db.close();
      }
    }
  }, [downloadEnabled]);
  const offlineChapters = useLiveQuery(
    () =>
      downloadEnabled && typeof window !== "undefined"
        ? db.offlineChapters
            .toArray()
            .catch((e) => {
              Sentry.captureException(e);
              setDownloadEnabled(false);
              return [];
            })
        : [],
    [downloadEnabled]
  );
  return (
    <OfflineContext.Provider value={{ offlineChapters }}>
      {children}
    </OfflineContext.Provider>
  );
};

export const useOfflineContext = (): OfflineContextValue => {
  const value = useContext(OfflineContext);
  if (value === null)
    throw new Error(
      "Make sure that you use your hook inside of OfflineContext"
    );
  return value;
};
