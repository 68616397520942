import { createContext, useContext, useState } from "react";

interface VirtualKeyboardContextValue {
  activeInput: HTMLInputElement | null;
  setActiveInput: (input: HTMLInputElement | null) => void;
}

const VirtualKeyboardContext =
  createContext<VirtualKeyboardContextValue | null>(null);

export const VirtualKeyboardContextProvider: React.FC = ({ children }) => {
  const [activeInput, setActiveInput] = useState<HTMLInputElement | null>(null);
  return (
    <VirtualKeyboardContext.Provider value={{ activeInput, setActiveInput }}>
      {children}
    </VirtualKeyboardContext.Provider>
  );
};

export const useVirtualKeyboardContext = (): VirtualKeyboardContextValue => {
  const value = useContext(VirtualKeyboardContext);
  if (value === null)
    throw new Error(
      "Make sure that you use your hook inside of VirtualKeyboardContext"
    );
  return value;
};
