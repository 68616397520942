import React from "react";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}
const Home: React.FC<IconProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 23 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.578 10L11.264 4 16.95 10h-.002v10H5.58V10h-.002zM3.685 12l-1.399 1.476-1.338-1.413 8.978-9.477c.739-.78 1.938-.78 2.677 0l8.978 9.477-1.339 1.414-1.399-1.478v8.003c0 1.104-.848 2-1.895 2H5.58c-1.046 0-1.895-.896-1.895-2v-8.002z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoHome = React.memo(Home);
export default MemoHome;
