import ar from "./ar.json";
import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import hi from "./hi.json";
import it from "./it.json";
import ja from "./ja.json";
import nb from "./nb.json";
import nl from "./nl.json";
import pl from "./pl.json";
import pt_BR from "./pt-BR.json";
import pt from "./pt.json";
import ro from "./ro.json";
import ru from "./ru.json";
import sv from "./sv.json";
import zh_CN from "./zh-CN.json";
import zh_TW from "./zh-TW.json";
import ko from "./ko.json";

export const messages = {
  ar,
  de,
  en,
  es,
  fr,
  hi,
  it,
  ja,
  nb,
  nl,
  pl,
  "pt-BR": pt_BR,
  pt,
  ro,
  ru,
  'se': sv,
  "zh-CN": zh_CN,
  "zh-TW": zh_TW,
  ko
};
