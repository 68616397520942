import { LayoutItem } from "../interfaces";

/**
 * Layout: Polish
 * Source: maciej-sielski (https://github.com/maciej-sielski)
 */
export default <LayoutItem>{
  layout: {
    default: [
      "\u02DB 1 2 3 4 5 6 7 8 9 0 + ' {bksp}",
      "q w e \u0119 r t y u i o \u00F3 p",
      "a \u0105 s \u015B d f g h j k l \u0142",
      "{shift} < z \u017C \u017A x c \u0107 v b n \u0144 m , . - {shift}",
      ".com @ {space} {enter}",
    ],
    shift: [
      '\u00B7 ! " # \u00A4 % & / ( ) = ? * {bksp}',
      "Q W E \u0118 R T Y U I O \u00D3 P",
      "A \u0104 S \u015A D F G H J K L \u0141",
      "{shift} > Z \u017B \u0179 X C \u0106 V B N \u0143 M ; : _ {shift}",
      ".com @ {space} {enter}",
    ],
  },
};
