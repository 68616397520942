import {
  Country,
  LOCAL_STORAGE_COUNTRY_KEY,
  LOCAL_STORAGE_LOCALE_KEY,
} from "app/context/country.context";
import {
  ar,
  de,
  enGB,
  es,
  fr,
  hi,
  it,
  ja,
  nb,
  nl,
  pl,
  ptBR,
  pt,
  ro,
  ru,
  zhCN,
  zhTW,
  sv,
} from "date-fns/locale";
import { Locale } from "date-fns";

const dateFnsLocales = {
  ar,
  de,
  en: enGB,
  es,
  fr,
  hi,
  it,
  ja,
  nb,
  nl,
  pl,
  "pt-BR": ptBR,
  pt,
  ro,
  ru,
  se: sv,
  "zh-CN": zhCN,
  "zh-TW": zhTW,
};

export const getDateFnsLocale = () => {
  const localeLang = getNextLocale();
  return ((dateFnsLocales as any)[localeLang] as Locale) || enGB;
};

export const getNextLocale = () => {
  const locale = window.localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY)!;
  return locale !== "defaultLang" ? locale : "en";
};

const internalGetNavigatorLanguage = (checkIpCountry = true): string => {
  let language: string = "";
  let country: string = "";
  let nextLocale: string = "";
  if (
    typeof navigator !== "undefined" &&
    navigator.languages &&
    navigator.languages.length
  ) {
    language = navigator.languages[0];
  } else if (typeof navigator !== "undefined" && navigator.language) {
    language = navigator.language;
  } else {
    language = "en_GB";
  }
  language = language.replaceAll("-", "_");

  if (typeof window !== "undefined" && window.localStorage && checkIpCountry) {
    const localCountry = window.localStorage.getItem(LOCAL_STORAGE_COUNTRY_KEY);
    if (localCountry !== null) {
      try {
        const parsedCountry = JSON.parse(localCountry) as Country;
        country = parsedCountry.countryCode;
      } catch (e) {}
    }
    const localLocale = getNextLocale();
    if (localLocale !== null) {
      nextLocale = localLocale;
    }
  }

  if (nextLocale) {
    language = nextLocale;
    language = language.replaceAll("-", "_");
  }

  if (country && checkIpCountry) {
    const splitArray = language.split("_");
    language = `${splitArray[0]}_${country.toUpperCase()}`;
  }
  return language;
};

export const getNavigatorLanguage = (): string => internalGetNavigatorLanguage(true);
export const getNavigatorLanguageForSignUp = (): string => internalGetNavigatorLanguage(false);