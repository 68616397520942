import { useTranslate } from "app/hooks/lang";
import clsx from "clsx";
import Link from "next/link";
import { MouseEvent } from "react";
import MemoLogo from "../icons/Logo";
import styles from "./footer.module.scss";

export const Footer: React.FC<{ showFooter: boolean }> = ({
  showFooter = true,
}) => {
  const t = useTranslate();

  const onPrivacySettingsClick = (e: MouseEvent) => {
    try {
      (window as any).Didomi?.notice?.show();
    } catch (e) {}

    e.preventDefault();
  };

  return (
    <footer
      className={clsx(styles.footer, {
        [styles.no_background]: !showFooter,
      })}
    >
      {showFooter && (
        <div className={styles.inner_footer}>
          <div className={clsx(styles.section, styles.menu)}>
            <div className={styles.header}>
              <MemoLogo className={styles.logo} />
              <div className={styles.prm}>
                  <Link href={"https://prm.radioline.co/"} passHref>
                    <a target="_blank" className={styles.alt_button}>
                      {t("add-manage-you-station-or-podcast")}
                    </a>
                  </Link>
                </div>
            </div>
            <div className={styles.main}>
              <a
                className={styles.store_button}
                href="https://apps.apple.com/app/radioline-radio-et-podcasts/id945478998"
                target="_blank"
              >
                <img src="/App_Store.png" />
              </a>
              <a
                className={styles.store_button}
                href="https://appgallery.huawei.com/app/C102907973"
                target="_blank"
              >
                <img src="/app_gallery.png" />
              </a>
              <a
                className={styles.store_button}
                href="https://play.google.com/store/apps/details?id=com.radioline.android.radioline"
                target="_blank"
              >
                <img src="/Google_Play_Store.png" />
              </a>
            </div>
          </div>

          <div className={styles.bottom}>
            <menu>
              <Link href={"/privacy-policy"} passHref>
                <a>{t("privacy-policy")}</a>
              </Link>
              <Link href={"#"} passHref>
                <a onClick={onPrivacySettingsClick}>{t("privacy-settings")}</a>
              </Link>
              <Link href={"/terms-of-use"} passHref>
                <a>{t("terms-of-use")}</a>
              </Link>
              <Link href={"https://business.radioline.co/"} passHref>
                <a target="_blank">{t("our-solutions")}</a>
              </Link>
              <Link href={"/contact-us"} passHref>
                <a>{t("contact")}</a>
              </Link>
              <Link href={"/navigation-map"} passHref>
                <a>{t("navigation-map")}</a>
              </Link>
            </menu>
          </div>
        </div>
      )}
    </footer>
  );
};
