import { Dispatch, SetStateAction, useEffect, useState } from "react";

export function useStickyState<T>(
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const getValue = () => {
    if (typeof window !== "undefined") {
      const stickyValue = window.localStorage.getItem(key);

      if (stickyValue !== null) {
        return JSON.parse(stickyValue);
      }
    }
    return null;
  };
  const [value, setValue] = useState<T>(getValue() || defaultValue);
  useEffect(() => {
    const stickyValue = getValue();

    if (stickyValue !== null) {
      setValue(stickyValue);
    }
  }, [key]);

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
