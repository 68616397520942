import Image from "next/image";
import React from "react";
interface IconProps extends React.HTMLProps<HTMLImageElement> {
  className?: string;
  width?: number;
  height?: number;
}
const Logo: React.FC<IconProps> = ({
  width = 1060,
  height = 214,
  ...props
}) => {
  return (
    <img src="/radioline.png" alt="Radioline" className={props.className} />
  );
};

const MemoLogo = React.memo(Logo);
export default MemoLogo;
