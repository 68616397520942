import { useFeaturesContext } from "app/context/features.context";

type VlybyContainerProps = {
  height?: string;
  width?: string;
  variant?: "floating" | "fullScreenPlayer";
};

const iFrameSrcs = {
  floating: "/vlyby/vlyby-player-floating.html",
  fullScreenPlayer: "/vlyby/vlyby-player.html",
};

export const VlybyContainer: React.FC<VlybyContainerProps> = ({
  height = "100%",
  width = "100%",
  variant = "fullScreenPlayer",
}) => {
  const { vlybyPublisherId } = useFeaturesContext();

  if (typeof window !== "undefined") {
    (window as any).vlybyId = vlybyPublisherId;
  }

  return <iframe src={iFrameSrcs[variant]} width={width} height={height} />;
};
