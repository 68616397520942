import React, { ReactNode, useMemo, useRef, useState } from "react";
import Logo from "../icons/Logo";
import styles from "./header.module.scss";
import Link from "next/link";
import MemoHome from "../icons/Home";
import MemoFavorite from "../icons/Favorite";
import MemoDiscover from "../icons/Discover";
import MemoOfflineOutline from "../icons/OfflineOutline";
import clsx from "clsx";
import MemoSettings from "../icons/Settings";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useTranslate } from "app/hooks/lang";
import { useFeaturesContext } from "app/context/features.context";
import MemoSearch from "../icons/Search";

const LazyPlayer = dynamic(
  () => import("app/components/station-player/player-wrapper.component"),
  { ssr: false }
);

const LazyPodcastPlayer = dynamic(
  () =>
    import("app/components/podcast-player/podcast-player-wrapper.component"),
  { ssr: false }
);

const isActiveLink = (href: string, currentPathname: string): boolean => {
  const rawHref = href.replace(/\?(.)+$/g, "");
  if (rawHref === "/") {
    return rawHref === currentPathname;
  }
  return currentPathname.startsWith(rawHref);
};

export const Header: React.FC = () => {
  const router = useRouter();
  const { push } = useRouter();
  const t = useTranslate();
  const { isCarMode } = useFeaturesContext();
  const onSearchCallback = (query: string) => {
    push(`/search?query=${query}`);
  };
  const { downloadEnabled, demoVersion } = useFeaturesContext();
  const links: { name: string; href: string; icon: ReactNode }[] =
    useMemo(() => {
      const list = [
        {
          name: t("header.menu.home"),
          href: "/",
          icon: <MemoHome className={styles.menu_item_icon} />,
        },
        {
          name: t("header.menu.favorites"),
          href: "/favorites",
          icon: <MemoFavorite className={styles.menu_item_icon} />,
        },
        {
          name: t("header.menu.discover"),
          href: "/discover",
          icon: <MemoDiscover className={styles.menu_item_icon} />,
        },
      ];
      if (isCarMode) {
        list[0].href = "/discover";
        // remove discover
        list.length = 2;
      }
      if (downloadEnabled) {
        list.push({
          name: t("header.menu.offline"),
          href: "/offline",
          icon: <MemoOfflineOutline className={styles.menu_item_icon} />,
        });
      }
      return list;
    }, []);
  return (
    <>
      <div className={styles.header}>
        <div className={styles.inner_wrapper} data-navigate-row>
          <Link passHref href={"/settings"}>
            <a className={clsx(styles.profile, styles.mobile)}>
              <button className={styles.icon_wrapper}>
                <MemoSettings className={styles.icon} />
              </button>
            </a>
          </Link>

          <div className={styles.logo_wrapper}>
            <Link href={isCarMode ? "/discover" : "/"} passHref>
              <a>
                <Logo className={clsx(styles.logo)} />
              </a>
            </Link>
            {demoVersion && (
              <p className={styles.demo_indicator}>{t("demo-version")}</p>
            )}
          </div>
          <div className={styles.player_wrapper}>
            <LazyPlayer />
            <LazyPodcastPlayer />
          </div>
          <div className={styles.side}>
            <div className={styles.menu_items}>
              {links.map((link, index) => (
                <Link
                  key={`header-link-${link}-${index}`}
                  passHref
                  href={link.href}
                  scroll={false}
                >
                  <a
                    data-navigate-item
                    className={clsx(styles.menu_item, {
                      [styles.active]: isActiveLink(link.href, router.pathname),
                    })}
                  >
                    <div className={styles.menu_item_icon_wrapper}>
                      {link.icon}
                    </div>
                    <span>{link.name}</span>
                  </a>
                </Link>
              ))}
              <Link passHref href={"/search?autofocus=true"}>
                <a data-navigate-item className={clsx(styles.profile)}>
                  <button className={styles.icon_wrapper}>
                    <MemoSearch className={styles.icon} />
                  </button>
                </a>
              </Link>
              <Link passHref href={"/settings"}>
                <a data-navigate-item className={clsx(styles.profile)}>
                  <button className={styles.icon_wrapper}>
                    <MemoSettings className={styles.icon} />
                  </button>
                </a>
              </Link>
            </div>
            {/* </div> */}
          </div>
          <div className={styles.search_mobile}>
            <Link passHref href={"/search?autofocus=true"}>
              <a className={clsx(styles.profile, styles.mobile)}>
                <button className={styles.icon_wrapper}>
                  <MemoSearch className={styles.icon} />
                </button>
              </a>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.navigation_mobile}>
        <div className={styles.navigation_mobile_inner}>
          {links.map((link, index) => (
            <Link
              key={`header-link-mobile-${link}-${index}`}
              passHref
              href={link.href}
              scroll={false}
            >
              <a data-navigate-item>
                <div
                  className={clsx(styles.navigation_item, {
                    [styles.active]: isActiveLink(link.href, router.pathname),
                  })}
                >
                  {link.icon}
                  <span className={styles.label}>{link.name}</span>
                </div>
              </a>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};
