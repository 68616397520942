import React from "react";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}
const Favorite: React.FC<IconProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 21" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.418 0c-1.371 0-2.255.172-3.269.696a5.809 5.809 0 00-1.054.7 5.933 5.933 0 00-1.012-.668C9.05.188 8.121 0 6.798 0 3.257 0 .684 3.097.684 7.12c0 3.038 1.604 5.972 4.593 8.81 1.57 1.49 3.572 2.963 5.007 3.747l.82.45.821-.45c1.435-.784 3.438-2.258 5.007-3.747 2.989-2.838 4.594-5.772 4.594-8.81 0-3.98-2.598-7.105-6.108-7.12zm4.213 7.118c0 2.38-1.34 4.83-3.966 7.323-1.432 1.36-3.273 2.718-4.56 3.431-1.288-.713-3.129-2.071-4.561-3.43-2.626-2.494-3.966-4.944-3.966-7.324 0-2.972 1.785-5.12 4.22-5.12 1.052 0 1.7.132 2.445.521.44.23.83.534 1.166.913l.698.785.69-.791c.345-.395.74-.705 1.185-.935.724-.374 1.331-.493 2.432-.493 2.406.011 4.217 2.19 4.217 5.12z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoFavorite = React.memo(Favorite);
export default MemoFavorite;
