import Dexie, { Table } from 'dexie';
import { Category, PodcastChapter } from 'services-hooks/types';

export interface OfflineChapter {
  permalink: string;
  chapter: PodcastChapter;
  podcast: Category;
  file: Blob;
  playbackProgress: number;
  isPlayed: boolean;
}

export class OfflineChaptersDexie extends Dexie {
  offlineChapters!: Table<OfflineChapter>; 

  constructor() {
    super('RadiolineOfflineChapters');
    this.version(1).stores({
      offlineChapters: '++permalink, chapterName, podcastName' // Primary key and indexed props
    });
  }
}

export const db = new OfflineChaptersDexie();