import clsx from "clsx";
import React from "react";
import styles from "./Loader.module.scss";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
  dark?: boolean;
}
const Loader: React.FC<IconProps> = ({ dark, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 38 38"
      stroke={dark ? "#000" : "#fff"}
      {...props}
      className={clsx(styles.rotate_bar, props.className)}
    >
      <g
        transform="translate(1 1)"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
      >
        <circle strokeOpacity={0.5} cx={18} cy={18} r={18}></circle>
        <path d="M36 18c0-9.94-8.06-18-18-18"></path>
      </g>
    </svg>
  );
};

const MemoLoader = React.memo(Loader);
export default MemoLoader;
