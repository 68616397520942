import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import styles from "./toast.module.scss";
import { nanoid } from "nanoid";
import clsx from "clsx";
import { Portal } from "../ui/portal";

interface ToastProps {
  toast: ToastItem;
  onDismiss: VoidFunction;
}
export const Toast: React.FC<ToastProps> = ({ toast }) => {
  const getAnimationClass = () => {
    switch (toast.config.position) {
      case "bottom-center":
      case "bottom-left":
      case "bottom-right":
        return styles["slide_down"];
      case "top-center":
      case "top-left":
      case "top-right":
        return styles["slide_up"];
    }
  };
  return toast ? (
    <div
      className={clsx(styles.toast, getAnimationClass(), toast.toast.className)}
    >
      {toast.toast.content}
    </div>
  ) : null;
};

interface ToastItem {
  toast: ToastIdentified;
  config: ToastConfig;
}

interface ToastIdentified extends ToastDef {
  id: string;
}

interface ToastDef {
  content: ReactNode;
  className?: string;
}

type ToastPosition =
  | "top-left"
  | "top-center"
  | "top-right"
  | "bottom-left"
  | "bottom-center"
  | "bottom-right";
interface ToastConfig {
  duration: number;
  position: ToastPosition;
}
type ToastList = { [k in ToastPosition]: ToastItem[] };
interface ToastContextValue {
  toasts: ToastList;
  addToast: (toast: ToastItem) => void;
  hideToast: (toastId: string) => void;
}

const ToastContext = createContext<ToastContextValue | null>(null);
export type TAction =
  | { type: "ADD"; toast: ToastItem }
  | { type: "REMOVE"; id: string }
  | { type: "REMOVE_ALL" };

const initialState: ToastList = {
  "top-left": [],
  "top-center": [],
  "top-right": [],
  "bottom-left": [],
  "bottom-center": [],
  "bottom-right": [],
};

const toastReducer = (toasts: ToastList, action: TAction): ToastList => {
  switch (action.type) {
    case "ADD":
      return {
        ...toasts,
        [action.toast.config.position]: [
          ...toasts[action.toast.config.position],
          action.toast,
        ],
      };
    case "REMOVE": {
      for (const [key, value] of Object.entries(toasts)) {
        const toastToHideIndex = value.findIndex(
          (toastItem) => toastItem.toast.id === action.id
        );
        if (toastToHideIndex !== -1) {
          const toastsCopy = [...value];
          toastsCopy.splice(toastToHideIndex, 1);
          return {
            ...toasts,
            [key]: toastsCopy,
          };
        }
      }
      return toasts;
    }
    case "REMOVE_ALL":
      return initialState;
    default:
      throw new Error();
  }
};

export const ToastRenderer: React.FC = () => {
  const { toasts, hideToast } = useToastContext();
  const renderToasts = (position: ToastPosition) => {
    return (
      <div className={clsx(styles.toast_container, styles[position])}>
        {toasts[position].map((toastItem) => {
          return (
            <Toast
              key={toastItem.toast.id}
              toast={toastItem}
              onDismiss={() => hideToast(toastItem.toast.id)}
            />
          );
        })}
      </div>
    );
  };
  return (
    <Portal>
      {renderToasts("bottom-center")}
      {renderToasts("bottom-left")}
      {renderToasts("bottom-right")}
      {renderToasts("top-center")}
      {renderToasts("top-left")}
      {renderToasts("top-right")}
    </Portal>
  );
};

export const ToastProvider: React.FC = ({ children }) => {
  const [toasts, dispatch] = useReducer(toastReducer, initialState);
  const addToast = (toast: ToastItem) => {
    dispatch({ type: "ADD", toast });
    setTimeout(function () {
      hideToast(toast.toast.id);
    }, toast.config.duration);
  };
  const hideToast = (toastId: string) => {
    dispatch({ type: "REMOVE", id: toastId });
  };

  return (
    <ToastContext.Provider value={{ toasts, addToast, hideToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToastContext = (): ToastContextValue => {
  const value = useContext(ToastContext);
  if (value === null)
    throw new Error(
      "Use useToastContext only in components wrapped in ToastProvider"
    );
  return value;
};
export const useToasts = (toast: {
  success: ReactNode;
  error: ReactNode;
}): [(message?: string) => void, (message?: string) => void] => {
  const toastConfig: ToastConfig = {
    ...{ duration: 4000, position: "top-center" },
  };
  const toastContext = useToastContext();
  const showToast = (toastContentOverride?: string) => {
    const toastId = nanoid();
    toastContext.addToast({
      toast: { content: toastContentOverride || toast.success, id: toastId },
      config: toastConfig,
    });
  };
  const showErrorToast = (toastContentOverride?: string) => {
    const toastId = nanoid();
    toastContext.addToast({
      toast: { content: toastContentOverride || toast.error, id: toastId },
      config: toastConfig,
    });
  };
  return [showToast, showErrorToast];
};

export const useToast = (
  toast: ToastDef,
  config: Partial<ToastConfig> = {}
): ((message?: string) => void) => {
  const toastConfig: ToastConfig = {
    ...{ duration: 3000, position: "top-center" },
    ...config,
  };
  const toastContext = useToastContext();
  const showToast = (toastContentOverride?: string) => {
    const toastId = nanoid();
    toastContext.addToast({
      toast: {
        content: toastContentOverride || toast.content,
        className: toast.className,
        id: toastId,
      },
      config: toastConfig,
    });
  };
  return showToast;
};

export const useErrorToast = (
  toast: ToastDef,
  config: Partial<ToastConfig> = {}
): ((message?: string) => void) => {
  return useToast(
    { ...toast, className: clsx(styles.toast_error, toast.className) },
    config
  );
};
