import { Button, ButtonOutline } from "../ui/button.component";
import { Portal } from "../ui/portal";
import styles from "./exit-confirmation.module.scss";
import { useArrowKeyNavigationOverlay } from "app/hooks/keyboard-navigation.hook";
import useModalScroll from "app/hooks/modal-scroll.hook";
import { useGoBackEvent } from "app/hooks/go-back.hook";
import { useEffectOnce } from "react-use";
import { useCallback, useRef } from "react";

interface ExitConfirmationProps {
  onClose: VoidFunction;
}

export const ExitConfirmation: React.FC<ExitConfirmationProps> = ({
  onClose,
}) => {
  const wrapperRef = useArrowKeyNavigationOverlay();
  const noButtonRef = useRef<HTMLButtonElement>(null);
  useModalScroll();
  const goBackCallback = useCallback(() => {
    onClose();
  }, [onClose]);
  useGoBackEvent("ExitConfirmation", goBackCallback);
  useEffectOnce(() => {
    noButtonRef.current?.focus();
  });

  return (
    <Portal>
      <div className={styles.wrapper} ref={wrapperRef}>
        <div className={styles.content}>
          <p className={styles.title}>Are you sure you want to exit? </p>
          <div className={styles.action_wrapper} data-navigate-row>
            <ButtonOutline
              ref={noButtonRef}
              small
              data-navigate-item
              onClick={() => onClose()}
            >
              No
            </ButtonOutline>
            <ButtonOutline
              small
              data-navigate-item
              onClick={() => {
                try {
                  console.log("CLOSE APP");
                  window.close();
                } catch (e) {
                  console.error(e);
                }
              }}
            >
              Yes
            </ButtonOutline>
          </div>
        </div>
      </div>
    </Portal>
  );
};
