import { db } from "app/utils/db";
import {
  postPillowSessionV2SessionCreate,
  postPillowSessionV2SessionLogout,
  postPillowSessionV2SessionRefresh,
} from "services-hooks/services";
import * as Sentry from "@sentry/nextjs";

export const refreshToken = (refreshToken: string) => {
  return postPillowSessionV2SessionRefresh({
    client_id: process.env.NEXT_PUBLIC_CLIENT_ID!,
    client_key: process.env.NEXT_PUBLIC_CLIENT_KEY!,
    refresh_token: refreshToken,
  });
};

export const signIn = (deviceId: string) => {
  return postPillowSessionV2SessionCreate({
    client_id: process.env.NEXT_PUBLIC_CLIENT_ID!,
    client_key: process.env.NEXT_PUBLIC_CLIENT_KEY!,
    device_serial: deviceId,
  });
};

export const signOut = async () => {
  try {
    if (db.isOpen()) {
      await db.offlineChapters.clear();
    }
    await postPillowSessionV2SessionLogout();
  } catch (e) {
    Sentry.captureException(e);
  }
};
