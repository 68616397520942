import { selectUserState, userActions } from "app/store/slices/user";
import { useAppDispatch, useAppSelector } from "app/store/store";
import { createContext, useContext, useRef } from "react";
import {
  getPillowUserV2UsersMe,
  postPillowSessionV2SessionCreate,
  postPillowSessionV2SessionLogout,
  postPillowUserV2UsersPasswordReset,
  postPillowUserV2UsersRegister,
} from "services-hooks/services";
import * as Sentry from "@sentry/nextjs";
import { db } from "app/utils/db";
import qs from "qs";
import { getNavigatorLanguageForSignUp } from "app/utils/language";
import { Session, UserRefreshResponse } from "services-hooks/types";
import { SwaggerResponse } from "services-hooks/config";

const REDIRECT_KEY = "REDIRECT_KEY";
interface AuthUserContextValue {
  signInUser: (email: string, password: string) => Promise<Session>;
  signOut: () => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  clearRedirect: VoidFunction;
  redirectToFacebookLogin: VoidFunction;
  resetPassword: (email: string) => Promise<void>;
  refreshUserData: () => Promise<void>;
  fetchUserData: () => Promise<SwaggerResponse<UserRefreshResponse>>;
  updateUserData: (r: SwaggerResponse<UserRefreshResponse>) => void;
}

const AuthUserContext = createContext<AuthUserContextValue | null>(null);

export const AuthUserProvider: React.FC = ({ children }) => {
  const { session, deviceId } = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();
  const timer = useRef<NodeJS.Timeout>();
  const lang = getNavigatorLanguageForSignUp();

  const clearRefreshTokenTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  const signUp = async (email: string, password: string) => {
    await postPillowUserV2UsersRegister({
      client_id: process.env.NEXT_PUBLIC_CLIENT_ID!,
      client_key: process.env.NEXT_PUBLIC_CLIENT_KEY!,
      country: lang.substring(lang.lastIndexOf("_") + 1) || "GB",
      email: email,
      password: password,
    });
  };

  const signInUser = async (
    email: string,
    password: string
  ): Promise<Session> => {
    const response = await postPillowSessionV2SessionCreate({
      client_id: process.env.NEXT_PUBLIC_CLIENT_ID!,
      client_key: process.env.NEXT_PUBLIC_CLIENT_KEY!,
      device_serial: deviceId,
      email,
      password,
    });
    dispatch(userActions.setSession(response.data.data));
    dispatch(userActions.loadFavorites());
    return response.data.data;
  };

  const redirectToFacebookLogin = () => {
    window.location.href =
      "https://www.facebook.com/v14.0/dialog/oauth?" +
      qs.stringify({
        client_id: process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID,
        redirect_uri: `${window.location.origin}/facebook`,
        response_type: "token",
        scope: "public_profile",
      });
  };

  const fetchUserData = async () => {
    return getPillowUserV2UsersMe();
  };

  const updateUserData = (response: SwaggerResponse<UserRefreshResponse>) => {
    if (response.data && response.data.data) {
      dispatch(
        userActions.setUser({
          country: response.data.data.country as string,
          email: response.data.data.email as string,
          first_name: response.data.data.first_name as string,
          id: response.data.data.id as string,
          last_name: response.data.data.last_name as string,
          permalink: response.data.data.permalink as string,
          premium: response.data.data.premium as boolean,
          spotify_connect: response.data.data.spotify_connect as boolean,
          deezer_connect: response.data.data.deezer_connect as boolean,
        })
      );
    }
  };

  const refreshUserData = async () => {
    if (session?.user) {
      try {
        const response = await fetchUserData();
        updateUserData(response);
      } catch (e) {}
    }
  };

  const resetPassword = async (email: string) => {
    await postPillowUserV2UsersPasswordReset({
      client_id: process.env.NEXT_PUBLIC_CLIENT_ID!,
      client_key: process.env.NEXT_PUBLIC_CLIENT_KEY!,
      email,
    });
  };

  const signOut = async () => {
    try {
      if (db.isOpen()) {
        await db.offlineChapters.clear();
      }
      await postPillowSessionV2SessionLogout();
    } catch (e) {
      Sentry.captureException(e);
    }
    dispatch(userActions.logout());
  };

  const clearRedirect = () => {
    if (typeof window !== "undefined") {
      window.localStorage.removeItem(REDIRECT_KEY);
    }
  };

  return (
    <AuthUserContext.Provider
      value={{
        signOut,
        clearRedirect,
        signInUser,
        signUp,
        resetPassword,
        refreshUserData,
        redirectToFacebookLogin,
        fetchUserData,
        updateUserData
      }}
    >
      {children}
    </AuthUserContext.Provider>
  );
};

export const useAuth = (): AuthUserContextValue => {
  const value = useContext(AuthUserContext);
  if (value === null)
    throw new Error(
      "Make sure that you use your hook inside of AuthUserContext"
    );
  return value;
};
