import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../store";

export interface AppVersion {
  version: string | null;
}

const initialState: AppVersion = {
  version: null
};

export const appVersionSlice = createSlice({
  name: "appVersion",
  initialState,
  reducers: {
    setAppVersion: (state, payload: PayloadAction<AppVersion | null>) => {
      state.version = payload.payload?.version || null;
    }
  }
});

export const appVersionActions = appVersionSlice.actions;
export const selectAppVersionState = (state: AppState) => state.appVersion;
export default appVersionSlice.reducer;