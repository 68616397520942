import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Category,
  PodcastChapter,
  TargetspoConfig,
} from "services-hooks/types";
import { AppState } from "../store";

export interface PodcastMedia {
  podcast: Category;
  podcastChapter: PodcastChapter;
}

export interface ActiveMedia {
  category: Category | null;
  podcast: PodcastMedia | null;
}

export interface MediaRadioQueue {
  activeIndex: number | null;
  items: Category[];
}

export interface MediaPodcastQueue {
  activeIndex: number | null;
  items: PodcastChapter[];
}

export interface PlayerState {
  activeMedia: ActiveMedia | null;
  shuffle: boolean;
  loop: boolean;
  radioQueue: MediaRadioQueue;
  podcastQueue: MediaPodcastQueue;
  failedPlaybackPermalink?: string | null;
  targetspotConfig?: TargetspoConfig | null;
}

const initialState: PlayerState = {
  activeMedia: null,
  loop: false,
  shuffle: false,
  radioQueue: { items: [], activeIndex: null },
  podcastQueue: { items: [], activeIndex: null },
};

export const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    selectTrack: (state, payload: PayloadAction<ActiveMedia | null>) => {
      state.activeMedia = payload.payload;
    },
    selectTrackWithQueue: (
      state,
      payload: PayloadAction<{
        track: ActiveMedia | null;
        queue: MediaRadioQueue;
      }>
    ) => {
      state.activeMedia = payload.payload.track;
      state.radioQueue = payload.payload.queue;
    },
    selectNextStation: (state) => {
      if (
        state.radioQueue.activeIndex !== null &&
        state.radioQueue.items[state.radioQueue.activeIndex] &&
        state.radioQueue.items[state.radioQueue.activeIndex].permalink !==
          state.activeMedia?.category?.permalink
      ) {
        state.activeMedia = {
          category: state.radioQueue.items[state.radioQueue.activeIndex],
          podcast: null,
        };
      }
    },
    setRadioQueue: (state, payload: PayloadAction<MediaRadioQueue>) => {
      state.radioQueue = payload.payload;
    },
    setPodcastQueue: (state, payload: PayloadAction<MediaPodcastQueue>) => {
      state.podcastQueue = payload.payload;
    },
    setFailedPlaybackPermalink: (
      state,
      payload: PayloadAction<string | null>
    ) => {
      state.failedPlaybackPermalink = payload.payload;
    },
    setTargetspoConfig: (
      state,
      payload: PayloadAction<TargetspoConfig | null>
    ) => {
      state.targetspotConfig = payload.payload;
    },
    reset: () => initialState,
  },
});
export const playerActions = playerSlice.actions;
export const selectPlayerState = (state: AppState) => state.player;
export default playerSlice.reducer;
