import React from "react";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}
const Discover: React.FC<IconProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.473 12c0-6.624 5.376-12 12-12s12 5.376 12 12-5.376 12-12 12-12-5.376-12-12zm2.4 0c0 5.292 4.308 9.6 9.6 9.6s9.6-4.308 9.6-9.6-4.308-9.6-9.6-9.6-9.6 4.308-9.6 9.6zm3 6.6l9.012-4.188L19.073 5.4l-9.012 4.188L5.873 18.6zm7.92-6.6c0-.732-.588-1.32-1.32-1.32-.732 0-1.32.588-1.32 1.32 0 .732.588 1.32 1.32 1.32.732 0 1.32-.588 1.32-1.32z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoDiscover = React.memo(Discover);
export default MemoDiscover;
