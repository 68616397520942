import { WEBOS_PAUSE, WEBOS_PLAY } from "./keyboard-navigation.hook";
import { useEffect } from "react";

export const useVirtualPlayPauseEvent = (
  onPlay: VoidFunction,
  onPause: VoidFunction,
  onPlayPause: VoidFunction,
  onStop: VoidFunction,
  enabled: boolean
) => {
  useEffect(() => {
    if (enabled) {
      const eventHandler = (event: KeyboardEvent) => {
        if (
          event.keyCode === WEBOS_PLAY ||
          ((window as any)["VK_PLAY"] &&
            event.keyCode === (window as any)["VK_PLAY"])
        ) {
          onPlay();
        }
        if (
          event.keyCode === WEBOS_PAUSE ||
          ((window as any)["VK_PAUSE"] &&
            event.keyCode === (window as any)["VK_PAUSE"])
        ) {
          onPause();
        }
        if (
          (window as any)["VK_PLAY_PAUSE"] &&
          event.keyCode === (window as any)["VK_PLAY_PAUSE"]
        ) {
          onPlayPause();
        }
        if (
          (window as any)["VK_STOP"] &&
          event.keyCode === (window as any)["VK_STOP"]
        ) {
          onStop();
        }
      };
      document.addEventListener("keydown", eventHandler);
      return () => document.removeEventListener("keydown", eventHandler);
    }
  }, [onPlay, enabled]);
};
