import { ExitConfirmation } from "app/components/exit-confirmation/exit-confirmation.component";
import { createContext, useCallback, useContext, useState } from "react";

export const LOCAL_STORAGE_COUNTRY_KEY = "countryCode";
export const LOCAL_STORAGE_LOCALE_KEY = "locale";

interface NavigationContextValue {
  isOverlayPresent: boolean;
  setIdActive: (id: string, active: boolean) => void;
  removeId: (id: string) => void;
  showExitConfirmationPopup: VoidFunction;
}

const NavigationContext = createContext<NavigationContextValue | null>(null);

export const NavigationContextProvider: React.FC = ({ children }) => {
  const [stack, setStack] = useState<Record<string, boolean>>({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const setIdActive = (id: string, active: boolean) => {
    setStack((value) => ({ ...value, [id]: active }));
  };

  const removeId = (id: string) => {
    const newStack = { ...stack };
    delete newStack[id];
    setStack(newStack);
  };

  const isOverlayPresent = Object.keys(stack).reduce(
    (isPresent, stackKey) => isPresent || stack[stackKey],
    false
  );

  const showExitConfirmationPopup = () => {
    setShowConfirmation(true);
  };
  const closeExitConfirmationCallback = useCallback(
    () => setShowConfirmation(false),
    [setShowConfirmation]
  );
  return (
    <NavigationContext.Provider
      value={{
        setIdActive,
        removeId,
        isOverlayPresent,
        showExitConfirmationPopup,
      }}
    >
      {children}
      {showConfirmation && (
        <ExitConfirmation onClose={closeExitConfirmationCallback} />
      )}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = (): NavigationContextValue => {
  const value = useContext(NavigationContext);
  if (value === null)
    throw new Error(
      "Make sure that you use your hook inside of NavigationContext"
    );
  return value;
};
