import { useCallback, useState } from "react";
import { Portal } from "../ui/portal";
import styles from "./virtual-keyboard.module.scss";
import arabic from "./layouts/arabic";
import english from "./layouts/english";
import russian from "./layouts/russian";
import swedish from "./layouts/swedish";
import brazilian from "./layouts/brazilian";
import korean from "./layouts/korean";
import chinese from "./layouts/chinese";
import polish from "./layouts/polish";
import japanese from "./layouts/japanese";
import hindi from "./layouts/hindi";
import french from "./layouts/french";
import { useArrowKeyNavigationOverlay } from "app/hooks/keyboard-navigation.hook";
import { useVirtualKeyboardContext } from "app/context/virtual-keyboard.context";
import { useGoBackEvent } from "app/hooks/go-back.hook";
import { useRouter } from "next/router";
import spanish from "./layouts/spanish";
import german from "./layouts/german";
import italian from "./layouts/italian";
import norwegian from "./layouts/norwegian";
import { LayoutItem } from "./interfaces";

const localeLayouts = {
  de: german,
  en: english,
  es: spanish,
  fr: french,
  it: italian,
  ro: english,
  nl: english,
  nb: norwegian,
  pl: polish,
  pt: brazilian,
  "pt-BR": brazilian,
  ru: russian,
  se: swedish,
  ja: japanese,
  ko: korean,
  "zh-TW": chinese,
  "zh-CN": chinese,
  hi: hindi,
  ar: arabic,
};

export const VirtualKeyboard: React.FC = () => {
  const router = useRouter();
  const locale = router.locale !== "defaultLang" ? router.locale || "en" : "en";
  const { activeInput, setActiveInput } = useVirtualKeyboardContext();
  const [activeLayout, setActiveLayout] = useState<"default" | "shift">(
    "default"
  );
  const blurInputCallback = useCallback(() => {
    setActiveInput(null);
  }, [setActiveInput]);

  useGoBackEvent("VirtualKeyboard", blurInputCallback, !!activeInput, true);
  const wrapperRef = useArrowKeyNavigationOverlay(!!activeInput);
  const renderKeyCap = (key: string) => {
    switch (key) {
      case "{shift}":
        return "\u21E7";
      case "{close}":
        return "\u2715";
      case "{enter}":
        return "\u23CE";
      case "{space}":
        return "\u2423";
      case "{bksp}":
        return "\u232B";
      default:
        return key;
    }
  };

  const setInputValue = (newValue: string) => {
    if (activeInput) {
      //https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "value"
      )?.set;
      nativeInputValueSetter?.call(activeInput, newValue);

      const event = new Event("input", { bubbles: true });
      activeInput.dispatchEvent(event);
    }
  };

  const handleClick = (key: string) => () => {
    switch (key) {
      case "{shift}":
        setActiveLayout(activeLayout === "default" ? "shift" : "default");
        break;
      case "{enter}":
        if (activeInput) {
          const form = activeInput?.form || null;
          if (form) {
            const formInputs = Array.from(
              form.querySelectorAll("input")
            ) as HTMLInputElement[];
            const inputIndex = formInputs.indexOf(activeInput);
            if (inputIndex < formInputs.length - 1) {
              setActiveInput(formInputs[inputIndex + 1]);
              setTimeout(() => {
                formInputs[inputIndex + 1].scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }, 100);
            } else {
              setActiveInput(null);
              (
                form.querySelector('button[type="submit"]') as HTMLButtonElement
              )?.focus();
            }
          } else {
            setActiveInput(null);
          }
        }
        break;
      case "{space}":
        if (activeInput) {
          setInputValue(`${activeInput.value} `);
        }
        break;
      case "{bksp}":
        if (activeInput) {
          setInputValue(activeInput.value.slice(0, -1));
        }
        break;
      default:
        if (activeLayout === "shift") {
          setActiveLayout("default");
        }
        if (activeInput) {
          setInputValue(`${activeInput.value}${key}`);
        }
    }
  };
  return (
    <>
      {!!activeInput && (
        <Portal>
          <div className={styles.wrapper} ref={wrapperRef} data-navigate-grid>
            {((localeLayouts as any)[locale] as LayoutItem).layout[
              activeLayout
            ].map((row) => (
              <div className={styles.row} data-navigate-row>
                {row.split(" ").map((keyCap) => (
                  <button
                    className={styles.keycap}
                    data-navigate-item
                    onClick={handleClick(keyCap)}
                  >
                    {renderKeyCap(keyCap)}
                  </button>
                ))}
              </div>
            ))}
          </div>
        </Portal>
      )}
    </>
  );
};
