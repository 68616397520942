export const clearMediaSession = () => {
  try {
    navigator.mediaSession.metadata = null;
    navigator.mediaSession.playbackState = "none";
  } catch (e) {}

  try {
    navigator.mediaSession.setActionHandler("play", null);
  } catch (e) {
    console.error(e);
  }

  try {
    navigator.mediaSession.setActionHandler("pause", null);
  } catch (e) {
    console.error(e);
  }

  try {
    navigator.mediaSession.setActionHandler("seekbackward", null);
  } catch (e) {
    console.error(e);
  }

  try {
    navigator.mediaSession.setActionHandler("seekforward", null);
  } catch (e) {
    console.error(e);
  }

  try {
    navigator.mediaSession.setActionHandler("stop", null);
  } catch (e) {
    console.error(e);
  }

  try {
    navigator.mediaSession.setActionHandler("previoustrack", null);
  } catch (e) {
    console.error(e);
  }

  try {
    navigator.mediaSession.setActionHandler("nexttrack", null);
  } catch (e) {
    console.error(e);
  }

  try {
    navigator.mediaSession.setActionHandler("favorite" as any, null);
    navigator.mediaSession.setActionHandler("shuffle" as any, null);
    navigator.mediaSession.setActionHandler("loopone" as any, null);
    navigator.mediaSession.setActionHandler("loopall" as any, null);
  } catch (e) {}
};
