import { useStickyState } from "app/hooks/sticky-state.hook";
import { createContext, useContext, useMemo } from "react";

export const LOCAL_STORAGE_COUNTRY_KEY = "countryCode";
export const LOCAL_STORAGE_LOCALE_KEY = "locale";

interface FeaturesContextValue {
  downloadEnabled: boolean;
  arrowNavigationEnabled: boolean;
  customVirtualKeyboardEnabled: boolean;
  showFooter: boolean;
  demoVersion: boolean;
  appVersion: string;
  forceAutoplay: boolean;
  forceMediaSession: boolean;
  exitConfirmationPopupEnabled: boolean;
  showRadioWebsite: boolean;
  premiumVersion: boolean;
  facebookLoginEnabled: boolean;
  showAddDevice: boolean;
  showInstallApp: boolean;
  targetspotStaging: boolean;
  targetspotEnabled: boolean;
  targetspotUrl: string | undefined;
  targetspotApikey: string | undefined;
  showQRCode: boolean;
  hideScrollbars: boolean;
  audioDotsEnabled: boolean;
  audioDotsPublisherId: string | undefined;
  audioDotsChannelId: string | undefined;
  disableDeleteAccount: boolean;
  isCarMode: boolean;
  isHTTPModeEnabled: boolean;
  vlybyPublisherId: string | undefined;
  setForceAutoPlay: (forceAutoplay: boolean) => void;
  setDownloadEnabled: (offlineEnabled: boolean) => void;
}

const FeaturesContext = createContext<FeaturesContextValue | null>(null);

export const FeaturesContextProvider: React.FC = ({ children }) => {
  const downloadFeatureEnabled = useMemo(
    () => process.env.NEXT_PUBLIC_ALLOW_DOWNLOAD === "true",
    []
  );

  const arrowNavigationEnabled = useMemo(
    () => process.env.NEXT_PUBLIC_ARROW_NAVIGATION === "true",
    []
  );

  const customVirtualKeyboardEnabled = useMemo(
    () => process.env.NEXT_PUBLIC_CUSTOM_VIRTUAL_KEYBOARD === "true",
    []
  );

  const demoVersion = useMemo(
    () => process.env.NEXT_PUBLIC_DEMO_VERSION === "true",
    []
  );

  const showFooter = useMemo(
    () => process.env.NEXT_PUBLIC_SHOW_FOOTER === "true",
    []
  );

  const forceMediaSession = useMemo(
    () => process.env.NEXT_PUBLIC_FORCE_MEDIASESSION === "true",
    []
  );

  const forceAutoplayFeatureEnabled = useMemo(
    () => process.env.NEXT_PUBLIC_FORCE_AUTOPLAY === "true",
    []
  );

  const showRadioWebsite = useMemo(
    () => process.env.NEXT_PUBLIC_SHOW_RADIO_WEBSITE_LINK === "true",
    []
  );

  const premiumVersion = useMemo(
    () => process.env.NEXT_PUBLIC_PREMIUM_VERSION === "true",
    []
  );
  const showAddDevice = useMemo(
    () => process.env.NEXT_PUBLIC_SHOW_ADD_DEVICE === "true",
    []
  );
  const showInstallApp = useMemo(
    () => process.env.NEXT_PUBLIC_SHOW_INSTALL_APP === "true",
    []
  );

  const targetspotEnabled = useMemo(
    () => process.env.NEXT_PUBLIC_TARGETSPOT_ENABLED === "true",
    []
  );

  const targetspotStaging = useMemo(
    () => process.env.NEXT_PUBLIC_TARGETSPOT_STAGING === "true",
    []
  );

  const targetspotUrl = useMemo(
    () => process.env.NEXT_PUBLIC_TARGETSPOT_URL,
    []
  );

  const audioDotsPublisherId = useMemo(
    () => process.env.NEXT_PUBLIC_AV_PUBLISHERID,
    []
  );

  const audioDotsChannelId = useMemo(
    () => process.env.NEXT_PUBLIC_AV_CHANNELID,
    []
  );

  const audioDotsEnabled = useMemo(
    () => !!audioDotsPublisherId && !!audioDotsChannelId,
    [audioDotsPublisherId, audioDotsChannelId]
  );

  const vlybyPublisherId = useMemo(
    () => process.env.NEXT_PUBLIC_VLYBY_PUBLISHERID,
    [] 
  );

  const showQRCode = useMemo(
    () => process.env.NEXT_PUBLIC_SHOW_QR_CODE === "true",
    []
  );

  const exitConfirmationPopupEnabled = useMemo(
    () => process.env.NEXT_PUBLIC_EXIT_CONFIRMATION === "true",
    []
  );

  const hideScrollbars = useMemo(
    () => process.env.NEXT_PUBLIC_HIDE_NATIVE_SCROLLBARS === "true",
    []
  );

  const targetspotApikey = useMemo(
    () => process.env.NEXT_PUBLIC_TARGETSPOT_API_KEY,
    []
  );

  const facebookLoginEnabled = useMemo(
    () => !!process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID,
    []
  );

  const disableDeleteAccount = useMemo(
    () => process.env.NEXT_PUBLIC_DISABLE_DELETE_ACCOUNT === "true",
    []
  );

  const isCarMode = useMemo(
    () => process.env.NEXT_PUBLIC_CAR_MODE === "true",
    []
  );

  const isHTTPModeEnabled = useMemo(
    () => process.env.NEXT_PUBLIC_HTTP_PLAYER_ENABLED === "true",
    []
  );

  const [forceAutoplay, setForceAutoPlay] = useStickyState(
    "AUTOPLAY_FEATURE_ENABLED",
    forceAutoplayFeatureEnabled
  );

  const [downloadEnabled, setDownloadEnabled] = useStickyState(
    "OFFLINE_FEATURE_ENABLED",
    downloadFeatureEnabled
  );

  const targetspotActive = useMemo(
    () => !!targetspotEnabled && !!targetspotUrl && !!targetspotApikey,
    [targetspotEnabled, targetspotUrl, targetspotApikey]
  );

  const appVersion = useMemo(() => process.env.packageVersion!, []);
  return (
    <FeaturesContext.Provider
      value={{
        audioDotsEnabled,
        customVirtualKeyboardEnabled,
        audioDotsPublisherId,
        exitConfirmationPopupEnabled,
        audioDotsChannelId,
        vlybyPublisherId,
        downloadEnabled,
        arrowNavigationEnabled,
        showFooter,
        demoVersion,
        appVersion,
        facebookLoginEnabled,
        forceAutoplay,
        forceMediaSession,
        targetspotStaging,
        setForceAutoPlay,
        setDownloadEnabled,
        premiumVersion,
        showRadioWebsite,
        showAddDevice,
        showInstallApp,
        targetspotApikey,
        targetspotUrl,
        targetspotEnabled: targetspotActive,
        showQRCode,
        hideScrollbars,
        disableDeleteAccount,
        isCarMode,
        isHTTPModeEnabled,
      }}
    >
      {children}
    </FeaturesContext.Provider>
  );
};

export const useFeaturesContext = (): FeaturesContextValue => {
  const value = useContext(FeaturesContext);
  if (value === null)
    throw new Error(
      "Make sure that you use your hook inside of FeaturesContext"
    );
  return value;
};
