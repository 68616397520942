import { usePortal } from "app/hooks/portal.hook";
import React from "react";
import { createPortal } from "react-dom";

interface PortalProps {
  id?: string;
}

/**
 * @example
 * <Portal id="modal">
 *   <p>Thinking with portals</p>
 * </Portal>
 */
export const Portal: React.FC<PortalProps> = ({ id, children }) => {
  const target = usePortal(id);
  return target ? createPortal(children, target) : null;
};
