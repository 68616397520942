import React from "react";

interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}

const Search: React.FC<IconProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 21" fill="none" {...props}>
      <path
        d="M19.898 19.086L15 14.19a8.375 8.375 0 001.9-5.309C16.9 4.26 13.14.5 8.522.5c-1.13 0-2.226.221-3.259.659A8.372 8.372 0 002.6 2.956 8.38 8.38 0 00.8 5.62a8.312 8.312 0 00-.66 3.26c0 4.622 3.759 8.38 8.378 8.38a8.364 8.364 0 005.306-1.9l4.895 4.897a.825.825 0 00.587.243.818.818 0 00.587-.243.824.824 0 00.004-1.17zM15.241 8.88a6.728 6.728 0 01-6.72 6.722c-3.707 0-6.72-3.016-6.72-6.722 0-3.705 3.015-6.722 6.72-6.722 3.704 0 6.72 3.017 6.72 6.722z"
        fill="#fff"
      />
    </svg>
  );
};

const MemoSearch = React.memo(Search);
export default MemoSearch;
