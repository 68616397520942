import { usePlayerContext } from "app/context/player.context";
import { useArrowKeyNavigationOverlay } from "app/hooks/keyboard-navigation.hook";
import { useTranslate } from "app/hooks/lang";
import { playerActions, selectPlayerState } from "app/store/slices/player";
import { selectUserState, userActions } from "app/store/slices/user";
import { useAppDispatch, useAppSelector } from "app/store/store";
import { Portal } from "../ui/portal";
import styles from "./previously-played.module.scss";

export const PreviouslyPlayed: React.FC<{}> = () => {
  const t = useTranslate();
  const { previouslyPlaying } = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();
  const { setPlayerEnabled } = usePlayerContext();
  const wrapperRef = useArrowKeyNavigationOverlay(!!previouslyPlaying?.media && !previouslyPlaying?.dismissed);

  return previouslyPlaying?.media && !previouslyPlaying?.dismissed ? (
    <Portal>
      <div className={styles.wrapper}
        ref={wrapperRef}>
        <div className={styles.inner}>
          <p className={styles.header}>{t("continuity.popup-header")}</p>
          <div className={styles.info}>
            <div className={styles.category_info}>
              <img
                className={styles.cover}
                src={
                  previouslyPlaying.media.podcast
                    ? previouslyPlaying.media.podcast.podcast.logo
                    : previouslyPlaying.media.category
                      ? previouslyPlaying.media.category?.logo
                      : ""
                }
                alt=""
              />
              <div>
                <p className={styles.category_title}>
                  {previouslyPlaying.media.podcast
                    ? previouslyPlaying.media.podcast.podcast.name
                    : previouslyPlaying.media.category
                      ? previouslyPlaying.media.category.name
                      : ""}
                </p>
                {previouslyPlaying.media.podcast && (
                  <p className={styles.category_subtitle}>
                    {previouslyPlaying.media.podcast.podcastChapter.name}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.buttons} data-navigate-row>
              <button
                data-navigate-item
                className={styles.button_close}
                onClick={() => {
                  dispatch(
                    userActions.setPreviouslyPlaying({
                      ...previouslyPlaying,
                      dismissed: true,
                    })
                  );
                }}
              >
                {t("continuity.popup-close-btn")}
              </button>
              <button
                data-navigate-item
                className={styles.button_cta}
                onClick={() => {
                  setPlayerEnabled(true);
                  if (previouslyPlaying.media?.category) {
                    dispatch(
                      playerActions.setRadioQueue({
                        items: [previouslyPlaying.media?.category],
                        activeIndex: 0,
                      })
                    );
                  } else if (previouslyPlaying.media?.podcast) {
                    dispatch(
                      playerActions.setPodcastQueue({
                        items: [
                          previouslyPlaying.media?.podcast.podcastChapter,
                        ],
                        activeIndex: 0,
                      })
                    );
                  }
                  dispatch(playerActions.selectTrack(previouslyPlaying.media));
                  dispatch(
                    userActions.setPreviouslyPlaying({
                      ...previouslyPlaying,
                      dismissed: true,
                    })
                  );
                }}
              >
                {t("continuity.popup-play-btn")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  ) : null;
};
