import { LayoutItem } from "../interfaces";

/**
 * Layout: Korean
 */
export default <LayoutItem>{
  layout: {
    default: [
      "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
      "\u1107 \u110c \u1103 \u1100 \u1109 \u116d \u1167 \u1163 \u1162 \u1166 [ ] \\",
      "\u1106 \u1102 \u110b \u1105 \u1112 \u1169 \u1165 \u1161 \u1175 ; '",
      "{shift} \u110f \u1110 \u110e \u1111 \u1172 \u116e \u1173 , . / {shift}",
      ".com @ {space} {enter}",
    ],
    shift: [
      "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
      "\u1108 \u110d \u1104 \u1101 \u110a \u116d \u1167 \u1163 \u1164 \u1168 { } |",
      '\u1106 \u1102 \u110b \u1105 \u1112 \u1169 \u1165 \u1161 \u1175 : "',
      "{shift} \u110f \u1110 \u110e \u1111 \u1172 \u116e \u1173 < > ? {shift}",
      ".com @ {space} {enter}",
    ],
  },
  layoutCandidates: {
    가: "가",
    가ᄀ: "각",
    가ᄁ: "갂",
    가ᄀᄉ: "갃",
    가ᄂ: "간",
    가ᄂᄌ: "갅",
    가ᄂᄒ: "갆",
    가ᄃ: "갇",
    가ᄅ: "갈",
    가ᄅᄀ: "갉",
    가ᄅᄆ: "갊",
    가ᄅᄇ: "갋",
    가ᄅᄉ: "갌",
    가ᄅᄐ: "갍",
    가ᄅᄑ: "갎",
    가ᄅᄒ: "갏",
    가ᄆ: "감",
    가ᄇ: "갑",
    가ᄇᄉ: "값",
    가ᄉ: "갓",
    가ᄊ: "갔",
    가ᄋ: "강",
    가ᄌ: "갖",
    가ᄎ: "갗",
    가ᄏ: "갘",
    가ᄐ: "같",
    가ᄑ: "갚",
    가ᄒ: "갛",
    개: "개",
    개ᄀ: "객",
    개ᄁ: "갞",
    개ᄀᄉ: "갟",
    개ᄂ: "갠",
    개ᄂᄌ: "갡",
    개ᄂᄒ: "갢",
    개ᄃ: "갣",
    개ᄅ: "갤",
    개ᄅᄀ: "갥",
    개ᄅᄆ: "갦",
    개ᄅᄇ: "갧",
    개ᄅᄉ: "갨",
    개ᄅᄐ: "갩",
    개ᄅᄑ: "갪",
    개ᄅᄒ: "갫",
    개ᄆ: "갬",
    개ᄇ: "갭",
    개ᄇᄉ: "갮",
    개ᄉ: "갯",
    개ᄊ: "갰",
    개ᄋ: "갱",
    개ᄌ: "갲",
    개ᄎ: "갳",
    개ᄏ: "갴",
    개ᄐ: "갵",
    개ᄑ: "갶",
    개ᄒ: "갷",
    갸: "갸",
    갸ᄀ: "갹",
    갸ᄁ: "갺",
    갸ᄀᄉ: "갻",
    갸ᄂ: "갼",
    갸ᄂᄌ: "갽",
    갸ᄂᄒ: "갾",
    갸ᄃ: "갿",
    갸ᄅ: "걀",
    갸ᄅᄀ: "걁",
    갸ᄅᄆ: "걂",
    갸ᄅᄇ: "걃",
    갸ᄅᄉ: "걄",
    갸ᄅᄐ: "걅",
    갸ᄅᄑ: "걆",
    갸ᄅᄒ: "걇",
    갸ᄆ: "걈",
    갸ᄇ: "걉",
    갸ᄇᄉ: "걊",
    갸ᄉ: "걋",
    갸ᄊ: "걌",
    갸ᄋ: "걍",
    갸ᄌ: "걎",
    갸ᄎ: "걏",
    갸ᄏ: "걐",
    갸ᄐ: "걑",
    갸ᄑ: "걒",
    갸ᄒ: "걓",
    걔: "걔",
    걔ᄀ: "걕",
    걔ᄁ: "걖",
    걔ᄀᄉ: "걗",
    걔ᄂ: "걘",
    걔ᄂᄌ: "걙",
    걔ᄂᄒ: "걚",
    걔ᄃ: "걛",
    걔ᄅ: "걜",
    걔ᄅᄀ: "걝",
    걔ᄅᄆ: "걞",
    걔ᄅᄇ: "걟",
    걔ᄅᄉ: "걠",
    걔ᄅᄐ: "걡",
    걔ᄅᄑ: "걢",
    걔ᄅᄒ: "걣",
    걔ᄆ: "걤",
    걔ᄇ: "걥",
    걔ᄇᄉ: "걦",
    걔ᄉ: "걧",
    걔ᄊ: "걨",
    걔ᄋ: "걩",
    걔ᄌ: "걪",
    걔ᄎ: "걫",
    걔ᄏ: "걬",
    걔ᄐ: "걭",
    걔ᄑ: "걮",
    걔ᄒ: "걯",
    거: "거",
    거ᄀ: "걱",
    거ᄁ: "걲",
    거ᄀᄉ: "걳",
    거ᄂ: "건",
    거ᄂᄌ: "걵",
    거ᄂᄒ: "걶",
    거ᄃ: "걷",
    거ᄅ: "걸",
    거ᄅᄀ: "걹",
    거ᄅᄆ: "걺",
    거ᄅᄇ: "걻",
    거ᄅᄉ: "걼",
    거ᄅᄐ: "걽",
    거ᄅᄑ: "걾",
    거ᄅᄒ: "걿",
    거ᄆ: "검",
    거ᄇ: "겁",
    거ᄇᄉ: "겂",
    거ᄉ: "것",
    거ᄊ: "겄",
    거ᄋ: "겅",
    거ᄌ: "겆",
    거ᄎ: "겇",
    거ᄏ: "겈",
    거ᄐ: "겉",
    거ᄑ: "겊",
    거ᄒ: "겋",
    게: "게",
    게ᄀ: "겍",
    게ᄁ: "겎",
    게ᄀᄉ: "겏",
    게ᄂ: "겐",
    게ᄂᄌ: "겑",
    게ᄂᄒ: "겒",
    게ᄃ: "겓",
    게ᄅ: "겔",
    게ᄅᄀ: "겕",
    게ᄅᄆ: "겖",
    게ᄅᄇ: "겗",
    게ᄅᄉ: "겘",
    게ᄅᄐ: "겙",
    게ᄅᄑ: "겚",
    게ᄅᄒ: "겛",
    게ᄆ: "겜",
    게ᄇ: "겝",
    게ᄇᄉ: "겞",
    게ᄉ: "겟",
    게ᄊ: "겠",
    게ᄋ: "겡",
    게ᄌ: "겢",
    게ᄎ: "겣",
    게ᄏ: "겤",
    게ᄐ: "겥",
    게ᄑ: "겦",
    게ᄒ: "겧",
    겨: "겨",
    겨ᄀ: "격",
    겨ᄁ: "겪",
    겨ᄀᄉ: "겫",
    겨ᄂ: "견",
    겨ᄂᄌ: "겭",
    겨ᄂᄒ: "겮",
    겨ᄃ: "겯",
    겨ᄅ: "결",
    겨ᄅᄀ: "겱",
    겨ᄅᄆ: "겲",
    겨ᄅᄇ: "겳",
    겨ᄅᄉ: "겴",
    겨ᄅᄐ: "겵",
    겨ᄅᄑ: "겶",
    겨ᄅᄒ: "겷",
    겨ᄆ: "겸",
    겨ᄇ: "겹",
    겨ᄇᄉ: "겺",
    겨ᄉ: "겻",
    겨ᄊ: "겼",
    겨ᄋ: "경",
    겨ᄌ: "겾",
    겨ᄎ: "겿",
    겨ᄏ: "곀",
    겨ᄐ: "곁",
    겨ᄑ: "곂",
    겨ᄒ: "곃",
    계: "계",
    계ᄀ: "곅",
    계ᄁ: "곆",
    계ᄀᄉ: "곇",
    계ᄂ: "곈",
    계ᄂᄌ: "곉",
    계ᄂᄒ: "곊",
    계ᄃ: "곋",
    계ᄅ: "곌",
    계ᄅᄀ: "곍",
    계ᄅᄆ: "곎",
    계ᄅᄇ: "곏",
    계ᄅᄉ: "곐",
    계ᄅᄐ: "곑",
    계ᄅᄑ: "곒",
    계ᄅᄒ: "곓",
    계ᄆ: "곔",
    계ᄇ: "곕",
    계ᄇᄉ: "곖",
    계ᄉ: "곗",
    계ᄊ: "곘",
    계ᄋ: "곙",
    계ᄌ: "곚",
    계ᄎ: "곛",
    계ᄏ: "곜",
    계ᄐ: "곝",
    계ᄑ: "곞",
    계ᄒ: "곟",
    고: "고",
    고ᄀ: "곡",
    고ᄁ: "곢",
    고ᄀᄉ: "곣",
    고ᄂ: "곤",
    고ᄂᄌ: "곥",
    고ᄂᄒ: "곦",
    고ᄃ: "곧",
    고ᄅ: "골",
    고ᄅᄀ: "곩",
    고ᄅᄆ: "곪",
    고ᄅᄇ: "곫",
    고ᄅᄉ: "곬",
    고ᄅᄐ: "곭",
    고ᄅᄑ: "곮",
    고ᄅᄒ: "곯",
    고ᄆ: "곰",
    고ᄇ: "곱",
    고ᄇᄉ: "곲",
    고ᄉ: "곳",
    고ᄊ: "곴",
    고ᄋ: "공",
    고ᄌ: "곶",
    고ᄎ: "곷",
    고ᄏ: "곸",
    고ᄐ: "곹",
    고ᄑ: "곺",
    고ᄒ: "곻",
    고ᅡ: "과",
    고ᅡᄀ: "곽",
    고ᅡᄁ: "곾",
    고ᅡᄀᄉ: "곿",
    고ᅡᄂ: "관",
    고ᅡᄂᄌ: "괁",
    고ᅡᄂᄒ: "괂",
    고ᅡᄃ: "괃",
    고ᅡᄅ: "괄",
    고ᅡᄅᄀ: "괅",
    고ᅡᄅᄆ: "괆",
    고ᅡᄅᄇ: "괇",
    고ᅡᄅᄉ: "괈",
    고ᅡᄅᄐ: "괉",
    고ᅡᄅᄑ: "괊",
    고ᅡᄅᄒ: "괋",
    고ᅡᄆ: "괌",
    고ᅡᄇ: "괍",
    고ᅡᄇᄉ: "괎",
    고ᅡᄉ: "괏",
    고ᅡᄊ: "괐",
    고ᅡᄋ: "광",
    고ᅡᄌ: "괒",
    고ᅡᄎ: "괓",
    고ᅡᄏ: "괔",
    고ᅡᄐ: "괕",
    고ᅡᄑ: "괖",
    고ᅡᄒ: "괗",
    고ᅢ: "괘",
    고ᅢᄀ: "괙",
    고ᅢᄁ: "괚",
    고ᅢᄀᄉ: "괛",
    고ᅢᄂ: "괜",
    고ᅢᄂᄌ: "괝",
    고ᅢᄂᄒ: "괞",
    고ᅢᄃ: "괟",
    고ᅢᄅ: "괠",
    고ᅢᄅᄀ: "괡",
    고ᅢᄅᄆ: "괢",
    고ᅢᄅᄇ: "괣",
    고ᅢᄅᄉ: "괤",
    고ᅢᄅᄐ: "괥",
    고ᅢᄅᄑ: "괦",
    고ᅢᄅᄒ: "괧",
    고ᅢᄆ: "괨",
    고ᅢᄇ: "괩",
    고ᅢᄇᄉ: "괪",
    고ᅢᄉ: "괫",
    고ᅢᄊ: "괬",
    고ᅢᄋ: "괭",
    고ᅢᄌ: "괮",
    고ᅢᄎ: "괯",
    고ᅢᄏ: "괰",
    고ᅢᄐ: "괱",
    고ᅢᄑ: "괲",
    고ᅢᄒ: "괳",
    고ᅵ: "괴",
    고ᅵᄀ: "괵",
    고ᅵᄁ: "괶",
    고ᅵᄀᄉ: "괷",
    고ᅵᄂ: "괸",
    고ᅵᄂᄌ: "괹",
    고ᅵᄂᄒ: "괺",
    고ᅵᄃ: "괻",
    고ᅵᄅ: "괼",
    고ᅵᄅᄀ: "괽",
    고ᅵᄅᄆ: "괾",
    고ᅵᄅᄇ: "괿",
    고ᅵᄅᄉ: "굀",
    고ᅵᄅᄐ: "굁",
    고ᅵᄅᄑ: "굂",
    고ᅵᄅᄒ: "굃",
    고ᅵᄆ: "굄",
    고ᅵᄇ: "굅",
    고ᅵᄇᄉ: "굆",
    고ᅵᄉ: "굇",
    고ᅵᄊ: "굈",
    고ᅵᄋ: "굉",
    고ᅵᄌ: "굊",
    고ᅵᄎ: "굋",
    고ᅵᄏ: "굌",
    고ᅵᄐ: "굍",
    고ᅵᄑ: "굎",
    고ᅵᄒ: "굏",
    교: "교",
    교ᄀ: "굑",
    교ᄁ: "굒",
    교ᄀᄉ: "굓",
    교ᄂ: "굔",
    교ᄂᄌ: "굕",
    교ᄂᄒ: "굖",
    교ᄃ: "굗",
    교ᄅ: "굘",
    교ᄅᄀ: "굙",
    교ᄅᄆ: "굚",
    교ᄅᄇ: "굛",
    교ᄅᄉ: "굜",
    교ᄅᄐ: "굝",
    교ᄅᄑ: "굞",
    교ᄅᄒ: "굟",
    교ᄆ: "굠",
    교ᄇ: "굡",
    교ᄇᄉ: "굢",
    교ᄉ: "굣",
    교ᄊ: "굤",
    교ᄋ: "굥",
    교ᄌ: "굦",
    교ᄎ: "굧",
    교ᄏ: "굨",
    교ᄐ: "굩",
    교ᄑ: "굪",
    교ᄒ: "굫",
    구: "구",
    구ᄀ: "국",
    구ᄁ: "굮",
    구ᄀᄉ: "굯",
    구ᄂ: "군",
    구ᄂᄌ: "굱",
    구ᄂᄒ: "굲",
    구ᄃ: "굳",
    구ᄅ: "굴",
    구ᄅᄀ: "굵",
    구ᄅᄆ: "굶",
    구ᄅᄇ: "굷",
    구ᄅᄉ: "굸",
    구ᄅᄐ: "굹",
    구ᄅᄑ: "굺",
    구ᄅᄒ: "굻",
    구ᄆ: "굼",
    구ᄇ: "굽",
    구ᄇᄉ: "굾",
    구ᄉ: "굿",
    구ᄊ: "궀",
    구ᄋ: "궁",
    구ᄌ: "궂",
    구ᄎ: "궃",
    구ᄏ: "궄",
    구ᄐ: "궅",
    구ᄑ: "궆",
    구ᄒ: "궇",
    구ᅥ: "궈",
    구ᅥᄀ: "궉",
    구ᅥᄁ: "궊",
    구ᅥᄀᄉ: "궋",
    구ᅥᄂ: "권",
    구ᅥᄂᄌ: "궍",
    구ᅥᄂᄒ: "궎",
    구ᅥᄃ: "궏",
    구ᅥᄅ: "궐",
    구ᅥᄅᄀ: "궑",
    구ᅥᄅᄆ: "궒",
    구ᅥᄅᄇ: "궓",
    구ᅥᄅᄉ: "궔",
    구ᅥᄅᄐ: "궕",
    구ᅥᄅᄑ: "궖",
    구ᅥᄅᄒ: "궗",
    구ᅥᄆ: "궘",
    구ᅥᄇ: "궙",
    구ᅥᄇᄉ: "궚",
    구ᅥᄉ: "궛",
    구ᅥᄊ: "궜",
    구ᅥᄋ: "궝",
    구ᅥᄌ: "궞",
    구ᅥᄎ: "궟",
    구ᅥᄏ: "궠",
    구ᅥᄐ: "궡",
    구ᅥᄑ: "궢",
    구ᅥᄒ: "궣",
    구ᅦ: "궤",
    구ᅦᄀ: "궥",
    구ᅦᄁ: "궦",
    구ᅦᄀᄉ: "궧",
    구ᅦᄂ: "궨",
    구ᅦᄂᄌ: "궩",
    구ᅦᄂᄒ: "궪",
    구ᅦᄃ: "궫",
    구ᅦᄅ: "궬",
    구ᅦᄅᄀ: "궭",
    구ᅦᄅᄆ: "궮",
    구ᅦᄅᄇ: "궯",
    구ᅦᄅᄉ: "궰",
    구ᅦᄅᄐ: "궱",
    구ᅦᄅᄑ: "궲",
    구ᅦᄅᄒ: "궳",
    구ᅦᄆ: "궴",
    구ᅦᄇ: "궵",
    구ᅦᄇᄉ: "궶",
    구ᅦᄉ: "궷",
    구ᅦᄊ: "궸",
    구ᅦᄋ: "궹",
    구ᅦᄌ: "궺",
    구ᅦᄎ: "궻",
    구ᅦᄏ: "궼",
    구ᅦᄐ: "궽",
    구ᅦᄑ: "궾",
    구ᅦᄒ: "궿",
    구ᅵ: "귀",
    구ᅵᄀ: "귁",
    구ᅵᄁ: "귂",
    구ᅵᄀᄉ: "귃",
    구ᅵᄂ: "귄",
    구ᅵᄂᄌ: "귅",
    구ᅵᄂᄒ: "귆",
    구ᅵᄃ: "귇",
    구ᅵᄅ: "귈",
    구ᅵᄅᄀ: "귉",
    구ᅵᄅᄆ: "귊",
    구ᅵᄅᄇ: "귋",
    구ᅵᄅᄉ: "귌",
    구ᅵᄅᄐ: "귍",
    구ᅵᄅᄑ: "귎",
    구ᅵᄅᄒ: "귏",
    구ᅵᄆ: "귐",
    구ᅵᄇ: "귑",
    구ᅵᄇᄉ: "귒",
    구ᅵᄉ: "귓",
    구ᅵᄊ: "귔",
    구ᅵᄋ: "귕",
    구ᅵᄌ: "귖",
    구ᅵᄎ: "귗",
    구ᅵᄏ: "귘",
    구ᅵᄐ: "귙",
    구ᅵᄑ: "귚",
    구ᅵᄒ: "귛",
    규: "규",
    규ᄀ: "귝",
    규ᄁ: "귞",
    규ᄀᄉ: "귟",
    규ᄂ: "균",
    규ᄂᄌ: "귡",
    규ᄂᄒ: "귢",
    규ᄃ: "귣",
    규ᄅ: "귤",
    규ᄅᄀ: "귥",
    규ᄅᄆ: "귦",
    규ᄅᄇ: "귧",
    규ᄅᄉ: "귨",
    규ᄅᄐ: "귩",
    규ᄅᄑ: "귪",
    규ᄅᄒ: "귫",
    규ᄆ: "귬",
    규ᄇ: "귭",
    규ᄇᄉ: "귮",
    규ᄉ: "귯",
    규ᄊ: "귰",
    규ᄋ: "귱",
    규ᄌ: "귲",
    규ᄎ: "귳",
    규ᄏ: "귴",
    규ᄐ: "귵",
    규ᄑ: "귶",
    규ᄒ: "귷",
    그: "그",
    그ᄀ: "극",
    그ᄁ: "귺",
    그ᄀᄉ: "귻",
    그ᄂ: "근",
    그ᄂᄌ: "귽",
    그ᄂᄒ: "귾",
    그ᄃ: "귿",
    그ᄅ: "글",
    그ᄅᄀ: "긁",
    그ᄅᄆ: "긂",
    그ᄅᄇ: "긃",
    그ᄅᄉ: "긄",
    그ᄅᄐ: "긅",
    그ᄅᄑ: "긆",
    그ᄅᄒ: "긇",
    그ᄆ: "금",
    그ᄇ: "급",
    그ᄇᄉ: "긊",
    그ᄉ: "긋",
    그ᄊ: "긌",
    그ᄋ: "긍",
    그ᄌ: "긎",
    그ᄎ: "긏",
    그ᄏ: "긐",
    그ᄐ: "긑",
    그ᄑ: "긒",
    그ᄒ: "긓",
    그ᅵ: "긔",
    그ᅵᄀ: "긕",
    그ᅵᄁ: "긖",
    그ᅵᄀᄉ: "긗",
    그ᅵᄂ: "긘",
    그ᅵᄂᄌ: "긙",
    그ᅵᄂᄒ: "긚",
    그ᅵᄃ: "긛",
    그ᅵᄅ: "긜",
    그ᅵᄅᄀ: "긝",
    그ᅵᄅᄆ: "긞",
    그ᅵᄅᄇ: "긟",
    그ᅵᄅᄉ: "긠",
    그ᅵᄅᄐ: "긡",
    그ᅵᄅᄑ: "긢",
    그ᅵᄅᄒ: "긣",
    그ᅵᄆ: "긤",
    그ᅵᄇ: "긥",
    그ᅵᄇᄉ: "긦",
    그ᅵᄉ: "긧",
    그ᅵᄊ: "긨",
    그ᅵᄋ: "긩",
    그ᅵᄌ: "긪",
    그ᅵᄎ: "긫",
    그ᅵᄏ: "긬",
    그ᅵᄐ: "긭",
    그ᅵᄑ: "긮",
    그ᅵᄒ: "긯",
    기: "기",
    기ᄀ: "긱",
    기ᄁ: "긲",
    기ᄀᄉ: "긳",
    기ᄂ: "긴",
    기ᄂᄌ: "긵",
    기ᄂᄒ: "긶",
    기ᄃ: "긷",
    기ᄅ: "길",
    기ᄅᄀ: "긹",
    기ᄅᄆ: "긺",
    기ᄅᄇ: "긻",
    기ᄅᄉ: "긼",
    기ᄅᄐ: "긽",
    기ᄅᄑ: "긾",
    기ᄅᄒ: "긿",
    기ᄆ: "김",
    기ᄇ: "깁",
    기ᄇᄉ: "깂",
    기ᄉ: "깃",
    기ᄊ: "깄",
    기ᄋ: "깅",
    기ᄌ: "깆",
    기ᄎ: "깇",
    기ᄏ: "깈",
    기ᄐ: "깉",
    기ᄑ: "깊",
    기ᄒ: "깋",
    까: "까",
    까ᄀ: "깍",
    까ᄁ: "깎",
    까ᄀᄉ: "깏",
    까ᄂ: "깐",
    까ᄂᄌ: "깑",
    까ᄂᄒ: "깒",
    까ᄃ: "깓",
    까ᄅ: "깔",
    까ᄅᄀ: "깕",
    까ᄅᄆ: "깖",
    까ᄅᄇ: "깗",
    까ᄅᄉ: "깘",
    까ᄅᄐ: "깙",
    까ᄅᄑ: "깚",
    까ᄅᄒ: "깛",
    까ᄆ: "깜",
    까ᄇ: "깝",
    까ᄇᄉ: "깞",
    까ᄉ: "깟",
    까ᄊ: "깠",
    까ᄋ: "깡",
    까ᄌ: "깢",
    까ᄎ: "깣",
    까ᄏ: "깤",
    까ᄐ: "깥",
    까ᄑ: "깦",
    까ᄒ: "깧",
    깨: "깨",
    깨ᄀ: "깩",
    깨ᄁ: "깪",
    깨ᄀᄉ: "깫",
    깨ᄂ: "깬",
    깨ᄂᄌ: "깭",
    깨ᄂᄒ: "깮",
    깨ᄃ: "깯",
    깨ᄅ: "깰",
    깨ᄅᄀ: "깱",
    깨ᄅᄆ: "깲",
    깨ᄅᄇ: "깳",
    깨ᄅᄉ: "깴",
    깨ᄅᄐ: "깵",
    깨ᄅᄑ: "깶",
    깨ᄅᄒ: "깷",
    깨ᄆ: "깸",
    깨ᄇ: "깹",
    깨ᄇᄉ: "깺",
    깨ᄉ: "깻",
    깨ᄊ: "깼",
    깨ᄋ: "깽",
    깨ᄌ: "깾",
    깨ᄎ: "깿",
    깨ᄏ: "꺀",
    깨ᄐ: "꺁",
    깨ᄑ: "꺂",
    깨ᄒ: "꺃",
    꺄: "꺄",
    꺄ᄀ: "꺅",
    꺄ᄁ: "꺆",
    꺄ᄀᄉ: "꺇",
    꺄ᄂ: "꺈",
    꺄ᄂᄌ: "꺉",
    꺄ᄂᄒ: "꺊",
    꺄ᄃ: "꺋",
    꺄ᄅ: "꺌",
    꺄ᄅᄀ: "꺍",
    꺄ᄅᄆ: "꺎",
    꺄ᄅᄇ: "꺏",
    꺄ᄅᄉ: "꺐",
    꺄ᄅᄐ: "꺑",
    꺄ᄅᄑ: "꺒",
    꺄ᄅᄒ: "꺓",
    꺄ᄆ: "꺔",
    꺄ᄇ: "꺕",
    꺄ᄇᄉ: "꺖",
    꺄ᄉ: "꺗",
    꺄ᄊ: "꺘",
    꺄ᄋ: "꺙",
    꺄ᄌ: "꺚",
    꺄ᄎ: "꺛",
    꺄ᄏ: "꺜",
    꺄ᄐ: "꺝",
    꺄ᄑ: "꺞",
    꺄ᄒ: "꺟",
    꺠: "꺠",
    꺠ᄀ: "꺡",
    꺠ᄁ: "꺢",
    꺠ᄀᄉ: "꺣",
    꺠ᄂ: "꺤",
    꺠ᄂᄌ: "꺥",
    꺠ᄂᄒ: "꺦",
    꺠ᄃ: "꺧",
    꺠ᄅ: "꺨",
    꺠ᄅᄀ: "꺩",
    꺠ᄅᄆ: "꺪",
    꺠ᄅᄇ: "꺫",
    꺠ᄅᄉ: "꺬",
    꺠ᄅᄐ: "꺭",
    꺠ᄅᄑ: "꺮",
    꺠ᄅᄒ: "꺯",
    꺠ᄆ: "꺰",
    꺠ᄇ: "꺱",
    꺠ᄇᄉ: "꺲",
    꺠ᄉ: "꺳",
    꺠ᄊ: "꺴",
    꺠ᄋ: "꺵",
    꺠ᄌ: "꺶",
    꺠ᄎ: "꺷",
    꺠ᄏ: "꺸",
    꺠ᄐ: "꺹",
    꺠ᄑ: "꺺",
    꺠ᄒ: "꺻",
    꺼: "꺼",
    꺼ᄀ: "꺽",
    꺼ᄁ: "꺾",
    꺼ᄀᄉ: "꺿",
    꺼ᄂ: "껀",
    꺼ᄂᄌ: "껁",
    꺼ᄂᄒ: "껂",
    꺼ᄃ: "껃",
    꺼ᄅ: "껄",
    꺼ᄅᄀ: "껅",
    꺼ᄅᄆ: "껆",
    꺼ᄅᄇ: "껇",
    꺼ᄅᄉ: "껈",
    꺼ᄅᄐ: "껉",
    꺼ᄅᄑ: "껊",
    꺼ᄅᄒ: "껋",
    꺼ᄆ: "껌",
    꺼ᄇ: "껍",
    꺼ᄇᄉ: "껎",
    꺼ᄉ: "껏",
    꺼ᄊ: "껐",
    꺼ᄋ: "껑",
    꺼ᄌ: "껒",
    꺼ᄎ: "껓",
    꺼ᄏ: "껔",
    꺼ᄐ: "껕",
    꺼ᄑ: "껖",
    꺼ᄒ: "껗",
    께: "께",
    께ᄀ: "껙",
    께ᄁ: "껚",
    께ᄀᄉ: "껛",
    께ᄂ: "껜",
    께ᄂᄌ: "껝",
    께ᄂᄒ: "껞",
    께ᄃ: "껟",
    께ᄅ: "껠",
    께ᄅᄀ: "껡",
    께ᄅᄆ: "껢",
    께ᄅᄇ: "껣",
    께ᄅᄉ: "껤",
    께ᄅᄐ: "껥",
    께ᄅᄑ: "껦",
    께ᄅᄒ: "껧",
    께ᄆ: "껨",
    께ᄇ: "껩",
    께ᄇᄉ: "껪",
    께ᄉ: "껫",
    께ᄊ: "껬",
    께ᄋ: "껭",
    께ᄌ: "껮",
    께ᄎ: "껯",
    께ᄏ: "껰",
    께ᄐ: "껱",
    께ᄑ: "껲",
    께ᄒ: "껳",
    껴: "껴",
    껴ᄀ: "껵",
    껴ᄁ: "껶",
    껴ᄀᄉ: "껷",
    껴ᄂ: "껸",
    껴ᄂᄌ: "껹",
    껴ᄂᄒ: "껺",
    껴ᄃ: "껻",
    껴ᄅ: "껼",
    껴ᄅᄀ: "껽",
    껴ᄅᄆ: "껾",
    껴ᄅᄇ: "껿",
    껴ᄅᄉ: "꼀",
    껴ᄅᄐ: "꼁",
    껴ᄅᄑ: "꼂",
    껴ᄅᄒ: "꼃",
    껴ᄆ: "꼄",
    껴ᄇ: "꼅",
    껴ᄇᄉ: "꼆",
    껴ᄉ: "꼇",
    껴ᄊ: "꼈",
    껴ᄋ: "꼉",
    껴ᄌ: "꼊",
    껴ᄎ: "꼋",
    껴ᄏ: "꼌",
    껴ᄐ: "꼍",
    껴ᄑ: "꼎",
    껴ᄒ: "꼏",
    꼐: "꼐",
    꼐ᄀ: "꼑",
    꼐ᄁ: "꼒",
    꼐ᄀᄉ: "꼓",
    꼐ᄂ: "꼔",
    꼐ᄂᄌ: "꼕",
    꼐ᄂᄒ: "꼖",
    꼐ᄃ: "꼗",
    꼐ᄅ: "꼘",
    꼐ᄅᄀ: "꼙",
    꼐ᄅᄆ: "꼚",
    꼐ᄅᄇ: "꼛",
    꼐ᄅᄉ: "꼜",
    꼐ᄅᄐ: "꼝",
    꼐ᄅᄑ: "꼞",
    꼐ᄅᄒ: "꼟",
    꼐ᄆ: "꼠",
    꼐ᄇ: "꼡",
    꼐ᄇᄉ: "꼢",
    꼐ᄉ: "꼣",
    꼐ᄊ: "꼤",
    꼐ᄋ: "꼥",
    꼐ᄌ: "꼦",
    꼐ᄎ: "꼧",
    꼐ᄏ: "꼨",
    꼐ᄐ: "꼩",
    꼐ᄑ: "꼪",
    꼐ᄒ: "꼫",
    꼬: "꼬",
    꼬ᄀ: "꼭",
    꼬ᄁ: "꼮",
    꼬ᄀᄉ: "꼯",
    꼬ᄂ: "꼰",
    꼬ᄂᄌ: "꼱",
    꼬ᄂᄒ: "꼲",
    꼬ᄃ: "꼳",
    꼬ᄅ: "꼴",
    꼬ᄅᄀ: "꼵",
    꼬ᄅᄆ: "꼶",
    꼬ᄅᄇ: "꼷",
    꼬ᄅᄉ: "꼸",
    꼬ᄅᄐ: "꼹",
    꼬ᄅᄑ: "꼺",
    꼬ᄅᄒ: "꼻",
    꼬ᄆ: "꼼",
    꼬ᄇ: "꼽",
    꼬ᄇᄉ: "꼾",
    꼬ᄉ: "꼿",
    꼬ᄊ: "꽀",
    꼬ᄋ: "꽁",
    꼬ᄌ: "꽂",
    꼬ᄎ: "꽃",
    꼬ᄏ: "꽄",
    꼬ᄐ: "꽅",
    꼬ᄑ: "꽆",
    꼬ᄒ: "꽇",
    꼬ᅡ: "꽈",
    꼬ᅡᄀ: "꽉",
    꼬ᅡᄁ: "꽊",
    꼬ᅡᄀᄉ: "꽋",
    꼬ᅡᄂ: "꽌",
    꼬ᅡᄂᄌ: "꽍",
    꼬ᅡᄂᄒ: "꽎",
    꼬ᅡᄃ: "꽏",
    꼬ᅡᄅ: "꽐",
    꼬ᅡᄅᄀ: "꽑",
    꼬ᅡᄅᄆ: "꽒",
    꼬ᅡᄅᄇ: "꽓",
    꼬ᅡᄅᄉ: "꽔",
    꼬ᅡᄅᄐ: "꽕",
    꼬ᅡᄅᄑ: "꽖",
    꼬ᅡᄅᄒ: "꽗",
    꼬ᅡᄆ: "꽘",
    꼬ᅡᄇ: "꽙",
    꼬ᅡᄇᄉ: "꽚",
    꼬ᅡᄉ: "꽛",
    꼬ᅡᄊ: "꽜",
    꼬ᅡᄋ: "꽝",
    꼬ᅡᄌ: "꽞",
    꼬ᅡᄎ: "꽟",
    꼬ᅡᄏ: "꽠",
    꼬ᅡᄐ: "꽡",
    꼬ᅡᄑ: "꽢",
    꼬ᅡᄒ: "꽣",
    꼬ᅢ: "꽤",
    꼬ᅢᄀ: "꽥",
    꼬ᅢᄁ: "꽦",
    꼬ᅢᄀᄉ: "꽧",
    꼬ᅢᄂ: "꽨",
    꼬ᅢᄂᄌ: "꽩",
    꼬ᅢᄂᄒ: "꽪",
    꼬ᅢᄃ: "꽫",
    꼬ᅢᄅ: "꽬",
    꼬ᅢᄅᄀ: "꽭",
    꼬ᅢᄅᄆ: "꽮",
    꼬ᅢᄅᄇ: "꽯",
    꼬ᅢᄅᄉ: "꽰",
    꼬ᅢᄅᄐ: "꽱",
    꼬ᅢᄅᄑ: "꽲",
    꼬ᅢᄅᄒ: "꽳",
    꼬ᅢᄆ: "꽴",
    꼬ᅢᄇ: "꽵",
    꼬ᅢᄇᄉ: "꽶",
    꼬ᅢᄉ: "꽷",
    꼬ᅢᄊ: "꽸",
    꼬ᅢᄋ: "꽹",
    꼬ᅢᄌ: "꽺",
    꼬ᅢᄎ: "꽻",
    꼬ᅢᄏ: "꽼",
    꼬ᅢᄐ: "꽽",
    꼬ᅢᄑ: "꽾",
    꼬ᅢᄒ: "꽿",
    꼬ᅵ: "꾀",
    꼬ᅵᄀ: "꾁",
    꼬ᅵᄁ: "꾂",
    꼬ᅵᄀᄉ: "꾃",
    꼬ᅵᄂ: "꾄",
    꼬ᅵᄂᄌ: "꾅",
    꼬ᅵᄂᄒ: "꾆",
    꼬ᅵᄃ: "꾇",
    꼬ᅵᄅ: "꾈",
    꼬ᅵᄅᄀ: "꾉",
    꼬ᅵᄅᄆ: "꾊",
    꼬ᅵᄅᄇ: "꾋",
    꼬ᅵᄅᄉ: "꾌",
    꼬ᅵᄅᄐ: "꾍",
    꼬ᅵᄅᄑ: "꾎",
    꼬ᅵᄅᄒ: "꾏",
    꼬ᅵᄆ: "꾐",
    꼬ᅵᄇ: "꾑",
    꼬ᅵᄇᄉ: "꾒",
    꼬ᅵᄉ: "꾓",
    꼬ᅵᄊ: "꾔",
    꼬ᅵᄋ: "꾕",
    꼬ᅵᄌ: "꾖",
    꼬ᅵᄎ: "꾗",
    꼬ᅵᄏ: "꾘",
    꼬ᅵᄐ: "꾙",
    꼬ᅵᄑ: "꾚",
    꼬ᅵᄒ: "꾛",
    꾜: "꾜",
    꾜ᄀ: "꾝",
    꾜ᄁ: "꾞",
    꾜ᄀᄉ: "꾟",
    꾜ᄂ: "꾠",
    꾜ᄂᄌ: "꾡",
    꾜ᄂᄒ: "꾢",
    꾜ᄃ: "꾣",
    꾜ᄅ: "꾤",
    꾜ᄅᄀ: "꾥",
    꾜ᄅᄆ: "꾦",
    꾜ᄅᄇ: "꾧",
    꾜ᄅᄉ: "꾨",
    꾜ᄅᄐ: "꾩",
    꾜ᄅᄑ: "꾪",
    꾜ᄅᄒ: "꾫",
    꾜ᄆ: "꾬",
    꾜ᄇ: "꾭",
    꾜ᄇᄉ: "꾮",
    꾜ᄉ: "꾯",
    꾜ᄊ: "꾰",
    꾜ᄋ: "꾱",
    꾜ᄌ: "꾲",
    꾜ᄎ: "꾳",
    꾜ᄏ: "꾴",
    꾜ᄐ: "꾵",
    꾜ᄑ: "꾶",
    꾜ᄒ: "꾷",
    꾸: "꾸",
    꾸ᄀ: "꾹",
    꾸ᄁ: "꾺",
    꾸ᄀᄉ: "꾻",
    꾸ᄂ: "꾼",
    꾸ᄂᄌ: "꾽",
    꾸ᄂᄒ: "꾾",
    꾸ᄃ: "꾿",
    꾸ᄅ: "꿀",
    꾸ᄅᄀ: "꿁",
    꾸ᄅᄆ: "꿂",
    꾸ᄅᄇ: "꿃",
    꾸ᄅᄉ: "꿄",
    꾸ᄅᄐ: "꿅",
    꾸ᄅᄑ: "꿆",
    꾸ᄅᄒ: "꿇",
    꾸ᄆ: "꿈",
    꾸ᄇ: "꿉",
    꾸ᄇᄉ: "꿊",
    꾸ᄉ: "꿋",
    꾸ᄊ: "꿌",
    꾸ᄋ: "꿍",
    꾸ᄌ: "꿎",
    꾸ᄎ: "꿏",
    꾸ᄏ: "꿐",
    꾸ᄐ: "꿑",
    꾸ᄑ: "꿒",
    꾸ᄒ: "꿓",
    꾸ᅥ: "꿔",
    꾸ᅥᄀ: "꿕",
    꾸ᅥᄁ: "꿖",
    꾸ᅥᄀᄉ: "꿗",
    꾸ᅥᄂ: "꿘",
    꾸ᅥᄂᄌ: "꿙",
    꾸ᅥᄂᄒ: "꿚",
    꾸ᅥᄃ: "꿛",
    꾸ᅥᄅ: "꿜",
    꾸ᅥᄅᄀ: "꿝",
    꾸ᅥᄅᄆ: "꿞",
    꾸ᅥᄅᄇ: "꿟",
    꾸ᅥᄅᄉ: "꿠",
    꾸ᅥᄅᄐ: "꿡",
    꾸ᅥᄅᄑ: "꿢",
    꾸ᅥᄅᄒ: "꿣",
    꾸ᅥᄆ: "꿤",
    꾸ᅥᄇ: "꿥",
    꾸ᅥᄇᄉ: "꿦",
    꾸ᅥᄉ: "꿧",
    꾸ᅥᄊ: "꿨",
    꾸ᅥᄋ: "꿩",
    꾸ᅥᄌ: "꿪",
    꾸ᅥᄎ: "꿫",
    꾸ᅥᄏ: "꿬",
    꾸ᅥᄐ: "꿭",
    꾸ᅥᄑ: "꿮",
    꾸ᅥᄒ: "꿯",
    꾸ᅦ: "꿰",
    꾸ᅦᄀ: "꿱",
    꾸ᅦᄁ: "꿲",
    꾸ᅦᄀᄉ: "꿳",
    꾸ᅦᄂ: "꿴",
    꾸ᅦᄂᄌ: "꿵",
    꾸ᅦᄂᄒ: "꿶",
    꾸ᅦᄃ: "꿷",
    꾸ᅦᄅ: "꿸",
    꾸ᅦᄅᄀ: "꿹",
    꾸ᅦᄅᄆ: "꿺",
    꾸ᅦᄅᄇ: "꿻",
    꾸ᅦᄅᄉ: "꿼",
    꾸ᅦᄅᄐ: "꿽",
    꾸ᅦᄅᄑ: "꿾",
    꾸ᅦᄅᄒ: "꿿",
    꾸ᅦᄆ: "뀀",
    꾸ᅦᄇ: "뀁",
    꾸ᅦᄇᄉ: "뀂",
    꾸ᅦᄉ: "뀃",
    꾸ᅦᄊ: "뀄",
    꾸ᅦᄋ: "뀅",
    꾸ᅦᄌ: "뀆",
    꾸ᅦᄎ: "뀇",
    꾸ᅦᄏ: "뀈",
    꾸ᅦᄐ: "뀉",
    꾸ᅦᄑ: "뀊",
    꾸ᅦᄒ: "뀋",
    꾸ᅵ: "뀌",
    꾸ᅵᄀ: "뀍",
    꾸ᅵᄁ: "뀎",
    꾸ᅵᄀᄉ: "뀏",
    꾸ᅵᄂ: "뀐",
    꾸ᅵᄂᄌ: "뀑",
    꾸ᅵᄂᄒ: "뀒",
    꾸ᅵᄃ: "뀓",
    꾸ᅵᄅ: "뀔",
    꾸ᅵᄅᄀ: "뀕",
    꾸ᅵᄅᄆ: "뀖",
    꾸ᅵᄅᄇ: "뀗",
    꾸ᅵᄅᄉ: "뀘",
    꾸ᅵᄅᄐ: "뀙",
    꾸ᅵᄅᄑ: "뀚",
    꾸ᅵᄅᄒ: "뀛",
    꾸ᅵᄆ: "뀜",
    꾸ᅵᄇ: "뀝",
    꾸ᅵᄇᄉ: "뀞",
    꾸ᅵᄉ: "뀟",
    꾸ᅵᄊ: "뀠",
    꾸ᅵᄋ: "뀡",
    꾸ᅵᄌ: "뀢",
    꾸ᅵᄎ: "뀣",
    꾸ᅵᄏ: "뀤",
    꾸ᅵᄐ: "뀥",
    꾸ᅵᄑ: "뀦",
    꾸ᅵᄒ: "뀧",
    뀨: "뀨",
    뀨ᄀ: "뀩",
    뀨ᄁ: "뀪",
    뀨ᄀᄉ: "뀫",
    뀨ᄂ: "뀬",
    뀨ᄂᄌ: "뀭",
    뀨ᄂᄒ: "뀮",
    뀨ᄃ: "뀯",
    뀨ᄅ: "뀰",
    뀨ᄅᄀ: "뀱",
    뀨ᄅᄆ: "뀲",
    뀨ᄅᄇ: "뀳",
    뀨ᄅᄉ: "뀴",
    뀨ᄅᄐ: "뀵",
    뀨ᄅᄑ: "뀶",
    뀨ᄅᄒ: "뀷",
    뀨ᄆ: "뀸",
    뀨ᄇ: "뀹",
    뀨ᄇᄉ: "뀺",
    뀨ᄉ: "뀻",
    뀨ᄊ: "뀼",
    뀨ᄋ: "뀽",
    뀨ᄌ: "뀾",
    뀨ᄎ: "뀿",
    뀨ᄏ: "끀",
    뀨ᄐ: "끁",
    뀨ᄑ: "끂",
    뀨ᄒ: "끃",
    끄: "끄",
    끄ᄀ: "끅",
    끄ᄁ: "끆",
    끄ᄀᄉ: "끇",
    끄ᄂ: "끈",
    끄ᄂᄌ: "끉",
    끄ᄂᄒ: "끊",
    끄ᄃ: "끋",
    끄ᄅ: "끌",
    끄ᄅᄀ: "끍",
    끄ᄅᄆ: "끎",
    끄ᄅᄇ: "끏",
    끄ᄅᄉ: "끐",
    끄ᄅᄐ: "끑",
    끄ᄅᄑ: "끒",
    끄ᄅᄒ: "끓",
    끄ᄆ: "끔",
    끄ᄇ: "끕",
    끄ᄇᄉ: "끖",
    끄ᄉ: "끗",
    끄ᄊ: "끘",
    끄ᄋ: "끙",
    끄ᄌ: "끚",
    끄ᄎ: "끛",
    끄ᄏ: "끜",
    끄ᄐ: "끝",
    끄ᄑ: "끞",
    끄ᄒ: "끟",
    끄ᅵ: "끠",
    끄ᅵᄀ: "끡",
    끄ᅵᄁ: "끢",
    끄ᅵᄀᄉ: "끣",
    끄ᅵᄂ: "끤",
    끄ᅵᄂᄌ: "끥",
    끄ᅵᄂᄒ: "끦",
    끄ᅵᄃ: "끧",
    끄ᅵᄅ: "끨",
    끄ᅵᄅᄀ: "끩",
    끄ᅵᄅᄆ: "끪",
    끄ᅵᄅᄇ: "끫",
    끄ᅵᄅᄉ: "끬",
    끄ᅵᄅᄐ: "끭",
    끄ᅵᄅᄑ: "끮",
    끄ᅵᄅᄒ: "끯",
    끄ᅵᄆ: "끰",
    끄ᅵᄇ: "끱",
    끄ᅵᄇᄉ: "끲",
    끄ᅵᄉ: "끳",
    끄ᅵᄊ: "끴",
    끄ᅵᄋ: "끵",
    끄ᅵᄌ: "끶",
    끄ᅵᄎ: "끷",
    끄ᅵᄏ: "끸",
    끄ᅵᄐ: "끹",
    끄ᅵᄑ: "끺",
    끄ᅵᄒ: "끻",
    끼: "끼",
    끼ᄀ: "끽",
    끼ᄁ: "끾",
    끼ᄀᄉ: "끿",
    끼ᄂ: "낀",
    끼ᄂᄌ: "낁",
    끼ᄂᄒ: "낂",
    끼ᄃ: "낃",
    끼ᄅ: "낄",
    끼ᄅᄀ: "낅",
    끼ᄅᄆ: "낆",
    끼ᄅᄇ: "낇",
    끼ᄅᄉ: "낈",
    끼ᄅᄐ: "낉",
    끼ᄅᄑ: "낊",
    끼ᄅᄒ: "낋",
    끼ᄆ: "낌",
    끼ᄇ: "낍",
    끼ᄇᄉ: "낎",
    끼ᄉ: "낏",
    끼ᄊ: "낐",
    끼ᄋ: "낑",
    끼ᄌ: "낒",
    끼ᄎ: "낓",
    끼ᄏ: "낔",
    끼ᄐ: "낕",
    끼ᄑ: "낖",
    끼ᄒ: "낗",
    나: "나",
    나ᄀ: "낙",
    나ᄁ: "낚",
    나ᄀᄉ: "낛",
    나ᄂ: "난",
    나ᄂᄌ: "낝",
    나ᄂᄒ: "낞",
    나ᄃ: "낟",
    나ᄅ: "날",
    나ᄅᄀ: "낡",
    나ᄅᄆ: "낢",
    나ᄅᄇ: "낣",
    나ᄅᄉ: "낤",
    나ᄅᄐ: "낥",
    나ᄅᄑ: "낦",
    나ᄅᄒ: "낧",
    나ᄆ: "남",
    나ᄇ: "납",
    나ᄇᄉ: "낪",
    나ᄉ: "낫",
    나ᄊ: "났",
    나ᄋ: "낭",
    나ᄌ: "낮",
    나ᄎ: "낯",
    나ᄏ: "낰",
    나ᄐ: "낱",
    나ᄑ: "낲",
    나ᄒ: "낳",
    내: "내",
    내ᄀ: "낵",
    내ᄁ: "낶",
    내ᄀᄉ: "낷",
    내ᄂ: "낸",
    내ᄂᄌ: "낹",
    내ᄂᄒ: "낺",
    내ᄃ: "낻",
    내ᄅ: "낼",
    내ᄅᄀ: "낽",
    내ᄅᄆ: "낾",
    내ᄅᄇ: "낿",
    내ᄅᄉ: "냀",
    내ᄅᄐ: "냁",
    내ᄅᄑ: "냂",
    내ᄅᄒ: "냃",
    내ᄆ: "냄",
    내ᄇ: "냅",
    내ᄇᄉ: "냆",
    내ᄉ: "냇",
    내ᄊ: "냈",
    내ᄋ: "냉",
    내ᄌ: "냊",
    내ᄎ: "냋",
    내ᄏ: "냌",
    내ᄐ: "냍",
    내ᄑ: "냎",
    내ᄒ: "냏",
    냐: "냐",
    냐ᄀ: "냑",
    냐ᄁ: "냒",
    냐ᄀᄉ: "냓",
    냐ᄂ: "냔",
    냐ᄂᄌ: "냕",
    냐ᄂᄒ: "냖",
    냐ᄃ: "냗",
    냐ᄅ: "냘",
    냐ᄅᄀ: "냙",
    냐ᄅᄆ: "냚",
    냐ᄅᄇ: "냛",
    냐ᄅᄉ: "냜",
    냐ᄅᄐ: "냝",
    냐ᄅᄑ: "냞",
    냐ᄅᄒ: "냟",
    냐ᄆ: "냠",
    냐ᄇ: "냡",
    냐ᄇᄉ: "냢",
    냐ᄉ: "냣",
    냐ᄊ: "냤",
    냐ᄋ: "냥",
    냐ᄌ: "냦",
    냐ᄎ: "냧",
    냐ᄏ: "냨",
    냐ᄐ: "냩",
    냐ᄑ: "냪",
    냐ᄒ: "냫",
    냬: "냬",
    냬ᄀ: "냭",
    냬ᄁ: "냮",
    냬ᄀᄉ: "냯",
    냬ᄂ: "냰",
    냬ᄂᄌ: "냱",
    냬ᄂᄒ: "냲",
    냬ᄃ: "냳",
    냬ᄅ: "냴",
    냬ᄅᄀ: "냵",
    냬ᄅᄆ: "냶",
    냬ᄅᄇ: "냷",
    냬ᄅᄉ: "냸",
    냬ᄅᄐ: "냹",
    냬ᄅᄑ: "냺",
    냬ᄅᄒ: "냻",
    냬ᄆ: "냼",
    냬ᄇ: "냽",
    냬ᄇᄉ: "냾",
    냬ᄉ: "냿",
    냬ᄊ: "넀",
    냬ᄋ: "넁",
    냬ᄌ: "넂",
    냬ᄎ: "넃",
    냬ᄏ: "넄",
    냬ᄐ: "넅",
    냬ᄑ: "넆",
    냬ᄒ: "넇",
    너: "너",
    너ᄀ: "넉",
    너ᄁ: "넊",
    너ᄀᄉ: "넋",
    너ᄂ: "넌",
    너ᄂᄌ: "넍",
    너ᄂᄒ: "넎",
    너ᄃ: "넏",
    너ᄅ: "널",
    너ᄅᄀ: "넑",
    너ᄅᄆ: "넒",
    너ᄅᄇ: "넓",
    너ᄅᄉ: "넔",
    너ᄅᄐ: "넕",
    너ᄅᄑ: "넖",
    너ᄅᄒ: "넗",
    너ᄆ: "넘",
    너ᄇ: "넙",
    너ᄇᄉ: "넚",
    너ᄉ: "넛",
    너ᄊ: "넜",
    너ᄋ: "넝",
    너ᄌ: "넞",
    너ᄎ: "넟",
    너ᄏ: "넠",
    너ᄐ: "넡",
    너ᄑ: "넢",
    너ᄒ: "넣",
    네: "네",
    네ᄀ: "넥",
    네ᄁ: "넦",
    네ᄀᄉ: "넧",
    네ᄂ: "넨",
    네ᄂᄌ: "넩",
    네ᄂᄒ: "넪",
    네ᄃ: "넫",
    네ᄅ: "넬",
    네ᄅᄀ: "넭",
    네ᄅᄆ: "넮",
    네ᄅᄇ: "넯",
    네ᄅᄉ: "넰",
    네ᄅᄐ: "넱",
    네ᄅᄑ: "넲",
    네ᄅᄒ: "넳",
    네ᄆ: "넴",
    네ᄇ: "넵",
    네ᄇᄉ: "넶",
    네ᄉ: "넷",
    네ᄊ: "넸",
    네ᄋ: "넹",
    네ᄌ: "넺",
    네ᄎ: "넻",
    네ᄏ: "넼",
    네ᄐ: "넽",
    네ᄑ: "넾",
    네ᄒ: "넿",
    녀: "녀",
    녀ᄀ: "녁",
    녀ᄁ: "녂",
    녀ᄀᄉ: "녃",
    녀ᄂ: "년",
    녀ᄂᄌ: "녅",
    녀ᄂᄒ: "녆",
    녀ᄃ: "녇",
    녀ᄅ: "녈",
    녀ᄅᄀ: "녉",
    녀ᄅᄆ: "녊",
    녀ᄅᄇ: "녋",
    녀ᄅᄉ: "녌",
    녀ᄅᄐ: "녍",
    녀ᄅᄑ: "녎",
    녀ᄅᄒ: "녏",
    녀ᄆ: "념",
    녀ᄇ: "녑",
    녀ᄇᄉ: "녒",
    녀ᄉ: "녓",
    녀ᄊ: "녔",
    녀ᄋ: "녕",
    녀ᄌ: "녖",
    녀ᄎ: "녗",
    녀ᄏ: "녘",
    녀ᄐ: "녙",
    녀ᄑ: "녚",
    녀ᄒ: "녛",
    녜: "녜",
    녜ᄀ: "녝",
    녜ᄁ: "녞",
    녜ᄀᄉ: "녟",
    녜ᄂ: "녠",
    녜ᄂᄌ: "녡",
    녜ᄂᄒ: "녢",
    녜ᄃ: "녣",
    녜ᄅ: "녤",
    녜ᄅᄀ: "녥",
    녜ᄅᄆ: "녦",
    녜ᄅᄇ: "녧",
    녜ᄅᄉ: "녨",
    녜ᄅᄐ: "녩",
    녜ᄅᄑ: "녪",
    녜ᄅᄒ: "녫",
    녜ᄆ: "녬",
    녜ᄇ: "녭",
    녜ᄇᄉ: "녮",
    녜ᄉ: "녯",
    녜ᄊ: "녰",
    녜ᄋ: "녱",
    녜ᄌ: "녲",
    녜ᄎ: "녳",
    녜ᄏ: "녴",
    녜ᄐ: "녵",
    녜ᄑ: "녶",
    녜ᄒ: "녷",
    노: "노",
    노ᄀ: "녹",
    노ᄁ: "녺",
    노ᄀᄉ: "녻",
    노ᄂ: "논",
    노ᄂᄌ: "녽",
    노ᄂᄒ: "녾",
    노ᄃ: "녿",
    노ᄅ: "놀",
    노ᄅᄀ: "놁",
    노ᄅᄆ: "놂",
    노ᄅᄇ: "놃",
    노ᄅᄉ: "놄",
    노ᄅᄐ: "놅",
    노ᄅᄑ: "놆",
    노ᄅᄒ: "놇",
    노ᄆ: "놈",
    노ᄇ: "놉",
    노ᄇᄉ: "놊",
    노ᄉ: "놋",
    노ᄊ: "놌",
    노ᄋ: "농",
    노ᄌ: "놎",
    노ᄎ: "놏",
    노ᄏ: "놐",
    노ᄐ: "놑",
    노ᄑ: "높",
    노ᄒ: "놓",
    노ᅡ: "놔",
    노ᅡᄀ: "놕",
    노ᅡᄁ: "놖",
    노ᅡᄀᄉ: "놗",
    노ᅡᄂ: "놘",
    노ᅡᄂᄌ: "놙",
    노ᅡᄂᄒ: "놚",
    노ᅡᄃ: "놛",
    노ᅡᄅ: "놜",
    노ᅡᄅᄀ: "놝",
    노ᅡᄅᄆ: "놞",
    노ᅡᄅᄇ: "놟",
    노ᅡᄅᄉ: "놠",
    노ᅡᄅᄐ: "놡",
    노ᅡᄅᄑ: "놢",
    노ᅡᄅᄒ: "놣",
    노ᅡᄆ: "놤",
    노ᅡᄇ: "놥",
    노ᅡᄇᄉ: "놦",
    노ᅡᄉ: "놧",
    노ᅡᄊ: "놨",
    노ᅡᄋ: "놩",
    노ᅡᄌ: "놪",
    노ᅡᄎ: "놫",
    노ᅡᄏ: "놬",
    노ᅡᄐ: "놭",
    노ᅡᄑ: "놮",
    노ᅡᄒ: "놯",
    노ᅢ: "놰",
    노ᅢᄀ: "놱",
    노ᅢᄁ: "놲",
    노ᅢᄀᄉ: "놳",
    노ᅢᄂ: "놴",
    노ᅢᄂᄌ: "놵",
    노ᅢᄂᄒ: "놶",
    노ᅢᄃ: "놷",
    노ᅢᄅ: "놸",
    노ᅢᄅᄀ: "놹",
    노ᅢᄅᄆ: "놺",
    노ᅢᄅᄇ: "놻",
    노ᅢᄅᄉ: "놼",
    노ᅢᄅᄐ: "놽",
    노ᅢᄅᄑ: "놾",
    노ᅢᄅᄒ: "놿",
    노ᅢᄆ: "뇀",
    노ᅢᄇ: "뇁",
    노ᅢᄇᄉ: "뇂",
    노ᅢᄉ: "뇃",
    노ᅢᄊ: "뇄",
    노ᅢᄋ: "뇅",
    노ᅢᄌ: "뇆",
    노ᅢᄎ: "뇇",
    노ᅢᄏ: "뇈",
    노ᅢᄐ: "뇉",
    노ᅢᄑ: "뇊",
    노ᅢᄒ: "뇋",
    노ᅵ: "뇌",
    노ᅵᄀ: "뇍",
    노ᅵᄁ: "뇎",
    노ᅵᄀᄉ: "뇏",
    노ᅵᄂ: "뇐",
    노ᅵᄂᄌ: "뇑",
    노ᅵᄂᄒ: "뇒",
    노ᅵᄃ: "뇓",
    노ᅵᄅ: "뇔",
    노ᅵᄅᄀ: "뇕",
    노ᅵᄅᄆ: "뇖",
    노ᅵᄅᄇ: "뇗",
    노ᅵᄅᄉ: "뇘",
    노ᅵᄅᄐ: "뇙",
    노ᅵᄅᄑ: "뇚",
    노ᅵᄅᄒ: "뇛",
    노ᅵᄆ: "뇜",
    노ᅵᄇ: "뇝",
    노ᅵᄇᄉ: "뇞",
    노ᅵᄉ: "뇟",
    노ᅵᄊ: "뇠",
    노ᅵᄋ: "뇡",
    노ᅵᄌ: "뇢",
    노ᅵᄎ: "뇣",
    노ᅵᄏ: "뇤",
    노ᅵᄐ: "뇥",
    노ᅵᄑ: "뇦",
    노ᅵᄒ: "뇧",
    뇨: "뇨",
    뇨ᄀ: "뇩",
    뇨ᄁ: "뇪",
    뇨ᄀᄉ: "뇫",
    뇨ᄂ: "뇬",
    뇨ᄂᄌ: "뇭",
    뇨ᄂᄒ: "뇮",
    뇨ᄃ: "뇯",
    뇨ᄅ: "뇰",
    뇨ᄅᄀ: "뇱",
    뇨ᄅᄆ: "뇲",
    뇨ᄅᄇ: "뇳",
    뇨ᄅᄉ: "뇴",
    뇨ᄅᄐ: "뇵",
    뇨ᄅᄑ: "뇶",
    뇨ᄅᄒ: "뇷",
    뇨ᄆ: "뇸",
    뇨ᄇ: "뇹",
    뇨ᄇᄉ: "뇺",
    뇨ᄉ: "뇻",
    뇨ᄊ: "뇼",
    뇨ᄋ: "뇽",
    뇨ᄌ: "뇾",
    뇨ᄎ: "뇿",
    뇨ᄏ: "눀",
    뇨ᄐ: "눁",
    뇨ᄑ: "눂",
    뇨ᄒ: "눃",
    누: "누",
    누ᄀ: "눅",
    누ᄁ: "눆",
    누ᄀᄉ: "눇",
    누ᄂ: "눈",
    누ᄂᄌ: "눉",
    누ᄂᄒ: "눊",
    누ᄃ: "눋",
    누ᄅ: "눌",
    누ᄅᄀ: "눍",
    누ᄅᄆ: "눎",
    누ᄅᄇ: "눏",
    누ᄅᄉ: "눐",
    누ᄅᄐ: "눑",
    누ᄅᄑ: "눒",
    누ᄅᄒ: "눓",
    누ᄆ: "눔",
    누ᄇ: "눕",
    누ᄇᄉ: "눖",
    누ᄉ: "눗",
    누ᄊ: "눘",
    누ᄋ: "눙",
    누ᄌ: "눚",
    누ᄎ: "눛",
    누ᄏ: "눜",
    누ᄐ: "눝",
    누ᄑ: "눞",
    누ᄒ: "눟",
    누ᅥ: "눠",
    누ᅥᄀ: "눡",
    누ᅥᄁ: "눢",
    누ᅥᄀᄉ: "눣",
    누ᅥᄂ: "눤",
    누ᅥᄂᄌ: "눥",
    누ᅥᄂᄒ: "눦",
    누ᅥᄃ: "눧",
    누ᅥᄅ: "눨",
    누ᅥᄅᄀ: "눩",
    누ᅥᄅᄆ: "눪",
    누ᅥᄅᄇ: "눫",
    누ᅥᄅᄉ: "눬",
    누ᅥᄅᄐ: "눭",
    누ᅥᄅᄑ: "눮",
    누ᅥᄅᄒ: "눯",
    누ᅥᄆ: "눰",
    누ᅥᄇ: "눱",
    누ᅥᄇᄉ: "눲",
    누ᅥᄉ: "눳",
    누ᅥᄊ: "눴",
    누ᅥᄋ: "눵",
    누ᅥᄌ: "눶",
    누ᅥᄎ: "눷",
    누ᅥᄏ: "눸",
    누ᅥᄐ: "눹",
    누ᅥᄑ: "눺",
    누ᅥᄒ: "눻",
    누ᅦ: "눼",
    누ᅦᄀ: "눽",
    누ᅦᄁ: "눾",
    누ᅦᄀᄉ: "눿",
    누ᅦᄂ: "뉀",
    누ᅦᄂᄌ: "뉁",
    누ᅦᄂᄒ: "뉂",
    누ᅦᄃ: "뉃",
    누ᅦᄅ: "뉄",
    누ᅦᄅᄀ: "뉅",
    누ᅦᄅᄆ: "뉆",
    누ᅦᄅᄇ: "뉇",
    누ᅦᄅᄉ: "뉈",
    누ᅦᄅᄐ: "뉉",
    누ᅦᄅᄑ: "뉊",
    누ᅦᄅᄒ: "뉋",
    누ᅦᄆ: "뉌",
    누ᅦᄇ: "뉍",
    누ᅦᄇᄉ: "뉎",
    누ᅦᄉ: "뉏",
    누ᅦᄊ: "뉐",
    누ᅦᄋ: "뉑",
    누ᅦᄌ: "뉒",
    누ᅦᄎ: "뉓",
    누ᅦᄏ: "뉔",
    누ᅦᄐ: "뉕",
    누ᅦᄑ: "뉖",
    누ᅦᄒ: "뉗",
    누ᅵ: "뉘",
    누ᅵᄀ: "뉙",
    누ᅵᄁ: "뉚",
    누ᅵᄀᄉ: "뉛",
    누ᅵᄂ: "뉜",
    누ᅵᄂᄌ: "뉝",
    누ᅵᄂᄒ: "뉞",
    누ᅵᄃ: "뉟",
    누ᅵᄅ: "뉠",
    누ᅵᄅᄀ: "뉡",
    누ᅵᄅᄆ: "뉢",
    누ᅵᄅᄇ: "뉣",
    누ᅵᄅᄉ: "뉤",
    누ᅵᄅᄐ: "뉥",
    누ᅵᄅᄑ: "뉦",
    누ᅵᄅᄒ: "뉧",
    누ᅵᄆ: "뉨",
    누ᅵᄇ: "뉩",
    누ᅵᄇᄉ: "뉪",
    누ᅵᄉ: "뉫",
    누ᅵᄊ: "뉬",
    누ᅵᄋ: "뉭",
    누ᅵᄌ: "뉮",
    누ᅵᄎ: "뉯",
    누ᅵᄏ: "뉰",
    누ᅵᄐ: "뉱",
    누ᅵᄑ: "뉲",
    누ᅵᄒ: "뉳",
    뉴: "뉴",
    뉴ᄀ: "뉵",
    뉴ᄁ: "뉶",
    뉴ᄀᄉ: "뉷",
    뉴ᄂ: "뉸",
    뉴ᄂᄌ: "뉹",
    뉴ᄂᄒ: "뉺",
    뉴ᄃ: "뉻",
    뉴ᄅ: "뉼",
    뉴ᄅᄀ: "뉽",
    뉴ᄅᄆ: "뉾",
    뉴ᄅᄇ: "뉿",
    뉴ᄅᄉ: "늀",
    뉴ᄅᄐ: "늁",
    뉴ᄅᄑ: "늂",
    뉴ᄅᄒ: "늃",
    뉴ᄆ: "늄",
    뉴ᄇ: "늅",
    뉴ᄇᄉ: "늆",
    뉴ᄉ: "늇",
    뉴ᄊ: "늈",
    뉴ᄋ: "늉",
    뉴ᄌ: "늊",
    뉴ᄎ: "늋",
    뉴ᄏ: "늌",
    뉴ᄐ: "늍",
    뉴ᄑ: "늎",
    뉴ᄒ: "늏",
    느: "느",
    느ᄀ: "늑",
    느ᄁ: "늒",
    느ᄀᄉ: "늓",
    느ᄂ: "는",
    느ᄂᄌ: "늕",
    느ᄂᄒ: "늖",
    느ᄃ: "늗",
    느ᄅ: "늘",
    느ᄅᄀ: "늙",
    느ᄅᄆ: "늚",
    느ᄅᄇ: "늛",
    느ᄅᄉ: "늜",
    느ᄅᄐ: "늝",
    느ᄅᄑ: "늞",
    느ᄅᄒ: "늟",
    느ᄆ: "늠",
    느ᄇ: "늡",
    느ᄇᄉ: "늢",
    느ᄉ: "늣",
    느ᄊ: "늤",
    느ᄋ: "능",
    느ᄌ: "늦",
    느ᄎ: "늧",
    느ᄏ: "늨",
    느ᄐ: "늩",
    느ᄑ: "늪",
    느ᄒ: "늫",
    느ᅵ: "늬",
    느ᅵᄀ: "늭",
    느ᅵᄁ: "늮",
    느ᅵᄀᄉ: "늯",
    느ᅵᄂ: "늰",
    느ᅵᄂᄌ: "늱",
    느ᅵᄂᄒ: "늲",
    느ᅵᄃ: "늳",
    느ᅵᄅ: "늴",
    느ᅵᄅᄀ: "늵",
    느ᅵᄅᄆ: "늶",
    느ᅵᄅᄇ: "늷",
    느ᅵᄅᄉ: "늸",
    느ᅵᄅᄐ: "늹",
    느ᅵᄅᄑ: "늺",
    느ᅵᄅᄒ: "늻",
    느ᅵᄆ: "늼",
    느ᅵᄇ: "늽",
    느ᅵᄇᄉ: "늾",
    느ᅵᄉ: "늿",
    느ᅵᄊ: "닀",
    느ᅵᄋ: "닁",
    느ᅵᄌ: "닂",
    느ᅵᄎ: "닃",
    느ᅵᄏ: "닄",
    느ᅵᄐ: "닅",
    느ᅵᄑ: "닆",
    느ᅵᄒ: "닇",
    니: "니",
    니ᄀ: "닉",
    니ᄁ: "닊",
    니ᄀᄉ: "닋",
    니ᄂ: "닌",
    니ᄂᄌ: "닍",
    니ᄂᄒ: "닎",
    니ᄃ: "닏",
    니ᄅ: "닐",
    니ᄅᄀ: "닑",
    니ᄅᄆ: "닒",
    니ᄅᄇ: "닓",
    니ᄅᄉ: "닔",
    니ᄅᄐ: "닕",
    니ᄅᄑ: "닖",
    니ᄅᄒ: "닗",
    니ᄆ: "님",
    니ᄇ: "닙",
    니ᄇᄉ: "닚",
    니ᄉ: "닛",
    니ᄊ: "닜",
    니ᄋ: "닝",
    니ᄌ: "닞",
    니ᄎ: "닟",
    니ᄏ: "닠",
    니ᄐ: "닡",
    니ᄑ: "닢",
    니ᄒ: "닣",
    다: "다",
    다ᄀ: "닥",
    다ᄁ: "닦",
    다ᄀᄉ: "닧",
    다ᄂ: "단",
    다ᄂᄌ: "닩",
    다ᄂᄒ: "닪",
    다ᄃ: "닫",
    다ᄅ: "달",
    다ᄅᄀ: "닭",
    다ᄅᄆ: "닮",
    다ᄅᄇ: "닯",
    다ᄅᄉ: "닰",
    다ᄅᄐ: "닱",
    다ᄅᄑ: "닲",
    다ᄅᄒ: "닳",
    다ᄆ: "담",
    다ᄇ: "답",
    다ᄇᄉ: "닶",
    다ᄉ: "닷",
    다ᄊ: "닸",
    다ᄋ: "당",
    다ᄌ: "닺",
    다ᄎ: "닻",
    다ᄏ: "닼",
    다ᄐ: "닽",
    다ᄑ: "닾",
    다ᄒ: "닿",
    대: "대",
    대ᄀ: "댁",
    대ᄁ: "댂",
    대ᄀᄉ: "댃",
    대ᄂ: "댄",
    대ᄂᄌ: "댅",
    대ᄂᄒ: "댆",
    대ᄃ: "댇",
    대ᄅ: "댈",
    대ᄅᄀ: "댉",
    대ᄅᄆ: "댊",
    대ᄅᄇ: "댋",
    대ᄅᄉ: "댌",
    대ᄅᄐ: "댍",
    대ᄅᄑ: "댎",
    대ᄅᄒ: "댏",
    대ᄆ: "댐",
    대ᄇ: "댑",
    대ᄇᄉ: "댒",
    대ᄉ: "댓",
    대ᄊ: "댔",
    대ᄋ: "댕",
    대ᄌ: "댖",
    대ᄎ: "댗",
    대ᄏ: "댘",
    대ᄐ: "댙",
    대ᄑ: "댚",
    대ᄒ: "댛",
    댜: "댜",
    댜ᄀ: "댝",
    댜ᄁ: "댞",
    댜ᄀᄉ: "댟",
    댜ᄂ: "댠",
    댜ᄂᄌ: "댡",
    댜ᄂᄒ: "댢",
    댜ᄃ: "댣",
    댜ᄅ: "댤",
    댜ᄅᄀ: "댥",
    댜ᄅᄆ: "댦",
    댜ᄅᄇ: "댧",
    댜ᄅᄉ: "댨",
    댜ᄅᄐ: "댩",
    댜ᄅᄑ: "댪",
    댜ᄅᄒ: "댫",
    댜ᄆ: "댬",
    댜ᄇ: "댭",
    댜ᄇᄉ: "댮",
    댜ᄉ: "댯",
    댜ᄊ: "댰",
    댜ᄋ: "댱",
    댜ᄌ: "댲",
    댜ᄎ: "댳",
    댜ᄏ: "댴",
    댜ᄐ: "댵",
    댜ᄑ: "댶",
    댜ᄒ: "댷",
    댸: "댸",
    댸ᄀ: "댹",
    댸ᄁ: "댺",
    댸ᄀᄉ: "댻",
    댸ᄂ: "댼",
    댸ᄂᄌ: "댽",
    댸ᄂᄒ: "댾",
    댸ᄃ: "댿",
    댸ᄅ: "덀",
    댸ᄅᄀ: "덁",
    댸ᄅᄆ: "덂",
    댸ᄅᄇ: "덃",
    댸ᄅᄉ: "덄",
    댸ᄅᄐ: "덅",
    댸ᄅᄑ: "덆",
    댸ᄅᄒ: "덇",
    댸ᄆ: "덈",
    댸ᄇ: "덉",
    댸ᄇᄉ: "덊",
    댸ᄉ: "덋",
    댸ᄊ: "덌",
    댸ᄋ: "덍",
    댸ᄌ: "덎",
    댸ᄎ: "덏",
    댸ᄏ: "덐",
    댸ᄐ: "덑",
    댸ᄑ: "덒",
    댸ᄒ: "덓",
    더: "더",
    더ᄀ: "덕",
    더ᄁ: "덖",
    더ᄀᄉ: "덗",
    더ᄂ: "던",
    더ᄂᄌ: "덙",
    더ᄂᄒ: "덚",
    더ᄃ: "덛",
    더ᄅ: "덜",
    더ᄅᄀ: "덝",
    더ᄅᄆ: "덞",
    더ᄅᄇ: "덟",
    더ᄅᄉ: "덠",
    더ᄅᄐ: "덡",
    더ᄅᄑ: "덢",
    더ᄅᄒ: "덣",
    더ᄆ: "덤",
    더ᄇ: "덥",
    더ᄇᄉ: "덦",
    더ᄉ: "덧",
    더ᄊ: "덨",
    더ᄋ: "덩",
    더ᄌ: "덪",
    더ᄎ: "덫",
    더ᄏ: "덬",
    더ᄐ: "덭",
    더ᄑ: "덮",
    더ᄒ: "덯",
    데: "데",
    데ᄀ: "덱",
    데ᄁ: "덲",
    데ᄀᄉ: "덳",
    데ᄂ: "덴",
    데ᄂᄌ: "덵",
    데ᄂᄒ: "덶",
    데ᄃ: "덷",
    데ᄅ: "델",
    데ᄅᄀ: "덹",
    데ᄅᄆ: "덺",
    데ᄅᄇ: "덻",
    데ᄅᄉ: "덼",
    데ᄅᄐ: "덽",
    데ᄅᄑ: "덾",
    데ᄅᄒ: "덿",
    데ᄆ: "뎀",
    데ᄇ: "뎁",
    데ᄇᄉ: "뎂",
    데ᄉ: "뎃",
    데ᄊ: "뎄",
    데ᄋ: "뎅",
    데ᄌ: "뎆",
    데ᄎ: "뎇",
    데ᄏ: "뎈",
    데ᄐ: "뎉",
    데ᄑ: "뎊",
    데ᄒ: "뎋",
    뎌: "뎌",
    뎌ᄀ: "뎍",
    뎌ᄁ: "뎎",
    뎌ᄀᄉ: "뎏",
    뎌ᄂ: "뎐",
    뎌ᄂᄌ: "뎑",
    뎌ᄂᄒ: "뎒",
    뎌ᄃ: "뎓",
    뎌ᄅ: "뎔",
    뎌ᄅᄀ: "뎕",
    뎌ᄅᄆ: "뎖",
    뎌ᄅᄇ: "뎗",
    뎌ᄅᄉ: "뎘",
    뎌ᄅᄐ: "뎙",
    뎌ᄅᄑ: "뎚",
    뎌ᄅᄒ: "뎛",
    뎌ᄆ: "뎜",
    뎌ᄇ: "뎝",
    뎌ᄇᄉ: "뎞",
    뎌ᄉ: "뎟",
    뎌ᄊ: "뎠",
    뎌ᄋ: "뎡",
    뎌ᄌ: "뎢",
    뎌ᄎ: "뎣",
    뎌ᄏ: "뎤",
    뎌ᄐ: "뎥",
    뎌ᄑ: "뎦",
    뎌ᄒ: "뎧",
    뎨: "뎨",
    뎨ᄀ: "뎩",
    뎨ᄁ: "뎪",
    뎨ᄀᄉ: "뎫",
    뎨ᄂ: "뎬",
    뎨ᄂᄌ: "뎭",
    뎨ᄂᄒ: "뎮",
    뎨ᄃ: "뎯",
    뎨ᄅ: "뎰",
    뎨ᄅᄀ: "뎱",
    뎨ᄅᄆ: "뎲",
    뎨ᄅᄇ: "뎳",
    뎨ᄅᄉ: "뎴",
    뎨ᄅᄐ: "뎵",
    뎨ᄅᄑ: "뎶",
    뎨ᄅᄒ: "뎷",
    뎨ᄆ: "뎸",
    뎨ᄇ: "뎹",
    뎨ᄇᄉ: "뎺",
    뎨ᄉ: "뎻",
    뎨ᄊ: "뎼",
    뎨ᄋ: "뎽",
    뎨ᄌ: "뎾",
    뎨ᄎ: "뎿",
    뎨ᄏ: "돀",
    뎨ᄐ: "돁",
    뎨ᄑ: "돂",
    뎨ᄒ: "돃",
    도: "도",
    도ᄀ: "독",
    도ᄁ: "돆",
    도ᄀᄉ: "돇",
    도ᄂ: "돈",
    도ᄂᄌ: "돉",
    도ᄂᄒ: "돊",
    도ᄃ: "돋",
    도ᄅ: "돌",
    도ᄅᄀ: "돍",
    도ᄅᄆ: "돎",
    도ᄅᄇ: "돏",
    도ᄅᄉ: "돐",
    도ᄅᄐ: "돑",
    도ᄅᄑ: "돒",
    도ᄅᄒ: "돓",
    도ᄆ: "돔",
    도ᄇ: "돕",
    도ᄇᄉ: "돖",
    도ᄉ: "돗",
    도ᄊ: "돘",
    도ᄋ: "동",
    도ᄌ: "돚",
    도ᄎ: "돛",
    도ᄏ: "돜",
    도ᄐ: "돝",
    도ᄑ: "돞",
    도ᄒ: "돟",
    도ᅡ: "돠",
    도ᅡᄀ: "돡",
    도ᅡᄁ: "돢",
    도ᅡᄀᄉ: "돣",
    도ᅡᄂ: "돤",
    도ᅡᄂᄌ: "돥",
    도ᅡᄂᄒ: "돦",
    도ᅡᄃ: "돧",
    도ᅡᄅ: "돨",
    도ᅡᄅᄀ: "돩",
    도ᅡᄅᄆ: "돪",
    도ᅡᄅᄇ: "돫",
    도ᅡᄅᄉ: "돬",
    도ᅡᄅᄐ: "돭",
    도ᅡᄅᄑ: "돮",
    도ᅡᄅᄒ: "돯",
    도ᅡᄆ: "돰",
    도ᅡᄇ: "돱",
    도ᅡᄇᄉ: "돲",
    도ᅡᄉ: "돳",
    도ᅡᄊ: "돴",
    도ᅡᄋ: "돵",
    도ᅡᄌ: "돶",
    도ᅡᄎ: "돷",
    도ᅡᄏ: "돸",
    도ᅡᄐ: "돹",
    도ᅡᄑ: "돺",
    도ᅡᄒ: "돻",
    도ᅢ: "돼",
    도ᅢᄀ: "돽",
    도ᅢᄁ: "돾",
    도ᅢᄀᄉ: "돿",
    도ᅢᄂ: "됀",
    도ᅢᄂᄌ: "됁",
    도ᅢᄂᄒ: "됂",
    도ᅢᄃ: "됃",
    도ᅢᄅ: "됄",
    도ᅢᄅᄀ: "됅",
    도ᅢᄅᄆ: "됆",
    도ᅢᄅᄇ: "됇",
    도ᅢᄅᄉ: "됈",
    도ᅢᄅᄐ: "됉",
    도ᅢᄅᄑ: "됊",
    도ᅢᄅᄒ: "됋",
    도ᅢᄆ: "됌",
    도ᅢᄇ: "됍",
    도ᅢᄇᄉ: "됎",
    도ᅢᄉ: "됏",
    도ᅢᄊ: "됐",
    도ᅢᄋ: "됑",
    도ᅢᄌ: "됒",
    도ᅢᄎ: "됓",
    도ᅢᄏ: "됔",
    도ᅢᄐ: "됕",
    도ᅢᄑ: "됖",
    도ᅢᄒ: "됗",
    도ᅵ: "되",
    도ᅵᄀ: "됙",
    도ᅵᄁ: "됚",
    도ᅵᄀᄉ: "됛",
    도ᅵᄂ: "된",
    도ᅵᄂᄌ: "됝",
    도ᅵᄂᄒ: "됞",
    도ᅵᄃ: "됟",
    도ᅵᄅ: "될",
    도ᅵᄅᄀ: "됡",
    도ᅵᄅᄆ: "됢",
    도ᅵᄅᄇ: "됣",
    도ᅵᄅᄉ: "됤",
    도ᅵᄅᄐ: "됥",
    도ᅵᄅᄑ: "됦",
    도ᅵᄅᄒ: "됧",
    도ᅵᄆ: "됨",
    도ᅵᄇ: "됩",
    도ᅵᄇᄉ: "됪",
    도ᅵᄉ: "됫",
    도ᅵᄊ: "됬",
    도ᅵᄋ: "됭",
    도ᅵᄌ: "됮",
    도ᅵᄎ: "됯",
    도ᅵᄏ: "됰",
    도ᅵᄐ: "됱",
    도ᅵᄑ: "됲",
    도ᅵᄒ: "됳",
    됴: "됴",
    됴ᄀ: "됵",
    됴ᄁ: "됶",
    됴ᄀᄉ: "됷",
    됴ᄂ: "됸",
    됴ᄂᄌ: "됹",
    됴ᄂᄒ: "됺",
    됴ᄃ: "됻",
    됴ᄅ: "됼",
    됴ᄅᄀ: "됽",
    됴ᄅᄆ: "됾",
    됴ᄅᄇ: "됿",
    됴ᄅᄉ: "둀",
    됴ᄅᄐ: "둁",
    됴ᄅᄑ: "둂",
    됴ᄅᄒ: "둃",
    됴ᄆ: "둄",
    됴ᄇ: "둅",
    됴ᄇᄉ: "둆",
    됴ᄉ: "둇",
    됴ᄊ: "둈",
    됴ᄋ: "둉",
    됴ᄌ: "둊",
    됴ᄎ: "둋",
    됴ᄏ: "둌",
    됴ᄐ: "둍",
    됴ᄑ: "둎",
    됴ᄒ: "둏",
    두: "두",
    두ᄀ: "둑",
    두ᄁ: "둒",
    두ᄀᄉ: "둓",
    두ᄂ: "둔",
    두ᄂᄌ: "둕",
    두ᄂᄒ: "둖",
    두ᄃ: "둗",
    두ᄅ: "둘",
    두ᄅᄀ: "둙",
    두ᄅᄆ: "둚",
    두ᄅᄇ: "둛",
    두ᄅᄉ: "둜",
    두ᄅᄐ: "둝",
    두ᄅᄑ: "둞",
    두ᄅᄒ: "둟",
    두ᄆ: "둠",
    두ᄇ: "둡",
    두ᄇᄉ: "둢",
    두ᄉ: "둣",
    두ᄊ: "둤",
    두ᄋ: "둥",
    두ᄌ: "둦",
    두ᄎ: "둧",
    두ᄏ: "둨",
    두ᄐ: "둩",
    두ᄑ: "둪",
    두ᄒ: "둫",
    두ᅥ: "둬",
    두ᅥᄀ: "둭",
    두ᅥᄁ: "둮",
    두ᅥᄀᄉ: "둯",
    두ᅥᄂ: "둰",
    두ᅥᄂᄌ: "둱",
    두ᅥᄂᄒ: "둲",
    두ᅥᄃ: "둳",
    두ᅥᄅ: "둴",
    두ᅥᄅᄀ: "둵",
    두ᅥᄅᄆ: "둶",
    두ᅥᄅᄇ: "둷",
    두ᅥᄅᄉ: "둸",
    두ᅥᄅᄐ: "둹",
    두ᅥᄅᄑ: "둺",
    두ᅥᄅᄒ: "둻",
    두ᅥᄆ: "둼",
    두ᅥᄇ: "둽",
    두ᅥᄇᄉ: "둾",
    두ᅥᄉ: "둿",
    두ᅥᄊ: "뒀",
    두ᅥᄋ: "뒁",
    두ᅥᄌ: "뒂",
    두ᅥᄎ: "뒃",
    두ᅥᄏ: "뒄",
    두ᅥᄐ: "뒅",
    두ᅥᄑ: "뒆",
    두ᅥᄒ: "뒇",
    두ᅦ: "뒈",
    두ᅦᄀ: "뒉",
    두ᅦᄁ: "뒊",
    두ᅦᄀᄉ: "뒋",
    두ᅦᄂ: "뒌",
    두ᅦᄂᄌ: "뒍",
    두ᅦᄂᄒ: "뒎",
    두ᅦᄃ: "뒏",
    두ᅦᄅ: "뒐",
    두ᅦᄅᄀ: "뒑",
    두ᅦᄅᄆ: "뒒",
    두ᅦᄅᄇ: "뒓",
    두ᅦᄅᄉ: "뒔",
    두ᅦᄅᄐ: "뒕",
    두ᅦᄅᄑ: "뒖",
    두ᅦᄅᄒ: "뒗",
    두ᅦᄆ: "뒘",
    두ᅦᄇ: "뒙",
    두ᅦᄇᄉ: "뒚",
    두ᅦᄉ: "뒛",
    두ᅦᄊ: "뒜",
    두ᅦᄋ: "뒝",
    두ᅦᄌ: "뒞",
    두ᅦᄎ: "뒟",
    두ᅦᄏ: "뒠",
    두ᅦᄐ: "뒡",
    두ᅦᄑ: "뒢",
    두ᅦᄒ: "뒣",
    두ᅵ: "뒤",
    두ᅵᄀ: "뒥",
    두ᅵᄁ: "뒦",
    두ᅵᄀᄉ: "뒧",
    두ᅵᄂ: "뒨",
    두ᅵᄂᄌ: "뒩",
    두ᅵᄂᄒ: "뒪",
    두ᅵᄃ: "뒫",
    두ᅵᄅ: "뒬",
    두ᅵᄅᄀ: "뒭",
    두ᅵᄅᄆ: "뒮",
    두ᅵᄅᄇ: "뒯",
    두ᅵᄅᄉ: "뒰",
    두ᅵᄅᄐ: "뒱",
    두ᅵᄅᄑ: "뒲",
    두ᅵᄅᄒ: "뒳",
    두ᅵᄆ: "뒴",
    두ᅵᄇ: "뒵",
    두ᅵᄇᄉ: "뒶",
    두ᅵᄉ: "뒷",
    두ᅵᄊ: "뒸",
    두ᅵᄋ: "뒹",
    두ᅵᄌ: "뒺",
    두ᅵᄎ: "뒻",
    두ᅵᄏ: "뒼",
    두ᅵᄐ: "뒽",
    두ᅵᄑ: "뒾",
    두ᅵᄒ: "뒿",
    듀: "듀",
    듀ᄀ: "듁",
    듀ᄁ: "듂",
    듀ᄀᄉ: "듃",
    듀ᄂ: "듄",
    듀ᄂᄌ: "듅",
    듀ᄂᄒ: "듆",
    듀ᄃ: "듇",
    듀ᄅ: "듈",
    듀ᄅᄀ: "듉",
    듀ᄅᄆ: "듊",
    듀ᄅᄇ: "듋",
    듀ᄅᄉ: "듌",
    듀ᄅᄐ: "듍",
    듀ᄅᄑ: "듎",
    듀ᄅᄒ: "듏",
    듀ᄆ: "듐",
    듀ᄇ: "듑",
    듀ᄇᄉ: "듒",
    듀ᄉ: "듓",
    듀ᄊ: "듔",
    듀ᄋ: "듕",
    듀ᄌ: "듖",
    듀ᄎ: "듗",
    듀ᄏ: "듘",
    듀ᄐ: "듙",
    듀ᄑ: "듚",
    듀ᄒ: "듛",
    드: "드",
    드ᄀ: "득",
    드ᄁ: "듞",
    드ᄀᄉ: "듟",
    드ᄂ: "든",
    드ᄂᄌ: "듡",
    드ᄂᄒ: "듢",
    드ᄃ: "듣",
    드ᄅ: "들",
    드ᄅᄀ: "듥",
    드ᄅᄆ: "듦",
    드ᄅᄇ: "듧",
    드ᄅᄉ: "듨",
    드ᄅᄐ: "듩",
    드ᄅᄑ: "듪",
    드ᄅᄒ: "듫",
    드ᄆ: "듬",
    드ᄇ: "듭",
    드ᄇᄉ: "듮",
    드ᄉ: "듯",
    드ᄊ: "듰",
    드ᄋ: "등",
    드ᄌ: "듲",
    드ᄎ: "듳",
    드ᄏ: "듴",
    드ᄐ: "듵",
    드ᄑ: "듶",
    드ᄒ: "듷",
    드ᅵ: "듸",
    드ᅵᄀ: "듹",
    드ᅵᄁ: "듺",
    드ᅵᄀᄉ: "듻",
    드ᅵᄂ: "듼",
    드ᅵᄂᄌ: "듽",
    드ᅵᄂᄒ: "듾",
    드ᅵᄃ: "듿",
    드ᅵᄅ: "딀",
    드ᅵᄅᄀ: "딁",
    드ᅵᄅᄆ: "딂",
    드ᅵᄅᄇ: "딃",
    드ᅵᄅᄉ: "딄",
    드ᅵᄅᄐ: "딅",
    드ᅵᄅᄑ: "딆",
    드ᅵᄅᄒ: "딇",
    드ᅵᄆ: "딈",
    드ᅵᄇ: "딉",
    드ᅵᄇᄉ: "딊",
    드ᅵᄉ: "딋",
    드ᅵᄊ: "딌",
    드ᅵᄋ: "딍",
    드ᅵᄌ: "딎",
    드ᅵᄎ: "딏",
    드ᅵᄏ: "딐",
    드ᅵᄐ: "딑",
    드ᅵᄑ: "딒",
    드ᅵᄒ: "딓",
    디: "디",
    디ᄀ: "딕",
    디ᄁ: "딖",
    디ᄀᄉ: "딗",
    디ᄂ: "딘",
    디ᄂᄌ: "딙",
    디ᄂᄒ: "딚",
    디ᄃ: "딛",
    디ᄅ: "딜",
    디ᄅᄀ: "딝",
    디ᄅᄆ: "딞",
    디ᄅᄇ: "딟",
    디ᄅᄉ: "딠",
    디ᄅᄐ: "딡",
    디ᄅᄑ: "딢",
    디ᄅᄒ: "딣",
    디ᄆ: "딤",
    디ᄇ: "딥",
    디ᄇᄉ: "딦",
    디ᄉ: "딧",
    디ᄊ: "딨",
    디ᄋ: "딩",
    디ᄌ: "딪",
    디ᄎ: "딫",
    디ᄏ: "딬",
    디ᄐ: "딭",
    디ᄑ: "딮",
    디ᄒ: "딯",
    따: "따",
    따ᄀ: "딱",
    따ᄁ: "딲",
    따ᄀᄉ: "딳",
    따ᄂ: "딴",
    따ᄂᄌ: "딵",
    따ᄂᄒ: "딶",
    따ᄃ: "딷",
    따ᄅ: "딸",
    따ᄅᄀ: "딹",
    따ᄅᄆ: "딺",
    따ᄅᄇ: "딻",
    따ᄅᄉ: "딼",
    따ᄅᄐ: "딽",
    따ᄅᄑ: "딾",
    따ᄅᄒ: "딿",
    따ᄆ: "땀",
    따ᄇ: "땁",
    따ᄇᄉ: "땂",
    따ᄉ: "땃",
    따ᄊ: "땄",
    따ᄋ: "땅",
    따ᄌ: "땆",
    따ᄎ: "땇",
    따ᄏ: "땈",
    따ᄐ: "땉",
    따ᄑ: "땊",
    따ᄒ: "땋",
    때: "때",
    때ᄀ: "땍",
    때ᄁ: "땎",
    때ᄀᄉ: "땏",
    때ᄂ: "땐",
    때ᄂᄌ: "땑",
    때ᄂᄒ: "땒",
    때ᄃ: "땓",
    때ᄅ: "땔",
    때ᄅᄀ: "땕",
    때ᄅᄆ: "땖",
    때ᄅᄇ: "땗",
    때ᄅᄉ: "땘",
    때ᄅᄐ: "땙",
    때ᄅᄑ: "땚",
    때ᄅᄒ: "땛",
    때ᄆ: "땜",
    때ᄇ: "땝",
    때ᄇᄉ: "땞",
    때ᄉ: "땟",
    때ᄊ: "땠",
    때ᄋ: "땡",
    때ᄌ: "땢",
    때ᄎ: "땣",
    때ᄏ: "땤",
    때ᄐ: "땥",
    때ᄑ: "땦",
    때ᄒ: "땧",
    땨: "땨",
    땨ᄀ: "땩",
    땨ᄁ: "땪",
    땨ᄀᄉ: "땫",
    땨ᄂ: "땬",
    땨ᄂᄌ: "땭",
    땨ᄂᄒ: "땮",
    땨ᄃ: "땯",
    땨ᄅ: "땰",
    땨ᄅᄀ: "땱",
    땨ᄅᄆ: "땲",
    땨ᄅᄇ: "땳",
    땨ᄅᄉ: "땴",
    땨ᄅᄐ: "땵",
    땨ᄅᄑ: "땶",
    땨ᄅᄒ: "땷",
    땨ᄆ: "땸",
    땨ᄇ: "땹",
    땨ᄇᄉ: "땺",
    땨ᄉ: "땻",
    땨ᄊ: "땼",
    땨ᄋ: "땽",
    땨ᄌ: "땾",
    땨ᄎ: "땿",
    땨ᄏ: "떀",
    땨ᄐ: "떁",
    땨ᄑ: "떂",
    땨ᄒ: "떃",
    떄: "떄",
    떄ᄀ: "떅",
    떄ᄁ: "떆",
    떄ᄀᄉ: "떇",
    떄ᄂ: "떈",
    떄ᄂᄌ: "떉",
    떄ᄂᄒ: "떊",
    떄ᄃ: "떋",
    떄ᄅ: "떌",
    떄ᄅᄀ: "떍",
    떄ᄅᄆ: "떎",
    떄ᄅᄇ: "떏",
    떄ᄅᄉ: "떐",
    떄ᄅᄐ: "떑",
    떄ᄅᄑ: "떒",
    떄ᄅᄒ: "떓",
    떄ᄆ: "떔",
    떄ᄇ: "떕",
    떄ᄇᄉ: "떖",
    떄ᄉ: "떗",
    떄ᄊ: "떘",
    떄ᄋ: "떙",
    떄ᄌ: "떚",
    떄ᄎ: "떛",
    떄ᄏ: "떜",
    떄ᄐ: "떝",
    떄ᄑ: "떞",
    떄ᄒ: "떟",
    떠: "떠",
    떠ᄀ: "떡",
    떠ᄁ: "떢",
    떠ᄀᄉ: "떣",
    떠ᄂ: "떤",
    떠ᄂᄌ: "떥",
    떠ᄂᄒ: "떦",
    떠ᄃ: "떧",
    떠ᄅ: "떨",
    떠ᄅᄀ: "떩",
    떠ᄅᄆ: "떪",
    떠ᄅᄇ: "떫",
    떠ᄅᄉ: "떬",
    떠ᄅᄐ: "떭",
    떠ᄅᄑ: "떮",
    떠ᄅᄒ: "떯",
    떠ᄆ: "떰",
    떠ᄇ: "떱",
    떠ᄇᄉ: "떲",
    떠ᄉ: "떳",
    떠ᄊ: "떴",
    떠ᄋ: "떵",
    떠ᄌ: "떶",
    떠ᄎ: "떷",
    떠ᄏ: "떸",
    떠ᄐ: "떹",
    떠ᄑ: "떺",
    떠ᄒ: "떻",
    떼: "떼",
    떼ᄀ: "떽",
    떼ᄁ: "떾",
    떼ᄀᄉ: "떿",
    떼ᄂ: "뗀",
    떼ᄂᄌ: "뗁",
    떼ᄂᄒ: "뗂",
    떼ᄃ: "뗃",
    떼ᄅ: "뗄",
    떼ᄅᄀ: "뗅",
    떼ᄅᄆ: "뗆",
    떼ᄅᄇ: "뗇",
    떼ᄅᄉ: "뗈",
    떼ᄅᄐ: "뗉",
    떼ᄅᄑ: "뗊",
    떼ᄅᄒ: "뗋",
    떼ᄆ: "뗌",
    떼ᄇ: "뗍",
    떼ᄇᄉ: "뗎",
    떼ᄉ: "뗏",
    떼ᄊ: "뗐",
    떼ᄋ: "뗑",
    떼ᄌ: "뗒",
    떼ᄎ: "뗓",
    떼ᄏ: "뗔",
    떼ᄐ: "뗕",
    떼ᄑ: "뗖",
    떼ᄒ: "뗗",
    뗘: "뗘",
    뗘ᄀ: "뗙",
    뗘ᄁ: "뗚",
    뗘ᄀᄉ: "뗛",
    뗘ᄂ: "뗜",
    뗘ᄂᄌ: "뗝",
    뗘ᄂᄒ: "뗞",
    뗘ᄃ: "뗟",
    뗘ᄅ: "뗠",
    뗘ᄅᄀ: "뗡",
    뗘ᄅᄆ: "뗢",
    뗘ᄅᄇ: "뗣",
    뗘ᄅᄉ: "뗤",
    뗘ᄅᄐ: "뗥",
    뗘ᄅᄑ: "뗦",
    뗘ᄅᄒ: "뗧",
    뗘ᄆ: "뗨",
    뗘ᄇ: "뗩",
    뗘ᄇᄉ: "뗪",
    뗘ᄉ: "뗫",
    뗘ᄊ: "뗬",
    뗘ᄋ: "뗭",
    뗘ᄌ: "뗮",
    뗘ᄎ: "뗯",
    뗘ᄏ: "뗰",
    뗘ᄐ: "뗱",
    뗘ᄑ: "뗲",
    뗘ᄒ: "뗳",
    뗴: "뗴",
    뗴ᄀ: "뗵",
    뗴ᄁ: "뗶",
    뗴ᄀᄉ: "뗷",
    뗴ᄂ: "뗸",
    뗴ᄂᄌ: "뗹",
    뗴ᄂᄒ: "뗺",
    뗴ᄃ: "뗻",
    뗴ᄅ: "뗼",
    뗴ᄅᄀ: "뗽",
    뗴ᄅᄆ: "뗾",
    뗴ᄅᄇ: "뗿",
    뗴ᄅᄉ: "똀",
    뗴ᄅᄐ: "똁",
    뗴ᄅᄑ: "똂",
    뗴ᄅᄒ: "똃",
    뗴ᄆ: "똄",
    뗴ᄇ: "똅",
    뗴ᄇᄉ: "똆",
    뗴ᄉ: "똇",
    뗴ᄊ: "똈",
    뗴ᄋ: "똉",
    뗴ᄌ: "똊",
    뗴ᄎ: "똋",
    뗴ᄏ: "똌",
    뗴ᄐ: "똍",
    뗴ᄑ: "똎",
    뗴ᄒ: "똏",
    또: "또",
    또ᄀ: "똑",
    또ᄁ: "똒",
    또ᄀᄉ: "똓",
    또ᄂ: "똔",
    또ᄂᄌ: "똕",
    또ᄂᄒ: "똖",
    또ᄃ: "똗",
    또ᄅ: "똘",
    또ᄅᄀ: "똙",
    또ᄅᄆ: "똚",
    또ᄅᄇ: "똛",
    또ᄅᄉ: "똜",
    또ᄅᄐ: "똝",
    또ᄅᄑ: "똞",
    또ᄅᄒ: "똟",
    또ᄆ: "똠",
    또ᄇ: "똡",
    또ᄇᄉ: "똢",
    또ᄉ: "똣",
    또ᄊ: "똤",
    또ᄋ: "똥",
    또ᄌ: "똦",
    또ᄎ: "똧",
    또ᄏ: "똨",
    또ᄐ: "똩",
    또ᄑ: "똪",
    또ᄒ: "똫",
    또ᅡ: "똬",
    또ᅡᄀ: "똭",
    또ᅡᄁ: "똮",
    또ᅡᄀᄉ: "똯",
    또ᅡᄂ: "똰",
    또ᅡᄂᄌ: "똱",
    또ᅡᄂᄒ: "똲",
    또ᅡᄃ: "똳",
    또ᅡᄅ: "똴",
    또ᅡᄅᄀ: "똵",
    또ᅡᄅᄆ: "똶",
    또ᅡᄅᄇ: "똷",
    또ᅡᄅᄉ: "똸",
    또ᅡᄅᄐ: "똹",
    또ᅡᄅᄑ: "똺",
    또ᅡᄅᄒ: "똻",
    또ᅡᄆ: "똼",
    또ᅡᄇ: "똽",
    또ᅡᄇᄉ: "똾",
    또ᅡᄉ: "똿",
    또ᅡᄊ: "뙀",
    또ᅡᄋ: "뙁",
    또ᅡᄌ: "뙂",
    또ᅡᄎ: "뙃",
    또ᅡᄏ: "뙄",
    또ᅡᄐ: "뙅",
    또ᅡᄑ: "뙆",
    또ᅡᄒ: "뙇",
    또ᅢ: "뙈",
    또ᅢᄀ: "뙉",
    또ᅢᄁ: "뙊",
    또ᅢᄀᄉ: "뙋",
    또ᅢᄂ: "뙌",
    또ᅢᄂᄌ: "뙍",
    또ᅢᄂᄒ: "뙎",
    또ᅢᄃ: "뙏",
    또ᅢᄅ: "뙐",
    또ᅢᄅᄀ: "뙑",
    또ᅢᄅᄆ: "뙒",
    또ᅢᄅᄇ: "뙓",
    또ᅢᄅᄉ: "뙔",
    또ᅢᄅᄐ: "뙕",
    또ᅢᄅᄑ: "뙖",
    또ᅢᄅᄒ: "뙗",
    또ᅢᄆ: "뙘",
    또ᅢᄇ: "뙙",
    또ᅢᄇᄉ: "뙚",
    또ᅢᄉ: "뙛",
    또ᅢᄊ: "뙜",
    또ᅢᄋ: "뙝",
    또ᅢᄌ: "뙞",
    또ᅢᄎ: "뙟",
    또ᅢᄏ: "뙠",
    또ᅢᄐ: "뙡",
    또ᅢᄑ: "뙢",
    또ᅢᄒ: "뙣",
    또ᅵ: "뙤",
    또ᅵᄀ: "뙥",
    또ᅵᄁ: "뙦",
    또ᅵᄀᄉ: "뙧",
    또ᅵᄂ: "뙨",
    또ᅵᄂᄌ: "뙩",
    또ᅵᄂᄒ: "뙪",
    또ᅵᄃ: "뙫",
    또ᅵᄅ: "뙬",
    또ᅵᄅᄀ: "뙭",
    또ᅵᄅᄆ: "뙮",
    또ᅵᄅᄇ: "뙯",
    또ᅵᄅᄉ: "뙰",
    또ᅵᄅᄐ: "뙱",
    또ᅵᄅᄑ: "뙲",
    또ᅵᄅᄒ: "뙳",
    또ᅵᄆ: "뙴",
    또ᅵᄇ: "뙵",
    또ᅵᄇᄉ: "뙶",
    또ᅵᄉ: "뙷",
    또ᅵᄊ: "뙸",
    또ᅵᄋ: "뙹",
    또ᅵᄌ: "뙺",
    또ᅵᄎ: "뙻",
    또ᅵᄏ: "뙼",
    또ᅵᄐ: "뙽",
    또ᅵᄑ: "뙾",
    또ᅵᄒ: "뙿",
    뚀: "뚀",
    뚀ᄀ: "뚁",
    뚀ᄁ: "뚂",
    뚀ᄀᄉ: "뚃",
    뚀ᄂ: "뚄",
    뚀ᄂᄌ: "뚅",
    뚀ᄂᄒ: "뚆",
    뚀ᄃ: "뚇",
    뚀ᄅ: "뚈",
    뚀ᄅᄀ: "뚉",
    뚀ᄅᄆ: "뚊",
    뚀ᄅᄇ: "뚋",
    뚀ᄅᄉ: "뚌",
    뚀ᄅᄐ: "뚍",
    뚀ᄅᄑ: "뚎",
    뚀ᄅᄒ: "뚏",
    뚀ᄆ: "뚐",
    뚀ᄇ: "뚑",
    뚀ᄇᄉ: "뚒",
    뚀ᄉ: "뚓",
    뚀ᄊ: "뚔",
    뚀ᄋ: "뚕",
    뚀ᄌ: "뚖",
    뚀ᄎ: "뚗",
    뚀ᄏ: "뚘",
    뚀ᄐ: "뚙",
    뚀ᄑ: "뚚",
    뚀ᄒ: "뚛",
    뚜: "뚜",
    뚜ᄀ: "뚝",
    뚜ᄁ: "뚞",
    뚜ᄀᄉ: "뚟",
    뚜ᄂ: "뚠",
    뚜ᄂᄌ: "뚡",
    뚜ᄂᄒ: "뚢",
    뚜ᄃ: "뚣",
    뚜ᄅ: "뚤",
    뚜ᄅᄀ: "뚥",
    뚜ᄅᄆ: "뚦",
    뚜ᄅᄇ: "뚧",
    뚜ᄅᄉ: "뚨",
    뚜ᄅᄐ: "뚩",
    뚜ᄅᄑ: "뚪",
    뚜ᄅᄒ: "뚫",
    뚜ᄆ: "뚬",
    뚜ᄇ: "뚭",
    뚜ᄇᄉ: "뚮",
    뚜ᄉ: "뚯",
    뚜ᄊ: "뚰",
    뚜ᄋ: "뚱",
    뚜ᄌ: "뚲",
    뚜ᄎ: "뚳",
    뚜ᄏ: "뚴",
    뚜ᄐ: "뚵",
    뚜ᄑ: "뚶",
    뚜ᄒ: "뚷",
    뚜ᅥ: "뚸",
    뚜ᅥᄀ: "뚹",
    뚜ᅥᄁ: "뚺",
    뚜ᅥᄀᄉ: "뚻",
    뚜ᅥᄂ: "뚼",
    뚜ᅥᄂᄌ: "뚽",
    뚜ᅥᄂᄒ: "뚾",
    뚜ᅥᄃ: "뚿",
    뚜ᅥᄅ: "뛀",
    뚜ᅥᄅᄀ: "뛁",
    뚜ᅥᄅᄆ: "뛂",
    뚜ᅥᄅᄇ: "뛃",
    뚜ᅥᄅᄉ: "뛄",
    뚜ᅥᄅᄐ: "뛅",
    뚜ᅥᄅᄑ: "뛆",
    뚜ᅥᄅᄒ: "뛇",
    뚜ᅥᄆ: "뛈",
    뚜ᅥᄇ: "뛉",
    뚜ᅥᄇᄉ: "뛊",
    뚜ᅥᄉ: "뛋",
    뚜ᅥᄊ: "뛌",
    뚜ᅥᄋ: "뛍",
    뚜ᅥᄌ: "뛎",
    뚜ᅥᄎ: "뛏",
    뚜ᅥᄏ: "뛐",
    뚜ᅥᄐ: "뛑",
    뚜ᅥᄑ: "뛒",
    뚜ᅥᄒ: "뛓",
    뚜ᅦ: "뛔",
    뚜ᅦᄀ: "뛕",
    뚜ᅦᄁ: "뛖",
    뚜ᅦᄀᄉ: "뛗",
    뚜ᅦᄂ: "뛘",
    뚜ᅦᄂᄌ: "뛙",
    뚜ᅦᄂᄒ: "뛚",
    뚜ᅦᄃ: "뛛",
    뚜ᅦᄅ: "뛜",
    뚜ᅦᄅᄀ: "뛝",
    뚜ᅦᄅᄆ: "뛞",
    뚜ᅦᄅᄇ: "뛟",
    뚜ᅦᄅᄉ: "뛠",
    뚜ᅦᄅᄐ: "뛡",
    뚜ᅦᄅᄑ: "뛢",
    뚜ᅦᄅᄒ: "뛣",
    뚜ᅦᄆ: "뛤",
    뚜ᅦᄇ: "뛥",
    뚜ᅦᄇᄉ: "뛦",
    뚜ᅦᄉ: "뛧",
    뚜ᅦᄊ: "뛨",
    뚜ᅦᄋ: "뛩",
    뚜ᅦᄌ: "뛪",
    뚜ᅦᄎ: "뛫",
    뚜ᅦᄏ: "뛬",
    뚜ᅦᄐ: "뛭",
    뚜ᅦᄑ: "뛮",
    뚜ᅦᄒ: "뛯",
    뚜ᅵ: "뛰",
    뚜ᅵᄀ: "뛱",
    뚜ᅵᄁ: "뛲",
    뚜ᅵᄀᄉ: "뛳",
    뚜ᅵᄂ: "뛴",
    뚜ᅵᄂᄌ: "뛵",
    뚜ᅵᄂᄒ: "뛶",
    뚜ᅵᄃ: "뛷",
    뚜ᅵᄅ: "뛸",
    뚜ᅵᄅᄀ: "뛹",
    뚜ᅵᄅᄆ: "뛺",
    뚜ᅵᄅᄇ: "뛻",
    뚜ᅵᄅᄉ: "뛼",
    뚜ᅵᄅᄐ: "뛽",
    뚜ᅵᄅᄑ: "뛾",
    뚜ᅵᄅᄒ: "뛿",
    뚜ᅵᄆ: "뜀",
    뚜ᅵᄇ: "뜁",
    뚜ᅵᄇᄉ: "뜂",
    뚜ᅵᄉ: "뜃",
    뚜ᅵᄊ: "뜄",
    뚜ᅵᄋ: "뜅",
    뚜ᅵᄌ: "뜆",
    뚜ᅵᄎ: "뜇",
    뚜ᅵᄏ: "뜈",
    뚜ᅵᄐ: "뜉",
    뚜ᅵᄑ: "뜊",
    뚜ᅵᄒ: "뜋",
    뜌: "뜌",
    뜌ᄀ: "뜍",
    뜌ᄁ: "뜎",
    뜌ᄀᄉ: "뜏",
    뜌ᄂ: "뜐",
    뜌ᄂᄌ: "뜑",
    뜌ᄂᄒ: "뜒",
    뜌ᄃ: "뜓",
    뜌ᄅ: "뜔",
    뜌ᄅᄀ: "뜕",
    뜌ᄅᄆ: "뜖",
    뜌ᄅᄇ: "뜗",
    뜌ᄅᄉ: "뜘",
    뜌ᄅᄐ: "뜙",
    뜌ᄅᄑ: "뜚",
    뜌ᄅᄒ: "뜛",
    뜌ᄆ: "뜜",
    뜌ᄇ: "뜝",
    뜌ᄇᄉ: "뜞",
    뜌ᄉ: "뜟",
    뜌ᄊ: "뜠",
    뜌ᄋ: "뜡",
    뜌ᄌ: "뜢",
    뜌ᄎ: "뜣",
    뜌ᄏ: "뜤",
    뜌ᄐ: "뜥",
    뜌ᄑ: "뜦",
    뜌ᄒ: "뜧",
    뜨: "뜨",
    뜨ᄀ: "뜩",
    뜨ᄁ: "뜪",
    뜨ᄀᄉ: "뜫",
    뜨ᄂ: "뜬",
    뜨ᄂᄌ: "뜭",
    뜨ᄂᄒ: "뜮",
    뜨ᄃ: "뜯",
    뜨ᄅ: "뜰",
    뜨ᄅᄀ: "뜱",
    뜨ᄅᄆ: "뜲",
    뜨ᄅᄇ: "뜳",
    뜨ᄅᄉ: "뜴",
    뜨ᄅᄐ: "뜵",
    뜨ᄅᄑ: "뜶",
    뜨ᄅᄒ: "뜷",
    뜨ᄆ: "뜸",
    뜨ᄇ: "뜹",
    뜨ᄇᄉ: "뜺",
    뜨ᄉ: "뜻",
    뜨ᄊ: "뜼",
    뜨ᄋ: "뜽",
    뜨ᄌ: "뜾",
    뜨ᄎ: "뜿",
    뜨ᄏ: "띀",
    뜨ᄐ: "띁",
    뜨ᄑ: "띂",
    뜨ᄒ: "띃",
    뜨ᅵ: "띄",
    뜨ᅵᄀ: "띅",
    뜨ᅵᄁ: "띆",
    뜨ᅵᄀᄉ: "띇",
    뜨ᅵᄂ: "띈",
    뜨ᅵᄂᄌ: "띉",
    뜨ᅵᄂᄒ: "띊",
    뜨ᅵᄃ: "띋",
    뜨ᅵᄅ: "띌",
    뜨ᅵᄅᄀ: "띍",
    뜨ᅵᄅᄆ: "띎",
    뜨ᅵᄅᄇ: "띏",
    뜨ᅵᄅᄉ: "띐",
    뜨ᅵᄅᄐ: "띑",
    뜨ᅵᄅᄑ: "띒",
    뜨ᅵᄅᄒ: "띓",
    뜨ᅵᄆ: "띔",
    뜨ᅵᄇ: "띕",
    뜨ᅵᄇᄉ: "띖",
    뜨ᅵᄉ: "띗",
    뜨ᅵᄊ: "띘",
    뜨ᅵᄋ: "띙",
    뜨ᅵᄌ: "띚",
    뜨ᅵᄎ: "띛",
    뜨ᅵᄏ: "띜",
    뜨ᅵᄐ: "띝",
    뜨ᅵᄑ: "띞",
    뜨ᅵᄒ: "띟",
    띠: "띠",
    띠ᄀ: "띡",
    띠ᄁ: "띢",
    띠ᄀᄉ: "띣",
    띠ᄂ: "띤",
    띠ᄂᄌ: "띥",
    띠ᄂᄒ: "띦",
    띠ᄃ: "띧",
    띠ᄅ: "띨",
    띠ᄅᄀ: "띩",
    띠ᄅᄆ: "띪",
    띠ᄅᄇ: "띫",
    띠ᄅᄉ: "띬",
    띠ᄅᄐ: "띭",
    띠ᄅᄑ: "띮",
    띠ᄅᄒ: "띯",
    띠ᄆ: "띰",
    띠ᄇ: "띱",
    띠ᄇᄉ: "띲",
    띠ᄉ: "띳",
    띠ᄊ: "띴",
    띠ᄋ: "띵",
    띠ᄌ: "띶",
    띠ᄎ: "띷",
    띠ᄏ: "띸",
    띠ᄐ: "띹",
    띠ᄑ: "띺",
    띠ᄒ: "띻",
    라: "라",
    라ᄀ: "락",
    라ᄁ: "띾",
    라ᄀᄉ: "띿",
    라ᄂ: "란",
    라ᄂᄌ: "랁",
    라ᄂᄒ: "랂",
    라ᄃ: "랃",
    라ᄅ: "랄",
    라ᄅᄀ: "랅",
    라ᄅᄆ: "랆",
    라ᄅᄇ: "랇",
    라ᄅᄉ: "랈",
    라ᄅᄐ: "랉",
    라ᄅᄑ: "랊",
    라ᄅᄒ: "랋",
    라ᄆ: "람",
    라ᄇ: "랍",
    라ᄇᄉ: "랎",
    라ᄉ: "랏",
    라ᄊ: "랐",
    라ᄋ: "랑",
    라ᄌ: "랒",
    라ᄎ: "랓",
    라ᄏ: "랔",
    라ᄐ: "랕",
    라ᄑ: "랖",
    라ᄒ: "랗",
    래: "래",
    래ᄀ: "랙",
    래ᄁ: "랚",
    래ᄀᄉ: "랛",
    래ᄂ: "랜",
    래ᄂᄌ: "랝",
    래ᄂᄒ: "랞",
    래ᄃ: "랟",
    래ᄅ: "랠",
    래ᄅᄀ: "랡",
    래ᄅᄆ: "랢",
    래ᄅᄇ: "랣",
    래ᄅᄉ: "랤",
    래ᄅᄐ: "랥",
    래ᄅᄑ: "랦",
    래ᄅᄒ: "랧",
    래ᄆ: "램",
    래ᄇ: "랩",
    래ᄇᄉ: "랪",
    래ᄉ: "랫",
    래ᄊ: "랬",
    래ᄋ: "랭",
    래ᄌ: "랮",
    래ᄎ: "랯",
    래ᄏ: "랰",
    래ᄐ: "랱",
    래ᄑ: "랲",
    래ᄒ: "랳",
    랴: "랴",
    랴ᄀ: "략",
    랴ᄁ: "랶",
    랴ᄀᄉ: "랷",
    랴ᄂ: "랸",
    랴ᄂᄌ: "랹",
    랴ᄂᄒ: "랺",
    랴ᄃ: "랻",
    랴ᄅ: "랼",
    랴ᄅᄀ: "랽",
    랴ᄅᄆ: "랾",
    랴ᄅᄇ: "랿",
    랴ᄅᄉ: "럀",
    랴ᄅᄐ: "럁",
    랴ᄅᄑ: "럂",
    랴ᄅᄒ: "럃",
    랴ᄆ: "럄",
    랴ᄇ: "럅",
    랴ᄇᄉ: "럆",
    랴ᄉ: "럇",
    랴ᄊ: "럈",
    랴ᄋ: "량",
    랴ᄌ: "럊",
    랴ᄎ: "럋",
    랴ᄏ: "럌",
    랴ᄐ: "럍",
    랴ᄑ: "럎",
    랴ᄒ: "럏",
    럐: "럐",
    럐ᄀ: "럑",
    럐ᄁ: "럒",
    럐ᄀᄉ: "럓",
    럐ᄂ: "럔",
    럐ᄂᄌ: "럕",
    럐ᄂᄒ: "럖",
    럐ᄃ: "럗",
    럐ᄅ: "럘",
    럐ᄅᄀ: "럙",
    럐ᄅᄆ: "럚",
    럐ᄅᄇ: "럛",
    럐ᄅᄉ: "럜",
    럐ᄅᄐ: "럝",
    럐ᄅᄑ: "럞",
    럐ᄅᄒ: "럟",
    럐ᄆ: "럠",
    럐ᄇ: "럡",
    럐ᄇᄉ: "럢",
    럐ᄉ: "럣",
    럐ᄊ: "럤",
    럐ᄋ: "럥",
    럐ᄌ: "럦",
    럐ᄎ: "럧",
    럐ᄏ: "럨",
    럐ᄐ: "럩",
    럐ᄑ: "럪",
    럐ᄒ: "럫",
    러: "러",
    러ᄀ: "럭",
    러ᄁ: "럮",
    러ᄀᄉ: "럯",
    러ᄂ: "런",
    러ᄂᄌ: "럱",
    러ᄂᄒ: "럲",
    러ᄃ: "럳",
    러ᄅ: "럴",
    러ᄅᄀ: "럵",
    러ᄅᄆ: "럶",
    러ᄅᄇ: "럷",
    러ᄅᄉ: "럸",
    러ᄅᄐ: "럹",
    러ᄅᄑ: "럺",
    러ᄅᄒ: "럻",
    러ᄆ: "럼",
    러ᄇ: "럽",
    러ᄇᄉ: "럾",
    러ᄉ: "럿",
    러ᄊ: "렀",
    러ᄋ: "렁",
    러ᄌ: "렂",
    러ᄎ: "렃",
    러ᄏ: "렄",
    러ᄐ: "렅",
    러ᄑ: "렆",
    러ᄒ: "렇",
    레: "레",
    레ᄀ: "렉",
    레ᄁ: "렊",
    레ᄀᄉ: "렋",
    레ᄂ: "렌",
    레ᄂᄌ: "렍",
    레ᄂᄒ: "렎",
    레ᄃ: "렏",
    레ᄅ: "렐",
    레ᄅᄀ: "렑",
    레ᄅᄆ: "렒",
    레ᄅᄇ: "렓",
    레ᄅᄉ: "렔",
    레ᄅᄐ: "렕",
    레ᄅᄑ: "렖",
    레ᄅᄒ: "렗",
    레ᄆ: "렘",
    레ᄇ: "렙",
    레ᄇᄉ: "렚",
    레ᄉ: "렛",
    레ᄊ: "렜",
    레ᄋ: "렝",
    레ᄌ: "렞",
    레ᄎ: "렟",
    레ᄏ: "렠",
    레ᄐ: "렡",
    레ᄑ: "렢",
    레ᄒ: "렣",
    려: "려",
    려ᄀ: "력",
    려ᄁ: "렦",
    려ᄀᄉ: "렧",
    려ᄂ: "련",
    려ᄂᄌ: "렩",
    려ᄂᄒ: "렪",
    려ᄃ: "렫",
    려ᄅ: "렬",
    려ᄅᄀ: "렭",
    려ᄅᄆ: "렮",
    려ᄅᄇ: "렯",
    려ᄅᄉ: "렰",
    려ᄅᄐ: "렱",
    려ᄅᄑ: "렲",
    려ᄅᄒ: "렳",
    려ᄆ: "렴",
    려ᄇ: "렵",
    려ᄇᄉ: "렶",
    려ᄉ: "렷",
    려ᄊ: "렸",
    려ᄋ: "령",
    려ᄌ: "렺",
    려ᄎ: "렻",
    려ᄏ: "렼",
    려ᄐ: "렽",
    려ᄑ: "렾",
    려ᄒ: "렿",
    례: "례",
    례ᄀ: "롁",
    례ᄁ: "롂",
    례ᄀᄉ: "롃",
    례ᄂ: "롄",
    례ᄂᄌ: "롅",
    례ᄂᄒ: "롆",
    례ᄃ: "롇",
    례ᄅ: "롈",
    례ᄅᄀ: "롉",
    례ᄅᄆ: "롊",
    례ᄅᄇ: "롋",
    례ᄅᄉ: "롌",
    례ᄅᄐ: "롍",
    례ᄅᄑ: "롎",
    례ᄅᄒ: "롏",
    례ᄆ: "롐",
    례ᄇ: "롑",
    례ᄇᄉ: "롒",
    례ᄉ: "롓",
    례ᄊ: "롔",
    례ᄋ: "롕",
    례ᄌ: "롖",
    례ᄎ: "롗",
    례ᄏ: "롘",
    례ᄐ: "롙",
    례ᄑ: "롚",
    례ᄒ: "롛",
    로: "로",
    로ᄀ: "록",
    로ᄁ: "롞",
    로ᄀᄉ: "롟",
    로ᄂ: "론",
    로ᄂᄌ: "롡",
    로ᄂᄒ: "롢",
    로ᄃ: "롣",
    로ᄅ: "롤",
    로ᄅᄀ: "롥",
    로ᄅᄆ: "롦",
    로ᄅᄇ: "롧",
    로ᄅᄉ: "롨",
    로ᄅᄐ: "롩",
    로ᄅᄑ: "롪",
    로ᄅᄒ: "롫",
    로ᄆ: "롬",
    로ᄇ: "롭",
    로ᄇᄉ: "롮",
    로ᄉ: "롯",
    로ᄊ: "롰",
    로ᄋ: "롱",
    로ᄌ: "롲",
    로ᄎ: "롳",
    로ᄏ: "롴",
    로ᄐ: "롵",
    로ᄑ: "롶",
    로ᄒ: "롷",
    로ᅡ: "롸",
    로ᅡᄀ: "롹",
    로ᅡᄁ: "롺",
    로ᅡᄀᄉ: "롻",
    로ᅡᄂ: "롼",
    로ᅡᄂᄌ: "롽",
    로ᅡᄂᄒ: "롾",
    로ᅡᄃ: "롿",
    로ᅡᄅ: "뢀",
    로ᅡᄅᄀ: "뢁",
    로ᅡᄅᄆ: "뢂",
    로ᅡᄅᄇ: "뢃",
    로ᅡᄅᄉ: "뢄",
    로ᅡᄅᄐ: "뢅",
    로ᅡᄅᄑ: "뢆",
    로ᅡᄅᄒ: "뢇",
    로ᅡᄆ: "뢈",
    로ᅡᄇ: "뢉",
    로ᅡᄇᄉ: "뢊",
    로ᅡᄉ: "뢋",
    로ᅡᄊ: "뢌",
    로ᅡᄋ: "뢍",
    로ᅡᄌ: "뢎",
    로ᅡᄎ: "뢏",
    로ᅡᄏ: "뢐",
    로ᅡᄐ: "뢑",
    로ᅡᄑ: "뢒",
    로ᅡᄒ: "뢓",
    로ᅢ: "뢔",
    로ᅢᄀ: "뢕",
    로ᅢᄁ: "뢖",
    로ᅢᄀᄉ: "뢗",
    로ᅢᄂ: "뢘",
    로ᅢᄂᄌ: "뢙",
    로ᅢᄂᄒ: "뢚",
    로ᅢᄃ: "뢛",
    로ᅢᄅ: "뢜",
    로ᅢᄅᄀ: "뢝",
    로ᅢᄅᄆ: "뢞",
    로ᅢᄅᄇ: "뢟",
    로ᅢᄅᄉ: "뢠",
    로ᅢᄅᄐ: "뢡",
    로ᅢᄅᄑ: "뢢",
    로ᅢᄅᄒ: "뢣",
    로ᅢᄆ: "뢤",
    로ᅢᄇ: "뢥",
    로ᅢᄇᄉ: "뢦",
    로ᅢᄉ: "뢧",
    로ᅢᄊ: "뢨",
    로ᅢᄋ: "뢩",
    로ᅢᄌ: "뢪",
    로ᅢᄎ: "뢫",
    로ᅢᄏ: "뢬",
    로ᅢᄐ: "뢭",
    로ᅢᄑ: "뢮",
    로ᅢᄒ: "뢯",
    로ᅵ: "뢰",
    로ᅵᄀ: "뢱",
    로ᅵᄁ: "뢲",
    로ᅵᄀᄉ: "뢳",
    로ᅵᄂ: "뢴",
    로ᅵᄂᄌ: "뢵",
    로ᅵᄂᄒ: "뢶",
    로ᅵᄃ: "뢷",
    로ᅵᄅ: "뢸",
    로ᅵᄅᄀ: "뢹",
    로ᅵᄅᄆ: "뢺",
    로ᅵᄅᄇ: "뢻",
    로ᅵᄅᄉ: "뢼",
    로ᅵᄅᄐ: "뢽",
    로ᅵᄅᄑ: "뢾",
    로ᅵᄅᄒ: "뢿",
    로ᅵᄆ: "룀",
    로ᅵᄇ: "룁",
    로ᅵᄇᄉ: "룂",
    로ᅵᄉ: "룃",
    로ᅵᄊ: "룄",
    로ᅵᄋ: "룅",
    로ᅵᄌ: "룆",
    로ᅵᄎ: "룇",
    로ᅵᄏ: "룈",
    로ᅵᄐ: "룉",
    로ᅵᄑ: "룊",
    로ᅵᄒ: "룋",
    료: "료",
    료ᄀ: "룍",
    료ᄁ: "룎",
    료ᄀᄉ: "룏",
    료ᄂ: "룐",
    료ᄂᄌ: "룑",
    료ᄂᄒ: "룒",
    료ᄃ: "룓",
    료ᄅ: "룔",
    료ᄅᄀ: "룕",
    료ᄅᄆ: "룖",
    료ᄅᄇ: "룗",
    료ᄅᄉ: "룘",
    료ᄅᄐ: "룙",
    료ᄅᄑ: "룚",
    료ᄅᄒ: "룛",
    료ᄆ: "룜",
    료ᄇ: "룝",
    료ᄇᄉ: "룞",
    료ᄉ: "룟",
    료ᄊ: "룠",
    료ᄋ: "룡",
    료ᄌ: "룢",
    료ᄎ: "룣",
    료ᄏ: "룤",
    료ᄐ: "룥",
    료ᄑ: "룦",
    료ᄒ: "룧",
    루: "루",
    루ᄀ: "룩",
    루ᄁ: "룪",
    루ᄀᄉ: "룫",
    루ᄂ: "룬",
    루ᄂᄌ: "룭",
    루ᄂᄒ: "룮",
    루ᄃ: "룯",
    루ᄅ: "룰",
    루ᄅᄀ: "룱",
    루ᄅᄆ: "룲",
    루ᄅᄇ: "룳",
    루ᄅᄉ: "룴",
    루ᄅᄐ: "룵",
    루ᄅᄑ: "룶",
    루ᄅᄒ: "룷",
    루ᄆ: "룸",
    루ᄇ: "룹",
    루ᄇᄉ: "룺",
    루ᄉ: "룻",
    루ᄊ: "룼",
    루ᄋ: "룽",
    루ᄌ: "룾",
    루ᄎ: "룿",
    루ᄏ: "뤀",
    루ᄐ: "뤁",
    루ᄑ: "뤂",
    루ᄒ: "뤃",
    루ᅥ: "뤄",
    루ᅥᄀ: "뤅",
    루ᅥᄁ: "뤆",
    루ᅥᄀᄉ: "뤇",
    루ᅥᄂ: "뤈",
    루ᅥᄂᄌ: "뤉",
    루ᅥᄂᄒ: "뤊",
    루ᅥᄃ: "뤋",
    루ᅥᄅ: "뤌",
    루ᅥᄅᄀ: "뤍",
    루ᅥᄅᄆ: "뤎",
    루ᅥᄅᄇ: "뤏",
    루ᅥᄅᄉ: "뤐",
    루ᅥᄅᄐ: "뤑",
    루ᅥᄅᄑ: "뤒",
    루ᅥᄅᄒ: "뤓",
    루ᅥᄆ: "뤔",
    루ᅥᄇ: "뤕",
    루ᅥᄇᄉ: "뤖",
    루ᅥᄉ: "뤗",
    루ᅥᄊ: "뤘",
    루ᅥᄋ: "뤙",
    루ᅥᄌ: "뤚",
    루ᅥᄎ: "뤛",
    루ᅥᄏ: "뤜",
    루ᅥᄐ: "뤝",
    루ᅥᄑ: "뤞",
    루ᅥᄒ: "뤟",
    루ᅦ: "뤠",
    루ᅦᄀ: "뤡",
    루ᅦᄁ: "뤢",
    루ᅦᄀᄉ: "뤣",
    루ᅦᄂ: "뤤",
    루ᅦᄂᄌ: "뤥",
    루ᅦᄂᄒ: "뤦",
    루ᅦᄃ: "뤧",
    루ᅦᄅ: "뤨",
    루ᅦᄅᄀ: "뤩",
    루ᅦᄅᄆ: "뤪",
    루ᅦᄅᄇ: "뤫",
    루ᅦᄅᄉ: "뤬",
    루ᅦᄅᄐ: "뤭",
    루ᅦᄅᄑ: "뤮",
    루ᅦᄅᄒ: "뤯",
    루ᅦᄆ: "뤰",
    루ᅦᄇ: "뤱",
    루ᅦᄇᄉ: "뤲",
    루ᅦᄉ: "뤳",
    루ᅦᄊ: "뤴",
    루ᅦᄋ: "뤵",
    루ᅦᄌ: "뤶",
    루ᅦᄎ: "뤷",
    루ᅦᄏ: "뤸",
    루ᅦᄐ: "뤹",
    루ᅦᄑ: "뤺",
    루ᅦᄒ: "뤻",
    루ᅵ: "뤼",
    루ᅵᄀ: "뤽",
    루ᅵᄁ: "뤾",
    루ᅵᄀᄉ: "뤿",
    루ᅵᄂ: "륀",
    루ᅵᄂᄌ: "륁",
    루ᅵᄂᄒ: "륂",
    루ᅵᄃ: "륃",
    루ᅵᄅ: "륄",
    루ᅵᄅᄀ: "륅",
    루ᅵᄅᄆ: "륆",
    루ᅵᄅᄇ: "륇",
    루ᅵᄅᄉ: "륈",
    루ᅵᄅᄐ: "륉",
    루ᅵᄅᄑ: "륊",
    루ᅵᄅᄒ: "륋",
    루ᅵᄆ: "륌",
    루ᅵᄇ: "륍",
    루ᅵᄇᄉ: "륎",
    루ᅵᄉ: "륏",
    루ᅵᄊ: "륐",
    루ᅵᄋ: "륑",
    루ᅵᄌ: "륒",
    루ᅵᄎ: "륓",
    루ᅵᄏ: "륔",
    루ᅵᄐ: "륕",
    루ᅵᄑ: "륖",
    루ᅵᄒ: "륗",
    류: "류",
    류ᄀ: "륙",
    류ᄁ: "륚",
    류ᄀᄉ: "륛",
    류ᄂ: "륜",
    류ᄂᄌ: "륝",
    류ᄂᄒ: "륞",
    류ᄃ: "륟",
    류ᄅ: "률",
    류ᄅᄀ: "륡",
    류ᄅᄆ: "륢",
    류ᄅᄇ: "륣",
    류ᄅᄉ: "륤",
    류ᄅᄐ: "륥",
    류ᄅᄑ: "륦",
    류ᄅᄒ: "륧",
    류ᄆ: "륨",
    류ᄇ: "륩",
    류ᄇᄉ: "륪",
    류ᄉ: "륫",
    류ᄊ: "륬",
    류ᄋ: "륭",
    류ᄌ: "륮",
    류ᄎ: "륯",
    류ᄏ: "륰",
    류ᄐ: "륱",
    류ᄑ: "륲",
    류ᄒ: "륳",
    르: "르",
    르ᄀ: "륵",
    르ᄁ: "륶",
    르ᄀᄉ: "륷",
    르ᄂ: "른",
    르ᄂᄌ: "륹",
    르ᄂᄒ: "륺",
    르ᄃ: "륻",
    르ᄅ: "를",
    르ᄅᄀ: "륽",
    르ᄅᄆ: "륾",
    르ᄅᄇ: "륿",
    르ᄅᄉ: "릀",
    르ᄅᄐ: "릁",
    르ᄅᄑ: "릂",
    르ᄅᄒ: "릃",
    르ᄆ: "름",
    르ᄇ: "릅",
    르ᄇᄉ: "릆",
    르ᄉ: "릇",
    르ᄊ: "릈",
    르ᄋ: "릉",
    르ᄌ: "릊",
    르ᄎ: "릋",
    르ᄏ: "릌",
    르ᄐ: "릍",
    르ᄑ: "릎",
    르ᄒ: "릏",
    르ᅵ: "릐",
    르ᅵᄀ: "릑",
    르ᅵᄁ: "릒",
    르ᅵᄀᄉ: "릓",
    르ᅵᄂ: "릔",
    르ᅵᄂᄌ: "릕",
    르ᅵᄂᄒ: "릖",
    르ᅵᄃ: "릗",
    르ᅵᄅ: "릘",
    르ᅵᄅᄀ: "릙",
    르ᅵᄅᄆ: "릚",
    르ᅵᄅᄇ: "릛",
    르ᅵᄅᄉ: "릜",
    르ᅵᄅᄐ: "릝",
    르ᅵᄅᄑ: "릞",
    르ᅵᄅᄒ: "릟",
    르ᅵᄆ: "릠",
    르ᅵᄇ: "릡",
    르ᅵᄇᄉ: "릢",
    르ᅵᄉ: "릣",
    르ᅵᄊ: "릤",
    르ᅵᄋ: "릥",
    르ᅵᄌ: "릦",
    르ᅵᄎ: "릧",
    르ᅵᄏ: "릨",
    르ᅵᄐ: "릩",
    르ᅵᄑ: "릪",
    르ᅵᄒ: "릫",
    리: "리",
    리ᄀ: "릭",
    리ᄁ: "릮",
    리ᄀᄉ: "릯",
    리ᄂ: "린",
    리ᄂᄌ: "릱",
    리ᄂᄒ: "릲",
    리ᄃ: "릳",
    리ᄅ: "릴",
    리ᄅᄀ: "릵",
    리ᄅᄆ: "릶",
    리ᄅᄇ: "릷",
    리ᄅᄉ: "릸",
    리ᄅᄐ: "릹",
    리ᄅᄑ: "릺",
    리ᄅᄒ: "릻",
    리ᄆ: "림",
    리ᄇ: "립",
    리ᄇᄉ: "릾",
    리ᄉ: "릿",
    리ᄊ: "맀",
    리ᄋ: "링",
    리ᄌ: "맂",
    리ᄎ: "맃",
    리ᄏ: "맄",
    리ᄐ: "맅",
    리ᄑ: "맆",
    리ᄒ: "맇",
    마: "마",
    마ᄀ: "막",
    마ᄁ: "맊",
    마ᄀᄉ: "맋",
    마ᄂ: "만",
    마ᄂᄌ: "맍",
    마ᄂᄒ: "많",
    마ᄃ: "맏",
    마ᄅ: "말",
    마ᄅᄀ: "맑",
    마ᄅᄆ: "맒",
    마ᄅᄇ: "맓",
    마ᄅᄉ: "맔",
    마ᄅᄐ: "맕",
    마ᄅᄑ: "맖",
    마ᄅᄒ: "맗",
    마ᄆ: "맘",
    마ᄇ: "맙",
    마ᄇᄉ: "맚",
    마ᄉ: "맛",
    마ᄊ: "맜",
    마ᄋ: "망",
    마ᄌ: "맞",
    마ᄎ: "맟",
    마ᄏ: "맠",
    마ᄐ: "맡",
    마ᄑ: "맢",
    마ᄒ: "맣",
    매: "매",
    매ᄀ: "맥",
    매ᄁ: "맦",
    매ᄀᄉ: "맧",
    매ᄂ: "맨",
    매ᄂᄌ: "맩",
    매ᄂᄒ: "맪",
    매ᄃ: "맫",
    매ᄅ: "맬",
    매ᄅᄀ: "맭",
    매ᄅᄆ: "맮",
    매ᄅᄇ: "맯",
    매ᄅᄉ: "맰",
    매ᄅᄐ: "맱",
    매ᄅᄑ: "맲",
    매ᄅᄒ: "맳",
    매ᄆ: "맴",
    매ᄇ: "맵",
    매ᄇᄉ: "맶",
    매ᄉ: "맷",
    매ᄊ: "맸",
    매ᄋ: "맹",
    매ᄌ: "맺",
    매ᄎ: "맻",
    매ᄏ: "맼",
    매ᄐ: "맽",
    매ᄑ: "맾",
    매ᄒ: "맿",
    먀: "먀",
    먀ᄀ: "먁",
    먀ᄁ: "먂",
    먀ᄀᄉ: "먃",
    먀ᄂ: "먄",
    먀ᄂᄌ: "먅",
    먀ᄂᄒ: "먆",
    먀ᄃ: "먇",
    먀ᄅ: "먈",
    먀ᄅᄀ: "먉",
    먀ᄅᄆ: "먊",
    먀ᄅᄇ: "먋",
    먀ᄅᄉ: "먌",
    먀ᄅᄐ: "먍",
    먀ᄅᄑ: "먎",
    먀ᄅᄒ: "먏",
    먀ᄆ: "먐",
    먀ᄇ: "먑",
    먀ᄇᄉ: "먒",
    먀ᄉ: "먓",
    먀ᄊ: "먔",
    먀ᄋ: "먕",
    먀ᄌ: "먖",
    먀ᄎ: "먗",
    먀ᄏ: "먘",
    먀ᄐ: "먙",
    먀ᄑ: "먚",
    먀ᄒ: "먛",
    먜: "먜",
    먜ᄀ: "먝",
    먜ᄁ: "먞",
    먜ᄀᄉ: "먟",
    먜ᄂ: "먠",
    먜ᄂᄌ: "먡",
    먜ᄂᄒ: "먢",
    먜ᄃ: "먣",
    먜ᄅ: "먤",
    먜ᄅᄀ: "먥",
    먜ᄅᄆ: "먦",
    먜ᄅᄇ: "먧",
    먜ᄅᄉ: "먨",
    먜ᄅᄐ: "먩",
    먜ᄅᄑ: "먪",
    먜ᄅᄒ: "먫",
    먜ᄆ: "먬",
    먜ᄇ: "먭",
    먜ᄇᄉ: "먮",
    먜ᄉ: "먯",
    먜ᄊ: "먰",
    먜ᄋ: "먱",
    먜ᄌ: "먲",
    먜ᄎ: "먳",
    먜ᄏ: "먴",
    먜ᄐ: "먵",
    먜ᄑ: "먶",
    먜ᄒ: "먷",
    머: "머",
    머ᄀ: "먹",
    머ᄁ: "먺",
    머ᄀᄉ: "먻",
    머ᄂ: "먼",
    머ᄂᄌ: "먽",
    머ᄂᄒ: "먾",
    머ᄃ: "먿",
    머ᄅ: "멀",
    머ᄅᄀ: "멁",
    머ᄅᄆ: "멂",
    머ᄅᄇ: "멃",
    머ᄅᄉ: "멄",
    머ᄅᄐ: "멅",
    머ᄅᄑ: "멆",
    머ᄅᄒ: "멇",
    머ᄆ: "멈",
    머ᄇ: "멉",
    머ᄇᄉ: "멊",
    머ᄉ: "멋",
    머ᄊ: "멌",
    머ᄋ: "멍",
    머ᄌ: "멎",
    머ᄎ: "멏",
    머ᄏ: "멐",
    머ᄐ: "멑",
    머ᄑ: "멒",
    머ᄒ: "멓",
    메: "메",
    메ᄀ: "멕",
    메ᄁ: "멖",
    메ᄀᄉ: "멗",
    메ᄂ: "멘",
    메ᄂᄌ: "멙",
    메ᄂᄒ: "멚",
    메ᄃ: "멛",
    메ᄅ: "멜",
    메ᄅᄀ: "멝",
    메ᄅᄆ: "멞",
    메ᄅᄇ: "멟",
    메ᄅᄉ: "멠",
    메ᄅᄐ: "멡",
    메ᄅᄑ: "멢",
    메ᄅᄒ: "멣",
    메ᄆ: "멤",
    메ᄇ: "멥",
    메ᄇᄉ: "멦",
    메ᄉ: "멧",
    메ᄊ: "멨",
    메ᄋ: "멩",
    메ᄌ: "멪",
    메ᄎ: "멫",
    메ᄏ: "멬",
    메ᄐ: "멭",
    메ᄑ: "멮",
    메ᄒ: "멯",
    며: "며",
    며ᄀ: "멱",
    며ᄁ: "멲",
    며ᄀᄉ: "멳",
    며ᄂ: "면",
    며ᄂᄌ: "멵",
    며ᄂᄒ: "멶",
    며ᄃ: "멷",
    며ᄅ: "멸",
    며ᄅᄀ: "멹",
    며ᄅᄆ: "멺",
    며ᄅᄇ: "멻",
    며ᄅᄉ: "멼",
    며ᄅᄐ: "멽",
    며ᄅᄑ: "멾",
    며ᄅᄒ: "멿",
    며ᄆ: "몀",
    며ᄇ: "몁",
    며ᄇᄉ: "몂",
    며ᄉ: "몃",
    며ᄊ: "몄",
    며ᄋ: "명",
    며ᄌ: "몆",
    며ᄎ: "몇",
    며ᄏ: "몈",
    며ᄐ: "몉",
    며ᄑ: "몊",
    며ᄒ: "몋",
    몌: "몌",
    몌ᄀ: "몍",
    몌ᄁ: "몎",
    몌ᄀᄉ: "몏",
    몌ᄂ: "몐",
    몌ᄂᄌ: "몑",
    몌ᄂᄒ: "몒",
    몌ᄃ: "몓",
    몌ᄅ: "몔",
    몌ᄅᄀ: "몕",
    몌ᄅᄆ: "몖",
    몌ᄅᄇ: "몗",
    몌ᄅᄉ: "몘",
    몌ᄅᄐ: "몙",
    몌ᄅᄑ: "몚",
    몌ᄅᄒ: "몛",
    몌ᄆ: "몜",
    몌ᄇ: "몝",
    몌ᄇᄉ: "몞",
    몌ᄉ: "몟",
    몌ᄊ: "몠",
    몌ᄋ: "몡",
    몌ᄌ: "몢",
    몌ᄎ: "몣",
    몌ᄏ: "몤",
    몌ᄐ: "몥",
    몌ᄑ: "몦",
    몌ᄒ: "몧",
    모: "모",
    모ᄀ: "목",
    모ᄁ: "몪",
    모ᄀᄉ: "몫",
    모ᄂ: "몬",
    모ᄂᄌ: "몭",
    모ᄂᄒ: "몮",
    모ᄃ: "몯",
    모ᄅ: "몰",
    모ᄅᄀ: "몱",
    모ᄅᄆ: "몲",
    모ᄅᄇ: "몳",
    모ᄅᄉ: "몴",
    모ᄅᄐ: "몵",
    모ᄅᄑ: "몶",
    모ᄅᄒ: "몷",
    모ᄆ: "몸",
    모ᄇ: "몹",
    모ᄇᄉ: "몺",
    모ᄉ: "못",
    모ᄊ: "몼",
    모ᄋ: "몽",
    모ᄌ: "몾",
    모ᄎ: "몿",
    모ᄏ: "뫀",
    모ᄐ: "뫁",
    모ᄑ: "뫂",
    모ᄒ: "뫃",
    모ᅡ: "뫄",
    모ᅡᄀ: "뫅",
    모ᅡᄁ: "뫆",
    모ᅡᄀᄉ: "뫇",
    모ᅡᄂ: "뫈",
    모ᅡᄂᄌ: "뫉",
    모ᅡᄂᄒ: "뫊",
    모ᅡᄃ: "뫋",
    모ᅡᄅ: "뫌",
    모ᅡᄅᄀ: "뫍",
    모ᅡᄅᄆ: "뫎",
    모ᅡᄅᄇ: "뫏",
    모ᅡᄅᄉ: "뫐",
    모ᅡᄅᄐ: "뫑",
    모ᅡᄅᄑ: "뫒",
    모ᅡᄅᄒ: "뫓",
    모ᅡᄆ: "뫔",
    모ᅡᄇ: "뫕",
    모ᅡᄇᄉ: "뫖",
    모ᅡᄉ: "뫗",
    모ᅡᄊ: "뫘",
    모ᅡᄋ: "뫙",
    모ᅡᄌ: "뫚",
    모ᅡᄎ: "뫛",
    모ᅡᄏ: "뫜",
    모ᅡᄐ: "뫝",
    모ᅡᄑ: "뫞",
    모ᅡᄒ: "뫟",
    모ᅢ: "뫠",
    모ᅢᄀ: "뫡",
    모ᅢᄁ: "뫢",
    모ᅢᄀᄉ: "뫣",
    모ᅢᄂ: "뫤",
    모ᅢᄂᄌ: "뫥",
    모ᅢᄂᄒ: "뫦",
    모ᅢᄃ: "뫧",
    모ᅢᄅ: "뫨",
    모ᅢᄅᄀ: "뫩",
    모ᅢᄅᄆ: "뫪",
    모ᅢᄅᄇ: "뫫",
    모ᅢᄅᄉ: "뫬",
    모ᅢᄅᄐ: "뫭",
    모ᅢᄅᄑ: "뫮",
    모ᅢᄅᄒ: "뫯",
    모ᅢᄆ: "뫰",
    모ᅢᄇ: "뫱",
    모ᅢᄇᄉ: "뫲",
    모ᅢᄉ: "뫳",
    모ᅢᄊ: "뫴",
    모ᅢᄋ: "뫵",
    모ᅢᄌ: "뫶",
    모ᅢᄎ: "뫷",
    모ᅢᄏ: "뫸",
    모ᅢᄐ: "뫹",
    모ᅢᄑ: "뫺",
    모ᅢᄒ: "뫻",
    모ᅵ: "뫼",
    모ᅵᄀ: "뫽",
    모ᅵᄁ: "뫾",
    모ᅵᄀᄉ: "뫿",
    모ᅵᄂ: "묀",
    모ᅵᄂᄌ: "묁",
    모ᅵᄂᄒ: "묂",
    모ᅵᄃ: "묃",
    모ᅵᄅ: "묄",
    모ᅵᄅᄀ: "묅",
    모ᅵᄅᄆ: "묆",
    모ᅵᄅᄇ: "묇",
    모ᅵᄅᄉ: "묈",
    모ᅵᄅᄐ: "묉",
    모ᅵᄅᄑ: "묊",
    모ᅵᄅᄒ: "묋",
    모ᅵᄆ: "묌",
    모ᅵᄇ: "묍",
    모ᅵᄇᄉ: "묎",
    모ᅵᄉ: "묏",
    모ᅵᄊ: "묐",
    모ᅵᄋ: "묑",
    모ᅵᄌ: "묒",
    모ᅵᄎ: "묓",
    모ᅵᄏ: "묔",
    모ᅵᄐ: "묕",
    모ᅵᄑ: "묖",
    모ᅵᄒ: "묗",
    묘: "묘",
    묘ᄀ: "묙",
    묘ᄁ: "묚",
    묘ᄀᄉ: "묛",
    묘ᄂ: "묜",
    묘ᄂᄌ: "묝",
    묘ᄂᄒ: "묞",
    묘ᄃ: "묟",
    묘ᄅ: "묠",
    묘ᄅᄀ: "묡",
    묘ᄅᄆ: "묢",
    묘ᄅᄇ: "묣",
    묘ᄅᄉ: "묤",
    묘ᄅᄐ: "묥",
    묘ᄅᄑ: "묦",
    묘ᄅᄒ: "묧",
    묘ᄆ: "묨",
    묘ᄇ: "묩",
    묘ᄇᄉ: "묪",
    묘ᄉ: "묫",
    묘ᄊ: "묬",
    묘ᄋ: "묭",
    묘ᄌ: "묮",
    묘ᄎ: "묯",
    묘ᄏ: "묰",
    묘ᄐ: "묱",
    묘ᄑ: "묲",
    묘ᄒ: "묳",
    무: "무",
    무ᄀ: "묵",
    무ᄁ: "묶",
    무ᄀᄉ: "묷",
    무ᄂ: "문",
    무ᄂᄌ: "묹",
    무ᄂᄒ: "묺",
    무ᄃ: "묻",
    무ᄅ: "물",
    무ᄅᄀ: "묽",
    무ᄅᄆ: "묾",
    무ᄅᄇ: "묿",
    무ᄅᄉ: "뭀",
    무ᄅᄐ: "뭁",
    무ᄅᄑ: "뭂",
    무ᄅᄒ: "뭃",
    무ᄆ: "뭄",
    무ᄇ: "뭅",
    무ᄇᄉ: "뭆",
    무ᄉ: "뭇",
    무ᄊ: "뭈",
    무ᄋ: "뭉",
    무ᄌ: "뭊",
    무ᄎ: "뭋",
    무ᄏ: "뭌",
    무ᄐ: "뭍",
    무ᄑ: "뭎",
    무ᄒ: "뭏",
    무ᅥ: "뭐",
    무ᅥᄀ: "뭑",
    무ᅥᄁ: "뭒",
    무ᅥᄀᄉ: "뭓",
    무ᅥᄂ: "뭔",
    무ᅥᄂᄌ: "뭕",
    무ᅥᄂᄒ: "뭖",
    무ᅥᄃ: "뭗",
    무ᅥᄅ: "뭘",
    무ᅥᄅᄀ: "뭙",
    무ᅥᄅᄆ: "뭚",
    무ᅥᄅᄇ: "뭛",
    무ᅥᄅᄉ: "뭜",
    무ᅥᄅᄐ: "뭝",
    무ᅥᄅᄑ: "뭞",
    무ᅥᄅᄒ: "뭟",
    무ᅥᄆ: "뭠",
    무ᅥᄇ: "뭡",
    무ᅥᄇᄉ: "뭢",
    무ᅥᄉ: "뭣",
    무ᅥᄊ: "뭤",
    무ᅥᄋ: "뭥",
    무ᅥᄌ: "뭦",
    무ᅥᄎ: "뭧",
    무ᅥᄏ: "뭨",
    무ᅥᄐ: "뭩",
    무ᅥᄑ: "뭪",
    무ᅥᄒ: "뭫",
    무ᅦ: "뭬",
    무ᅦᄀ: "뭭",
    무ᅦᄁ: "뭮",
    무ᅦᄀᄉ: "뭯",
    무ᅦᄂ: "뭰",
    무ᅦᄂᄌ: "뭱",
    무ᅦᄂᄒ: "뭲",
    무ᅦᄃ: "뭳",
    무ᅦᄅ: "뭴",
    무ᅦᄅᄀ: "뭵",
    무ᅦᄅᄆ: "뭶",
    무ᅦᄅᄇ: "뭷",
    무ᅦᄅᄉ: "뭸",
    무ᅦᄅᄐ: "뭹",
    무ᅦᄅᄑ: "뭺",
    무ᅦᄅᄒ: "뭻",
    무ᅦᄆ: "뭼",
    무ᅦᄇ: "뭽",
    무ᅦᄇᄉ: "뭾",
    무ᅦᄉ: "뭿",
    무ᅦᄊ: "뮀",
    무ᅦᄋ: "뮁",
    무ᅦᄌ: "뮂",
    무ᅦᄎ: "뮃",
    무ᅦᄏ: "뮄",
    무ᅦᄐ: "뮅",
    무ᅦᄑ: "뮆",
    무ᅦᄒ: "뮇",
    무ᅵ: "뮈",
    무ᅵᄀ: "뮉",
    무ᅵᄁ: "뮊",
    무ᅵᄀᄉ: "뮋",
    무ᅵᄂ: "뮌",
    무ᅵᄂᄌ: "뮍",
    무ᅵᄂᄒ: "뮎",
    무ᅵᄃ: "뮏",
    무ᅵᄅ: "뮐",
    무ᅵᄅᄀ: "뮑",
    무ᅵᄅᄆ: "뮒",
    무ᅵᄅᄇ: "뮓",
    무ᅵᄅᄉ: "뮔",
    무ᅵᄅᄐ: "뮕",
    무ᅵᄅᄑ: "뮖",
    무ᅵᄅᄒ: "뮗",
    무ᅵᄆ: "뮘",
    무ᅵᄇ: "뮙",
    무ᅵᄇᄉ: "뮚",
    무ᅵᄉ: "뮛",
    무ᅵᄊ: "뮜",
    무ᅵᄋ: "뮝",
    무ᅵᄌ: "뮞",
    무ᅵᄎ: "뮟",
    무ᅵᄏ: "뮠",
    무ᅵᄐ: "뮡",
    무ᅵᄑ: "뮢",
    무ᅵᄒ: "뮣",
    뮤: "뮤",
    뮤ᄀ: "뮥",
    뮤ᄁ: "뮦",
    뮤ᄀᄉ: "뮧",
    뮤ᄂ: "뮨",
    뮤ᄂᄌ: "뮩",
    뮤ᄂᄒ: "뮪",
    뮤ᄃ: "뮫",
    뮤ᄅ: "뮬",
    뮤ᄅᄀ: "뮭",
    뮤ᄅᄆ: "뮮",
    뮤ᄅᄇ: "뮯",
    뮤ᄅᄉ: "뮰",
    뮤ᄅᄐ: "뮱",
    뮤ᄅᄑ: "뮲",
    뮤ᄅᄒ: "뮳",
    뮤ᄆ: "뮴",
    뮤ᄇ: "뮵",
    뮤ᄇᄉ: "뮶",
    뮤ᄉ: "뮷",
    뮤ᄊ: "뮸",
    뮤ᄋ: "뮹",
    뮤ᄌ: "뮺",
    뮤ᄎ: "뮻",
    뮤ᄏ: "뮼",
    뮤ᄐ: "뮽",
    뮤ᄑ: "뮾",
    뮤ᄒ: "뮿",
    므: "므",
    므ᄀ: "믁",
    므ᄁ: "믂",
    므ᄀᄉ: "믃",
    므ᄂ: "믄",
    므ᄂᄌ: "믅",
    므ᄂᄒ: "믆",
    므ᄃ: "믇",
    므ᄅ: "믈",
    므ᄅᄀ: "믉",
    므ᄅᄆ: "믊",
    므ᄅᄇ: "믋",
    므ᄅᄉ: "믌",
    므ᄅᄐ: "믍",
    므ᄅᄑ: "믎",
    므ᄅᄒ: "믏",
    므ᄆ: "믐",
    므ᄇ: "믑",
    므ᄇᄉ: "믒",
    므ᄉ: "믓",
    므ᄊ: "믔",
    므ᄋ: "믕",
    므ᄌ: "믖",
    므ᄎ: "믗",
    므ᄏ: "믘",
    므ᄐ: "믙",
    므ᄑ: "믚",
    므ᄒ: "믛",
    므ᅵ: "믜",
    므ᅵᄀ: "믝",
    므ᅵᄁ: "믞",
    므ᅵᄀᄉ: "믟",
    므ᅵᄂ: "믠",
    므ᅵᄂᄌ: "믡",
    므ᅵᄂᄒ: "믢",
    므ᅵᄃ: "믣",
    므ᅵᄅ: "믤",
    므ᅵᄅᄀ: "믥",
    므ᅵᄅᄆ: "믦",
    므ᅵᄅᄇ: "믧",
    므ᅵᄅᄉ: "믨",
    므ᅵᄅᄐ: "믩",
    므ᅵᄅᄑ: "믪",
    므ᅵᄅᄒ: "믫",
    므ᅵᄆ: "믬",
    므ᅵᄇ: "믭",
    므ᅵᄇᄉ: "믮",
    므ᅵᄉ: "믯",
    므ᅵᄊ: "믰",
    므ᅵᄋ: "믱",
    므ᅵᄌ: "믲",
    므ᅵᄎ: "믳",
    므ᅵᄏ: "믴",
    므ᅵᄐ: "믵",
    므ᅵᄑ: "믶",
    므ᅵᄒ: "믷",
    미: "미",
    미ᄀ: "믹",
    미ᄁ: "믺",
    미ᄀᄉ: "믻",
    미ᄂ: "민",
    미ᄂᄌ: "믽",
    미ᄂᄒ: "믾",
    미ᄃ: "믿",
    미ᄅ: "밀",
    미ᄅᄀ: "밁",
    미ᄅᄆ: "밂",
    미ᄅᄇ: "밃",
    미ᄅᄉ: "밄",
    미ᄅᄐ: "밅",
    미ᄅᄑ: "밆",
    미ᄅᄒ: "밇",
    미ᄆ: "밈",
    미ᄇ: "밉",
    미ᄇᄉ: "밊",
    미ᄉ: "밋",
    미ᄊ: "밌",
    미ᄋ: "밍",
    미ᄌ: "밎",
    미ᄎ: "및",
    미ᄏ: "밐",
    미ᄐ: "밑",
    미ᄑ: "밒",
    미ᄒ: "밓",
    바: "바",
    바ᄀ: "박",
    바ᄁ: "밖",
    바ᄀᄉ: "밗",
    바ᄂ: "반",
    바ᄂᄌ: "밙",
    바ᄂᄒ: "밚",
    바ᄃ: "받",
    바ᄅ: "발",
    바ᄅᄀ: "밝",
    바ᄅᄆ: "밞",
    바ᄅᄇ: "밟",
    바ᄅᄉ: "밠",
    바ᄅᄐ: "밡",
    바ᄅᄑ: "밢",
    바ᄅᄒ: "밣",
    바ᄆ: "밤",
    바ᄇ: "밥",
    바ᄇᄉ: "밦",
    바ᄉ: "밧",
    바ᄊ: "밨",
    바ᄋ: "방",
    바ᄌ: "밪",
    바ᄎ: "밫",
    바ᄏ: "밬",
    바ᄐ: "밭",
    바ᄑ: "밮",
    바ᄒ: "밯",
    배: "배",
    배ᄀ: "백",
    배ᄁ: "밲",
    배ᄀᄉ: "밳",
    배ᄂ: "밴",
    배ᄂᄌ: "밵",
    배ᄂᄒ: "밶",
    배ᄃ: "밷",
    배ᄅ: "밸",
    배ᄅᄀ: "밹",
    배ᄅᄆ: "밺",
    배ᄅᄇ: "밻",
    배ᄅᄉ: "밼",
    배ᄅᄐ: "밽",
    배ᄅᄑ: "밾",
    배ᄅᄒ: "밿",
    배ᄆ: "뱀",
    배ᄇ: "뱁",
    배ᄇᄉ: "뱂",
    배ᄉ: "뱃",
    배ᄊ: "뱄",
    배ᄋ: "뱅",
    배ᄌ: "뱆",
    배ᄎ: "뱇",
    배ᄏ: "뱈",
    배ᄐ: "뱉",
    배ᄑ: "뱊",
    배ᄒ: "뱋",
    뱌: "뱌",
    뱌ᄀ: "뱍",
    뱌ᄁ: "뱎",
    뱌ᄀᄉ: "뱏",
    뱌ᄂ: "뱐",
    뱌ᄂᄌ: "뱑",
    뱌ᄂᄒ: "뱒",
    뱌ᄃ: "뱓",
    뱌ᄅ: "뱔",
    뱌ᄅᄀ: "뱕",
    뱌ᄅᄆ: "뱖",
    뱌ᄅᄇ: "뱗",
    뱌ᄅᄉ: "뱘",
    뱌ᄅᄐ: "뱙",
    뱌ᄅᄑ: "뱚",
    뱌ᄅᄒ: "뱛",
    뱌ᄆ: "뱜",
    뱌ᄇ: "뱝",
    뱌ᄇᄉ: "뱞",
    뱌ᄉ: "뱟",
    뱌ᄊ: "뱠",
    뱌ᄋ: "뱡",
    뱌ᄌ: "뱢",
    뱌ᄎ: "뱣",
    뱌ᄏ: "뱤",
    뱌ᄐ: "뱥",
    뱌ᄑ: "뱦",
    뱌ᄒ: "뱧",
    뱨: "뱨",
    뱨ᄀ: "뱩",
    뱨ᄁ: "뱪",
    뱨ᄀᄉ: "뱫",
    뱨ᄂ: "뱬",
    뱨ᄂᄌ: "뱭",
    뱨ᄂᄒ: "뱮",
    뱨ᄃ: "뱯",
    뱨ᄅ: "뱰",
    뱨ᄅᄀ: "뱱",
    뱨ᄅᄆ: "뱲",
    뱨ᄅᄇ: "뱳",
    뱨ᄅᄉ: "뱴",
    뱨ᄅᄐ: "뱵",
    뱨ᄅᄑ: "뱶",
    뱨ᄅᄒ: "뱷",
    뱨ᄆ: "뱸",
    뱨ᄇ: "뱹",
    뱨ᄇᄉ: "뱺",
    뱨ᄉ: "뱻",
    뱨ᄊ: "뱼",
    뱨ᄋ: "뱽",
    뱨ᄌ: "뱾",
    뱨ᄎ: "뱿",
    뱨ᄏ: "벀",
    뱨ᄐ: "벁",
    뱨ᄑ: "벂",
    뱨ᄒ: "벃",
    버: "버",
    버ᄀ: "벅",
    버ᄁ: "벆",
    버ᄀᄉ: "벇",
    버ᄂ: "번",
    버ᄂᄌ: "벉",
    버ᄂᄒ: "벊",
    버ᄃ: "벋",
    버ᄅ: "벌",
    버ᄅᄀ: "벍",
    버ᄅᄆ: "벎",
    버ᄅᄇ: "벏",
    버ᄅᄉ: "벐",
    버ᄅᄐ: "벑",
    버ᄅᄑ: "벒",
    버ᄅᄒ: "벓",
    버ᄆ: "범",
    버ᄇ: "법",
    버ᄇᄉ: "벖",
    버ᄉ: "벗",
    버ᄊ: "벘",
    버ᄋ: "벙",
    버ᄌ: "벚",
    버ᄎ: "벛",
    버ᄏ: "벜",
    버ᄐ: "벝",
    버ᄑ: "벞",
    버ᄒ: "벟",
    베: "베",
    베ᄀ: "벡",
    베ᄁ: "벢",
    베ᄀᄉ: "벣",
    베ᄂ: "벤",
    베ᄂᄌ: "벥",
    베ᄂᄒ: "벦",
    베ᄃ: "벧",
    베ᄅ: "벨",
    베ᄅᄀ: "벩",
    베ᄅᄆ: "벪",
    베ᄅᄇ: "벫",
    베ᄅᄉ: "벬",
    베ᄅᄐ: "벭",
    베ᄅᄑ: "벮",
    베ᄅᄒ: "벯",
    베ᄆ: "벰",
    베ᄇ: "벱",
    베ᄇᄉ: "벲",
    베ᄉ: "벳",
    베ᄊ: "벴",
    베ᄋ: "벵",
    베ᄌ: "벶",
    베ᄎ: "벷",
    베ᄏ: "벸",
    베ᄐ: "벹",
    베ᄑ: "벺",
    베ᄒ: "벻",
    벼: "벼",
    벼ᄀ: "벽",
    벼ᄁ: "벾",
    벼ᄀᄉ: "벿",
    벼ᄂ: "변",
    벼ᄂᄌ: "볁",
    벼ᄂᄒ: "볂",
    벼ᄃ: "볃",
    벼ᄅ: "별",
    벼ᄅᄀ: "볅",
    벼ᄅᄆ: "볆",
    벼ᄅᄇ: "볇",
    벼ᄅᄉ: "볈",
    벼ᄅᄐ: "볉",
    벼ᄅᄑ: "볊",
    벼ᄅᄒ: "볋",
    벼ᄆ: "볌",
    벼ᄇ: "볍",
    벼ᄇᄉ: "볎",
    벼ᄉ: "볏",
    벼ᄊ: "볐",
    벼ᄋ: "병",
    벼ᄌ: "볒",
    벼ᄎ: "볓",
    벼ᄏ: "볔",
    벼ᄐ: "볕",
    벼ᄑ: "볖",
    벼ᄒ: "볗",
    볘: "볘",
    볘ᄀ: "볙",
    볘ᄁ: "볚",
    볘ᄀᄉ: "볛",
    볘ᄂ: "볜",
    볘ᄂᄌ: "볝",
    볘ᄂᄒ: "볞",
    볘ᄃ: "볟",
    볘ᄅ: "볠",
    볘ᄅᄀ: "볡",
    볘ᄅᄆ: "볢",
    볘ᄅᄇ: "볣",
    볘ᄅᄉ: "볤",
    볘ᄅᄐ: "볥",
    볘ᄅᄑ: "볦",
    볘ᄅᄒ: "볧",
    볘ᄆ: "볨",
    볘ᄇ: "볩",
    볘ᄇᄉ: "볪",
    볘ᄉ: "볫",
    볘ᄊ: "볬",
    볘ᄋ: "볭",
    볘ᄌ: "볮",
    볘ᄎ: "볯",
    볘ᄏ: "볰",
    볘ᄐ: "볱",
    볘ᄑ: "볲",
    볘ᄒ: "볳",
    보: "보",
    보ᄀ: "복",
    보ᄁ: "볶",
    보ᄀᄉ: "볷",
    보ᄂ: "본",
    보ᄂᄌ: "볹",
    보ᄂᄒ: "볺",
    보ᄃ: "볻",
    보ᄅ: "볼",
    보ᄅᄀ: "볽",
    보ᄅᄆ: "볾",
    보ᄅᄇ: "볿",
    보ᄅᄉ: "봀",
    보ᄅᄐ: "봁",
    보ᄅᄑ: "봂",
    보ᄅᄒ: "봃",
    보ᄆ: "봄",
    보ᄇ: "봅",
    보ᄇᄉ: "봆",
    보ᄉ: "봇",
    보ᄊ: "봈",
    보ᄋ: "봉",
    보ᄌ: "봊",
    보ᄎ: "봋",
    보ᄏ: "봌",
    보ᄐ: "봍",
    보ᄑ: "봎",
    보ᄒ: "봏",
    보ᅡ: "봐",
    보ᅡᄀ: "봑",
    보ᅡᄁ: "봒",
    보ᅡᄀᄉ: "봓",
    보ᅡᄂ: "봔",
    보ᅡᄂᄌ: "봕",
    보ᅡᄂᄒ: "봖",
    보ᅡᄃ: "봗",
    보ᅡᄅ: "봘",
    보ᅡᄅᄀ: "봙",
    보ᅡᄅᄆ: "봚",
    보ᅡᄅᄇ: "봛",
    보ᅡᄅᄉ: "봜",
    보ᅡᄅᄐ: "봝",
    보ᅡᄅᄑ: "봞",
    보ᅡᄅᄒ: "봟",
    보ᅡᄆ: "봠",
    보ᅡᄇ: "봡",
    보ᅡᄇᄉ: "봢",
    보ᅡᄉ: "봣",
    보ᅡᄊ: "봤",
    보ᅡᄋ: "봥",
    보ᅡᄌ: "봦",
    보ᅡᄎ: "봧",
    보ᅡᄏ: "봨",
    보ᅡᄐ: "봩",
    보ᅡᄑ: "봪",
    보ᅡᄒ: "봫",
    보ᅢ: "봬",
    보ᅢᄀ: "봭",
    보ᅢᄁ: "봮",
    보ᅢᄀᄉ: "봯",
    보ᅢᄂ: "봰",
    보ᅢᄂᄌ: "봱",
    보ᅢᄂᄒ: "봲",
    보ᅢᄃ: "봳",
    보ᅢᄅ: "봴",
    보ᅢᄅᄀ: "봵",
    보ᅢᄅᄆ: "봶",
    보ᅢᄅᄇ: "봷",
    보ᅢᄅᄉ: "봸",
    보ᅢᄅᄐ: "봹",
    보ᅢᄅᄑ: "봺",
    보ᅢᄅᄒ: "봻",
    보ᅢᄆ: "봼",
    보ᅢᄇ: "봽",
    보ᅢᄇᄉ: "봾",
    보ᅢᄉ: "봿",
    보ᅢᄊ: "뵀",
    보ᅢᄋ: "뵁",
    보ᅢᄌ: "뵂",
    보ᅢᄎ: "뵃",
    보ᅢᄏ: "뵄",
    보ᅢᄐ: "뵅",
    보ᅢᄑ: "뵆",
    보ᅢᄒ: "뵇",
    보ᅵ: "뵈",
    보ᅵᄀ: "뵉",
    보ᅵᄁ: "뵊",
    보ᅵᄀᄉ: "뵋",
    보ᅵᄂ: "뵌",
    보ᅵᄂᄌ: "뵍",
    보ᅵᄂᄒ: "뵎",
    보ᅵᄃ: "뵏",
    보ᅵᄅ: "뵐",
    보ᅵᄅᄀ: "뵑",
    보ᅵᄅᄆ: "뵒",
    보ᅵᄅᄇ: "뵓",
    보ᅵᄅᄉ: "뵔",
    보ᅵᄅᄐ: "뵕",
    보ᅵᄅᄑ: "뵖",
    보ᅵᄅᄒ: "뵗",
    보ᅵᄆ: "뵘",
    보ᅵᄇ: "뵙",
    보ᅵᄇᄉ: "뵚",
    보ᅵᄉ: "뵛",
    보ᅵᄊ: "뵜",
    보ᅵᄋ: "뵝",
    보ᅵᄌ: "뵞",
    보ᅵᄎ: "뵟",
    보ᅵᄏ: "뵠",
    보ᅵᄐ: "뵡",
    보ᅵᄑ: "뵢",
    보ᅵᄒ: "뵣",
    뵤: "뵤",
    뵤ᄀ: "뵥",
    뵤ᄁ: "뵦",
    뵤ᄀᄉ: "뵧",
    뵤ᄂ: "뵨",
    뵤ᄂᄌ: "뵩",
    뵤ᄂᄒ: "뵪",
    뵤ᄃ: "뵫",
    뵤ᄅ: "뵬",
    뵤ᄅᄀ: "뵭",
    뵤ᄅᄆ: "뵮",
    뵤ᄅᄇ: "뵯",
    뵤ᄅᄉ: "뵰",
    뵤ᄅᄐ: "뵱",
    뵤ᄅᄑ: "뵲",
    뵤ᄅᄒ: "뵳",
    뵤ᄆ: "뵴",
    뵤ᄇ: "뵵",
    뵤ᄇᄉ: "뵶",
    뵤ᄉ: "뵷",
    뵤ᄊ: "뵸",
    뵤ᄋ: "뵹",
    뵤ᄌ: "뵺",
    뵤ᄎ: "뵻",
    뵤ᄏ: "뵼",
    뵤ᄐ: "뵽",
    뵤ᄑ: "뵾",
    뵤ᄒ: "뵿",
    부: "부",
    부ᄀ: "북",
    부ᄁ: "붂",
    부ᄀᄉ: "붃",
    부ᄂ: "분",
    부ᄂᄌ: "붅",
    부ᄂᄒ: "붆",
    부ᄃ: "붇",
    부ᄅ: "불",
    부ᄅᄀ: "붉",
    부ᄅᄆ: "붊",
    부ᄅᄇ: "붋",
    부ᄅᄉ: "붌",
    부ᄅᄐ: "붍",
    부ᄅᄑ: "붎",
    부ᄅᄒ: "붏",
    부ᄆ: "붐",
    부ᄇ: "붑",
    부ᄇᄉ: "붒",
    부ᄉ: "붓",
    부ᄊ: "붔",
    부ᄋ: "붕",
    부ᄌ: "붖",
    부ᄎ: "붗",
    부ᄏ: "붘",
    부ᄐ: "붙",
    부ᄑ: "붚",
    부ᄒ: "붛",
    부ᅥ: "붜",
    부ᅥᄀ: "붝",
    부ᅥᄁ: "붞",
    부ᅥᄀᄉ: "붟",
    부ᅥᄂ: "붠",
    부ᅥᄂᄌ: "붡",
    부ᅥᄂᄒ: "붢",
    부ᅥᄃ: "붣",
    부ᅥᄅ: "붤",
    부ᅥᄅᄀ: "붥",
    부ᅥᄅᄆ: "붦",
    부ᅥᄅᄇ: "붧",
    부ᅥᄅᄉ: "붨",
    부ᅥᄅᄐ: "붩",
    부ᅥᄅᄑ: "붪",
    부ᅥᄅᄒ: "붫",
    부ᅥᄆ: "붬",
    부ᅥᄇ: "붭",
    부ᅥᄇᄉ: "붮",
    부ᅥᄉ: "붯",
    부ᅥᄊ: "붰",
    부ᅥᄋ: "붱",
    부ᅥᄌ: "붲",
    부ᅥᄎ: "붳",
    부ᅥᄏ: "붴",
    부ᅥᄐ: "붵",
    부ᅥᄑ: "붶",
    부ᅥᄒ: "붷",
    부ᅦ: "붸",
    부ᅦᄀ: "붹",
    부ᅦᄁ: "붺",
    부ᅦᄀᄉ: "붻",
    부ᅦᄂ: "붼",
    부ᅦᄂᄌ: "붽",
    부ᅦᄂᄒ: "붾",
    부ᅦᄃ: "붿",
    부ᅦᄅ: "뷀",
    부ᅦᄅᄀ: "뷁",
    부ᅦᄅᄆ: "뷂",
    부ᅦᄅᄇ: "뷃",
    부ᅦᄅᄉ: "뷄",
    부ᅦᄅᄐ: "뷅",
    부ᅦᄅᄑ: "뷆",
    부ᅦᄅᄒ: "뷇",
    부ᅦᄆ: "뷈",
    부ᅦᄇ: "뷉",
    부ᅦᄇᄉ: "뷊",
    부ᅦᄉ: "뷋",
    부ᅦᄊ: "뷌",
    부ᅦᄋ: "뷍",
    부ᅦᄌ: "뷎",
    부ᅦᄎ: "뷏",
    부ᅦᄏ: "뷐",
    부ᅦᄐ: "뷑",
    부ᅦᄑ: "뷒",
    부ᅦᄒ: "뷓",
    부ᅵ: "뷔",
    부ᅵᄀ: "뷕",
    부ᅵᄁ: "뷖",
    부ᅵᄀᄉ: "뷗",
    부ᅵᄂ: "뷘",
    부ᅵᄂᄌ: "뷙",
    부ᅵᄂᄒ: "뷚",
    부ᅵᄃ: "뷛",
    부ᅵᄅ: "뷜",
    부ᅵᄅᄀ: "뷝",
    부ᅵᄅᄆ: "뷞",
    부ᅵᄅᄇ: "뷟",
    부ᅵᄅᄉ: "뷠",
    부ᅵᄅᄐ: "뷡",
    부ᅵᄅᄑ: "뷢",
    부ᅵᄅᄒ: "뷣",
    부ᅵᄆ: "뷤",
    부ᅵᄇ: "뷥",
    부ᅵᄇᄉ: "뷦",
    부ᅵᄉ: "뷧",
    부ᅵᄊ: "뷨",
    부ᅵᄋ: "뷩",
    부ᅵᄌ: "뷪",
    부ᅵᄎ: "뷫",
    부ᅵᄏ: "뷬",
    부ᅵᄐ: "뷭",
    부ᅵᄑ: "뷮",
    부ᅵᄒ: "뷯",
    뷰: "뷰",
    뷰ᄀ: "뷱",
    뷰ᄁ: "뷲",
    뷰ᄀᄉ: "뷳",
    뷰ᄂ: "뷴",
    뷰ᄂᄌ: "뷵",
    뷰ᄂᄒ: "뷶",
    뷰ᄃ: "뷷",
    뷰ᄅ: "뷸",
    뷰ᄅᄀ: "뷹",
    뷰ᄅᄆ: "뷺",
    뷰ᄅᄇ: "뷻",
    뷰ᄅᄉ: "뷼",
    뷰ᄅᄐ: "뷽",
    뷰ᄅᄑ: "뷾",
    뷰ᄅᄒ: "뷿",
    뷰ᄆ: "븀",
    뷰ᄇ: "븁",
    뷰ᄇᄉ: "븂",
    뷰ᄉ: "븃",
    뷰ᄊ: "븄",
    뷰ᄋ: "븅",
    뷰ᄌ: "븆",
    뷰ᄎ: "븇",
    뷰ᄏ: "븈",
    뷰ᄐ: "븉",
    뷰ᄑ: "븊",
    뷰ᄒ: "븋",
    브: "브",
    브ᄀ: "븍",
    브ᄁ: "븎",
    브ᄀᄉ: "븏",
    브ᄂ: "븐",
    브ᄂᄌ: "븑",
    브ᄂᄒ: "븒",
    브ᄃ: "븓",
    브ᄅ: "블",
    브ᄅᄀ: "븕",
    브ᄅᄆ: "븖",
    브ᄅᄇ: "븗",
    브ᄅᄉ: "븘",
    브ᄅᄐ: "븙",
    브ᄅᄑ: "븚",
    브ᄅᄒ: "븛",
    브ᄆ: "븜",
    브ᄇ: "븝",
    브ᄇᄉ: "븞",
    브ᄉ: "븟",
    브ᄊ: "븠",
    브ᄋ: "븡",
    브ᄌ: "븢",
    브ᄎ: "븣",
    브ᄏ: "븤",
    브ᄐ: "븥",
    브ᄑ: "븦",
    브ᄒ: "븧",
    브ᅵ: "븨",
    브ᅵᄀ: "븩",
    브ᅵᄁ: "븪",
    브ᅵᄀᄉ: "븫",
    브ᅵᄂ: "븬",
    브ᅵᄂᄌ: "븭",
    브ᅵᄂᄒ: "븮",
    브ᅵᄃ: "븯",
    브ᅵᄅ: "븰",
    브ᅵᄅᄀ: "븱",
    브ᅵᄅᄆ: "븲",
    브ᅵᄅᄇ: "븳",
    브ᅵᄅᄉ: "븴",
    브ᅵᄅᄐ: "븵",
    브ᅵᄅᄑ: "븶",
    브ᅵᄅᄒ: "븷",
    브ᅵᄆ: "븸",
    브ᅵᄇ: "븹",
    브ᅵᄇᄉ: "븺",
    브ᅵᄉ: "븻",
    브ᅵᄊ: "븼",
    브ᅵᄋ: "븽",
    브ᅵᄌ: "븾",
    브ᅵᄎ: "븿",
    브ᅵᄏ: "빀",
    브ᅵᄐ: "빁",
    브ᅵᄑ: "빂",
    브ᅵᄒ: "빃",
    비: "비",
    비ᄀ: "빅",
    비ᄁ: "빆",
    비ᄀᄉ: "빇",
    비ᄂ: "빈",
    비ᄂᄌ: "빉",
    비ᄂᄒ: "빊",
    비ᄃ: "빋",
    비ᄅ: "빌",
    비ᄅᄀ: "빍",
    비ᄅᄆ: "빎",
    비ᄅᄇ: "빏",
    비ᄅᄉ: "빐",
    비ᄅᄐ: "빑",
    비ᄅᄑ: "빒",
    비ᄅᄒ: "빓",
    비ᄆ: "빔",
    비ᄇ: "빕",
    비ᄇᄉ: "빖",
    비ᄉ: "빗",
    비ᄊ: "빘",
    비ᄋ: "빙",
    비ᄌ: "빚",
    비ᄎ: "빛",
    비ᄏ: "빜",
    비ᄐ: "빝",
    비ᄑ: "빞",
    비ᄒ: "빟",
    빠: "빠",
    빠ᄀ: "빡",
    빠ᄁ: "빢",
    빠ᄀᄉ: "빣",
    빠ᄂ: "빤",
    빠ᄂᄌ: "빥",
    빠ᄂᄒ: "빦",
    빠ᄃ: "빧",
    빠ᄅ: "빨",
    빠ᄅᄀ: "빩",
    빠ᄅᄆ: "빪",
    빠ᄅᄇ: "빫",
    빠ᄅᄉ: "빬",
    빠ᄅᄐ: "빭",
    빠ᄅᄑ: "빮",
    빠ᄅᄒ: "빯",
    빠ᄆ: "빰",
    빠ᄇ: "빱",
    빠ᄇᄉ: "빲",
    빠ᄉ: "빳",
    빠ᄊ: "빴",
    빠ᄋ: "빵",
    빠ᄌ: "빶",
    빠ᄎ: "빷",
    빠ᄏ: "빸",
    빠ᄐ: "빹",
    빠ᄑ: "빺",
    빠ᄒ: "빻",
    빼: "빼",
    빼ᄀ: "빽",
    빼ᄁ: "빾",
    빼ᄀᄉ: "빿",
    빼ᄂ: "뺀",
    빼ᄂᄌ: "뺁",
    빼ᄂᄒ: "뺂",
    빼ᄃ: "뺃",
    빼ᄅ: "뺄",
    빼ᄅᄀ: "뺅",
    빼ᄅᄆ: "뺆",
    빼ᄅᄇ: "뺇",
    빼ᄅᄉ: "뺈",
    빼ᄅᄐ: "뺉",
    빼ᄅᄑ: "뺊",
    빼ᄅᄒ: "뺋",
    빼ᄆ: "뺌",
    빼ᄇ: "뺍",
    빼ᄇᄉ: "뺎",
    빼ᄉ: "뺏",
    빼ᄊ: "뺐",
    빼ᄋ: "뺑",
    빼ᄌ: "뺒",
    빼ᄎ: "뺓",
    빼ᄏ: "뺔",
    빼ᄐ: "뺕",
    빼ᄑ: "뺖",
    빼ᄒ: "뺗",
    뺘: "뺘",
    뺘ᄀ: "뺙",
    뺘ᄁ: "뺚",
    뺘ᄀᄉ: "뺛",
    뺘ᄂ: "뺜",
    뺘ᄂᄌ: "뺝",
    뺘ᄂᄒ: "뺞",
    뺘ᄃ: "뺟",
    뺘ᄅ: "뺠",
    뺘ᄅᄀ: "뺡",
    뺘ᄅᄆ: "뺢",
    뺘ᄅᄇ: "뺣",
    뺘ᄅᄉ: "뺤",
    뺘ᄅᄐ: "뺥",
    뺘ᄅᄑ: "뺦",
    뺘ᄅᄒ: "뺧",
    뺘ᄆ: "뺨",
    뺘ᄇ: "뺩",
    뺘ᄇᄉ: "뺪",
    뺘ᄉ: "뺫",
    뺘ᄊ: "뺬",
    뺘ᄋ: "뺭",
    뺘ᄌ: "뺮",
    뺘ᄎ: "뺯",
    뺘ᄏ: "뺰",
    뺘ᄐ: "뺱",
    뺘ᄑ: "뺲",
    뺘ᄒ: "뺳",
    뺴: "뺴",
    뺴ᄀ: "뺵",
    뺴ᄁ: "뺶",
    뺴ᄀᄉ: "뺷",
    뺴ᄂ: "뺸",
    뺴ᄂᄌ: "뺹",
    뺴ᄂᄒ: "뺺",
    뺴ᄃ: "뺻",
    뺴ᄅ: "뺼",
    뺴ᄅᄀ: "뺽",
    뺴ᄅᄆ: "뺾",
    뺴ᄅᄇ: "뺿",
    뺴ᄅᄉ: "뻀",
    뺴ᄅᄐ: "뻁",
    뺴ᄅᄑ: "뻂",
    뺴ᄅᄒ: "뻃",
    뺴ᄆ: "뻄",
    뺴ᄇ: "뻅",
    뺴ᄇᄉ: "뻆",
    뺴ᄉ: "뻇",
    뺴ᄊ: "뻈",
    뺴ᄋ: "뻉",
    뺴ᄌ: "뻊",
    뺴ᄎ: "뻋",
    뺴ᄏ: "뻌",
    뺴ᄐ: "뻍",
    뺴ᄑ: "뻎",
    뺴ᄒ: "뻏",
    뻐: "뻐",
    뻐ᄀ: "뻑",
    뻐ᄁ: "뻒",
    뻐ᄀᄉ: "뻓",
    뻐ᄂ: "뻔",
    뻐ᄂᄌ: "뻕",
    뻐ᄂᄒ: "뻖",
    뻐ᄃ: "뻗",
    뻐ᄅ: "뻘",
    뻐ᄅᄀ: "뻙",
    뻐ᄅᄆ: "뻚",
    뻐ᄅᄇ: "뻛",
    뻐ᄅᄉ: "뻜",
    뻐ᄅᄐ: "뻝",
    뻐ᄅᄑ: "뻞",
    뻐ᄅᄒ: "뻟",
    뻐ᄆ: "뻠",
    뻐ᄇ: "뻡",
    뻐ᄇᄉ: "뻢",
    뻐ᄉ: "뻣",
    뻐ᄊ: "뻤",
    뻐ᄋ: "뻥",
    뻐ᄌ: "뻦",
    뻐ᄎ: "뻧",
    뻐ᄏ: "뻨",
    뻐ᄐ: "뻩",
    뻐ᄑ: "뻪",
    뻐ᄒ: "뻫",
    뻬: "뻬",
    뻬ᄀ: "뻭",
    뻬ᄁ: "뻮",
    뻬ᄀᄉ: "뻯",
    뻬ᄂ: "뻰",
    뻬ᄂᄌ: "뻱",
    뻬ᄂᄒ: "뻲",
    뻬ᄃ: "뻳",
    뻬ᄅ: "뻴",
    뻬ᄅᄀ: "뻵",
    뻬ᄅᄆ: "뻶",
    뻬ᄅᄇ: "뻷",
    뻬ᄅᄉ: "뻸",
    뻬ᄅᄐ: "뻹",
    뻬ᄅᄑ: "뻺",
    뻬ᄅᄒ: "뻻",
    뻬ᄆ: "뻼",
    뻬ᄇ: "뻽",
    뻬ᄇᄉ: "뻾",
    뻬ᄉ: "뻿",
    뻬ᄊ: "뼀",
    뻬ᄋ: "뼁",
    뻬ᄌ: "뼂",
    뻬ᄎ: "뼃",
    뻬ᄏ: "뼄",
    뻬ᄐ: "뼅",
    뻬ᄑ: "뼆",
    뻬ᄒ: "뼇",
    뼈: "뼈",
    뼈ᄀ: "뼉",
    뼈ᄁ: "뼊",
    뼈ᄀᄉ: "뼋",
    뼈ᄂ: "뼌",
    뼈ᄂᄌ: "뼍",
    뼈ᄂᄒ: "뼎",
    뼈ᄃ: "뼏",
    뼈ᄅ: "뼐",
    뼈ᄅᄀ: "뼑",
    뼈ᄅᄆ: "뼒",
    뼈ᄅᄇ: "뼓",
    뼈ᄅᄉ: "뼔",
    뼈ᄅᄐ: "뼕",
    뼈ᄅᄑ: "뼖",
    뼈ᄅᄒ: "뼗",
    뼈ᄆ: "뼘",
    뼈ᄇ: "뼙",
    뼈ᄇᄉ: "뼚",
    뼈ᄉ: "뼛",
    뼈ᄊ: "뼜",
    뼈ᄋ: "뼝",
    뼈ᄌ: "뼞",
    뼈ᄎ: "뼟",
    뼈ᄏ: "뼠",
    뼈ᄐ: "뼡",
    뼈ᄑ: "뼢",
    뼈ᄒ: "뼣",
    뼤: "뼤",
    뼤ᄀ: "뼥",
    뼤ᄁ: "뼦",
    뼤ᄀᄉ: "뼧",
    뼤ᄂ: "뼨",
    뼤ᄂᄌ: "뼩",
    뼤ᄂᄒ: "뼪",
    뼤ᄃ: "뼫",
    뼤ᄅ: "뼬",
    뼤ᄅᄀ: "뼭",
    뼤ᄅᄆ: "뼮",
    뼤ᄅᄇ: "뼯",
    뼤ᄅᄉ: "뼰",
    뼤ᄅᄐ: "뼱",
    뼤ᄅᄑ: "뼲",
    뼤ᄅᄒ: "뼳",
    뼤ᄆ: "뼴",
    뼤ᄇ: "뼵",
    뼤ᄇᄉ: "뼶",
    뼤ᄉ: "뼷",
    뼤ᄊ: "뼸",
    뼤ᄋ: "뼹",
    뼤ᄌ: "뼺",
    뼤ᄎ: "뼻",
    뼤ᄏ: "뼼",
    뼤ᄐ: "뼽",
    뼤ᄑ: "뼾",
    뼤ᄒ: "뼿",
    뽀: "뽀",
    뽀ᄀ: "뽁",
    뽀ᄁ: "뽂",
    뽀ᄀᄉ: "뽃",
    뽀ᄂ: "뽄",
    뽀ᄂᄌ: "뽅",
    뽀ᄂᄒ: "뽆",
    뽀ᄃ: "뽇",
    뽀ᄅ: "뽈",
    뽀ᄅᄀ: "뽉",
    뽀ᄅᄆ: "뽊",
    뽀ᄅᄇ: "뽋",
    뽀ᄅᄉ: "뽌",
    뽀ᄅᄐ: "뽍",
    뽀ᄅᄑ: "뽎",
    뽀ᄅᄒ: "뽏",
    뽀ᄆ: "뽐",
    뽀ᄇ: "뽑",
    뽀ᄇᄉ: "뽒",
    뽀ᄉ: "뽓",
    뽀ᄊ: "뽔",
    뽀ᄋ: "뽕",
    뽀ᄌ: "뽖",
    뽀ᄎ: "뽗",
    뽀ᄏ: "뽘",
    뽀ᄐ: "뽙",
    뽀ᄑ: "뽚",
    뽀ᄒ: "뽛",
    뽀ᅡ: "뽜",
    뽀ᅡᄀ: "뽝",
    뽀ᅡᄁ: "뽞",
    뽀ᅡᄀᄉ: "뽟",
    뽀ᅡᄂ: "뽠",
    뽀ᅡᄂᄌ: "뽡",
    뽀ᅡᄂᄒ: "뽢",
    뽀ᅡᄃ: "뽣",
    뽀ᅡᄅ: "뽤",
    뽀ᅡᄅᄀ: "뽥",
    뽀ᅡᄅᄆ: "뽦",
    뽀ᅡᄅᄇ: "뽧",
    뽀ᅡᄅᄉ: "뽨",
    뽀ᅡᄅᄐ: "뽩",
    뽀ᅡᄅᄑ: "뽪",
    뽀ᅡᄅᄒ: "뽫",
    뽀ᅡᄆ: "뽬",
    뽀ᅡᄇ: "뽭",
    뽀ᅡᄇᄉ: "뽮",
    뽀ᅡᄉ: "뽯",
    뽀ᅡᄊ: "뽰",
    뽀ᅡᄋ: "뽱",
    뽀ᅡᄌ: "뽲",
    뽀ᅡᄎ: "뽳",
    뽀ᅡᄏ: "뽴",
    뽀ᅡᄐ: "뽵",
    뽀ᅡᄑ: "뽶",
    뽀ᅡᄒ: "뽷",
    뽀ᅢ: "뽸",
    뽀ᅢᄀ: "뽹",
    뽀ᅢᄁ: "뽺",
    뽀ᅢᄀᄉ: "뽻",
    뽀ᅢᄂ: "뽼",
    뽀ᅢᄂᄌ: "뽽",
    뽀ᅢᄂᄒ: "뽾",
    뽀ᅢᄃ: "뽿",
    뽀ᅢᄅ: "뾀",
    뽀ᅢᄅᄀ: "뾁",
    뽀ᅢᄅᄆ: "뾂",
    뽀ᅢᄅᄇ: "뾃",
    뽀ᅢᄅᄉ: "뾄",
    뽀ᅢᄅᄐ: "뾅",
    뽀ᅢᄅᄑ: "뾆",
    뽀ᅢᄅᄒ: "뾇",
    뽀ᅢᄆ: "뾈",
    뽀ᅢᄇ: "뾉",
    뽀ᅢᄇᄉ: "뾊",
    뽀ᅢᄉ: "뾋",
    뽀ᅢᄊ: "뾌",
    뽀ᅢᄋ: "뾍",
    뽀ᅢᄌ: "뾎",
    뽀ᅢᄎ: "뾏",
    뽀ᅢᄏ: "뾐",
    뽀ᅢᄐ: "뾑",
    뽀ᅢᄑ: "뾒",
    뽀ᅢᄒ: "뾓",
    뽀ᅵ: "뾔",
    뽀ᅵᄀ: "뾕",
    뽀ᅵᄁ: "뾖",
    뽀ᅵᄀᄉ: "뾗",
    뽀ᅵᄂ: "뾘",
    뽀ᅵᄂᄌ: "뾙",
    뽀ᅵᄂᄒ: "뾚",
    뽀ᅵᄃ: "뾛",
    뽀ᅵᄅ: "뾜",
    뽀ᅵᄅᄀ: "뾝",
    뽀ᅵᄅᄆ: "뾞",
    뽀ᅵᄅᄇ: "뾟",
    뽀ᅵᄅᄉ: "뾠",
    뽀ᅵᄅᄐ: "뾡",
    뽀ᅵᄅᄑ: "뾢",
    뽀ᅵᄅᄒ: "뾣",
    뽀ᅵᄆ: "뾤",
    뽀ᅵᄇ: "뾥",
    뽀ᅵᄇᄉ: "뾦",
    뽀ᅵᄉ: "뾧",
    뽀ᅵᄊ: "뾨",
    뽀ᅵᄋ: "뾩",
    뽀ᅵᄌ: "뾪",
    뽀ᅵᄎ: "뾫",
    뽀ᅵᄏ: "뾬",
    뽀ᅵᄐ: "뾭",
    뽀ᅵᄑ: "뾮",
    뽀ᅵᄒ: "뾯",
    뾰: "뾰",
    뾰ᄀ: "뾱",
    뾰ᄁ: "뾲",
    뾰ᄀᄉ: "뾳",
    뾰ᄂ: "뾴",
    뾰ᄂᄌ: "뾵",
    뾰ᄂᄒ: "뾶",
    뾰ᄃ: "뾷",
    뾰ᄅ: "뾸",
    뾰ᄅᄀ: "뾹",
    뾰ᄅᄆ: "뾺",
    뾰ᄅᄇ: "뾻",
    뾰ᄅᄉ: "뾼",
    뾰ᄅᄐ: "뾽",
    뾰ᄅᄑ: "뾾",
    뾰ᄅᄒ: "뾿",
    뾰ᄆ: "뿀",
    뾰ᄇ: "뿁",
    뾰ᄇᄉ: "뿂",
    뾰ᄉ: "뿃",
    뾰ᄊ: "뿄",
    뾰ᄋ: "뿅",
    뾰ᄌ: "뿆",
    뾰ᄎ: "뿇",
    뾰ᄏ: "뿈",
    뾰ᄐ: "뿉",
    뾰ᄑ: "뿊",
    뾰ᄒ: "뿋",
    뿌: "뿌",
    뿌ᄀ: "뿍",
    뿌ᄁ: "뿎",
    뿌ᄀᄉ: "뿏",
    뿌ᄂ: "뿐",
    뿌ᄂᄌ: "뿑",
    뿌ᄂᄒ: "뿒",
    뿌ᄃ: "뿓",
    뿌ᄅ: "뿔",
    뿌ᄅᄀ: "뿕",
    뿌ᄅᄆ: "뿖",
    뿌ᄅᄇ: "뿗",
    뿌ᄅᄉ: "뿘",
    뿌ᄅᄐ: "뿙",
    뿌ᄅᄑ: "뿚",
    뿌ᄅᄒ: "뿛",
    뿌ᄆ: "뿜",
    뿌ᄇ: "뿝",
    뿌ᄇᄉ: "뿞",
    뿌ᄉ: "뿟",
    뿌ᄊ: "뿠",
    뿌ᄋ: "뿡",
    뿌ᄌ: "뿢",
    뿌ᄎ: "뿣",
    뿌ᄏ: "뿤",
    뿌ᄐ: "뿥",
    뿌ᄑ: "뿦",
    뿌ᄒ: "뿧",
    뿌ᅥ: "뿨",
    뿌ᅥᄀ: "뿩",
    뿌ᅥᄁ: "뿪",
    뿌ᅥᄀᄉ: "뿫",
    뿌ᅥᄂ: "뿬",
    뿌ᅥᄂᄌ: "뿭",
    뿌ᅥᄂᄒ: "뿮",
    뿌ᅥᄃ: "뿯",
    뿌ᅥᄅ: "뿰",
    뿌ᅥᄅᄀ: "뿱",
    뿌ᅥᄅᄆ: "뿲",
    뿌ᅥᄅᄇ: "뿳",
    뿌ᅥᄅᄉ: "뿴",
    뿌ᅥᄅᄐ: "뿵",
    뿌ᅥᄅᄑ: "뿶",
    뿌ᅥᄅᄒ: "뿷",
    뿌ᅥᄆ: "뿸",
    뿌ᅥᄇ: "뿹",
    뿌ᅥᄇᄉ: "뿺",
    뿌ᅥᄉ: "뿻",
    뿌ᅥᄊ: "뿼",
    뿌ᅥᄋ: "뿽",
    뿌ᅥᄌ: "뿾",
    뿌ᅥᄎ: "뿿",
    뿌ᅥᄏ: "쀀",
    뿌ᅥᄐ: "쀁",
    뿌ᅥᄑ: "쀂",
    뿌ᅥᄒ: "쀃",
    뿌ᅦ: "쀄",
    뿌ᅦᄀ: "쀅",
    뿌ᅦᄁ: "쀆",
    뿌ᅦᄀᄉ: "쀇",
    뿌ᅦᄂ: "쀈",
    뿌ᅦᄂᄌ: "쀉",
    뿌ᅦᄂᄒ: "쀊",
    뿌ᅦᄃ: "쀋",
    뿌ᅦᄅ: "쀌",
    뿌ᅦᄅᄀ: "쀍",
    뿌ᅦᄅᄆ: "쀎",
    뿌ᅦᄅᄇ: "쀏",
    뿌ᅦᄅᄉ: "쀐",
    뿌ᅦᄅᄐ: "쀑",
    뿌ᅦᄅᄑ: "쀒",
    뿌ᅦᄅᄒ: "쀓",
    뿌ᅦᄆ: "쀔",
    뿌ᅦᄇ: "쀕",
    뿌ᅦᄇᄉ: "쀖",
    뿌ᅦᄉ: "쀗",
    뿌ᅦᄊ: "쀘",
    뿌ᅦᄋ: "쀙",
    뿌ᅦᄌ: "쀚",
    뿌ᅦᄎ: "쀛",
    뿌ᅦᄏ: "쀜",
    뿌ᅦᄐ: "쀝",
    뿌ᅦᄑ: "쀞",
    뿌ᅦᄒ: "쀟",
    뿌ᅵ: "쀠",
    뿌ᅵᄀ: "쀡",
    뿌ᅵᄁ: "쀢",
    뿌ᅵᄀᄉ: "쀣",
    뿌ᅵᄂ: "쀤",
    뿌ᅵᄂᄌ: "쀥",
    뿌ᅵᄂᄒ: "쀦",
    뿌ᅵᄃ: "쀧",
    뿌ᅵᄅ: "쀨",
    뿌ᅵᄅᄀ: "쀩",
    뿌ᅵᄅᄆ: "쀪",
    뿌ᅵᄅᄇ: "쀫",
    뿌ᅵᄅᄉ: "쀬",
    뿌ᅵᄅᄐ: "쀭",
    뿌ᅵᄅᄑ: "쀮",
    뿌ᅵᄅᄒ: "쀯",
    뿌ᅵᄆ: "쀰",
    뿌ᅵᄇ: "쀱",
    뿌ᅵᄇᄉ: "쀲",
    뿌ᅵᄉ: "쀳",
    뿌ᅵᄊ: "쀴",
    뿌ᅵᄋ: "쀵",
    뿌ᅵᄌ: "쀶",
    뿌ᅵᄎ: "쀷",
    뿌ᅵᄏ: "쀸",
    뿌ᅵᄐ: "쀹",
    뿌ᅵᄑ: "쀺",
    뿌ᅵᄒ: "쀻",
    쀼: "쀼",
    쀼ᄀ: "쀽",
    쀼ᄁ: "쀾",
    쀼ᄀᄉ: "쀿",
    쀼ᄂ: "쁀",
    쀼ᄂᄌ: "쁁",
    쀼ᄂᄒ: "쁂",
    쀼ᄃ: "쁃",
    쀼ᄅ: "쁄",
    쀼ᄅᄀ: "쁅",
    쀼ᄅᄆ: "쁆",
    쀼ᄅᄇ: "쁇",
    쀼ᄅᄉ: "쁈",
    쀼ᄅᄐ: "쁉",
    쀼ᄅᄑ: "쁊",
    쀼ᄅᄒ: "쁋",
    쀼ᄆ: "쁌",
    쀼ᄇ: "쁍",
    쀼ᄇᄉ: "쁎",
    쀼ᄉ: "쁏",
    쀼ᄊ: "쁐",
    쀼ᄋ: "쁑",
    쀼ᄌ: "쁒",
    쀼ᄎ: "쁓",
    쀼ᄏ: "쁔",
    쀼ᄐ: "쁕",
    쀼ᄑ: "쁖",
    쀼ᄒ: "쁗",
    쁘: "쁘",
    쁘ᄀ: "쁙",
    쁘ᄁ: "쁚",
    쁘ᄀᄉ: "쁛",
    쁘ᄂ: "쁜",
    쁘ᄂᄌ: "쁝",
    쁘ᄂᄒ: "쁞",
    쁘ᄃ: "쁟",
    쁘ᄅ: "쁠",
    쁘ᄅᄀ: "쁡",
    쁘ᄅᄆ: "쁢",
    쁘ᄅᄇ: "쁣",
    쁘ᄅᄉ: "쁤",
    쁘ᄅᄐ: "쁥",
    쁘ᄅᄑ: "쁦",
    쁘ᄅᄒ: "쁧",
    쁘ᄆ: "쁨",
    쁘ᄇ: "쁩",
    쁘ᄇᄉ: "쁪",
    쁘ᄉ: "쁫",
    쁘ᄊ: "쁬",
    쁘ᄋ: "쁭",
    쁘ᄌ: "쁮",
    쁘ᄎ: "쁯",
    쁘ᄏ: "쁰",
    쁘ᄐ: "쁱",
    쁘ᄑ: "쁲",
    쁘ᄒ: "쁳",
    쁘ᅵ: "쁴",
    쁘ᅵᄀ: "쁵",
    쁘ᅵᄁ: "쁶",
    쁘ᅵᄀᄉ: "쁷",
    쁘ᅵᄂ: "쁸",
    쁘ᅵᄂᄌ: "쁹",
    쁘ᅵᄂᄒ: "쁺",
    쁘ᅵᄃ: "쁻",
    쁘ᅵᄅ: "쁼",
    쁘ᅵᄅᄀ: "쁽",
    쁘ᅵᄅᄆ: "쁾",
    쁘ᅵᄅᄇ: "쁿",
    쁘ᅵᄅᄉ: "삀",
    쁘ᅵᄅᄐ: "삁",
    쁘ᅵᄅᄑ: "삂",
    쁘ᅵᄅᄒ: "삃",
    쁘ᅵᄆ: "삄",
    쁘ᅵᄇ: "삅",
    쁘ᅵᄇᄉ: "삆",
    쁘ᅵᄉ: "삇",
    쁘ᅵᄊ: "삈",
    쁘ᅵᄋ: "삉",
    쁘ᅵᄌ: "삊",
    쁘ᅵᄎ: "삋",
    쁘ᅵᄏ: "삌",
    쁘ᅵᄐ: "삍",
    쁘ᅵᄑ: "삎",
    쁘ᅵᄒ: "삏",
    삐: "삐",
    삐ᄀ: "삑",
    삐ᄁ: "삒",
    삐ᄀᄉ: "삓",
    삐ᄂ: "삔",
    삐ᄂᄌ: "삕",
    삐ᄂᄒ: "삖",
    삐ᄃ: "삗",
    삐ᄅ: "삘",
    삐ᄅᄀ: "삙",
    삐ᄅᄆ: "삚",
    삐ᄅᄇ: "삛",
    삐ᄅᄉ: "삜",
    삐ᄅᄐ: "삝",
    삐ᄅᄑ: "삞",
    삐ᄅᄒ: "삟",
    삐ᄆ: "삠",
    삐ᄇ: "삡",
    삐ᄇᄉ: "삢",
    삐ᄉ: "삣",
    삐ᄊ: "삤",
    삐ᄋ: "삥",
    삐ᄌ: "삦",
    삐ᄎ: "삧",
    삐ᄏ: "삨",
    삐ᄐ: "삩",
    삐ᄑ: "삪",
    삐ᄒ: "삫",
    사: "사",
    사ᄀ: "삭",
    사ᄁ: "삮",
    사ᄀᄉ: "삯",
    사ᄂ: "산",
    사ᄂᄌ: "삱",
    사ᄂᄒ: "삲",
    사ᄃ: "삳",
    사ᄅ: "살",
    사ᄅᄀ: "삵",
    사ᄅᄆ: "삶",
    사ᄅᄇ: "삷",
    사ᄅᄉ: "삸",
    사ᄅᄐ: "삹",
    사ᄅᄑ: "삺",
    사ᄅᄒ: "삻",
    사ᄆ: "삼",
    사ᄇ: "삽",
    사ᄇᄉ: "삾",
    사ᄉ: "삿",
    사ᄊ: "샀",
    사ᄋ: "상",
    사ᄌ: "샂",
    사ᄎ: "샃",
    사ᄏ: "샄",
    사ᄐ: "샅",
    사ᄑ: "샆",
    사ᄒ: "샇",
    새: "새",
    새ᄀ: "색",
    새ᄁ: "샊",
    새ᄀᄉ: "샋",
    새ᄂ: "샌",
    새ᄂᄌ: "샍",
    새ᄂᄒ: "샎",
    새ᄃ: "샏",
    새ᄅ: "샐",
    새ᄅᄀ: "샑",
    새ᄅᄆ: "샒",
    새ᄅᄇ: "샓",
    새ᄅᄉ: "샔",
    새ᄅᄐ: "샕",
    새ᄅᄑ: "샖",
    새ᄅᄒ: "샗",
    새ᄆ: "샘",
    새ᄇ: "샙",
    새ᄇᄉ: "샚",
    새ᄉ: "샛",
    새ᄊ: "샜",
    새ᄋ: "생",
    새ᄌ: "샞",
    새ᄎ: "샟",
    새ᄏ: "샠",
    새ᄐ: "샡",
    새ᄑ: "샢",
    새ᄒ: "샣",
    샤: "샤",
    샤ᄀ: "샥",
    샤ᄁ: "샦",
    샤ᄀᄉ: "샧",
    샤ᄂ: "샨",
    샤ᄂᄌ: "샩",
    샤ᄂᄒ: "샪",
    샤ᄃ: "샫",
    샤ᄅ: "샬",
    샤ᄅᄀ: "샭",
    샤ᄅᄆ: "샮",
    샤ᄅᄇ: "샯",
    샤ᄅᄉ: "샰",
    샤ᄅᄐ: "샱",
    샤ᄅᄑ: "샲",
    샤ᄅᄒ: "샳",
    샤ᄆ: "샴",
    샤ᄇ: "샵",
    샤ᄇᄉ: "샶",
    샤ᄉ: "샷",
    샤ᄊ: "샸",
    샤ᄋ: "샹",
    샤ᄌ: "샺",
    샤ᄎ: "샻",
    샤ᄏ: "샼",
    샤ᄐ: "샽",
    샤ᄑ: "샾",
    샤ᄒ: "샿",
    섀: "섀",
    섀ᄀ: "섁",
    섀ᄁ: "섂",
    섀ᄀᄉ: "섃",
    섀ᄂ: "섄",
    섀ᄂᄌ: "섅",
    섀ᄂᄒ: "섆",
    섀ᄃ: "섇",
    섀ᄅ: "섈",
    섀ᄅᄀ: "섉",
    섀ᄅᄆ: "섊",
    섀ᄅᄇ: "섋",
    섀ᄅᄉ: "섌",
    섀ᄅᄐ: "섍",
    섀ᄅᄑ: "섎",
    섀ᄅᄒ: "섏",
    섀ᄆ: "섐",
    섀ᄇ: "섑",
    섀ᄇᄉ: "섒",
    섀ᄉ: "섓",
    섀ᄊ: "섔",
    섀ᄋ: "섕",
    섀ᄌ: "섖",
    섀ᄎ: "섗",
    섀ᄏ: "섘",
    섀ᄐ: "섙",
    섀ᄑ: "섚",
    섀ᄒ: "섛",
    서: "서",
    서ᄀ: "석",
    서ᄁ: "섞",
    서ᄀᄉ: "섟",
    서ᄂ: "선",
    서ᄂᄌ: "섡",
    서ᄂᄒ: "섢",
    서ᄃ: "섣",
    서ᄅ: "설",
    서ᄅᄀ: "섥",
    서ᄅᄆ: "섦",
    서ᄅᄇ: "섧",
    서ᄅᄉ: "섨",
    서ᄅᄐ: "섩",
    서ᄅᄑ: "섪",
    서ᄅᄒ: "섫",
    서ᄆ: "섬",
    서ᄇ: "섭",
    서ᄇᄉ: "섮",
    서ᄉ: "섯",
    서ᄊ: "섰",
    서ᄋ: "성",
    서ᄌ: "섲",
    서ᄎ: "섳",
    서ᄏ: "섴",
    서ᄐ: "섵",
    서ᄑ: "섶",
    서ᄒ: "섷",
    세: "세",
    세ᄀ: "섹",
    세ᄁ: "섺",
    세ᄀᄉ: "섻",
    세ᄂ: "센",
    세ᄂᄌ: "섽",
    세ᄂᄒ: "섾",
    세ᄃ: "섿",
    세ᄅ: "셀",
    세ᄅᄀ: "셁",
    세ᄅᄆ: "셂",
    세ᄅᄇ: "셃",
    세ᄅᄉ: "셄",
    세ᄅᄐ: "셅",
    세ᄅᄑ: "셆",
    세ᄅᄒ: "셇",
    세ᄆ: "셈",
    세ᄇ: "셉",
    세ᄇᄉ: "셊",
    세ᄉ: "셋",
    세ᄊ: "셌",
    세ᄋ: "셍",
    세ᄌ: "셎",
    세ᄎ: "셏",
    세ᄏ: "셐",
    세ᄐ: "셑",
    세ᄑ: "셒",
    세ᄒ: "셓",
    셔: "셔",
    셔ᄀ: "셕",
    셔ᄁ: "셖",
    셔ᄀᄉ: "셗",
    셔ᄂ: "션",
    셔ᄂᄌ: "셙",
    셔ᄂᄒ: "셚",
    셔ᄃ: "셛",
    셔ᄅ: "셜",
    셔ᄅᄀ: "셝",
    셔ᄅᄆ: "셞",
    셔ᄅᄇ: "셟",
    셔ᄅᄉ: "셠",
    셔ᄅᄐ: "셡",
    셔ᄅᄑ: "셢",
    셔ᄅᄒ: "셣",
    셔ᄆ: "셤",
    셔ᄇ: "셥",
    셔ᄇᄉ: "셦",
    셔ᄉ: "셧",
    셔ᄊ: "셨",
    셔ᄋ: "셩",
    셔ᄌ: "셪",
    셔ᄎ: "셫",
    셔ᄏ: "셬",
    셔ᄐ: "셭",
    셔ᄑ: "셮",
    셔ᄒ: "셯",
    셰: "셰",
    셰ᄀ: "셱",
    셰ᄁ: "셲",
    셰ᄀᄉ: "셳",
    셰ᄂ: "셴",
    셰ᄂᄌ: "셵",
    셰ᄂᄒ: "셶",
    셰ᄃ: "셷",
    셰ᄅ: "셸",
    셰ᄅᄀ: "셹",
    셰ᄅᄆ: "셺",
    셰ᄅᄇ: "셻",
    셰ᄅᄉ: "셼",
    셰ᄅᄐ: "셽",
    셰ᄅᄑ: "셾",
    셰ᄅᄒ: "셿",
    셰ᄆ: "솀",
    셰ᄇ: "솁",
    셰ᄇᄉ: "솂",
    셰ᄉ: "솃",
    셰ᄊ: "솄",
    셰ᄋ: "솅",
    셰ᄌ: "솆",
    셰ᄎ: "솇",
    셰ᄏ: "솈",
    셰ᄐ: "솉",
    셰ᄑ: "솊",
    셰ᄒ: "솋",
    소: "소",
    소ᄀ: "속",
    소ᄁ: "솎",
    소ᄀᄉ: "솏",
    소ᄂ: "손",
    소ᄂᄌ: "솑",
    소ᄂᄒ: "솒",
    소ᄃ: "솓",
    소ᄅ: "솔",
    소ᄅᄀ: "솕",
    소ᄅᄆ: "솖",
    소ᄅᄇ: "솗",
    소ᄅᄉ: "솘",
    소ᄅᄐ: "솙",
    소ᄅᄑ: "솚",
    소ᄅᄒ: "솛",
    소ᄆ: "솜",
    소ᄇ: "솝",
    소ᄇᄉ: "솞",
    소ᄉ: "솟",
    소ᄊ: "솠",
    소ᄋ: "송",
    소ᄌ: "솢",
    소ᄎ: "솣",
    소ᄏ: "솤",
    소ᄐ: "솥",
    소ᄑ: "솦",
    소ᄒ: "솧",
    소ᅡ: "솨",
    소ᅡᄀ: "솩",
    소ᅡᄁ: "솪",
    소ᅡᄀᄉ: "솫",
    소ᅡᄂ: "솬",
    소ᅡᄂᄌ: "솭",
    소ᅡᄂᄒ: "솮",
    소ᅡᄃ: "솯",
    소ᅡᄅ: "솰",
    소ᅡᄅᄀ: "솱",
    소ᅡᄅᄆ: "솲",
    소ᅡᄅᄇ: "솳",
    소ᅡᄅᄉ: "솴",
    소ᅡᄅᄐ: "솵",
    소ᅡᄅᄑ: "솶",
    소ᅡᄅᄒ: "솷",
    소ᅡᄆ: "솸",
    소ᅡᄇ: "솹",
    소ᅡᄇᄉ: "솺",
    소ᅡᄉ: "솻",
    소ᅡᄊ: "솼",
    소ᅡᄋ: "솽",
    소ᅡᄌ: "솾",
    소ᅡᄎ: "솿",
    소ᅡᄏ: "쇀",
    소ᅡᄐ: "쇁",
    소ᅡᄑ: "쇂",
    소ᅡᄒ: "쇃",
    소ᅢ: "쇄",
    소ᅢᄀ: "쇅",
    소ᅢᄁ: "쇆",
    소ᅢᄀᄉ: "쇇",
    소ᅢᄂ: "쇈",
    소ᅢᄂᄌ: "쇉",
    소ᅢᄂᄒ: "쇊",
    소ᅢᄃ: "쇋",
    소ᅢᄅ: "쇌",
    소ᅢᄅᄀ: "쇍",
    소ᅢᄅᄆ: "쇎",
    소ᅢᄅᄇ: "쇏",
    소ᅢᄅᄉ: "쇐",
    소ᅢᄅᄐ: "쇑",
    소ᅢᄅᄑ: "쇒",
    소ᅢᄅᄒ: "쇓",
    소ᅢᄆ: "쇔",
    소ᅢᄇ: "쇕",
    소ᅢᄇᄉ: "쇖",
    소ᅢᄉ: "쇗",
    소ᅢᄊ: "쇘",
    소ᅢᄋ: "쇙",
    소ᅢᄌ: "쇚",
    소ᅢᄎ: "쇛",
    소ᅢᄏ: "쇜",
    소ᅢᄐ: "쇝",
    소ᅢᄑ: "쇞",
    소ᅢᄒ: "쇟",
    소ᅵ: "쇠",
    소ᅵᄀ: "쇡",
    소ᅵᄁ: "쇢",
    소ᅵᄀᄉ: "쇣",
    소ᅵᄂ: "쇤",
    소ᅵᄂᄌ: "쇥",
    소ᅵᄂᄒ: "쇦",
    소ᅵᄃ: "쇧",
    소ᅵᄅ: "쇨",
    소ᅵᄅᄀ: "쇩",
    소ᅵᄅᄆ: "쇪",
    소ᅵᄅᄇ: "쇫",
    소ᅵᄅᄉ: "쇬",
    소ᅵᄅᄐ: "쇭",
    소ᅵᄅᄑ: "쇮",
    소ᅵᄅᄒ: "쇯",
    소ᅵᄆ: "쇰",
    소ᅵᄇ: "쇱",
    소ᅵᄇᄉ: "쇲",
    소ᅵᄉ: "쇳",
    소ᅵᄊ: "쇴",
    소ᅵᄋ: "쇵",
    소ᅵᄌ: "쇶",
    소ᅵᄎ: "쇷",
    소ᅵᄏ: "쇸",
    소ᅵᄐ: "쇹",
    소ᅵᄑ: "쇺",
    소ᅵᄒ: "쇻",
    쇼: "쇼",
    쇼ᄀ: "쇽",
    쇼ᄁ: "쇾",
    쇼ᄀᄉ: "쇿",
    쇼ᄂ: "숀",
    쇼ᄂᄌ: "숁",
    쇼ᄂᄒ: "숂",
    쇼ᄃ: "숃",
    쇼ᄅ: "숄",
    쇼ᄅᄀ: "숅",
    쇼ᄅᄆ: "숆",
    쇼ᄅᄇ: "숇",
    쇼ᄅᄉ: "숈",
    쇼ᄅᄐ: "숉",
    쇼ᄅᄑ: "숊",
    쇼ᄅᄒ: "숋",
    쇼ᄆ: "숌",
    쇼ᄇ: "숍",
    쇼ᄇᄉ: "숎",
    쇼ᄉ: "숏",
    쇼ᄊ: "숐",
    쇼ᄋ: "숑",
    쇼ᄌ: "숒",
    쇼ᄎ: "숓",
    쇼ᄏ: "숔",
    쇼ᄐ: "숕",
    쇼ᄑ: "숖",
    쇼ᄒ: "숗",
    수: "수",
    수ᄀ: "숙",
    수ᄁ: "숚",
    수ᄀᄉ: "숛",
    수ᄂ: "순",
    수ᄂᄌ: "숝",
    수ᄂᄒ: "숞",
    수ᄃ: "숟",
    수ᄅ: "술",
    수ᄅᄀ: "숡",
    수ᄅᄆ: "숢",
    수ᄅᄇ: "숣",
    수ᄅᄉ: "숤",
    수ᄅᄐ: "숥",
    수ᄅᄑ: "숦",
    수ᄅᄒ: "숧",
    수ᄆ: "숨",
    수ᄇ: "숩",
    수ᄇᄉ: "숪",
    수ᄉ: "숫",
    수ᄊ: "숬",
    수ᄋ: "숭",
    수ᄌ: "숮",
    수ᄎ: "숯",
    수ᄏ: "숰",
    수ᄐ: "숱",
    수ᄑ: "숲",
    수ᄒ: "숳",
    수ᅥ: "숴",
    수ᅥᄀ: "숵",
    수ᅥᄁ: "숶",
    수ᅥᄀᄉ: "숷",
    수ᅥᄂ: "숸",
    수ᅥᄂᄌ: "숹",
    수ᅥᄂᄒ: "숺",
    수ᅥᄃ: "숻",
    수ᅥᄅ: "숼",
    수ᅥᄅᄀ: "숽",
    수ᅥᄅᄆ: "숾",
    수ᅥᄅᄇ: "숿",
    수ᅥᄅᄉ: "쉀",
    수ᅥᄅᄐ: "쉁",
    수ᅥᄅᄑ: "쉂",
    수ᅥᄅᄒ: "쉃",
    수ᅥᄆ: "쉄",
    수ᅥᄇ: "쉅",
    수ᅥᄇᄉ: "쉆",
    수ᅥᄉ: "쉇",
    수ᅥᄊ: "쉈",
    수ᅥᄋ: "쉉",
    수ᅥᄌ: "쉊",
    수ᅥᄎ: "쉋",
    수ᅥᄏ: "쉌",
    수ᅥᄐ: "쉍",
    수ᅥᄑ: "쉎",
    수ᅥᄒ: "쉏",
    수ᅦ: "쉐",
    수ᅦᄀ: "쉑",
    수ᅦᄁ: "쉒",
    수ᅦᄀᄉ: "쉓",
    수ᅦᄂ: "쉔",
    수ᅦᄂᄌ: "쉕",
    수ᅦᄂᄒ: "쉖",
    수ᅦᄃ: "쉗",
    수ᅦᄅ: "쉘",
    수ᅦᄅᄀ: "쉙",
    수ᅦᄅᄆ: "쉚",
    수ᅦᄅᄇ: "쉛",
    수ᅦᄅᄉ: "쉜",
    수ᅦᄅᄐ: "쉝",
    수ᅦᄅᄑ: "쉞",
    수ᅦᄅᄒ: "쉟",
    수ᅦᄆ: "쉠",
    수ᅦᄇ: "쉡",
    수ᅦᄇᄉ: "쉢",
    수ᅦᄉ: "쉣",
    수ᅦᄊ: "쉤",
    수ᅦᄋ: "쉥",
    수ᅦᄌ: "쉦",
    수ᅦᄎ: "쉧",
    수ᅦᄏ: "쉨",
    수ᅦᄐ: "쉩",
    수ᅦᄑ: "쉪",
    수ᅦᄒ: "쉫",
    수ᅵ: "쉬",
    수ᅵᄀ: "쉭",
    수ᅵᄁ: "쉮",
    수ᅵᄀᄉ: "쉯",
    수ᅵᄂ: "쉰",
    수ᅵᄂᄌ: "쉱",
    수ᅵᄂᄒ: "쉲",
    수ᅵᄃ: "쉳",
    수ᅵᄅ: "쉴",
    수ᅵᄅᄀ: "쉵",
    수ᅵᄅᄆ: "쉶",
    수ᅵᄅᄇ: "쉷",
    수ᅵᄅᄉ: "쉸",
    수ᅵᄅᄐ: "쉹",
    수ᅵᄅᄑ: "쉺",
    수ᅵᄅᄒ: "쉻",
    수ᅵᄆ: "쉼",
    수ᅵᄇ: "쉽",
    수ᅵᄇᄉ: "쉾",
    수ᅵᄉ: "쉿",
    수ᅵᄊ: "슀",
    수ᅵᄋ: "슁",
    수ᅵᄌ: "슂",
    수ᅵᄎ: "슃",
    수ᅵᄏ: "슄",
    수ᅵᄐ: "슅",
    수ᅵᄑ: "슆",
    수ᅵᄒ: "슇",
    슈: "슈",
    슈ᄀ: "슉",
    슈ᄁ: "슊",
    슈ᄀᄉ: "슋",
    슈ᄂ: "슌",
    슈ᄂᄌ: "슍",
    슈ᄂᄒ: "슎",
    슈ᄃ: "슏",
    슈ᄅ: "슐",
    슈ᄅᄀ: "슑",
    슈ᄅᄆ: "슒",
    슈ᄅᄇ: "슓",
    슈ᄅᄉ: "슔",
    슈ᄅᄐ: "슕",
    슈ᄅᄑ: "슖",
    슈ᄅᄒ: "슗",
    슈ᄆ: "슘",
    슈ᄇ: "슙",
    슈ᄇᄉ: "슚",
    슈ᄉ: "슛",
    슈ᄊ: "슜",
    슈ᄋ: "슝",
    슈ᄌ: "슞",
    슈ᄎ: "슟",
    슈ᄏ: "슠",
    슈ᄐ: "슡",
    슈ᄑ: "슢",
    슈ᄒ: "슣",
    스: "스",
    스ᄀ: "슥",
    스ᄁ: "슦",
    스ᄀᄉ: "슧",
    스ᄂ: "슨",
    스ᄂᄌ: "슩",
    스ᄂᄒ: "슪",
    스ᄃ: "슫",
    스ᄅ: "슬",
    스ᄅᄀ: "슭",
    스ᄅᄆ: "슮",
    스ᄅᄇ: "슯",
    스ᄅᄉ: "슰",
    스ᄅᄐ: "슱",
    스ᄅᄑ: "슲",
    스ᄅᄒ: "슳",
    스ᄆ: "슴",
    스ᄇ: "습",
    스ᄇᄉ: "슶",
    스ᄉ: "슷",
    스ᄊ: "슸",
    스ᄋ: "승",
    스ᄌ: "슺",
    스ᄎ: "슻",
    스ᄏ: "슼",
    스ᄐ: "슽",
    스ᄑ: "슾",
    스ᄒ: "슿",
    스ᅵ: "싀",
    스ᅵᄀ: "싁",
    스ᅵᄁ: "싂",
    스ᅵᄀᄉ: "싃",
    스ᅵᄂ: "싄",
    스ᅵᄂᄌ: "싅",
    스ᅵᄂᄒ: "싆",
    스ᅵᄃ: "싇",
    스ᅵᄅ: "싈",
    스ᅵᄅᄀ: "싉",
    스ᅵᄅᄆ: "싊",
    스ᅵᄅᄇ: "싋",
    스ᅵᄅᄉ: "싌",
    스ᅵᄅᄐ: "싍",
    스ᅵᄅᄑ: "싎",
    스ᅵᄅᄒ: "싏",
    스ᅵᄆ: "싐",
    스ᅵᄇ: "싑",
    스ᅵᄇᄉ: "싒",
    스ᅵᄉ: "싓",
    스ᅵᄊ: "싔",
    스ᅵᄋ: "싕",
    스ᅵᄌ: "싖",
    스ᅵᄎ: "싗",
    스ᅵᄏ: "싘",
    스ᅵᄐ: "싙",
    스ᅵᄑ: "싚",
    스ᅵᄒ: "싛",
    시: "시",
    시ᄀ: "식",
    시ᄁ: "싞",
    시ᄀᄉ: "싟",
    시ᄂ: "신",
    시ᄂᄌ: "싡",
    시ᄂᄒ: "싢",
    시ᄃ: "싣",
    시ᄅ: "실",
    시ᄅᄀ: "싥",
    시ᄅᄆ: "싦",
    시ᄅᄇ: "싧",
    시ᄅᄉ: "싨",
    시ᄅᄐ: "싩",
    시ᄅᄑ: "싪",
    시ᄅᄒ: "싫",
    시ᄆ: "심",
    시ᄇ: "십",
    시ᄇᄉ: "싮",
    시ᄉ: "싯",
    시ᄊ: "싰",
    시ᄋ: "싱",
    시ᄌ: "싲",
    시ᄎ: "싳",
    시ᄏ: "싴",
    시ᄐ: "싵",
    시ᄑ: "싶",
    시ᄒ: "싷",
    싸: "싸",
    싸ᄀ: "싹",
    싸ᄁ: "싺",
    싸ᄀᄉ: "싻",
    싸ᄂ: "싼",
    싸ᄂᄌ: "싽",
    싸ᄂᄒ: "싾",
    싸ᄃ: "싿",
    싸ᄅ: "쌀",
    싸ᄅᄀ: "쌁",
    싸ᄅᄆ: "쌂",
    싸ᄅᄇ: "쌃",
    싸ᄅᄉ: "쌄",
    싸ᄅᄐ: "쌅",
    싸ᄅᄑ: "쌆",
    싸ᄅᄒ: "쌇",
    싸ᄆ: "쌈",
    싸ᄇ: "쌉",
    싸ᄇᄉ: "쌊",
    싸ᄉ: "쌋",
    싸ᄊ: "쌌",
    싸ᄋ: "쌍",
    싸ᄌ: "쌎",
    싸ᄎ: "쌏",
    싸ᄏ: "쌐",
    싸ᄐ: "쌑",
    싸ᄑ: "쌒",
    싸ᄒ: "쌓",
    쌔: "쌔",
    쌔ᄀ: "쌕",
    쌔ᄁ: "쌖",
    쌔ᄀᄉ: "쌗",
    쌔ᄂ: "쌘",
    쌔ᄂᄌ: "쌙",
    쌔ᄂᄒ: "쌚",
    쌔ᄃ: "쌛",
    쌔ᄅ: "쌜",
    쌔ᄅᄀ: "쌝",
    쌔ᄅᄆ: "쌞",
    쌔ᄅᄇ: "쌟",
    쌔ᄅᄉ: "쌠",
    쌔ᄅᄐ: "쌡",
    쌔ᄅᄑ: "쌢",
    쌔ᄅᄒ: "쌣",
    쌔ᄆ: "쌤",
    쌔ᄇ: "쌥",
    쌔ᄇᄉ: "쌦",
    쌔ᄉ: "쌧",
    쌔ᄊ: "쌨",
    쌔ᄋ: "쌩",
    쌔ᄌ: "쌪",
    쌔ᄎ: "쌫",
    쌔ᄏ: "쌬",
    쌔ᄐ: "쌭",
    쌔ᄑ: "쌮",
    쌔ᄒ: "쌯",
    쌰: "쌰",
    쌰ᄀ: "쌱",
    쌰ᄁ: "쌲",
    쌰ᄀᄉ: "쌳",
    쌰ᄂ: "쌴",
    쌰ᄂᄌ: "쌵",
    쌰ᄂᄒ: "쌶",
    쌰ᄃ: "쌷",
    쌰ᄅ: "쌸",
    쌰ᄅᄀ: "쌹",
    쌰ᄅᄆ: "쌺",
    쌰ᄅᄇ: "쌻",
    쌰ᄅᄉ: "쌼",
    쌰ᄅᄐ: "쌽",
    쌰ᄅᄑ: "쌾",
    쌰ᄅᄒ: "쌿",
    쌰ᄆ: "썀",
    쌰ᄇ: "썁",
    쌰ᄇᄉ: "썂",
    쌰ᄉ: "썃",
    쌰ᄊ: "썄",
    쌰ᄋ: "썅",
    쌰ᄌ: "썆",
    쌰ᄎ: "썇",
    쌰ᄏ: "썈",
    쌰ᄐ: "썉",
    쌰ᄑ: "썊",
    쌰ᄒ: "썋",
    썌: "썌",
    썌ᄀ: "썍",
    썌ᄁ: "썎",
    썌ᄀᄉ: "썏",
    썌ᄂ: "썐",
    썌ᄂᄌ: "썑",
    썌ᄂᄒ: "썒",
    썌ᄃ: "썓",
    썌ᄅ: "썔",
    썌ᄅᄀ: "썕",
    썌ᄅᄆ: "썖",
    썌ᄅᄇ: "썗",
    썌ᄅᄉ: "썘",
    썌ᄅᄐ: "썙",
    썌ᄅᄑ: "썚",
    썌ᄅᄒ: "썛",
    썌ᄆ: "썜",
    썌ᄇ: "썝",
    썌ᄇᄉ: "썞",
    썌ᄉ: "썟",
    썌ᄊ: "썠",
    썌ᄋ: "썡",
    썌ᄌ: "썢",
    썌ᄎ: "썣",
    썌ᄏ: "썤",
    썌ᄐ: "썥",
    썌ᄑ: "썦",
    썌ᄒ: "썧",
    써: "써",
    써ᄀ: "썩",
    써ᄁ: "썪",
    써ᄀᄉ: "썫",
    써ᄂ: "썬",
    써ᄂᄌ: "썭",
    써ᄂᄒ: "썮",
    써ᄃ: "썯",
    써ᄅ: "썰",
    써ᄅᄀ: "썱",
    써ᄅᄆ: "썲",
    써ᄅᄇ: "썳",
    써ᄅᄉ: "썴",
    써ᄅᄐ: "썵",
    써ᄅᄑ: "썶",
    써ᄅᄒ: "썷",
    써ᄆ: "썸",
    써ᄇ: "썹",
    써ᄇᄉ: "썺",
    써ᄉ: "썻",
    써ᄊ: "썼",
    써ᄋ: "썽",
    써ᄌ: "썾",
    써ᄎ: "썿",
    써ᄏ: "쎀",
    써ᄐ: "쎁",
    써ᄑ: "쎂",
    써ᄒ: "쎃",
    쎄: "쎄",
    쎄ᄀ: "쎅",
    쎄ᄁ: "쎆",
    쎄ᄀᄉ: "쎇",
    쎄ᄂ: "쎈",
    쎄ᄂᄌ: "쎉",
    쎄ᄂᄒ: "쎊",
    쎄ᄃ: "쎋",
    쎄ᄅ: "쎌",
    쎄ᄅᄀ: "쎍",
    쎄ᄅᄆ: "쎎",
    쎄ᄅᄇ: "쎏",
    쎄ᄅᄉ: "쎐",
    쎄ᄅᄐ: "쎑",
    쎄ᄅᄑ: "쎒",
    쎄ᄅᄒ: "쎓",
    쎄ᄆ: "쎔",
    쎄ᄇ: "쎕",
    쎄ᄇᄉ: "쎖",
    쎄ᄉ: "쎗",
    쎄ᄊ: "쎘",
    쎄ᄋ: "쎙",
    쎄ᄌ: "쎚",
    쎄ᄎ: "쎛",
    쎄ᄏ: "쎜",
    쎄ᄐ: "쎝",
    쎄ᄑ: "쎞",
    쎄ᄒ: "쎟",
    쎠: "쎠",
    쎠ᄀ: "쎡",
    쎠ᄁ: "쎢",
    쎠ᄀᄉ: "쎣",
    쎠ᄂ: "쎤",
    쎠ᄂᄌ: "쎥",
    쎠ᄂᄒ: "쎦",
    쎠ᄃ: "쎧",
    쎠ᄅ: "쎨",
    쎠ᄅᄀ: "쎩",
    쎠ᄅᄆ: "쎪",
    쎠ᄅᄇ: "쎫",
    쎠ᄅᄉ: "쎬",
    쎠ᄅᄐ: "쎭",
    쎠ᄅᄑ: "쎮",
    쎠ᄅᄒ: "쎯",
    쎠ᄆ: "쎰",
    쎠ᄇ: "쎱",
    쎠ᄇᄉ: "쎲",
    쎠ᄉ: "쎳",
    쎠ᄊ: "쎴",
    쎠ᄋ: "쎵",
    쎠ᄌ: "쎶",
    쎠ᄎ: "쎷",
    쎠ᄏ: "쎸",
    쎠ᄐ: "쎹",
    쎠ᄑ: "쎺",
    쎠ᄒ: "쎻",
    쎼: "쎼",
    쎼ᄀ: "쎽",
    쎼ᄁ: "쎾",
    쎼ᄀᄉ: "쎿",
    쎼ᄂ: "쏀",
    쎼ᄂᄌ: "쏁",
    쎼ᄂᄒ: "쏂",
    쎼ᄃ: "쏃",
    쎼ᄅ: "쏄",
    쎼ᄅᄀ: "쏅",
    쎼ᄅᄆ: "쏆",
    쎼ᄅᄇ: "쏇",
    쎼ᄅᄉ: "쏈",
    쎼ᄅᄐ: "쏉",
    쎼ᄅᄑ: "쏊",
    쎼ᄅᄒ: "쏋",
    쎼ᄆ: "쏌",
    쎼ᄇ: "쏍",
    쎼ᄇᄉ: "쏎",
    쎼ᄉ: "쏏",
    쎼ᄊ: "쏐",
    쎼ᄋ: "쏑",
    쎼ᄌ: "쏒",
    쎼ᄎ: "쏓",
    쎼ᄏ: "쏔",
    쎼ᄐ: "쏕",
    쎼ᄑ: "쏖",
    쎼ᄒ: "쏗",
    쏘: "쏘",
    쏘ᄀ: "쏙",
    쏘ᄁ: "쏚",
    쏘ᄀᄉ: "쏛",
    쏘ᄂ: "쏜",
    쏘ᄂᄌ: "쏝",
    쏘ᄂᄒ: "쏞",
    쏘ᄃ: "쏟",
    쏘ᄅ: "쏠",
    쏘ᄅᄀ: "쏡",
    쏘ᄅᄆ: "쏢",
    쏘ᄅᄇ: "쏣",
    쏘ᄅᄉ: "쏤",
    쏘ᄅᄐ: "쏥",
    쏘ᄅᄑ: "쏦",
    쏘ᄅᄒ: "쏧",
    쏘ᄆ: "쏨",
    쏘ᄇ: "쏩",
    쏘ᄇᄉ: "쏪",
    쏘ᄉ: "쏫",
    쏘ᄊ: "쏬",
    쏘ᄋ: "쏭",
    쏘ᄌ: "쏮",
    쏘ᄎ: "쏯",
    쏘ᄏ: "쏰",
    쏘ᄐ: "쏱",
    쏘ᄑ: "쏲",
    쏘ᄒ: "쏳",
    쏘ᅡ: "쏴",
    쏘ᅡᄀ: "쏵",
    쏘ᅡᄁ: "쏶",
    쏘ᅡᄀᄉ: "쏷",
    쏘ᅡᄂ: "쏸",
    쏘ᅡᄂᄌ: "쏹",
    쏘ᅡᄂᄒ: "쏺",
    쏘ᅡᄃ: "쏻",
    쏘ᅡᄅ: "쏼",
    쏘ᅡᄅᄀ: "쏽",
    쏘ᅡᄅᄆ: "쏾",
    쏘ᅡᄅᄇ: "쏿",
    쏘ᅡᄅᄉ: "쐀",
    쏘ᅡᄅᄐ: "쐁",
    쏘ᅡᄅᄑ: "쐂",
    쏘ᅡᄅᄒ: "쐃",
    쏘ᅡᄆ: "쐄",
    쏘ᅡᄇ: "쐅",
    쏘ᅡᄇᄉ: "쐆",
    쏘ᅡᄉ: "쐇",
    쏘ᅡᄊ: "쐈",
    쏘ᅡᄋ: "쐉",
    쏘ᅡᄌ: "쐊",
    쏘ᅡᄎ: "쐋",
    쏘ᅡᄏ: "쐌",
    쏘ᅡᄐ: "쐍",
    쏘ᅡᄑ: "쐎",
    쏘ᅡᄒ: "쐏",
    쏘ᅢ: "쐐",
    쏘ᅢᄀ: "쐑",
    쏘ᅢᄁ: "쐒",
    쏘ᅢᄀᄉ: "쐓",
    쏘ᅢᄂ: "쐔",
    쏘ᅢᄂᄌ: "쐕",
    쏘ᅢᄂᄒ: "쐖",
    쏘ᅢᄃ: "쐗",
    쏘ᅢᄅ: "쐘",
    쏘ᅢᄅᄀ: "쐙",
    쏘ᅢᄅᄆ: "쐚",
    쏘ᅢᄅᄇ: "쐛",
    쏘ᅢᄅᄉ: "쐜",
    쏘ᅢᄅᄐ: "쐝",
    쏘ᅢᄅᄑ: "쐞",
    쏘ᅢᄅᄒ: "쐟",
    쏘ᅢᄆ: "쐠",
    쏘ᅢᄇ: "쐡",
    쏘ᅢᄇᄉ: "쐢",
    쏘ᅢᄉ: "쐣",
    쏘ᅢᄊ: "쐤",
    쏘ᅢᄋ: "쐥",
    쏘ᅢᄌ: "쐦",
    쏘ᅢᄎ: "쐧",
    쏘ᅢᄏ: "쐨",
    쏘ᅢᄐ: "쐩",
    쏘ᅢᄑ: "쐪",
    쏘ᅢᄒ: "쐫",
    쏘ᅵ: "쐬",
    쏘ᅵᄀ: "쐭",
    쏘ᅵᄁ: "쐮",
    쏘ᅵᄀᄉ: "쐯",
    쏘ᅵᄂ: "쐰",
    쏘ᅵᄂᄌ: "쐱",
    쏘ᅵᄂᄒ: "쐲",
    쏘ᅵᄃ: "쐳",
    쏘ᅵᄅ: "쐴",
    쏘ᅵᄅᄀ: "쐵",
    쏘ᅵᄅᄆ: "쐶",
    쏘ᅵᄅᄇ: "쐷",
    쏘ᅵᄅᄉ: "쐸",
    쏘ᅵᄅᄐ: "쐹",
    쏘ᅵᄅᄑ: "쐺",
    쏘ᅵᄅᄒ: "쐻",
    쏘ᅵᄆ: "쐼",
    쏘ᅵᄇ: "쐽",
    쏘ᅵᄇᄉ: "쐾",
    쏘ᅵᄉ: "쐿",
    쏘ᅵᄊ: "쑀",
    쏘ᅵᄋ: "쑁",
    쏘ᅵᄌ: "쑂",
    쏘ᅵᄎ: "쑃",
    쏘ᅵᄏ: "쑄",
    쏘ᅵᄐ: "쑅",
    쏘ᅵᄑ: "쑆",
    쏘ᅵᄒ: "쑇",
    쑈: "쑈",
    쑈ᄀ: "쑉",
    쑈ᄁ: "쑊",
    쑈ᄀᄉ: "쑋",
    쑈ᄂ: "쑌",
    쑈ᄂᄌ: "쑍",
    쑈ᄂᄒ: "쑎",
    쑈ᄃ: "쑏",
    쑈ᄅ: "쑐",
    쑈ᄅᄀ: "쑑",
    쑈ᄅᄆ: "쑒",
    쑈ᄅᄇ: "쑓",
    쑈ᄅᄉ: "쑔",
    쑈ᄅᄐ: "쑕",
    쑈ᄅᄑ: "쑖",
    쑈ᄅᄒ: "쑗",
    쑈ᄆ: "쑘",
    쑈ᄇ: "쑙",
    쑈ᄇᄉ: "쑚",
    쑈ᄉ: "쑛",
    쑈ᄊ: "쑜",
    쑈ᄋ: "쑝",
    쑈ᄌ: "쑞",
    쑈ᄎ: "쑟",
    쑈ᄏ: "쑠",
    쑈ᄐ: "쑡",
    쑈ᄑ: "쑢",
    쑈ᄒ: "쑣",
    쑤: "쑤",
    쑤ᄀ: "쑥",
    쑤ᄁ: "쑦",
    쑤ᄀᄉ: "쑧",
    쑤ᄂ: "쑨",
    쑤ᄂᄌ: "쑩",
    쑤ᄂᄒ: "쑪",
    쑤ᄃ: "쑫",
    쑤ᄅ: "쑬",
    쑤ᄅᄀ: "쑭",
    쑤ᄅᄆ: "쑮",
    쑤ᄅᄇ: "쑯",
    쑤ᄅᄉ: "쑰",
    쑤ᄅᄐ: "쑱",
    쑤ᄅᄑ: "쑲",
    쑤ᄅᄒ: "쑳",
    쑤ᄆ: "쑴",
    쑤ᄇ: "쑵",
    쑤ᄇᄉ: "쑶",
    쑤ᄉ: "쑷",
    쑤ᄊ: "쑸",
    쑤ᄋ: "쑹",
    쑤ᄌ: "쑺",
    쑤ᄎ: "쑻",
    쑤ᄏ: "쑼",
    쑤ᄐ: "쑽",
    쑤ᄑ: "쑾",
    쑤ᄒ: "쑿",
    쑤ᅥ: "쒀",
    쑤ᅥᄀ: "쒁",
    쑤ᅥᄁ: "쒂",
    쑤ᅥᄀᄉ: "쒃",
    쑤ᅥᄂ: "쒄",
    쑤ᅥᄂᄌ: "쒅",
    쑤ᅥᄂᄒ: "쒆",
    쑤ᅥᄃ: "쒇",
    쑤ᅥᄅ: "쒈",
    쑤ᅥᄅᄀ: "쒉",
    쑤ᅥᄅᄆ: "쒊",
    쑤ᅥᄅᄇ: "쒋",
    쑤ᅥᄅᄉ: "쒌",
    쑤ᅥᄅᄐ: "쒍",
    쑤ᅥᄅᄑ: "쒎",
    쑤ᅥᄅᄒ: "쒏",
    쑤ᅥᄆ: "쒐",
    쑤ᅥᄇ: "쒑",
    쑤ᅥᄇᄉ: "쒒",
    쑤ᅥᄉ: "쒓",
    쑤ᅥᄊ: "쒔",
    쑤ᅥᄋ: "쒕",
    쑤ᅥᄌ: "쒖",
    쑤ᅥᄎ: "쒗",
    쑤ᅥᄏ: "쒘",
    쑤ᅥᄐ: "쒙",
    쑤ᅥᄑ: "쒚",
    쑤ᅥᄒ: "쒛",
    쑤ᅦ: "쒜",
    쑤ᅦᄀ: "쒝",
    쑤ᅦᄁ: "쒞",
    쑤ᅦᄀᄉ: "쒟",
    쑤ᅦᄂ: "쒠",
    쑤ᅦᄂᄌ: "쒡",
    쑤ᅦᄂᄒ: "쒢",
    쑤ᅦᄃ: "쒣",
    쑤ᅦᄅ: "쒤",
    쑤ᅦᄅᄀ: "쒥",
    쑤ᅦᄅᄆ: "쒦",
    쑤ᅦᄅᄇ: "쒧",
    쑤ᅦᄅᄉ: "쒨",
    쑤ᅦᄅᄐ: "쒩",
    쑤ᅦᄅᄑ: "쒪",
    쑤ᅦᄅᄒ: "쒫",
    쑤ᅦᄆ: "쒬",
    쑤ᅦᄇ: "쒭",
    쑤ᅦᄇᄉ: "쒮",
    쑤ᅦᄉ: "쒯",
    쑤ᅦᄊ: "쒰",
    쑤ᅦᄋ: "쒱",
    쑤ᅦᄌ: "쒲",
    쑤ᅦᄎ: "쒳",
    쑤ᅦᄏ: "쒴",
    쑤ᅦᄐ: "쒵",
    쑤ᅦᄑ: "쒶",
    쑤ᅦᄒ: "쒷",
    쑤ᅵ: "쒸",
    쑤ᅵᄀ: "쒹",
    쑤ᅵᄁ: "쒺",
    쑤ᅵᄀᄉ: "쒻",
    쑤ᅵᄂ: "쒼",
    쑤ᅵᄂᄌ: "쒽",
    쑤ᅵᄂᄒ: "쒾",
    쑤ᅵᄃ: "쒿",
    쑤ᅵᄅ: "쓀",
    쑤ᅵᄅᄀ: "쓁",
    쑤ᅵᄅᄆ: "쓂",
    쑤ᅵᄅᄇ: "쓃",
    쑤ᅵᄅᄉ: "쓄",
    쑤ᅵᄅᄐ: "쓅",
    쑤ᅵᄅᄑ: "쓆",
    쑤ᅵᄅᄒ: "쓇",
    쑤ᅵᄆ: "쓈",
    쑤ᅵᄇ: "쓉",
    쑤ᅵᄇᄉ: "쓊",
    쑤ᅵᄉ: "쓋",
    쑤ᅵᄊ: "쓌",
    쑤ᅵᄋ: "쓍",
    쑤ᅵᄌ: "쓎",
    쑤ᅵᄎ: "쓏",
    쑤ᅵᄏ: "쓐",
    쑤ᅵᄐ: "쓑",
    쑤ᅵᄑ: "쓒",
    쑤ᅵᄒ: "쓓",
    쓔: "쓔",
    쓔ᄀ: "쓕",
    쓔ᄁ: "쓖",
    쓔ᄀᄉ: "쓗",
    쓔ᄂ: "쓘",
    쓔ᄂᄌ: "쓙",
    쓔ᄂᄒ: "쓚",
    쓔ᄃ: "쓛",
    쓔ᄅ: "쓜",
    쓔ᄅᄀ: "쓝",
    쓔ᄅᄆ: "쓞",
    쓔ᄅᄇ: "쓟",
    쓔ᄅᄉ: "쓠",
    쓔ᄅᄐ: "쓡",
    쓔ᄅᄑ: "쓢",
    쓔ᄅᄒ: "쓣",
    쓔ᄆ: "쓤",
    쓔ᄇ: "쓥",
    쓔ᄇᄉ: "쓦",
    쓔ᄉ: "쓧",
    쓔ᄊ: "쓨",
    쓔ᄋ: "쓩",
    쓔ᄌ: "쓪",
    쓔ᄎ: "쓫",
    쓔ᄏ: "쓬",
    쓔ᄐ: "쓭",
    쓔ᄑ: "쓮",
    쓔ᄒ: "쓯",
    쓰: "쓰",
    쓰ᄀ: "쓱",
    쓰ᄁ: "쓲",
    쓰ᄀᄉ: "쓳",
    쓰ᄂ: "쓴",
    쓰ᄂᄌ: "쓵",
    쓰ᄂᄒ: "쓶",
    쓰ᄃ: "쓷",
    쓰ᄅ: "쓸",
    쓰ᄅᄀ: "쓹",
    쓰ᄅᄆ: "쓺",
    쓰ᄅᄇ: "쓻",
    쓰ᄅᄉ: "쓼",
    쓰ᄅᄐ: "쓽",
    쓰ᄅᄑ: "쓾",
    쓰ᄅᄒ: "쓿",
    쓰ᄆ: "씀",
    쓰ᄇ: "씁",
    쓰ᄇᄉ: "씂",
    쓰ᄉ: "씃",
    쓰ᄊ: "씄",
    쓰ᄋ: "씅",
    쓰ᄌ: "씆",
    쓰ᄎ: "씇",
    쓰ᄏ: "씈",
    쓰ᄐ: "씉",
    쓰ᄑ: "씊",
    쓰ᄒ: "씋",
    쓰ᅵ: "씌",
    쓰ᅵᄀ: "씍",
    쓰ᅵᄁ: "씎",
    쓰ᅵᄀᄉ: "씏",
    쓰ᅵᄂ: "씐",
    쓰ᅵᄂᄌ: "씑",
    쓰ᅵᄂᄒ: "씒",
    쓰ᅵᄃ: "씓",
    쓰ᅵᄅ: "씔",
    쓰ᅵᄅᄀ: "씕",
    쓰ᅵᄅᄆ: "씖",
    쓰ᅵᄅᄇ: "씗",
    쓰ᅵᄅᄉ: "씘",
    쓰ᅵᄅᄐ: "씙",
    쓰ᅵᄅᄑ: "씚",
    쓰ᅵᄅᄒ: "씛",
    쓰ᅵᄆ: "씜",
    쓰ᅵᄇ: "씝",
    쓰ᅵᄇᄉ: "씞",
    쓰ᅵᄉ: "씟",
    쓰ᅵᄊ: "씠",
    쓰ᅵᄋ: "씡",
    쓰ᅵᄌ: "씢",
    쓰ᅵᄎ: "씣",
    쓰ᅵᄏ: "씤",
    쓰ᅵᄐ: "씥",
    쓰ᅵᄑ: "씦",
    쓰ᅵᄒ: "씧",
    씨: "씨",
    씨ᄀ: "씩",
    씨ᄁ: "씪",
    씨ᄀᄉ: "씫",
    씨ᄂ: "씬",
    씨ᄂᄌ: "씭",
    씨ᄂᄒ: "씮",
    씨ᄃ: "씯",
    씨ᄅ: "씰",
    씨ᄅᄀ: "씱",
    씨ᄅᄆ: "씲",
    씨ᄅᄇ: "씳",
    씨ᄅᄉ: "씴",
    씨ᄅᄐ: "씵",
    씨ᄅᄑ: "씶",
    씨ᄅᄒ: "씷",
    씨ᄆ: "씸",
    씨ᄇ: "씹",
    씨ᄇᄉ: "씺",
    씨ᄉ: "씻",
    씨ᄊ: "씼",
    씨ᄋ: "씽",
    씨ᄌ: "씾",
    씨ᄎ: "씿",
    씨ᄏ: "앀",
    씨ᄐ: "앁",
    씨ᄑ: "앂",
    씨ᄒ: "앃",
    아: "아",
    아ᄀ: "악",
    아ᄁ: "앆",
    아ᄀᄉ: "앇",
    아ᄂ: "안",
    아ᄂᄌ: "앉",
    아ᄂᄒ: "않",
    아ᄃ: "앋",
    아ᄅ: "알",
    아ᄅᄀ: "앍",
    아ᄅᄆ: "앎",
    아ᄅᄇ: "앏",
    아ᄅᄉ: "앐",
    아ᄅᄐ: "앑",
    아ᄅᄑ: "앒",
    아ᄅᄒ: "앓",
    아ᄆ: "암",
    아ᄇ: "압",
    아ᄇᄉ: "앖",
    아ᄉ: "앗",
    아ᄊ: "았",
    아ᄋ: "앙",
    아ᄌ: "앚",
    아ᄎ: "앛",
    아ᄏ: "앜",
    아ᄐ: "앝",
    아ᄑ: "앞",
    아ᄒ: "앟",
    애: "애",
    애ᄀ: "액",
    애ᄁ: "앢",
    애ᄀᄉ: "앣",
    애ᄂ: "앤",
    애ᄂᄌ: "앥",
    애ᄂᄒ: "앦",
    애ᄃ: "앧",
    애ᄅ: "앨",
    애ᄅᄀ: "앩",
    애ᄅᄆ: "앪",
    애ᄅᄇ: "앫",
    애ᄅᄉ: "앬",
    애ᄅᄐ: "앭",
    애ᄅᄑ: "앮",
    애ᄅᄒ: "앯",
    애ᄆ: "앰",
    애ᄇ: "앱",
    애ᄇᄉ: "앲",
    애ᄉ: "앳",
    애ᄊ: "앴",
    애ᄋ: "앵",
    애ᄌ: "앶",
    애ᄎ: "앷",
    애ᄏ: "앸",
    애ᄐ: "앹",
    애ᄑ: "앺",
    애ᄒ: "앻",
    야: "야",
    야ᄀ: "약",
    야ᄁ: "앾",
    야ᄀᄉ: "앿",
    야ᄂ: "얀",
    야ᄂᄌ: "얁",
    야ᄂᄒ: "얂",
    야ᄃ: "얃",
    야ᄅ: "얄",
    야ᄅᄀ: "얅",
    야ᄅᄆ: "얆",
    야ᄅᄇ: "얇",
    야ᄅᄉ: "얈",
    야ᄅᄐ: "얉",
    야ᄅᄑ: "얊",
    야ᄅᄒ: "얋",
    야ᄆ: "얌",
    야ᄇ: "얍",
    야ᄇᄉ: "얎",
    야ᄉ: "얏",
    야ᄊ: "얐",
    야ᄋ: "양",
    야ᄌ: "얒",
    야ᄎ: "얓",
    야ᄏ: "얔",
    야ᄐ: "얕",
    야ᄑ: "얖",
    야ᄒ: "얗",
    얘: "얘",
    얘ᄀ: "얙",
    얘ᄁ: "얚",
    얘ᄀᄉ: "얛",
    얘ᄂ: "얜",
    얘ᄂᄌ: "얝",
    얘ᄂᄒ: "얞",
    얘ᄃ: "얟",
    얘ᄅ: "얠",
    얘ᄅᄀ: "얡",
    얘ᄅᄆ: "얢",
    얘ᄅᄇ: "얣",
    얘ᄅᄉ: "얤",
    얘ᄅᄐ: "얥",
    얘ᄅᄑ: "얦",
    얘ᄅᄒ: "얧",
    얘ᄆ: "얨",
    얘ᄇ: "얩",
    얘ᄇᄉ: "얪",
    얘ᄉ: "얫",
    얘ᄊ: "얬",
    얘ᄋ: "얭",
    얘ᄌ: "얮",
    얘ᄎ: "얯",
    얘ᄏ: "얰",
    얘ᄐ: "얱",
    얘ᄑ: "얲",
    얘ᄒ: "얳",
    어: "어",
    어ᄀ: "억",
    어ᄁ: "얶",
    어ᄀᄉ: "얷",
    어ᄂ: "언",
    어ᄂᄌ: "얹",
    어ᄂᄒ: "얺",
    어ᄃ: "얻",
    어ᄅ: "얼",
    어ᄅᄀ: "얽",
    어ᄅᄆ: "얾",
    어ᄅᄇ: "얿",
    어ᄅᄉ: "엀",
    어ᄅᄐ: "엁",
    어ᄅᄑ: "엂",
    어ᄅᄒ: "엃",
    어ᄆ: "엄",
    어ᄇ: "업",
    어ᄇᄉ: "없",
    어ᄉ: "엇",
    어ᄊ: "었",
    어ᄋ: "엉",
    어ᄌ: "엊",
    어ᄎ: "엋",
    어ᄏ: "엌",
    어ᄐ: "엍",
    어ᄑ: "엎",
    어ᄒ: "엏",
    에: "에",
    에ᄀ: "엑",
    에ᄁ: "엒",
    에ᄀᄉ: "엓",
    에ᄂ: "엔",
    에ᄂᄌ: "엕",
    에ᄂᄒ: "엖",
    에ᄃ: "엗",
    에ᄅ: "엘",
    에ᄅᄀ: "엙",
    에ᄅᄆ: "엚",
    에ᄅᄇ: "엛",
    에ᄅᄉ: "엜",
    에ᄅᄐ: "엝",
    에ᄅᄑ: "엞",
    에ᄅᄒ: "엟",
    에ᄆ: "엠",
    에ᄇ: "엡",
    에ᄇᄉ: "엢",
    에ᄉ: "엣",
    에ᄊ: "엤",
    에ᄋ: "엥",
    에ᄌ: "엦",
    에ᄎ: "엧",
    에ᄏ: "엨",
    에ᄐ: "엩",
    에ᄑ: "엪",
    에ᄒ: "엫",
    여: "여",
    여ᄀ: "역",
    여ᄁ: "엮",
    여ᄀᄉ: "엯",
    여ᄂ: "연",
    여ᄂᄌ: "엱",
    여ᄂᄒ: "엲",
    여ᄃ: "엳",
    여ᄅ: "열",
    여ᄅᄀ: "엵",
    여ᄅᄆ: "엶",
    여ᄅᄇ: "엷",
    여ᄅᄉ: "엸",
    여ᄅᄐ: "엹",
    여ᄅᄑ: "엺",
    여ᄅᄒ: "엻",
    여ᄆ: "염",
    여ᄇ: "엽",
    여ᄇᄉ: "엾",
    여ᄉ: "엿",
    여ᄊ: "였",
    여ᄋ: "영",
    여ᄌ: "옂",
    여ᄎ: "옃",
    여ᄏ: "옄",
    여ᄐ: "옅",
    여ᄑ: "옆",
    여ᄒ: "옇",
    예: "예",
    예ᄀ: "옉",
    예ᄁ: "옊",
    예ᄀᄉ: "옋",
    예ᄂ: "옌",
    예ᄂᄌ: "옍",
    예ᄂᄒ: "옎",
    예ᄃ: "옏",
    예ᄅ: "옐",
    예ᄅᄀ: "옑",
    예ᄅᄆ: "옒",
    예ᄅᄇ: "옓",
    예ᄅᄉ: "옔",
    예ᄅᄐ: "옕",
    예ᄅᄑ: "옖",
    예ᄅᄒ: "옗",
    예ᄆ: "옘",
    예ᄇ: "옙",
    예ᄇᄉ: "옚",
    예ᄉ: "옛",
    예ᄊ: "옜",
    예ᄋ: "옝",
    예ᄌ: "옞",
    예ᄎ: "옟",
    예ᄏ: "옠",
    예ᄐ: "옡",
    예ᄑ: "옢",
    예ᄒ: "옣",
    오: "오",
    오ᄀ: "옥",
    오ᄁ: "옦",
    오ᄀᄉ: "옧",
    오ᄂ: "온",
    오ᄂᄌ: "옩",
    오ᄂᄒ: "옪",
    오ᄃ: "옫",
    오ᄅ: "올",
    오ᄅᄀ: "옭",
    오ᄅᄆ: "옮",
    오ᄅᄇ: "옯",
    오ᄅᄉ: "옰",
    오ᄅᄐ: "옱",
    오ᄅᄑ: "옲",
    오ᄅᄒ: "옳",
    오ᄆ: "옴",
    오ᄇ: "옵",
    오ᄇᄉ: "옶",
    오ᄉ: "옷",
    오ᄊ: "옸",
    오ᄋ: "옹",
    오ᄌ: "옺",
    오ᄎ: "옻",
    오ᄏ: "옼",
    오ᄐ: "옽",
    오ᄑ: "옾",
    오ᄒ: "옿",
    오ᅡ: "와",
    오ᅡᄀ: "왁",
    오ᅡᄁ: "왂",
    오ᅡᄀᄉ: "왃",
    오ᅡᄂ: "완",
    오ᅡᄂᄌ: "왅",
    오ᅡᄂᄒ: "왆",
    오ᅡᄃ: "왇",
    오ᅡᄅ: "왈",
    오ᅡᄅᄀ: "왉",
    오ᅡᄅᄆ: "왊",
    오ᅡᄅᄇ: "왋",
    오ᅡᄅᄉ: "왌",
    오ᅡᄅᄐ: "왍",
    오ᅡᄅᄑ: "왎",
    오ᅡᄅᄒ: "왏",
    오ᅡᄆ: "왐",
    오ᅡᄇ: "왑",
    오ᅡᄇᄉ: "왒",
    오ᅡᄉ: "왓",
    오ᅡᄊ: "왔",
    오ᅡᄋ: "왕",
    오ᅡᄌ: "왖",
    오ᅡᄎ: "왗",
    오ᅡᄏ: "왘",
    오ᅡᄐ: "왙",
    오ᅡᄑ: "왚",
    오ᅡᄒ: "왛",
    오ᅢ: "왜",
    오ᅢᄀ: "왝",
    오ᅢᄁ: "왞",
    오ᅢᄀᄉ: "왟",
    오ᅢᄂ: "왠",
    오ᅢᄂᄌ: "왡",
    오ᅢᄂᄒ: "왢",
    오ᅢᄃ: "왣",
    오ᅢᄅ: "왤",
    오ᅢᄅᄀ: "왥",
    오ᅢᄅᄆ: "왦",
    오ᅢᄅᄇ: "왧",
    오ᅢᄅᄉ: "왨",
    오ᅢᄅᄐ: "왩",
    오ᅢᄅᄑ: "왪",
    오ᅢᄅᄒ: "왫",
    오ᅢᄆ: "왬",
    오ᅢᄇ: "왭",
    오ᅢᄇᄉ: "왮",
    오ᅢᄉ: "왯",
    오ᅢᄊ: "왰",
    오ᅢᄋ: "왱",
    오ᅢᄌ: "왲",
    오ᅢᄎ: "왳",
    오ᅢᄏ: "왴",
    오ᅢᄐ: "왵",
    오ᅢᄑ: "왶",
    오ᅢᄒ: "왷",
    오ᅵ: "외",
    오ᅵᄀ: "왹",
    오ᅵᄁ: "왺",
    오ᅵᄀᄉ: "왻",
    오ᅵᄂ: "왼",
    오ᅵᄂᄌ: "왽",
    오ᅵᄂᄒ: "왾",
    오ᅵᄃ: "왿",
    오ᅵᄅ: "욀",
    오ᅵᄅᄀ: "욁",
    오ᅵᄅᄆ: "욂",
    오ᅵᄅᄇ: "욃",
    오ᅵᄅᄉ: "욄",
    오ᅵᄅᄐ: "욅",
    오ᅵᄅᄑ: "욆",
    오ᅵᄅᄒ: "욇",
    오ᅵᄆ: "욈",
    오ᅵᄇ: "욉",
    오ᅵᄇᄉ: "욊",
    오ᅵᄉ: "욋",
    오ᅵᄊ: "욌",
    오ᅵᄋ: "욍",
    오ᅵᄌ: "욎",
    오ᅵᄎ: "욏",
    오ᅵᄏ: "욐",
    오ᅵᄐ: "욑",
    오ᅵᄑ: "욒",
    오ᅵᄒ: "욓",
    요: "요",
    요ᄀ: "욕",
    요ᄁ: "욖",
    요ᄀᄉ: "욗",
    요ᄂ: "욘",
    요ᄂᄌ: "욙",
    요ᄂᄒ: "욚",
    요ᄃ: "욛",
    요ᄅ: "욜",
    요ᄅᄀ: "욝",
    요ᄅᄆ: "욞",
    요ᄅᄇ: "욟",
    요ᄅᄉ: "욠",
    요ᄅᄐ: "욡",
    요ᄅᄑ: "욢",
    요ᄅᄒ: "욣",
    요ᄆ: "욤",
    요ᄇ: "욥",
    요ᄇᄉ: "욦",
    요ᄉ: "욧",
    요ᄊ: "욨",
    요ᄋ: "용",
    요ᄌ: "욪",
    요ᄎ: "욫",
    요ᄏ: "욬",
    요ᄐ: "욭",
    요ᄑ: "욮",
    요ᄒ: "욯",
    우: "우",
    우ᄀ: "욱",
    우ᄁ: "욲",
    우ᄀᄉ: "욳",
    우ᄂ: "운",
    우ᄂᄌ: "욵",
    우ᄂᄒ: "욶",
    우ᄃ: "욷",
    우ᄅ: "울",
    우ᄅᄀ: "욹",
    우ᄅᄆ: "욺",
    우ᄅᄇ: "욻",
    우ᄅᄉ: "욼",
    우ᄅᄐ: "욽",
    우ᄅᄑ: "욾",
    우ᄅᄒ: "욿",
    우ᄆ: "움",
    우ᄇ: "웁",
    우ᄇᄉ: "웂",
    우ᄉ: "웃",
    우ᄊ: "웄",
    우ᄋ: "웅",
    우ᄌ: "웆",
    우ᄎ: "웇",
    우ᄏ: "웈",
    우ᄐ: "웉",
    우ᄑ: "웊",
    우ᄒ: "웋",
    우ᅥ: "워",
    우ᅥᄀ: "웍",
    우ᅥᄁ: "웎",
    우ᅥᄀᄉ: "웏",
    우ᅥᄂ: "원",
    우ᅥᄂᄌ: "웑",
    우ᅥᄂᄒ: "웒",
    우ᅥᄃ: "웓",
    우ᅥᄅ: "월",
    우ᅥᄅᄀ: "웕",
    우ᅥᄅᄆ: "웖",
    우ᅥᄅᄇ: "웗",
    우ᅥᄅᄉ: "웘",
    우ᅥᄅᄐ: "웙",
    우ᅥᄅᄑ: "웚",
    우ᅥᄅᄒ: "웛",
    우ᅥᄆ: "웜",
    우ᅥᄇ: "웝",
    우ᅥᄇᄉ: "웞",
    우ᅥᄉ: "웟",
    우ᅥᄊ: "웠",
    우ᅥᄋ: "웡",
    우ᅥᄌ: "웢",
    우ᅥᄎ: "웣",
    우ᅥᄏ: "웤",
    우ᅥᄐ: "웥",
    우ᅥᄑ: "웦",
    우ᅥᄒ: "웧",
    우ᅦ: "웨",
    우ᅦᄀ: "웩",
    우ᅦᄁ: "웪",
    우ᅦᄀᄉ: "웫",
    우ᅦᄂ: "웬",
    우ᅦᄂᄌ: "웭",
    우ᅦᄂᄒ: "웮",
    우ᅦᄃ: "웯",
    우ᅦᄅ: "웰",
    우ᅦᄅᄀ: "웱",
    우ᅦᄅᄆ: "웲",
    우ᅦᄅᄇ: "웳",
    우ᅦᄅᄉ: "웴",
    우ᅦᄅᄐ: "웵",
    우ᅦᄅᄑ: "웶",
    우ᅦᄅᄒ: "웷",
    우ᅦᄆ: "웸",
    우ᅦᄇ: "웹",
    우ᅦᄇᄉ: "웺",
    우ᅦᄉ: "웻",
    우ᅦᄊ: "웼",
    우ᅦᄋ: "웽",
    우ᅦᄌ: "웾",
    우ᅦᄎ: "웿",
    우ᅦᄏ: "윀",
    우ᅦᄐ: "윁",
    우ᅦᄑ: "윂",
    우ᅦᄒ: "윃",
    우ᅵ: "위",
    우ᅵᄀ: "윅",
    우ᅵᄁ: "윆",
    우ᅵᄀᄉ: "윇",
    우ᅵᄂ: "윈",
    우ᅵᄂᄌ: "윉",
    우ᅵᄂᄒ: "윊",
    우ᅵᄃ: "윋",
    우ᅵᄅ: "윌",
    우ᅵᄅᄀ: "윍",
    우ᅵᄅᄆ: "윎",
    우ᅵᄅᄇ: "윏",
    우ᅵᄅᄉ: "윐",
    우ᅵᄅᄐ: "윑",
    우ᅵᄅᄑ: "윒",
    우ᅵᄅᄒ: "윓",
    우ᅵᄆ: "윔",
    우ᅵᄇ: "윕",
    우ᅵᄇᄉ: "윖",
    우ᅵᄉ: "윗",
    우ᅵᄊ: "윘",
    우ᅵᄋ: "윙",
    우ᅵᄌ: "윚",
    우ᅵᄎ: "윛",
    우ᅵᄏ: "윜",
    우ᅵᄐ: "윝",
    우ᅵᄑ: "윞",
    우ᅵᄒ: "윟",
    유: "유",
    유ᄀ: "육",
    유ᄁ: "윢",
    유ᄀᄉ: "윣",
    유ᄂ: "윤",
    유ᄂᄌ: "윥",
    유ᄂᄒ: "윦",
    유ᄃ: "윧",
    유ᄅ: "율",
    유ᄅᄀ: "윩",
    유ᄅᄆ: "윪",
    유ᄅᄇ: "윫",
    유ᄅᄉ: "윬",
    유ᄅᄐ: "윭",
    유ᄅᄑ: "윮",
    유ᄅᄒ: "윯",
    유ᄆ: "윰",
    유ᄇ: "윱",
    유ᄇᄉ: "윲",
    유ᄉ: "윳",
    유ᄊ: "윴",
    유ᄋ: "융",
    유ᄌ: "윶",
    유ᄎ: "윷",
    유ᄏ: "윸",
    유ᄐ: "윹",
    유ᄑ: "윺",
    유ᄒ: "윻",
    으: "으",
    으ᄀ: "윽",
    으ᄁ: "윾",
    으ᄀᄉ: "윿",
    으ᄂ: "은",
    으ᄂᄌ: "읁",
    으ᄂᄒ: "읂",
    으ᄃ: "읃",
    으ᄅ: "을",
    으ᄅᄀ: "읅",
    으ᄅᄆ: "읆",
    으ᄅᄇ: "읇",
    으ᄅᄉ: "읈",
    으ᄅᄐ: "읉",
    으ᄅᄑ: "읊",
    으ᄅᄒ: "읋",
    으ᄆ: "음",
    으ᄇ: "읍",
    으ᄇᄉ: "읎",
    으ᄉ: "읏",
    으ᄊ: "읐",
    으ᄋ: "응",
    으ᄌ: "읒",
    으ᄎ: "읓",
    으ᄏ: "읔",
    으ᄐ: "읕",
    으ᄑ: "읖",
    으ᄒ: "읗",
    으ᅵ: "의",
    으ᅵᄀ: "읙",
    으ᅵᄁ: "읚",
    으ᅵᄀᄉ: "읛",
    으ᅵᄂ: "읜",
    으ᅵᄂᄌ: "읝",
    으ᅵᄂᄒ: "읞",
    으ᅵᄃ: "읟",
    으ᅵᄅ: "읠",
    으ᅵᄅᄀ: "읡",
    으ᅵᄅᄆ: "읢",
    으ᅵᄅᄇ: "읣",
    으ᅵᄅᄉ: "읤",
    으ᅵᄅᄐ: "읥",
    으ᅵᄅᄑ: "읦",
    으ᅵᄅᄒ: "읧",
    으ᅵᄆ: "읨",
    으ᅵᄇ: "읩",
    으ᅵᄇᄉ: "읪",
    으ᅵᄉ: "읫",
    으ᅵᄊ: "읬",
    으ᅵᄋ: "읭",
    으ᅵᄌ: "읮",
    으ᅵᄎ: "읯",
    으ᅵᄏ: "읰",
    으ᅵᄐ: "읱",
    으ᅵᄑ: "읲",
    으ᅵᄒ: "읳",
    이: "이",
    이ᄀ: "익",
    이ᄁ: "읶",
    이ᄀᄉ: "읷",
    이ᄂ: "인",
    이ᄂᄌ: "읹",
    이ᄂᄒ: "읺",
    이ᄃ: "읻",
    이ᄅ: "일",
    이ᄅᄀ: "읽",
    이ᄅᄆ: "읾",
    이ᄅᄇ: "읿",
    이ᄅᄉ: "잀",
    이ᄅᄐ: "잁",
    이ᄅᄑ: "잂",
    이ᄅᄒ: "잃",
    이ᄆ: "임",
    이ᄇ: "입",
    이ᄇᄉ: "잆",
    이ᄉ: "잇",
    이ᄊ: "있",
    이ᄋ: "잉",
    이ᄌ: "잊",
    이ᄎ: "잋",
    이ᄏ: "잌",
    이ᄐ: "잍",
    이ᄑ: "잎",
    이ᄒ: "잏",
    자: "자",
    자ᄀ: "작",
    자ᄁ: "잒",
    자ᄀᄉ: "잓",
    자ᄂ: "잔",
    자ᄂᄌ: "잕",
    자ᄂᄒ: "잖",
    자ᄃ: "잗",
    자ᄅ: "잘",
    자ᄅᄀ: "잙",
    자ᄅᄆ: "잚",
    자ᄅᄇ: "잛",
    자ᄅᄉ: "잜",
    자ᄅᄐ: "잝",
    자ᄅᄑ: "잞",
    자ᄅᄒ: "잟",
    자ᄆ: "잠",
    자ᄇ: "잡",
    자ᄇᄉ: "잢",
    자ᄉ: "잣",
    자ᄊ: "잤",
    자ᄋ: "장",
    자ᄌ: "잦",
    자ᄎ: "잧",
    자ᄏ: "잨",
    자ᄐ: "잩",
    자ᄑ: "잪",
    자ᄒ: "잫",
    재: "재",
    재ᄀ: "잭",
    재ᄁ: "잮",
    재ᄀᄉ: "잯",
    재ᄂ: "잰",
    재ᄂᄌ: "잱",
    재ᄂᄒ: "잲",
    재ᄃ: "잳",
    재ᄅ: "잴",
    재ᄅᄀ: "잵",
    재ᄅᄆ: "잶",
    재ᄅᄇ: "잷",
    재ᄅᄉ: "잸",
    재ᄅᄐ: "잹",
    재ᄅᄑ: "잺",
    재ᄅᄒ: "잻",
    재ᄆ: "잼",
    재ᄇ: "잽",
    재ᄇᄉ: "잾",
    재ᄉ: "잿",
    재ᄊ: "쟀",
    재ᄋ: "쟁",
    재ᄌ: "쟂",
    재ᄎ: "쟃",
    재ᄏ: "쟄",
    재ᄐ: "쟅",
    재ᄑ: "쟆",
    재ᄒ: "쟇",
    쟈: "쟈",
    쟈ᄀ: "쟉",
    쟈ᄁ: "쟊",
    쟈ᄀᄉ: "쟋",
    쟈ᄂ: "쟌",
    쟈ᄂᄌ: "쟍",
    쟈ᄂᄒ: "쟎",
    쟈ᄃ: "쟏",
    쟈ᄅ: "쟐",
    쟈ᄅᄀ: "쟑",
    쟈ᄅᄆ: "쟒",
    쟈ᄅᄇ: "쟓",
    쟈ᄅᄉ: "쟔",
    쟈ᄅᄐ: "쟕",
    쟈ᄅᄑ: "쟖",
    쟈ᄅᄒ: "쟗",
    쟈ᄆ: "쟘",
    쟈ᄇ: "쟙",
    쟈ᄇᄉ: "쟚",
    쟈ᄉ: "쟛",
    쟈ᄊ: "쟜",
    쟈ᄋ: "쟝",
    쟈ᄌ: "쟞",
    쟈ᄎ: "쟟",
    쟈ᄏ: "쟠",
    쟈ᄐ: "쟡",
    쟈ᄑ: "쟢",
    쟈ᄒ: "쟣",
    쟤: "쟤",
    쟤ᄀ: "쟥",
    쟤ᄁ: "쟦",
    쟤ᄀᄉ: "쟧",
    쟤ᄂ: "쟨",
    쟤ᄂᄌ: "쟩",
    쟤ᄂᄒ: "쟪",
    쟤ᄃ: "쟫",
    쟤ᄅ: "쟬",
    쟤ᄅᄀ: "쟭",
    쟤ᄅᄆ: "쟮",
    쟤ᄅᄇ: "쟯",
    쟤ᄅᄉ: "쟰",
    쟤ᄅᄐ: "쟱",
    쟤ᄅᄑ: "쟲",
    쟤ᄅᄒ: "쟳",
    쟤ᄆ: "쟴",
    쟤ᄇ: "쟵",
    쟤ᄇᄉ: "쟶",
    쟤ᄉ: "쟷",
    쟤ᄊ: "쟸",
    쟤ᄋ: "쟹",
    쟤ᄌ: "쟺",
    쟤ᄎ: "쟻",
    쟤ᄏ: "쟼",
    쟤ᄐ: "쟽",
    쟤ᄑ: "쟾",
    쟤ᄒ: "쟿",
    저: "저",
    저ᄀ: "적",
    저ᄁ: "젂",
    저ᄀᄉ: "젃",
    저ᄂ: "전",
    저ᄂᄌ: "젅",
    저ᄂᄒ: "젆",
    저ᄃ: "젇",
    저ᄅ: "절",
    저ᄅᄀ: "젉",
    저ᄅᄆ: "젊",
    저ᄅᄇ: "젋",
    저ᄅᄉ: "젌",
    저ᄅᄐ: "젍",
    저ᄅᄑ: "젎",
    저ᄅᄒ: "젏",
    저ᄆ: "점",
    저ᄇ: "접",
    저ᄇᄉ: "젒",
    저ᄉ: "젓",
    저ᄊ: "젔",
    저ᄋ: "정",
    저ᄌ: "젖",
    저ᄎ: "젗",
    저ᄏ: "젘",
    저ᄐ: "젙",
    저ᄑ: "젚",
    저ᄒ: "젛",
    제: "제",
    제ᄀ: "젝",
    제ᄁ: "젞",
    제ᄀᄉ: "젟",
    제ᄂ: "젠",
    제ᄂᄌ: "젡",
    제ᄂᄒ: "젢",
    제ᄃ: "젣",
    제ᄅ: "젤",
    제ᄅᄀ: "젥",
    제ᄅᄆ: "젦",
    제ᄅᄇ: "젧",
    제ᄅᄉ: "젨",
    제ᄅᄐ: "젩",
    제ᄅᄑ: "젪",
    제ᄅᄒ: "젫",
    제ᄆ: "젬",
    제ᄇ: "젭",
    제ᄇᄉ: "젮",
    제ᄉ: "젯",
    제ᄊ: "젰",
    제ᄋ: "젱",
    제ᄌ: "젲",
    제ᄎ: "젳",
    제ᄏ: "젴",
    제ᄐ: "젵",
    제ᄑ: "젶",
    제ᄒ: "젷",
    져: "져",
    져ᄀ: "젹",
    져ᄁ: "젺",
    져ᄀᄉ: "젻",
    져ᄂ: "젼",
    져ᄂᄌ: "젽",
    져ᄂᄒ: "젾",
    져ᄃ: "젿",
    져ᄅ: "졀",
    져ᄅᄀ: "졁",
    져ᄅᄆ: "졂",
    져ᄅᄇ: "졃",
    져ᄅᄉ: "졄",
    져ᄅᄐ: "졅",
    져ᄅᄑ: "졆",
    져ᄅᄒ: "졇",
    져ᄆ: "졈",
    져ᄇ: "졉",
    져ᄇᄉ: "졊",
    져ᄉ: "졋",
    져ᄊ: "졌",
    져ᄋ: "졍",
    져ᄌ: "졎",
    져ᄎ: "졏",
    져ᄏ: "졐",
    져ᄐ: "졑",
    져ᄑ: "졒",
    져ᄒ: "졓",
    졔: "졔",
    졔ᄀ: "졕",
    졔ᄁ: "졖",
    졔ᄀᄉ: "졗",
    졔ᄂ: "졘",
    졔ᄂᄌ: "졙",
    졔ᄂᄒ: "졚",
    졔ᄃ: "졛",
    졔ᄅ: "졜",
    졔ᄅᄀ: "졝",
    졔ᄅᄆ: "졞",
    졔ᄅᄇ: "졟",
    졔ᄅᄉ: "졠",
    졔ᄅᄐ: "졡",
    졔ᄅᄑ: "졢",
    졔ᄅᄒ: "졣",
    졔ᄆ: "졤",
    졔ᄇ: "졥",
    졔ᄇᄉ: "졦",
    졔ᄉ: "졧",
    졔ᄊ: "졨",
    졔ᄋ: "졩",
    졔ᄌ: "졪",
    졔ᄎ: "졫",
    졔ᄏ: "졬",
    졔ᄐ: "졭",
    졔ᄑ: "졮",
    졔ᄒ: "졯",
    조: "조",
    조ᄀ: "족",
    조ᄁ: "졲",
    조ᄀᄉ: "졳",
    조ᄂ: "존",
    조ᄂᄌ: "졵",
    조ᄂᄒ: "졶",
    조ᄃ: "졷",
    조ᄅ: "졸",
    조ᄅᄀ: "졹",
    조ᄅᄆ: "졺",
    조ᄅᄇ: "졻",
    조ᄅᄉ: "졼",
    조ᄅᄐ: "졽",
    조ᄅᄑ: "졾",
    조ᄅᄒ: "졿",
    조ᄆ: "좀",
    조ᄇ: "좁",
    조ᄇᄉ: "좂",
    조ᄉ: "좃",
    조ᄊ: "좄",
    조ᄋ: "종",
    조ᄌ: "좆",
    조ᄎ: "좇",
    조ᄏ: "좈",
    조ᄐ: "좉",
    조ᄑ: "좊",
    조ᄒ: "좋",
    조ᅡ: "좌",
    조ᅡᄀ: "좍",
    조ᅡᄁ: "좎",
    조ᅡᄀᄉ: "좏",
    조ᅡᄂ: "좐",
    조ᅡᄂᄌ: "좑",
    조ᅡᄂᄒ: "좒",
    조ᅡᄃ: "좓",
    조ᅡᄅ: "좔",
    조ᅡᄅᄀ: "좕",
    조ᅡᄅᄆ: "좖",
    조ᅡᄅᄇ: "좗",
    조ᅡᄅᄉ: "좘",
    조ᅡᄅᄐ: "좙",
    조ᅡᄅᄑ: "좚",
    조ᅡᄅᄒ: "좛",
    조ᅡᄆ: "좜",
    조ᅡᄇ: "좝",
    조ᅡᄇᄉ: "좞",
    조ᅡᄉ: "좟",
    조ᅡᄊ: "좠",
    조ᅡᄋ: "좡",
    조ᅡᄌ: "좢",
    조ᅡᄎ: "좣",
    조ᅡᄏ: "좤",
    조ᅡᄐ: "좥",
    조ᅡᄑ: "좦",
    조ᅡᄒ: "좧",
    조ᅢ: "좨",
    조ᅢᄀ: "좩",
    조ᅢᄁ: "좪",
    조ᅢᄀᄉ: "좫",
    조ᅢᄂ: "좬",
    조ᅢᄂᄌ: "좭",
    조ᅢᄂᄒ: "좮",
    조ᅢᄃ: "좯",
    조ᅢᄅ: "좰",
    조ᅢᄅᄀ: "좱",
    조ᅢᄅᄆ: "좲",
    조ᅢᄅᄇ: "좳",
    조ᅢᄅᄉ: "좴",
    조ᅢᄅᄐ: "좵",
    조ᅢᄅᄑ: "좶",
    조ᅢᄅᄒ: "좷",
    조ᅢᄆ: "좸",
    조ᅢᄇ: "좹",
    조ᅢᄇᄉ: "좺",
    조ᅢᄉ: "좻",
    조ᅢᄊ: "좼",
    조ᅢᄋ: "좽",
    조ᅢᄌ: "좾",
    조ᅢᄎ: "좿",
    조ᅢᄏ: "죀",
    조ᅢᄐ: "죁",
    조ᅢᄑ: "죂",
    조ᅢᄒ: "죃",
    조ᅵ: "죄",
    조ᅵᄀ: "죅",
    조ᅵᄁ: "죆",
    조ᅵᄀᄉ: "죇",
    조ᅵᄂ: "죈",
    조ᅵᄂᄌ: "죉",
    조ᅵᄂᄒ: "죊",
    조ᅵᄃ: "죋",
    조ᅵᄅ: "죌",
    조ᅵᄅᄀ: "죍",
    조ᅵᄅᄆ: "죎",
    조ᅵᄅᄇ: "죏",
    조ᅵᄅᄉ: "죐",
    조ᅵᄅᄐ: "죑",
    조ᅵᄅᄑ: "죒",
    조ᅵᄅᄒ: "죓",
    조ᅵᄆ: "죔",
    조ᅵᄇ: "죕",
    조ᅵᄇᄉ: "죖",
    조ᅵᄉ: "죗",
    조ᅵᄊ: "죘",
    조ᅵᄋ: "죙",
    조ᅵᄌ: "죚",
    조ᅵᄎ: "죛",
    조ᅵᄏ: "죜",
    조ᅵᄐ: "죝",
    조ᅵᄑ: "죞",
    조ᅵᄒ: "죟",
    죠: "죠",
    죠ᄀ: "죡",
    죠ᄁ: "죢",
    죠ᄀᄉ: "죣",
    죠ᄂ: "죤",
    죠ᄂᄌ: "죥",
    죠ᄂᄒ: "죦",
    죠ᄃ: "죧",
    죠ᄅ: "죨",
    죠ᄅᄀ: "죩",
    죠ᄅᄆ: "죪",
    죠ᄅᄇ: "죫",
    죠ᄅᄉ: "죬",
    죠ᄅᄐ: "죭",
    죠ᄅᄑ: "죮",
    죠ᄅᄒ: "죯",
    죠ᄆ: "죰",
    죠ᄇ: "죱",
    죠ᄇᄉ: "죲",
    죠ᄉ: "죳",
    죠ᄊ: "죴",
    죠ᄋ: "죵",
    죠ᄌ: "죶",
    죠ᄎ: "죷",
    죠ᄏ: "죸",
    죠ᄐ: "죹",
    죠ᄑ: "죺",
    죠ᄒ: "죻",
    주: "주",
    주ᄀ: "죽",
    주ᄁ: "죾",
    주ᄀᄉ: "죿",
    주ᄂ: "준",
    주ᄂᄌ: "줁",
    주ᄂᄒ: "줂",
    주ᄃ: "줃",
    주ᄅ: "줄",
    주ᄅᄀ: "줅",
    주ᄅᄆ: "줆",
    주ᄅᄇ: "줇",
    주ᄅᄉ: "줈",
    주ᄅᄐ: "줉",
    주ᄅᄑ: "줊",
    주ᄅᄒ: "줋",
    주ᄆ: "줌",
    주ᄇ: "줍",
    주ᄇᄉ: "줎",
    주ᄉ: "줏",
    주ᄊ: "줐",
    주ᄋ: "중",
    주ᄌ: "줒",
    주ᄎ: "줓",
    주ᄏ: "줔",
    주ᄐ: "줕",
    주ᄑ: "줖",
    주ᄒ: "줗",
    주ᅥ: "줘",
    주ᅥᄀ: "줙",
    주ᅥᄁ: "줚",
    주ᅥᄀᄉ: "줛",
    주ᅥᄂ: "줜",
    주ᅥᄂᄌ: "줝",
    주ᅥᄂᄒ: "줞",
    주ᅥᄃ: "줟",
    주ᅥᄅ: "줠",
    주ᅥᄅᄀ: "줡",
    주ᅥᄅᄆ: "줢",
    주ᅥᄅᄇ: "줣",
    주ᅥᄅᄉ: "줤",
    주ᅥᄅᄐ: "줥",
    주ᅥᄅᄑ: "줦",
    주ᅥᄅᄒ: "줧",
    주ᅥᄆ: "줨",
    주ᅥᄇ: "줩",
    주ᅥᄇᄉ: "줪",
    주ᅥᄉ: "줫",
    주ᅥᄊ: "줬",
    주ᅥᄋ: "줭",
    주ᅥᄌ: "줮",
    주ᅥᄎ: "줯",
    주ᅥᄏ: "줰",
    주ᅥᄐ: "줱",
    주ᅥᄑ: "줲",
    주ᅥᄒ: "줳",
    주ᅦ: "줴",
    주ᅦᄀ: "줵",
    주ᅦᄁ: "줶",
    주ᅦᄀᄉ: "줷",
    주ᅦᄂ: "줸",
    주ᅦᄂᄌ: "줹",
    주ᅦᄂᄒ: "줺",
    주ᅦᄃ: "줻",
    주ᅦᄅ: "줼",
    주ᅦᄅᄀ: "줽",
    주ᅦᄅᄆ: "줾",
    주ᅦᄅᄇ: "줿",
    주ᅦᄅᄉ: "쥀",
    주ᅦᄅᄐ: "쥁",
    주ᅦᄅᄑ: "쥂",
    주ᅦᄅᄒ: "쥃",
    주ᅦᄆ: "쥄",
    주ᅦᄇ: "쥅",
    주ᅦᄇᄉ: "쥆",
    주ᅦᄉ: "쥇",
    주ᅦᄊ: "쥈",
    주ᅦᄋ: "쥉",
    주ᅦᄌ: "쥊",
    주ᅦᄎ: "쥋",
    주ᅦᄏ: "쥌",
    주ᅦᄐ: "쥍",
    주ᅦᄑ: "쥎",
    주ᅦᄒ: "쥏",
    주ᅵ: "쥐",
    주ᅵᄀ: "쥑",
    주ᅵᄁ: "쥒",
    주ᅵᄀᄉ: "쥓",
    주ᅵᄂ: "쥔",
    주ᅵᄂᄌ: "쥕",
    주ᅵᄂᄒ: "쥖",
    주ᅵᄃ: "쥗",
    주ᅵᄅ: "쥘",
    주ᅵᄅᄀ: "쥙",
    주ᅵᄅᄆ: "쥚",
    주ᅵᄅᄇ: "쥛",
    주ᅵᄅᄉ: "쥜",
    주ᅵᄅᄐ: "쥝",
    주ᅵᄅᄑ: "쥞",
    주ᅵᄅᄒ: "쥟",
    주ᅵᄆ: "쥠",
    주ᅵᄇ: "쥡",
    주ᅵᄇᄉ: "쥢",
    주ᅵᄉ: "쥣",
    주ᅵᄊ: "쥤",
    주ᅵᄋ: "쥥",
    주ᅵᄌ: "쥦",
    주ᅵᄎ: "쥧",
    주ᅵᄏ: "쥨",
    주ᅵᄐ: "쥩",
    주ᅵᄑ: "쥪",
    주ᅵᄒ: "쥫",
    쥬: "쥬",
    쥬ᄀ: "쥭",
    쥬ᄁ: "쥮",
    쥬ᄀᄉ: "쥯",
    쥬ᄂ: "쥰",
    쥬ᄂᄌ: "쥱",
    쥬ᄂᄒ: "쥲",
    쥬ᄃ: "쥳",
    쥬ᄅ: "쥴",
    쥬ᄅᄀ: "쥵",
    쥬ᄅᄆ: "쥶",
    쥬ᄅᄇ: "쥷",
    쥬ᄅᄉ: "쥸",
    쥬ᄅᄐ: "쥹",
    쥬ᄅᄑ: "쥺",
    쥬ᄅᄒ: "쥻",
    쥬ᄆ: "쥼",
    쥬ᄇ: "쥽",
    쥬ᄇᄉ: "쥾",
    쥬ᄉ: "쥿",
    쥬ᄊ: "즀",
    쥬ᄋ: "즁",
    쥬ᄌ: "즂",
    쥬ᄎ: "즃",
    쥬ᄏ: "즄",
    쥬ᄐ: "즅",
    쥬ᄑ: "즆",
    쥬ᄒ: "즇",
    즈: "즈",
    즈ᄀ: "즉",
    즈ᄁ: "즊",
    즈ᄀᄉ: "즋",
    즈ᄂ: "즌",
    즈ᄂᄌ: "즍",
    즈ᄂᄒ: "즎",
    즈ᄃ: "즏",
    즈ᄅ: "즐",
    즈ᄅᄀ: "즑",
    즈ᄅᄆ: "즒",
    즈ᄅᄇ: "즓",
    즈ᄅᄉ: "즔",
    즈ᄅᄐ: "즕",
    즈ᄅᄑ: "즖",
    즈ᄅᄒ: "즗",
    즈ᄆ: "즘",
    즈ᄇ: "즙",
    즈ᄇᄉ: "즚",
    즈ᄉ: "즛",
    즈ᄊ: "즜",
    즈ᄋ: "증",
    즈ᄌ: "즞",
    즈ᄎ: "즟",
    즈ᄏ: "즠",
    즈ᄐ: "즡",
    즈ᄑ: "즢",
    즈ᄒ: "즣",
    즈ᅵ: "즤",
    즈ᅵᄀ: "즥",
    즈ᅵᄁ: "즦",
    즈ᅵᄀᄉ: "즧",
    즈ᅵᄂ: "즨",
    즈ᅵᄂᄌ: "즩",
    즈ᅵᄂᄒ: "즪",
    즈ᅵᄃ: "즫",
    즈ᅵᄅ: "즬",
    즈ᅵᄅᄀ: "즭",
    즈ᅵᄅᄆ: "즮",
    즈ᅵᄅᄇ: "즯",
    즈ᅵᄅᄉ: "즰",
    즈ᅵᄅᄐ: "즱",
    즈ᅵᄅᄑ: "즲",
    즈ᅵᄅᄒ: "즳",
    즈ᅵᄆ: "즴",
    즈ᅵᄇ: "즵",
    즈ᅵᄇᄉ: "즶",
    즈ᅵᄉ: "즷",
    즈ᅵᄊ: "즸",
    즈ᅵᄋ: "즹",
    즈ᅵᄌ: "즺",
    즈ᅵᄎ: "즻",
    즈ᅵᄏ: "즼",
    즈ᅵᄐ: "즽",
    즈ᅵᄑ: "즾",
    즈ᅵᄒ: "즿",
    지: "지",
    지ᄀ: "직",
    지ᄁ: "짂",
    지ᄀᄉ: "짃",
    지ᄂ: "진",
    지ᄂᄌ: "짅",
    지ᄂᄒ: "짆",
    지ᄃ: "짇",
    지ᄅ: "질",
    지ᄅᄀ: "짉",
    지ᄅᄆ: "짊",
    지ᄅᄇ: "짋",
    지ᄅᄉ: "짌",
    지ᄅᄐ: "짍",
    지ᄅᄑ: "짎",
    지ᄅᄒ: "짏",
    지ᄆ: "짐",
    지ᄇ: "집",
    지ᄇᄉ: "짒",
    지ᄉ: "짓",
    지ᄊ: "짔",
    지ᄋ: "징",
    지ᄌ: "짖",
    지ᄎ: "짗",
    지ᄏ: "짘",
    지ᄐ: "짙",
    지ᄑ: "짚",
    지ᄒ: "짛",
    짜: "짜",
    짜ᄀ: "짝",
    짜ᄁ: "짞",
    짜ᄀᄉ: "짟",
    짜ᄂ: "짠",
    짜ᄂᄌ: "짡",
    짜ᄂᄒ: "짢",
    짜ᄃ: "짣",
    짜ᄅ: "짤",
    짜ᄅᄀ: "짥",
    짜ᄅᄆ: "짦",
    짜ᄅᄇ: "짧",
    짜ᄅᄉ: "짨",
    짜ᄅᄐ: "짩",
    짜ᄅᄑ: "짪",
    짜ᄅᄒ: "짫",
    짜ᄆ: "짬",
    짜ᄇ: "짭",
    짜ᄇᄉ: "짮",
    짜ᄉ: "짯",
    짜ᄊ: "짰",
    짜ᄋ: "짱",
    짜ᄌ: "짲",
    짜ᄎ: "짳",
    짜ᄏ: "짴",
    짜ᄐ: "짵",
    짜ᄑ: "짶",
    짜ᄒ: "짷",
    째: "째",
    째ᄀ: "짹",
    째ᄁ: "짺",
    째ᄀᄉ: "짻",
    째ᄂ: "짼",
    째ᄂᄌ: "짽",
    째ᄂᄒ: "짾",
    째ᄃ: "짿",
    째ᄅ: "쨀",
    째ᄅᄀ: "쨁",
    째ᄅᄆ: "쨂",
    째ᄅᄇ: "쨃",
    째ᄅᄉ: "쨄",
    째ᄅᄐ: "쨅",
    째ᄅᄑ: "쨆",
    째ᄅᄒ: "쨇",
    째ᄆ: "쨈",
    째ᄇ: "쨉",
    째ᄇᄉ: "쨊",
    째ᄉ: "쨋",
    째ᄊ: "쨌",
    째ᄋ: "쨍",
    째ᄌ: "쨎",
    째ᄎ: "쨏",
    째ᄏ: "쨐",
    째ᄐ: "쨑",
    째ᄑ: "쨒",
    째ᄒ: "쨓",
    쨔: "쨔",
    쨔ᄀ: "쨕",
    쨔ᄁ: "쨖",
    쨔ᄀᄉ: "쨗",
    쨔ᄂ: "쨘",
    쨔ᄂᄌ: "쨙",
    쨔ᄂᄒ: "쨚",
    쨔ᄃ: "쨛",
    쨔ᄅ: "쨜",
    쨔ᄅᄀ: "쨝",
    쨔ᄅᄆ: "쨞",
    쨔ᄅᄇ: "쨟",
    쨔ᄅᄉ: "쨠",
    쨔ᄅᄐ: "쨡",
    쨔ᄅᄑ: "쨢",
    쨔ᄅᄒ: "쨣",
    쨔ᄆ: "쨤",
    쨔ᄇ: "쨥",
    쨔ᄇᄉ: "쨦",
    쨔ᄉ: "쨧",
    쨔ᄊ: "쨨",
    쨔ᄋ: "쨩",
    쨔ᄌ: "쨪",
    쨔ᄎ: "쨫",
    쨔ᄏ: "쨬",
    쨔ᄐ: "쨭",
    쨔ᄑ: "쨮",
    쨔ᄒ: "쨯",
    쨰: "쨰",
    쨰ᄀ: "쨱",
    쨰ᄁ: "쨲",
    쨰ᄀᄉ: "쨳",
    쨰ᄂ: "쨴",
    쨰ᄂᄌ: "쨵",
    쨰ᄂᄒ: "쨶",
    쨰ᄃ: "쨷",
    쨰ᄅ: "쨸",
    쨰ᄅᄀ: "쨹",
    쨰ᄅᄆ: "쨺",
    쨰ᄅᄇ: "쨻",
    쨰ᄅᄉ: "쨼",
    쨰ᄅᄐ: "쨽",
    쨰ᄅᄑ: "쨾",
    쨰ᄅᄒ: "쨿",
    쨰ᄆ: "쩀",
    쨰ᄇ: "쩁",
    쨰ᄇᄉ: "쩂",
    쨰ᄉ: "쩃",
    쨰ᄊ: "쩄",
    쨰ᄋ: "쩅",
    쨰ᄌ: "쩆",
    쨰ᄎ: "쩇",
    쨰ᄏ: "쩈",
    쨰ᄐ: "쩉",
    쨰ᄑ: "쩊",
    쨰ᄒ: "쩋",
    쩌: "쩌",
    쩌ᄀ: "쩍",
    쩌ᄁ: "쩎",
    쩌ᄀᄉ: "쩏",
    쩌ᄂ: "쩐",
    쩌ᄂᄌ: "쩑",
    쩌ᄂᄒ: "쩒",
    쩌ᄃ: "쩓",
    쩌ᄅ: "쩔",
    쩌ᄅᄀ: "쩕",
    쩌ᄅᄆ: "쩖",
    쩌ᄅᄇ: "쩗",
    쩌ᄅᄉ: "쩘",
    쩌ᄅᄐ: "쩙",
    쩌ᄅᄑ: "쩚",
    쩌ᄅᄒ: "쩛",
    쩌ᄆ: "쩜",
    쩌ᄇ: "쩝",
    쩌ᄇᄉ: "쩞",
    쩌ᄉ: "쩟",
    쩌ᄊ: "쩠",
    쩌ᄋ: "쩡",
    쩌ᄌ: "쩢",
    쩌ᄎ: "쩣",
    쩌ᄏ: "쩤",
    쩌ᄐ: "쩥",
    쩌ᄑ: "쩦",
    쩌ᄒ: "쩧",
    쩨: "쩨",
    쩨ᄀ: "쩩",
    쩨ᄁ: "쩪",
    쩨ᄀᄉ: "쩫",
    쩨ᄂ: "쩬",
    쩨ᄂᄌ: "쩭",
    쩨ᄂᄒ: "쩮",
    쩨ᄃ: "쩯",
    쩨ᄅ: "쩰",
    쩨ᄅᄀ: "쩱",
    쩨ᄅᄆ: "쩲",
    쩨ᄅᄇ: "쩳",
    쩨ᄅᄉ: "쩴",
    쩨ᄅᄐ: "쩵",
    쩨ᄅᄑ: "쩶",
    쩨ᄅᄒ: "쩷",
    쩨ᄆ: "쩸",
    쩨ᄇ: "쩹",
    쩨ᄇᄉ: "쩺",
    쩨ᄉ: "쩻",
    쩨ᄊ: "쩼",
    쩨ᄋ: "쩽",
    쩨ᄌ: "쩾",
    쩨ᄎ: "쩿",
    쩨ᄏ: "쪀",
    쩨ᄐ: "쪁",
    쩨ᄑ: "쪂",
    쩨ᄒ: "쪃",
    쪄: "쪄",
    쪄ᄀ: "쪅",
    쪄ᄁ: "쪆",
    쪄ᄀᄉ: "쪇",
    쪄ᄂ: "쪈",
    쪄ᄂᄌ: "쪉",
    쪄ᄂᄒ: "쪊",
    쪄ᄃ: "쪋",
    쪄ᄅ: "쪌",
    쪄ᄅᄀ: "쪍",
    쪄ᄅᄆ: "쪎",
    쪄ᄅᄇ: "쪏",
    쪄ᄅᄉ: "쪐",
    쪄ᄅᄐ: "쪑",
    쪄ᄅᄑ: "쪒",
    쪄ᄅᄒ: "쪓",
    쪄ᄆ: "쪔",
    쪄ᄇ: "쪕",
    쪄ᄇᄉ: "쪖",
    쪄ᄉ: "쪗",
    쪄ᄊ: "쪘",
    쪄ᄋ: "쪙",
    쪄ᄌ: "쪚",
    쪄ᄎ: "쪛",
    쪄ᄏ: "쪜",
    쪄ᄐ: "쪝",
    쪄ᄑ: "쪞",
    쪄ᄒ: "쪟",
    쪠: "쪠",
    쪠ᄀ: "쪡",
    쪠ᄁ: "쪢",
    쪠ᄀᄉ: "쪣",
    쪠ᄂ: "쪤",
    쪠ᄂᄌ: "쪥",
    쪠ᄂᄒ: "쪦",
    쪠ᄃ: "쪧",
    쪠ᄅ: "쪨",
    쪠ᄅᄀ: "쪩",
    쪠ᄅᄆ: "쪪",
    쪠ᄅᄇ: "쪫",
    쪠ᄅᄉ: "쪬",
    쪠ᄅᄐ: "쪭",
    쪠ᄅᄑ: "쪮",
    쪠ᄅᄒ: "쪯",
    쪠ᄆ: "쪰",
    쪠ᄇ: "쪱",
    쪠ᄇᄉ: "쪲",
    쪠ᄉ: "쪳",
    쪠ᄊ: "쪴",
    쪠ᄋ: "쪵",
    쪠ᄌ: "쪶",
    쪠ᄎ: "쪷",
    쪠ᄏ: "쪸",
    쪠ᄐ: "쪹",
    쪠ᄑ: "쪺",
    쪠ᄒ: "쪻",
    쪼: "쪼",
    쪼ᄀ: "쪽",
    쪼ᄁ: "쪾",
    쪼ᄀᄉ: "쪿",
    쪼ᄂ: "쫀",
    쪼ᄂᄌ: "쫁",
    쪼ᄂᄒ: "쫂",
    쪼ᄃ: "쫃",
    쪼ᄅ: "쫄",
    쪼ᄅᄀ: "쫅",
    쪼ᄅᄆ: "쫆",
    쪼ᄅᄇ: "쫇",
    쪼ᄅᄉ: "쫈",
    쪼ᄅᄐ: "쫉",
    쪼ᄅᄑ: "쫊",
    쪼ᄅᄒ: "쫋",
    쪼ᄆ: "쫌",
    쪼ᄇ: "쫍",
    쪼ᄇᄉ: "쫎",
    쪼ᄉ: "쫏",
    쪼ᄊ: "쫐",
    쪼ᄋ: "쫑",
    쪼ᄌ: "쫒",
    쪼ᄎ: "쫓",
    쪼ᄏ: "쫔",
    쪼ᄐ: "쫕",
    쪼ᄑ: "쫖",
    쪼ᄒ: "쫗",
    쪼ᅡ: "쫘",
    쪼ᅡᄀ: "쫙",
    쪼ᅡᄁ: "쫚",
    쪼ᅡᄀᄉ: "쫛",
    쪼ᅡᄂ: "쫜",
    쪼ᅡᄂᄌ: "쫝",
    쪼ᅡᄂᄒ: "쫞",
    쪼ᅡᄃ: "쫟",
    쪼ᅡᄅ: "쫠",
    쪼ᅡᄅᄀ: "쫡",
    쪼ᅡᄅᄆ: "쫢",
    쪼ᅡᄅᄇ: "쫣",
    쪼ᅡᄅᄉ: "쫤",
    쪼ᅡᄅᄐ: "쫥",
    쪼ᅡᄅᄑ: "쫦",
    쪼ᅡᄅᄒ: "쫧",
    쪼ᅡᄆ: "쫨",
    쪼ᅡᄇ: "쫩",
    쪼ᅡᄇᄉ: "쫪",
    쪼ᅡᄉ: "쫫",
    쪼ᅡᄊ: "쫬",
    쪼ᅡᄋ: "쫭",
    쪼ᅡᄌ: "쫮",
    쪼ᅡᄎ: "쫯",
    쪼ᅡᄏ: "쫰",
    쪼ᅡᄐ: "쫱",
    쪼ᅡᄑ: "쫲",
    쪼ᅡᄒ: "쫳",
    쪼ᅢ: "쫴",
    쪼ᅢᄀ: "쫵",
    쪼ᅢᄁ: "쫶",
    쪼ᅢᄀᄉ: "쫷",
    쪼ᅢᄂ: "쫸",
    쪼ᅢᄂᄌ: "쫹",
    쪼ᅢᄂᄒ: "쫺",
    쪼ᅢᄃ: "쫻",
    쪼ᅢᄅ: "쫼",
    쪼ᅢᄅᄀ: "쫽",
    쪼ᅢᄅᄆ: "쫾",
    쪼ᅢᄅᄇ: "쫿",
    쪼ᅢᄅᄉ: "쬀",
    쪼ᅢᄅᄐ: "쬁",
    쪼ᅢᄅᄑ: "쬂",
    쪼ᅢᄅᄒ: "쬃",
    쪼ᅢᄆ: "쬄",
    쪼ᅢᄇ: "쬅",
    쪼ᅢᄇᄉ: "쬆",
    쪼ᅢᄉ: "쬇",
    쪼ᅢᄊ: "쬈",
    쪼ᅢᄋ: "쬉",
    쪼ᅢᄌ: "쬊",
    쪼ᅢᄎ: "쬋",
    쪼ᅢᄏ: "쬌",
    쪼ᅢᄐ: "쬍",
    쪼ᅢᄑ: "쬎",
    쪼ᅢᄒ: "쬏",
    쪼ᅵ: "쬐",
    쪼ᅵᄀ: "쬑",
    쪼ᅵᄁ: "쬒",
    쪼ᅵᄀᄉ: "쬓",
    쪼ᅵᄂ: "쬔",
    쪼ᅵᄂᄌ: "쬕",
    쪼ᅵᄂᄒ: "쬖",
    쪼ᅵᄃ: "쬗",
    쪼ᅵᄅ: "쬘",
    쪼ᅵᄅᄀ: "쬙",
    쪼ᅵᄅᄆ: "쬚",
    쪼ᅵᄅᄇ: "쬛",
    쪼ᅵᄅᄉ: "쬜",
    쪼ᅵᄅᄐ: "쬝",
    쪼ᅵᄅᄑ: "쬞",
    쪼ᅵᄅᄒ: "쬟",
    쪼ᅵᄆ: "쬠",
    쪼ᅵᄇ: "쬡",
    쪼ᅵᄇᄉ: "쬢",
    쪼ᅵᄉ: "쬣",
    쪼ᅵᄊ: "쬤",
    쪼ᅵᄋ: "쬥",
    쪼ᅵᄌ: "쬦",
    쪼ᅵᄎ: "쬧",
    쪼ᅵᄏ: "쬨",
    쪼ᅵᄐ: "쬩",
    쪼ᅵᄑ: "쬪",
    쪼ᅵᄒ: "쬫",
    쬬: "쬬",
    쬬ᄀ: "쬭",
    쬬ᄁ: "쬮",
    쬬ᄀᄉ: "쬯",
    쬬ᄂ: "쬰",
    쬬ᄂᄌ: "쬱",
    쬬ᄂᄒ: "쬲",
    쬬ᄃ: "쬳",
    쬬ᄅ: "쬴",
    쬬ᄅᄀ: "쬵",
    쬬ᄅᄆ: "쬶",
    쬬ᄅᄇ: "쬷",
    쬬ᄅᄉ: "쬸",
    쬬ᄅᄐ: "쬹",
    쬬ᄅᄑ: "쬺",
    쬬ᄅᄒ: "쬻",
    쬬ᄆ: "쬼",
    쬬ᄇ: "쬽",
    쬬ᄇᄉ: "쬾",
    쬬ᄉ: "쬿",
    쬬ᄊ: "쭀",
    쬬ᄋ: "쭁",
    쬬ᄌ: "쭂",
    쬬ᄎ: "쭃",
    쬬ᄏ: "쭄",
    쬬ᄐ: "쭅",
    쬬ᄑ: "쭆",
    쬬ᄒ: "쭇",
    쭈: "쭈",
    쭈ᄀ: "쭉",
    쭈ᄁ: "쭊",
    쭈ᄀᄉ: "쭋",
    쭈ᄂ: "쭌",
    쭈ᄂᄌ: "쭍",
    쭈ᄂᄒ: "쭎",
    쭈ᄃ: "쭏",
    쭈ᄅ: "쭐",
    쭈ᄅᄀ: "쭑",
    쭈ᄅᄆ: "쭒",
    쭈ᄅᄇ: "쭓",
    쭈ᄅᄉ: "쭔",
    쭈ᄅᄐ: "쭕",
    쭈ᄅᄑ: "쭖",
    쭈ᄅᄒ: "쭗",
    쭈ᄆ: "쭘",
    쭈ᄇ: "쭙",
    쭈ᄇᄉ: "쭚",
    쭈ᄉ: "쭛",
    쭈ᄊ: "쭜",
    쭈ᄋ: "쭝",
    쭈ᄌ: "쭞",
    쭈ᄎ: "쭟",
    쭈ᄏ: "쭠",
    쭈ᄐ: "쭡",
    쭈ᄑ: "쭢",
    쭈ᄒ: "쭣",
    쭈ᅥ: "쭤",
    쭈ᅥᄀ: "쭥",
    쭈ᅥᄁ: "쭦",
    쭈ᅥᄀᄉ: "쭧",
    쭈ᅥᄂ: "쭨",
    쭈ᅥᄂᄌ: "쭩",
    쭈ᅥᄂᄒ: "쭪",
    쭈ᅥᄃ: "쭫",
    쭈ᅥᄅ: "쭬",
    쭈ᅥᄅᄀ: "쭭",
    쭈ᅥᄅᄆ: "쭮",
    쭈ᅥᄅᄇ: "쭯",
    쭈ᅥᄅᄉ: "쭰",
    쭈ᅥᄅᄐ: "쭱",
    쭈ᅥᄅᄑ: "쭲",
    쭈ᅥᄅᄒ: "쭳",
    쭈ᅥᄆ: "쭴",
    쭈ᅥᄇ: "쭵",
    쭈ᅥᄇᄉ: "쭶",
    쭈ᅥᄉ: "쭷",
    쭈ᅥᄊ: "쭸",
    쭈ᅥᄋ: "쭹",
    쭈ᅥᄌ: "쭺",
    쭈ᅥᄎ: "쭻",
    쭈ᅥᄏ: "쭼",
    쭈ᅥᄐ: "쭽",
    쭈ᅥᄑ: "쭾",
    쭈ᅥᄒ: "쭿",
    쭈ᅦ: "쮀",
    쭈ᅦᄀ: "쮁",
    쭈ᅦᄁ: "쮂",
    쭈ᅦᄀᄉ: "쮃",
    쭈ᅦᄂ: "쮄",
    쭈ᅦᄂᄌ: "쮅",
    쭈ᅦᄂᄒ: "쮆",
    쭈ᅦᄃ: "쮇",
    쭈ᅦᄅ: "쮈",
    쭈ᅦᄅᄀ: "쮉",
    쭈ᅦᄅᄆ: "쮊",
    쭈ᅦᄅᄇ: "쮋",
    쭈ᅦᄅᄉ: "쮌",
    쭈ᅦᄅᄐ: "쮍",
    쭈ᅦᄅᄑ: "쮎",
    쭈ᅦᄅᄒ: "쮏",
    쭈ᅦᄆ: "쮐",
    쭈ᅦᄇ: "쮑",
    쭈ᅦᄇᄉ: "쮒",
    쭈ᅦᄉ: "쮓",
    쭈ᅦᄊ: "쮔",
    쭈ᅦᄋ: "쮕",
    쭈ᅦᄌ: "쮖",
    쭈ᅦᄎ: "쮗",
    쭈ᅦᄏ: "쮘",
    쭈ᅦᄐ: "쮙",
    쭈ᅦᄑ: "쮚",
    쭈ᅦᄒ: "쮛",
    쭈ᅵ: "쮜",
    쭈ᅵᄀ: "쮝",
    쭈ᅵᄁ: "쮞",
    쭈ᅵᄀᄉ: "쮟",
    쭈ᅵᄂ: "쮠",
    쭈ᅵᄂᄌ: "쮡",
    쭈ᅵᄂᄒ: "쮢",
    쭈ᅵᄃ: "쮣",
    쭈ᅵᄅ: "쮤",
    쭈ᅵᄅᄀ: "쮥",
    쭈ᅵᄅᄆ: "쮦",
    쭈ᅵᄅᄇ: "쮧",
    쭈ᅵᄅᄉ: "쮨",
    쭈ᅵᄅᄐ: "쮩",
    쭈ᅵᄅᄑ: "쮪",
    쭈ᅵᄅᄒ: "쮫",
    쭈ᅵᄆ: "쮬",
    쭈ᅵᄇ: "쮭",
    쭈ᅵᄇᄉ: "쮮",
    쭈ᅵᄉ: "쮯",
    쭈ᅵᄊ: "쮰",
    쭈ᅵᄋ: "쮱",
    쭈ᅵᄌ: "쮲",
    쭈ᅵᄎ: "쮳",
    쭈ᅵᄏ: "쮴",
    쭈ᅵᄐ: "쮵",
    쭈ᅵᄑ: "쮶",
    쭈ᅵᄒ: "쮷",
    쮸: "쮸",
    쮸ᄀ: "쮹",
    쮸ᄁ: "쮺",
    쮸ᄀᄉ: "쮻",
    쮸ᄂ: "쮼",
    쮸ᄂᄌ: "쮽",
    쮸ᄂᄒ: "쮾",
    쮸ᄃ: "쮿",
    쮸ᄅ: "쯀",
    쮸ᄅᄀ: "쯁",
    쮸ᄅᄆ: "쯂",
    쮸ᄅᄇ: "쯃",
    쮸ᄅᄉ: "쯄",
    쮸ᄅᄐ: "쯅",
    쮸ᄅᄑ: "쯆",
    쮸ᄅᄒ: "쯇",
    쮸ᄆ: "쯈",
    쮸ᄇ: "쯉",
    쮸ᄇᄉ: "쯊",
    쮸ᄉ: "쯋",
    쮸ᄊ: "쯌",
    쮸ᄋ: "쯍",
    쮸ᄌ: "쯎",
    쮸ᄎ: "쯏",
    쮸ᄏ: "쯐",
    쮸ᄐ: "쯑",
    쮸ᄑ: "쯒",
    쮸ᄒ: "쯓",
    쯔: "쯔",
    쯔ᄀ: "쯕",
    쯔ᄁ: "쯖",
    쯔ᄀᄉ: "쯗",
    쯔ᄂ: "쯘",
    쯔ᄂᄌ: "쯙",
    쯔ᄂᄒ: "쯚",
    쯔ᄃ: "쯛",
    쯔ᄅ: "쯜",
    쯔ᄅᄀ: "쯝",
    쯔ᄅᄆ: "쯞",
    쯔ᄅᄇ: "쯟",
    쯔ᄅᄉ: "쯠",
    쯔ᄅᄐ: "쯡",
    쯔ᄅᄑ: "쯢",
    쯔ᄅᄒ: "쯣",
    쯔ᄆ: "쯤",
    쯔ᄇ: "쯥",
    쯔ᄇᄉ: "쯦",
    쯔ᄉ: "쯧",
    쯔ᄊ: "쯨",
    쯔ᄋ: "쯩",
    쯔ᄌ: "쯪",
    쯔ᄎ: "쯫",
    쯔ᄏ: "쯬",
    쯔ᄐ: "쯭",
    쯔ᄑ: "쯮",
    쯔ᄒ: "쯯",
    쯔ᅵ: "쯰",
    쯔ᅵᄀ: "쯱",
    쯔ᅵᄁ: "쯲",
    쯔ᅵᄀᄉ: "쯳",
    쯔ᅵᄂ: "쯴",
    쯔ᅵᄂᄌ: "쯵",
    쯔ᅵᄂᄒ: "쯶",
    쯔ᅵᄃ: "쯷",
    쯔ᅵᄅ: "쯸",
    쯔ᅵᄅᄀ: "쯹",
    쯔ᅵᄅᄆ: "쯺",
    쯔ᅵᄅᄇ: "쯻",
    쯔ᅵᄅᄉ: "쯼",
    쯔ᅵᄅᄐ: "쯽",
    쯔ᅵᄅᄑ: "쯾",
    쯔ᅵᄅᄒ: "쯿",
    쯔ᅵᄆ: "찀",
    쯔ᅵᄇ: "찁",
    쯔ᅵᄇᄉ: "찂",
    쯔ᅵᄉ: "찃",
    쯔ᅵᄊ: "찄",
    쯔ᅵᄋ: "찅",
    쯔ᅵᄌ: "찆",
    쯔ᅵᄎ: "찇",
    쯔ᅵᄏ: "찈",
    쯔ᅵᄐ: "찉",
    쯔ᅵᄑ: "찊",
    쯔ᅵᄒ: "찋",
    찌: "찌",
    찌ᄀ: "찍",
    찌ᄁ: "찎",
    찌ᄀᄉ: "찏",
    찌ᄂ: "찐",
    찌ᄂᄌ: "찑",
    찌ᄂᄒ: "찒",
    찌ᄃ: "찓",
    찌ᄅ: "찔",
    찌ᄅᄀ: "찕",
    찌ᄅᄆ: "찖",
    찌ᄅᄇ: "찗",
    찌ᄅᄉ: "찘",
    찌ᄅᄐ: "찙",
    찌ᄅᄑ: "찚",
    찌ᄅᄒ: "찛",
    찌ᄆ: "찜",
    찌ᄇ: "찝",
    찌ᄇᄉ: "찞",
    찌ᄉ: "찟",
    찌ᄊ: "찠",
    찌ᄋ: "찡",
    찌ᄌ: "찢",
    찌ᄎ: "찣",
    찌ᄏ: "찤",
    찌ᄐ: "찥",
    찌ᄑ: "찦",
    찌ᄒ: "찧",
    차: "차",
    차ᄀ: "착",
    차ᄁ: "찪",
    차ᄀᄉ: "찫",
    차ᄂ: "찬",
    차ᄂᄌ: "찭",
    차ᄂᄒ: "찮",
    차ᄃ: "찯",
    차ᄅ: "찰",
    차ᄅᄀ: "찱",
    차ᄅᄆ: "찲",
    차ᄅᄇ: "찳",
    차ᄅᄉ: "찴",
    차ᄅᄐ: "찵",
    차ᄅᄑ: "찶",
    차ᄅᄒ: "찷",
    차ᄆ: "참",
    차ᄇ: "찹",
    차ᄇᄉ: "찺",
    차ᄉ: "찻",
    차ᄊ: "찼",
    차ᄋ: "창",
    차ᄌ: "찾",
    차ᄎ: "찿",
    차ᄏ: "챀",
    차ᄐ: "챁",
    차ᄑ: "챂",
    차ᄒ: "챃",
    채: "채",
    채ᄀ: "책",
    채ᄁ: "챆",
    채ᄀᄉ: "챇",
    채ᄂ: "챈",
    채ᄂᄌ: "챉",
    채ᄂᄒ: "챊",
    채ᄃ: "챋",
    채ᄅ: "챌",
    채ᄅᄀ: "챍",
    채ᄅᄆ: "챎",
    채ᄅᄇ: "챏",
    채ᄅᄉ: "챐",
    채ᄅᄐ: "챑",
    채ᄅᄑ: "챒",
    채ᄅᄒ: "챓",
    채ᄆ: "챔",
    채ᄇ: "챕",
    채ᄇᄉ: "챖",
    채ᄉ: "챗",
    채ᄊ: "챘",
    채ᄋ: "챙",
    채ᄌ: "챚",
    채ᄎ: "챛",
    채ᄏ: "챜",
    채ᄐ: "챝",
    채ᄑ: "챞",
    채ᄒ: "챟",
    챠: "챠",
    챠ᄀ: "챡",
    챠ᄁ: "챢",
    챠ᄀᄉ: "챣",
    챠ᄂ: "챤",
    챠ᄂᄌ: "챥",
    챠ᄂᄒ: "챦",
    챠ᄃ: "챧",
    챠ᄅ: "챨",
    챠ᄅᄀ: "챩",
    챠ᄅᄆ: "챪",
    챠ᄅᄇ: "챫",
    챠ᄅᄉ: "챬",
    챠ᄅᄐ: "챭",
    챠ᄅᄑ: "챮",
    챠ᄅᄒ: "챯",
    챠ᄆ: "챰",
    챠ᄇ: "챱",
    챠ᄇᄉ: "챲",
    챠ᄉ: "챳",
    챠ᄊ: "챴",
    챠ᄋ: "챵",
    챠ᄌ: "챶",
    챠ᄎ: "챷",
    챠ᄏ: "챸",
    챠ᄐ: "챹",
    챠ᄑ: "챺",
    챠ᄒ: "챻",
    챼: "챼",
    챼ᄀ: "챽",
    챼ᄁ: "챾",
    챼ᄀᄉ: "챿",
    챼ᄂ: "첀",
    챼ᄂᄌ: "첁",
    챼ᄂᄒ: "첂",
    챼ᄃ: "첃",
    챼ᄅ: "첄",
    챼ᄅᄀ: "첅",
    챼ᄅᄆ: "첆",
    챼ᄅᄇ: "첇",
    챼ᄅᄉ: "첈",
    챼ᄅᄐ: "첉",
    챼ᄅᄑ: "첊",
    챼ᄅᄒ: "첋",
    챼ᄆ: "첌",
    챼ᄇ: "첍",
    챼ᄇᄉ: "첎",
    챼ᄉ: "첏",
    챼ᄊ: "첐",
    챼ᄋ: "첑",
    챼ᄌ: "첒",
    챼ᄎ: "첓",
    챼ᄏ: "첔",
    챼ᄐ: "첕",
    챼ᄑ: "첖",
    챼ᄒ: "첗",
    처: "처",
    처ᄀ: "척",
    처ᄁ: "첚",
    처ᄀᄉ: "첛",
    처ᄂ: "천",
    처ᄂᄌ: "첝",
    처ᄂᄒ: "첞",
    처ᄃ: "첟",
    처ᄅ: "철",
    처ᄅᄀ: "첡",
    처ᄅᄆ: "첢",
    처ᄅᄇ: "첣",
    처ᄅᄉ: "첤",
    처ᄅᄐ: "첥",
    처ᄅᄑ: "첦",
    처ᄅᄒ: "첧",
    처ᄆ: "첨",
    처ᄇ: "첩",
    처ᄇᄉ: "첪",
    처ᄉ: "첫",
    처ᄊ: "첬",
    처ᄋ: "청",
    처ᄌ: "첮",
    처ᄎ: "첯",
    처ᄏ: "첰",
    처ᄐ: "첱",
    처ᄑ: "첲",
    처ᄒ: "첳",
    체: "체",
    체ᄀ: "첵",
    체ᄁ: "첶",
    체ᄀᄉ: "첷",
    체ᄂ: "첸",
    체ᄂᄌ: "첹",
    체ᄂᄒ: "첺",
    체ᄃ: "첻",
    체ᄅ: "첼",
    체ᄅᄀ: "첽",
    체ᄅᄆ: "첾",
    체ᄅᄇ: "첿",
    체ᄅᄉ: "쳀",
    체ᄅᄐ: "쳁",
    체ᄅᄑ: "쳂",
    체ᄅᄒ: "쳃",
    체ᄆ: "쳄",
    체ᄇ: "쳅",
    체ᄇᄉ: "쳆",
    체ᄉ: "쳇",
    체ᄊ: "쳈",
    체ᄋ: "쳉",
    체ᄌ: "쳊",
    체ᄎ: "쳋",
    체ᄏ: "쳌",
    체ᄐ: "쳍",
    체ᄑ: "쳎",
    체ᄒ: "쳏",
    쳐: "쳐",
    쳐ᄀ: "쳑",
    쳐ᄁ: "쳒",
    쳐ᄀᄉ: "쳓",
    쳐ᄂ: "쳔",
    쳐ᄂᄌ: "쳕",
    쳐ᄂᄒ: "쳖",
    쳐ᄃ: "쳗",
    쳐ᄅ: "쳘",
    쳐ᄅᄀ: "쳙",
    쳐ᄅᄆ: "쳚",
    쳐ᄅᄇ: "쳛",
    쳐ᄅᄉ: "쳜",
    쳐ᄅᄐ: "쳝",
    쳐ᄅᄑ: "쳞",
    쳐ᄅᄒ: "쳟",
    쳐ᄆ: "쳠",
    쳐ᄇ: "쳡",
    쳐ᄇᄉ: "쳢",
    쳐ᄉ: "쳣",
    쳐ᄊ: "쳤",
    쳐ᄋ: "쳥",
    쳐ᄌ: "쳦",
    쳐ᄎ: "쳧",
    쳐ᄏ: "쳨",
    쳐ᄐ: "쳩",
    쳐ᄑ: "쳪",
    쳐ᄒ: "쳫",
    쳬: "쳬",
    쳬ᄀ: "쳭",
    쳬ᄁ: "쳮",
    쳬ᄀᄉ: "쳯",
    쳬ᄂ: "쳰",
    쳬ᄂᄌ: "쳱",
    쳬ᄂᄒ: "쳲",
    쳬ᄃ: "쳳",
    쳬ᄅ: "쳴",
    쳬ᄅᄀ: "쳵",
    쳬ᄅᄆ: "쳶",
    쳬ᄅᄇ: "쳷",
    쳬ᄅᄉ: "쳸",
    쳬ᄅᄐ: "쳹",
    쳬ᄅᄑ: "쳺",
    쳬ᄅᄒ: "쳻",
    쳬ᄆ: "쳼",
    쳬ᄇ: "쳽",
    쳬ᄇᄉ: "쳾",
    쳬ᄉ: "쳿",
    쳬ᄊ: "촀",
    쳬ᄋ: "촁",
    쳬ᄌ: "촂",
    쳬ᄎ: "촃",
    쳬ᄏ: "촄",
    쳬ᄐ: "촅",
    쳬ᄑ: "촆",
    쳬ᄒ: "촇",
    초: "초",
    초ᄀ: "촉",
    초ᄁ: "촊",
    초ᄀᄉ: "촋",
    초ᄂ: "촌",
    초ᄂᄌ: "촍",
    초ᄂᄒ: "촎",
    초ᄃ: "촏",
    초ᄅ: "촐",
    초ᄅᄀ: "촑",
    초ᄅᄆ: "촒",
    초ᄅᄇ: "촓",
    초ᄅᄉ: "촔",
    초ᄅᄐ: "촕",
    초ᄅᄑ: "촖",
    초ᄅᄒ: "촗",
    초ᄆ: "촘",
    초ᄇ: "촙",
    초ᄇᄉ: "촚",
    초ᄉ: "촛",
    초ᄊ: "촜",
    초ᄋ: "총",
    초ᄌ: "촞",
    초ᄎ: "촟",
    초ᄏ: "촠",
    초ᄐ: "촡",
    초ᄑ: "촢",
    초ᄒ: "촣",
    초ᅡ: "촤",
    초ᅡᄀ: "촥",
    초ᅡᄁ: "촦",
    초ᅡᄀᄉ: "촧",
    초ᅡᄂ: "촨",
    초ᅡᄂᄌ: "촩",
    초ᅡᄂᄒ: "촪",
    초ᅡᄃ: "촫",
    초ᅡᄅ: "촬",
    초ᅡᄅᄀ: "촭",
    초ᅡᄅᄆ: "촮",
    초ᅡᄅᄇ: "촯",
    초ᅡᄅᄉ: "촰",
    초ᅡᄅᄐ: "촱",
    초ᅡᄅᄑ: "촲",
    초ᅡᄅᄒ: "촳",
    초ᅡᄆ: "촴",
    초ᅡᄇ: "촵",
    초ᅡᄇᄉ: "촶",
    초ᅡᄉ: "촷",
    초ᅡᄊ: "촸",
    초ᅡᄋ: "촹",
    초ᅡᄌ: "촺",
    초ᅡᄎ: "촻",
    초ᅡᄏ: "촼",
    초ᅡᄐ: "촽",
    초ᅡᄑ: "촾",
    초ᅡᄒ: "촿",
    초ᅢ: "쵀",
    초ᅢᄀ: "쵁",
    초ᅢᄁ: "쵂",
    초ᅢᄀᄉ: "쵃",
    초ᅢᄂ: "쵄",
    초ᅢᄂᄌ: "쵅",
    초ᅢᄂᄒ: "쵆",
    초ᅢᄃ: "쵇",
    초ᅢᄅ: "쵈",
    초ᅢᄅᄀ: "쵉",
    초ᅢᄅᄆ: "쵊",
    초ᅢᄅᄇ: "쵋",
    초ᅢᄅᄉ: "쵌",
    초ᅢᄅᄐ: "쵍",
    초ᅢᄅᄑ: "쵎",
    초ᅢᄅᄒ: "쵏",
    초ᅢᄆ: "쵐",
    초ᅢᄇ: "쵑",
    초ᅢᄇᄉ: "쵒",
    초ᅢᄉ: "쵓",
    초ᅢᄊ: "쵔",
    초ᅢᄋ: "쵕",
    초ᅢᄌ: "쵖",
    초ᅢᄎ: "쵗",
    초ᅢᄏ: "쵘",
    초ᅢᄐ: "쵙",
    초ᅢᄑ: "쵚",
    초ᅢᄒ: "쵛",
    초ᅵ: "최",
    초ᅵᄀ: "쵝",
    초ᅵᄁ: "쵞",
    초ᅵᄀᄉ: "쵟",
    초ᅵᄂ: "쵠",
    초ᅵᄂᄌ: "쵡",
    초ᅵᄂᄒ: "쵢",
    초ᅵᄃ: "쵣",
    초ᅵᄅ: "쵤",
    초ᅵᄅᄀ: "쵥",
    초ᅵᄅᄆ: "쵦",
    초ᅵᄅᄇ: "쵧",
    초ᅵᄅᄉ: "쵨",
    초ᅵᄅᄐ: "쵩",
    초ᅵᄅᄑ: "쵪",
    초ᅵᄅᄒ: "쵫",
    초ᅵᄆ: "쵬",
    초ᅵᄇ: "쵭",
    초ᅵᄇᄉ: "쵮",
    초ᅵᄉ: "쵯",
    초ᅵᄊ: "쵰",
    초ᅵᄋ: "쵱",
    초ᅵᄌ: "쵲",
    초ᅵᄎ: "쵳",
    초ᅵᄏ: "쵴",
    초ᅵᄐ: "쵵",
    초ᅵᄑ: "쵶",
    초ᅵᄒ: "쵷",
    쵸: "쵸",
    쵸ᄀ: "쵹",
    쵸ᄁ: "쵺",
    쵸ᄀᄉ: "쵻",
    쵸ᄂ: "쵼",
    쵸ᄂᄌ: "쵽",
    쵸ᄂᄒ: "쵾",
    쵸ᄃ: "쵿",
    쵸ᄅ: "춀",
    쵸ᄅᄀ: "춁",
    쵸ᄅᄆ: "춂",
    쵸ᄅᄇ: "춃",
    쵸ᄅᄉ: "춄",
    쵸ᄅᄐ: "춅",
    쵸ᄅᄑ: "춆",
    쵸ᄅᄒ: "춇",
    쵸ᄆ: "춈",
    쵸ᄇ: "춉",
    쵸ᄇᄉ: "춊",
    쵸ᄉ: "춋",
    쵸ᄊ: "춌",
    쵸ᄋ: "춍",
    쵸ᄌ: "춎",
    쵸ᄎ: "춏",
    쵸ᄏ: "춐",
    쵸ᄐ: "춑",
    쵸ᄑ: "춒",
    쵸ᄒ: "춓",
    추: "추",
    추ᄀ: "축",
    추ᄁ: "춖",
    추ᄀᄉ: "춗",
    추ᄂ: "춘",
    추ᄂᄌ: "춙",
    추ᄂᄒ: "춚",
    추ᄃ: "춛",
    추ᄅ: "출",
    추ᄅᄀ: "춝",
    추ᄅᄆ: "춞",
    추ᄅᄇ: "춟",
    추ᄅᄉ: "춠",
    추ᄅᄐ: "춡",
    추ᄅᄑ: "춢",
    추ᄅᄒ: "춣",
    추ᄆ: "춤",
    추ᄇ: "춥",
    추ᄇᄉ: "춦",
    추ᄉ: "춧",
    추ᄊ: "춨",
    추ᄋ: "충",
    추ᄌ: "춪",
    추ᄎ: "춫",
    추ᄏ: "춬",
    추ᄐ: "춭",
    추ᄑ: "춮",
    추ᄒ: "춯",
    추ᅥ: "춰",
    추ᅥᄀ: "춱",
    추ᅥᄁ: "춲",
    추ᅥᄀᄉ: "춳",
    추ᅥᄂ: "춴",
    추ᅥᄂᄌ: "춵",
    추ᅥᄂᄒ: "춶",
    추ᅥᄃ: "춷",
    추ᅥᄅ: "춸",
    추ᅥᄅᄀ: "춹",
    추ᅥᄅᄆ: "춺",
    추ᅥᄅᄇ: "춻",
    추ᅥᄅᄉ: "춼",
    추ᅥᄅᄐ: "춽",
    추ᅥᄅᄑ: "춾",
    추ᅥᄅᄒ: "춿",
    추ᅥᄆ: "췀",
    추ᅥᄇ: "췁",
    추ᅥᄇᄉ: "췂",
    추ᅥᄉ: "췃",
    추ᅥᄊ: "췄",
    추ᅥᄋ: "췅",
    추ᅥᄌ: "췆",
    추ᅥᄎ: "췇",
    추ᅥᄏ: "췈",
    추ᅥᄐ: "췉",
    추ᅥᄑ: "췊",
    추ᅥᄒ: "췋",
    추ᅦ: "췌",
    추ᅦᄀ: "췍",
    추ᅦᄁ: "췎",
    추ᅦᄀᄉ: "췏",
    추ᅦᄂ: "췐",
    추ᅦᄂᄌ: "췑",
    추ᅦᄂᄒ: "췒",
    추ᅦᄃ: "췓",
    추ᅦᄅ: "췔",
    추ᅦᄅᄀ: "췕",
    추ᅦᄅᄆ: "췖",
    추ᅦᄅᄇ: "췗",
    추ᅦᄅᄉ: "췘",
    추ᅦᄅᄐ: "췙",
    추ᅦᄅᄑ: "췚",
    추ᅦᄅᄒ: "췛",
    추ᅦᄆ: "췜",
    추ᅦᄇ: "췝",
    추ᅦᄇᄉ: "췞",
    추ᅦᄉ: "췟",
    추ᅦᄊ: "췠",
    추ᅦᄋ: "췡",
    추ᅦᄌ: "췢",
    추ᅦᄎ: "췣",
    추ᅦᄏ: "췤",
    추ᅦᄐ: "췥",
    추ᅦᄑ: "췦",
    추ᅦᄒ: "췧",
    추ᅵ: "취",
    추ᅵᄀ: "췩",
    추ᅵᄁ: "췪",
    추ᅵᄀᄉ: "췫",
    추ᅵᄂ: "췬",
    추ᅵᄂᄌ: "췭",
    추ᅵᄂᄒ: "췮",
    추ᅵᄃ: "췯",
    추ᅵᄅ: "췰",
    추ᅵᄅᄀ: "췱",
    추ᅵᄅᄆ: "췲",
    추ᅵᄅᄇ: "췳",
    추ᅵᄅᄉ: "췴",
    추ᅵᄅᄐ: "췵",
    추ᅵᄅᄑ: "췶",
    추ᅵᄅᄒ: "췷",
    추ᅵᄆ: "췸",
    추ᅵᄇ: "췹",
    추ᅵᄇᄉ: "췺",
    추ᅵᄉ: "췻",
    추ᅵᄊ: "췼",
    추ᅵᄋ: "췽",
    추ᅵᄌ: "췾",
    추ᅵᄎ: "췿",
    추ᅵᄏ: "츀",
    추ᅵᄐ: "츁",
    추ᅵᄑ: "츂",
    추ᅵᄒ: "츃",
    츄: "츄",
    츄ᄀ: "츅",
    츄ᄁ: "츆",
    츄ᄀᄉ: "츇",
    츄ᄂ: "츈",
    츄ᄂᄌ: "츉",
    츄ᄂᄒ: "츊",
    츄ᄃ: "츋",
    츄ᄅ: "츌",
    츄ᄅᄀ: "츍",
    츄ᄅᄆ: "츎",
    츄ᄅᄇ: "츏",
    츄ᄅᄉ: "츐",
    츄ᄅᄐ: "츑",
    츄ᄅᄑ: "츒",
    츄ᄅᄒ: "츓",
    츄ᄆ: "츔",
    츄ᄇ: "츕",
    츄ᄇᄉ: "츖",
    츄ᄉ: "츗",
    츄ᄊ: "츘",
    츄ᄋ: "츙",
    츄ᄌ: "츚",
    츄ᄎ: "츛",
    츄ᄏ: "츜",
    츄ᄐ: "츝",
    츄ᄑ: "츞",
    츄ᄒ: "츟",
    츠: "츠",
    츠ᄀ: "측",
    츠ᄁ: "츢",
    츠ᄀᄉ: "츣",
    츠ᄂ: "츤",
    츠ᄂᄌ: "츥",
    츠ᄂᄒ: "츦",
    츠ᄃ: "츧",
    츠ᄅ: "츨",
    츠ᄅᄀ: "츩",
    츠ᄅᄆ: "츪",
    츠ᄅᄇ: "츫",
    츠ᄅᄉ: "츬",
    츠ᄅᄐ: "츭",
    츠ᄅᄑ: "츮",
    츠ᄅᄒ: "츯",
    츠ᄆ: "츰",
    츠ᄇ: "츱",
    츠ᄇᄉ: "츲",
    츠ᄉ: "츳",
    츠ᄊ: "츴",
    츠ᄋ: "층",
    츠ᄌ: "츶",
    츠ᄎ: "츷",
    츠ᄏ: "츸",
    츠ᄐ: "츹",
    츠ᄑ: "츺",
    츠ᄒ: "츻",
    츠ᅵ: "츼",
    츠ᅵᄀ: "츽",
    츠ᅵᄁ: "츾",
    츠ᅵᄀᄉ: "츿",
    츠ᅵᄂ: "칀",
    츠ᅵᄂᄌ: "칁",
    츠ᅵᄂᄒ: "칂",
    츠ᅵᄃ: "칃",
    츠ᅵᄅ: "칄",
    츠ᅵᄅᄀ: "칅",
    츠ᅵᄅᄆ: "칆",
    츠ᅵᄅᄇ: "칇",
    츠ᅵᄅᄉ: "칈",
    츠ᅵᄅᄐ: "칉",
    츠ᅵᄅᄑ: "칊",
    츠ᅵᄅᄒ: "칋",
    츠ᅵᄆ: "칌",
    츠ᅵᄇ: "칍",
    츠ᅵᄇᄉ: "칎",
    츠ᅵᄉ: "칏",
    츠ᅵᄊ: "칐",
    츠ᅵᄋ: "칑",
    츠ᅵᄌ: "칒",
    츠ᅵᄎ: "칓",
    츠ᅵᄏ: "칔",
    츠ᅵᄐ: "칕",
    츠ᅵᄑ: "칖",
    츠ᅵᄒ: "칗",
    치: "치",
    치ᄀ: "칙",
    치ᄁ: "칚",
    치ᄀᄉ: "칛",
    치ᄂ: "친",
    치ᄂᄌ: "칝",
    치ᄂᄒ: "칞",
    치ᄃ: "칟",
    치ᄅ: "칠",
    치ᄅᄀ: "칡",
    치ᄅᄆ: "칢",
    치ᄅᄇ: "칣",
    치ᄅᄉ: "칤",
    치ᄅᄐ: "칥",
    치ᄅᄑ: "칦",
    치ᄅᄒ: "칧",
    치ᄆ: "침",
    치ᄇ: "칩",
    치ᄇᄉ: "칪",
    치ᄉ: "칫",
    치ᄊ: "칬",
    치ᄋ: "칭",
    치ᄌ: "칮",
    치ᄎ: "칯",
    치ᄏ: "칰",
    치ᄐ: "칱",
    치ᄑ: "칲",
    치ᄒ: "칳",
    카: "카",
    카ᄀ: "칵",
    카ᄁ: "칶",
    카ᄀᄉ: "칷",
    카ᄂ: "칸",
    카ᄂᄌ: "칹",
    카ᄂᄒ: "칺",
    카ᄃ: "칻",
    카ᄅ: "칼",
    카ᄅᄀ: "칽",
    카ᄅᄆ: "칾",
    카ᄅᄇ: "칿",
    카ᄅᄉ: "캀",
    카ᄅᄐ: "캁",
    카ᄅᄑ: "캂",
    카ᄅᄒ: "캃",
    카ᄆ: "캄",
    카ᄇ: "캅",
    카ᄇᄉ: "캆",
    카ᄉ: "캇",
    카ᄊ: "캈",
    카ᄋ: "캉",
    카ᄌ: "캊",
    카ᄎ: "캋",
    카ᄏ: "캌",
    카ᄐ: "캍",
    카ᄑ: "캎",
    카ᄒ: "캏",
    캐: "캐",
    캐ᄀ: "캑",
    캐ᄁ: "캒",
    캐ᄀᄉ: "캓",
    캐ᄂ: "캔",
    캐ᄂᄌ: "캕",
    캐ᄂᄒ: "캖",
    캐ᄃ: "캗",
    캐ᄅ: "캘",
    캐ᄅᄀ: "캙",
    캐ᄅᄆ: "캚",
    캐ᄅᄇ: "캛",
    캐ᄅᄉ: "캜",
    캐ᄅᄐ: "캝",
    캐ᄅᄑ: "캞",
    캐ᄅᄒ: "캟",
    캐ᄆ: "캠",
    캐ᄇ: "캡",
    캐ᄇᄉ: "캢",
    캐ᄉ: "캣",
    캐ᄊ: "캤",
    캐ᄋ: "캥",
    캐ᄌ: "캦",
    캐ᄎ: "캧",
    캐ᄏ: "캨",
    캐ᄐ: "캩",
    캐ᄑ: "캪",
    캐ᄒ: "캫",
    캬: "캬",
    캬ᄀ: "캭",
    캬ᄁ: "캮",
    캬ᄀᄉ: "캯",
    캬ᄂ: "캰",
    캬ᄂᄌ: "캱",
    캬ᄂᄒ: "캲",
    캬ᄃ: "캳",
    캬ᄅ: "캴",
    캬ᄅᄀ: "캵",
    캬ᄅᄆ: "캶",
    캬ᄅᄇ: "캷",
    캬ᄅᄉ: "캸",
    캬ᄅᄐ: "캹",
    캬ᄅᄑ: "캺",
    캬ᄅᄒ: "캻",
    캬ᄆ: "캼",
    캬ᄇ: "캽",
    캬ᄇᄉ: "캾",
    캬ᄉ: "캿",
    캬ᄊ: "컀",
    캬ᄋ: "컁",
    캬ᄌ: "컂",
    캬ᄎ: "컃",
    캬ᄏ: "컄",
    캬ᄐ: "컅",
    캬ᄑ: "컆",
    캬ᄒ: "컇",
    컈: "컈",
    컈ᄀ: "컉",
    컈ᄁ: "컊",
    컈ᄀᄉ: "컋",
    컈ᄂ: "컌",
    컈ᄂᄌ: "컍",
    컈ᄂᄒ: "컎",
    컈ᄃ: "컏",
    컈ᄅ: "컐",
    컈ᄅᄀ: "컑",
    컈ᄅᄆ: "컒",
    컈ᄅᄇ: "컓",
    컈ᄅᄉ: "컔",
    컈ᄅᄐ: "컕",
    컈ᄅᄑ: "컖",
    컈ᄅᄒ: "컗",
    컈ᄆ: "컘",
    컈ᄇ: "컙",
    컈ᄇᄉ: "컚",
    컈ᄉ: "컛",
    컈ᄊ: "컜",
    컈ᄋ: "컝",
    컈ᄌ: "컞",
    컈ᄎ: "컟",
    컈ᄏ: "컠",
    컈ᄐ: "컡",
    컈ᄑ: "컢",
    컈ᄒ: "컣",
    커: "커",
    커ᄀ: "컥",
    커ᄁ: "컦",
    커ᄀᄉ: "컧",
    커ᄂ: "컨",
    커ᄂᄌ: "컩",
    커ᄂᄒ: "컪",
    커ᄃ: "컫",
    커ᄅ: "컬",
    커ᄅᄀ: "컭",
    커ᄅᄆ: "컮",
    커ᄅᄇ: "컯",
    커ᄅᄉ: "컰",
    커ᄅᄐ: "컱",
    커ᄅᄑ: "컲",
    커ᄅᄒ: "컳",
    커ᄆ: "컴",
    커ᄇ: "컵",
    커ᄇᄉ: "컶",
    커ᄉ: "컷",
    커ᄊ: "컸",
    커ᄋ: "컹",
    커ᄌ: "컺",
    커ᄎ: "컻",
    커ᄏ: "컼",
    커ᄐ: "컽",
    커ᄑ: "컾",
    커ᄒ: "컿",
    케: "케",
    케ᄀ: "켁",
    케ᄁ: "켂",
    케ᄀᄉ: "켃",
    케ᄂ: "켄",
    케ᄂᄌ: "켅",
    케ᄂᄒ: "켆",
    케ᄃ: "켇",
    케ᄅ: "켈",
    케ᄅᄀ: "켉",
    케ᄅᄆ: "켊",
    케ᄅᄇ: "켋",
    케ᄅᄉ: "켌",
    케ᄅᄐ: "켍",
    케ᄅᄑ: "켎",
    케ᄅᄒ: "켏",
    케ᄆ: "켐",
    케ᄇ: "켑",
    케ᄇᄉ: "켒",
    케ᄉ: "켓",
    케ᄊ: "켔",
    케ᄋ: "켕",
    케ᄌ: "켖",
    케ᄎ: "켗",
    케ᄏ: "켘",
    케ᄐ: "켙",
    케ᄑ: "켚",
    케ᄒ: "켛",
    켜: "켜",
    켜ᄀ: "켝",
    켜ᄁ: "켞",
    켜ᄀᄉ: "켟",
    켜ᄂ: "켠",
    켜ᄂᄌ: "켡",
    켜ᄂᄒ: "켢",
    켜ᄃ: "켣",
    켜ᄅ: "켤",
    켜ᄅᄀ: "켥",
    켜ᄅᄆ: "켦",
    켜ᄅᄇ: "켧",
    켜ᄅᄉ: "켨",
    켜ᄅᄐ: "켩",
    켜ᄅᄑ: "켪",
    켜ᄅᄒ: "켫",
    켜ᄆ: "켬",
    켜ᄇ: "켭",
    켜ᄇᄉ: "켮",
    켜ᄉ: "켯",
    켜ᄊ: "켰",
    켜ᄋ: "켱",
    켜ᄌ: "켲",
    켜ᄎ: "켳",
    켜ᄏ: "켴",
    켜ᄐ: "켵",
    켜ᄑ: "켶",
    켜ᄒ: "켷",
    켸: "켸",
    켸ᄀ: "켹",
    켸ᄁ: "켺",
    켸ᄀᄉ: "켻",
    켸ᄂ: "켼",
    켸ᄂᄌ: "켽",
    켸ᄂᄒ: "켾",
    켸ᄃ: "켿",
    켸ᄅ: "콀",
    켸ᄅᄀ: "콁",
    켸ᄅᄆ: "콂",
    켸ᄅᄇ: "콃",
    켸ᄅᄉ: "콄",
    켸ᄅᄐ: "콅",
    켸ᄅᄑ: "콆",
    켸ᄅᄒ: "콇",
    켸ᄆ: "콈",
    켸ᄇ: "콉",
    켸ᄇᄉ: "콊",
    켸ᄉ: "콋",
    켸ᄊ: "콌",
    켸ᄋ: "콍",
    켸ᄌ: "콎",
    켸ᄎ: "콏",
    켸ᄏ: "콐",
    켸ᄐ: "콑",
    켸ᄑ: "콒",
    켸ᄒ: "콓",
    코: "코",
    코ᄀ: "콕",
    코ᄁ: "콖",
    코ᄀᄉ: "콗",
    코ᄂ: "콘",
    코ᄂᄌ: "콙",
    코ᄂᄒ: "콚",
    코ᄃ: "콛",
    코ᄅ: "콜",
    코ᄅᄀ: "콝",
    코ᄅᄆ: "콞",
    코ᄅᄇ: "콟",
    코ᄅᄉ: "콠",
    코ᄅᄐ: "콡",
    코ᄅᄑ: "콢",
    코ᄅᄒ: "콣",
    코ᄆ: "콤",
    코ᄇ: "콥",
    코ᄇᄉ: "콦",
    코ᄉ: "콧",
    코ᄊ: "콨",
    코ᄋ: "콩",
    코ᄌ: "콪",
    코ᄎ: "콫",
    코ᄏ: "콬",
    코ᄐ: "콭",
    코ᄑ: "콮",
    코ᄒ: "콯",
    코ᅡ: "콰",
    코ᅡᄀ: "콱",
    코ᅡᄁ: "콲",
    코ᅡᄀᄉ: "콳",
    코ᅡᄂ: "콴",
    코ᅡᄂᄌ: "콵",
    코ᅡᄂᄒ: "콶",
    코ᅡᄃ: "콷",
    코ᅡᄅ: "콸",
    코ᅡᄅᄀ: "콹",
    코ᅡᄅᄆ: "콺",
    코ᅡᄅᄇ: "콻",
    코ᅡᄅᄉ: "콼",
    코ᅡᄅᄐ: "콽",
    코ᅡᄅᄑ: "콾",
    코ᅡᄅᄒ: "콿",
    코ᅡᄆ: "쾀",
    코ᅡᄇ: "쾁",
    코ᅡᄇᄉ: "쾂",
    코ᅡᄉ: "쾃",
    코ᅡᄊ: "쾄",
    코ᅡᄋ: "쾅",
    코ᅡᄌ: "쾆",
    코ᅡᄎ: "쾇",
    코ᅡᄏ: "쾈",
    코ᅡᄐ: "쾉",
    코ᅡᄑ: "쾊",
    코ᅡᄒ: "쾋",
    코ᅢ: "쾌",
    코ᅢᄀ: "쾍",
    코ᅢᄁ: "쾎",
    코ᅢᄀᄉ: "쾏",
    코ᅢᄂ: "쾐",
    코ᅢᄂᄌ: "쾑",
    코ᅢᄂᄒ: "쾒",
    코ᅢᄃ: "쾓",
    코ᅢᄅ: "쾔",
    코ᅢᄅᄀ: "쾕",
    코ᅢᄅᄆ: "쾖",
    코ᅢᄅᄇ: "쾗",
    코ᅢᄅᄉ: "쾘",
    코ᅢᄅᄐ: "쾙",
    코ᅢᄅᄑ: "쾚",
    코ᅢᄅᄒ: "쾛",
    코ᅢᄆ: "쾜",
    코ᅢᄇ: "쾝",
    코ᅢᄇᄉ: "쾞",
    코ᅢᄉ: "쾟",
    코ᅢᄊ: "쾠",
    코ᅢᄋ: "쾡",
    코ᅢᄌ: "쾢",
    코ᅢᄎ: "쾣",
    코ᅢᄏ: "쾤",
    코ᅢᄐ: "쾥",
    코ᅢᄑ: "쾦",
    코ᅢᄒ: "쾧",
    코ᅵ: "쾨",
    코ᅵᄀ: "쾩",
    코ᅵᄁ: "쾪",
    코ᅵᄀᄉ: "쾫",
    코ᅵᄂ: "쾬",
    코ᅵᄂᄌ: "쾭",
    코ᅵᄂᄒ: "쾮",
    코ᅵᄃ: "쾯",
    코ᅵᄅ: "쾰",
    코ᅵᄅᄀ: "쾱",
    코ᅵᄅᄆ: "쾲",
    코ᅵᄅᄇ: "쾳",
    코ᅵᄅᄉ: "쾴",
    코ᅵᄅᄐ: "쾵",
    코ᅵᄅᄑ: "쾶",
    코ᅵᄅᄒ: "쾷",
    코ᅵᄆ: "쾸",
    코ᅵᄇ: "쾹",
    코ᅵᄇᄉ: "쾺",
    코ᅵᄉ: "쾻",
    코ᅵᄊ: "쾼",
    코ᅵᄋ: "쾽",
    코ᅵᄌ: "쾾",
    코ᅵᄎ: "쾿",
    코ᅵᄏ: "쿀",
    코ᅵᄐ: "쿁",
    코ᅵᄑ: "쿂",
    코ᅵᄒ: "쿃",
    쿄: "쿄",
    쿄ᄀ: "쿅",
    쿄ᄁ: "쿆",
    쿄ᄀᄉ: "쿇",
    쿄ᄂ: "쿈",
    쿄ᄂᄌ: "쿉",
    쿄ᄂᄒ: "쿊",
    쿄ᄃ: "쿋",
    쿄ᄅ: "쿌",
    쿄ᄅᄀ: "쿍",
    쿄ᄅᄆ: "쿎",
    쿄ᄅᄇ: "쿏",
    쿄ᄅᄉ: "쿐",
    쿄ᄅᄐ: "쿑",
    쿄ᄅᄑ: "쿒",
    쿄ᄅᄒ: "쿓",
    쿄ᄆ: "쿔",
    쿄ᄇ: "쿕",
    쿄ᄇᄉ: "쿖",
    쿄ᄉ: "쿗",
    쿄ᄊ: "쿘",
    쿄ᄋ: "쿙",
    쿄ᄌ: "쿚",
    쿄ᄎ: "쿛",
    쿄ᄏ: "쿜",
    쿄ᄐ: "쿝",
    쿄ᄑ: "쿞",
    쿄ᄒ: "쿟",
    쿠: "쿠",
    쿠ᄀ: "쿡",
    쿠ᄁ: "쿢",
    쿠ᄀᄉ: "쿣",
    쿠ᄂ: "쿤",
    쿠ᄂᄌ: "쿥",
    쿠ᄂᄒ: "쿦",
    쿠ᄃ: "쿧",
    쿠ᄅ: "쿨",
    쿠ᄅᄀ: "쿩",
    쿠ᄅᄆ: "쿪",
    쿠ᄅᄇ: "쿫",
    쿠ᄅᄉ: "쿬",
    쿠ᄅᄐ: "쿭",
    쿠ᄅᄑ: "쿮",
    쿠ᄅᄒ: "쿯",
    쿠ᄆ: "쿰",
    쿠ᄇ: "쿱",
    쿠ᄇᄉ: "쿲",
    쿠ᄉ: "쿳",
    쿠ᄊ: "쿴",
    쿠ᄋ: "쿵",
    쿠ᄌ: "쿶",
    쿠ᄎ: "쿷",
    쿠ᄏ: "쿸",
    쿠ᄐ: "쿹",
    쿠ᄑ: "쿺",
    쿠ᄒ: "쿻",
    쿠ᅥ: "쿼",
    쿠ᅥᄀ: "쿽",
    쿠ᅥᄁ: "쿾",
    쿠ᅥᄀᄉ: "쿿",
    쿠ᅥᄂ: "퀀",
    쿠ᅥᄂᄌ: "퀁",
    쿠ᅥᄂᄒ: "퀂",
    쿠ᅥᄃ: "퀃",
    쿠ᅥᄅ: "퀄",
    쿠ᅥᄅᄀ: "퀅",
    쿠ᅥᄅᄆ: "퀆",
    쿠ᅥᄅᄇ: "퀇",
    쿠ᅥᄅᄉ: "퀈",
    쿠ᅥᄅᄐ: "퀉",
    쿠ᅥᄅᄑ: "퀊",
    쿠ᅥᄅᄒ: "퀋",
    쿠ᅥᄆ: "퀌",
    쿠ᅥᄇ: "퀍",
    쿠ᅥᄇᄉ: "퀎",
    쿠ᅥᄉ: "퀏",
    쿠ᅥᄊ: "퀐",
    쿠ᅥᄋ: "퀑",
    쿠ᅥᄌ: "퀒",
    쿠ᅥᄎ: "퀓",
    쿠ᅥᄏ: "퀔",
    쿠ᅥᄐ: "퀕",
    쿠ᅥᄑ: "퀖",
    쿠ᅥᄒ: "퀗",
    쿠ᅦ: "퀘",
    쿠ᅦᄀ: "퀙",
    쿠ᅦᄁ: "퀚",
    쿠ᅦᄀᄉ: "퀛",
    쿠ᅦᄂ: "퀜",
    쿠ᅦᄂᄌ: "퀝",
    쿠ᅦᄂᄒ: "퀞",
    쿠ᅦᄃ: "퀟",
    쿠ᅦᄅ: "퀠",
    쿠ᅦᄅᄀ: "퀡",
    쿠ᅦᄅᄆ: "퀢",
    쿠ᅦᄅᄇ: "퀣",
    쿠ᅦᄅᄉ: "퀤",
    쿠ᅦᄅᄐ: "퀥",
    쿠ᅦᄅᄑ: "퀦",
    쿠ᅦᄅᄒ: "퀧",
    쿠ᅦᄆ: "퀨",
    쿠ᅦᄇ: "퀩",
    쿠ᅦᄇᄉ: "퀪",
    쿠ᅦᄉ: "퀫",
    쿠ᅦᄊ: "퀬",
    쿠ᅦᄋ: "퀭",
    쿠ᅦᄌ: "퀮",
    쿠ᅦᄎ: "퀯",
    쿠ᅦᄏ: "퀰",
    쿠ᅦᄐ: "퀱",
    쿠ᅦᄑ: "퀲",
    쿠ᅦᄒ: "퀳",
    쿠ᅵ: "퀴",
    쿠ᅵᄀ: "퀵",
    쿠ᅵᄁ: "퀶",
    쿠ᅵᄀᄉ: "퀷",
    쿠ᅵᄂ: "퀸",
    쿠ᅵᄂᄌ: "퀹",
    쿠ᅵᄂᄒ: "퀺",
    쿠ᅵᄃ: "퀻",
    쿠ᅵᄅ: "퀼",
    쿠ᅵᄅᄀ: "퀽",
    쿠ᅵᄅᄆ: "퀾",
    쿠ᅵᄅᄇ: "퀿",
    쿠ᅵᄅᄉ: "큀",
    쿠ᅵᄅᄐ: "큁",
    쿠ᅵᄅᄑ: "큂",
    쿠ᅵᄅᄒ: "큃",
    쿠ᅵᄆ: "큄",
    쿠ᅵᄇ: "큅",
    쿠ᅵᄇᄉ: "큆",
    쿠ᅵᄉ: "큇",
    쿠ᅵᄊ: "큈",
    쿠ᅵᄋ: "큉",
    쿠ᅵᄌ: "큊",
    쿠ᅵᄎ: "큋",
    쿠ᅵᄏ: "큌",
    쿠ᅵᄐ: "큍",
    쿠ᅵᄑ: "큎",
    쿠ᅵᄒ: "큏",
    큐: "큐",
    큐ᄀ: "큑",
    큐ᄁ: "큒",
    큐ᄀᄉ: "큓",
    큐ᄂ: "큔",
    큐ᄂᄌ: "큕",
    큐ᄂᄒ: "큖",
    큐ᄃ: "큗",
    큐ᄅ: "큘",
    큐ᄅᄀ: "큙",
    큐ᄅᄆ: "큚",
    큐ᄅᄇ: "큛",
    큐ᄅᄉ: "큜",
    큐ᄅᄐ: "큝",
    큐ᄅᄑ: "큞",
    큐ᄅᄒ: "큟",
    큐ᄆ: "큠",
    큐ᄇ: "큡",
    큐ᄇᄉ: "큢",
    큐ᄉ: "큣",
    큐ᄊ: "큤",
    큐ᄋ: "큥",
    큐ᄌ: "큦",
    큐ᄎ: "큧",
    큐ᄏ: "큨",
    큐ᄐ: "큩",
    큐ᄑ: "큪",
    큐ᄒ: "큫",
    크: "크",
    크ᄀ: "큭",
    크ᄁ: "큮",
    크ᄀᄉ: "큯",
    크ᄂ: "큰",
    크ᄂᄌ: "큱",
    크ᄂᄒ: "큲",
    크ᄃ: "큳",
    크ᄅ: "클",
    크ᄅᄀ: "큵",
    크ᄅᄆ: "큶",
    크ᄅᄇ: "큷",
    크ᄅᄉ: "큸",
    크ᄅᄐ: "큹",
    크ᄅᄑ: "큺",
    크ᄅᄒ: "큻",
    크ᄆ: "큼",
    크ᄇ: "큽",
    크ᄇᄉ: "큾",
    크ᄉ: "큿",
    크ᄊ: "킀",
    크ᄋ: "킁",
    크ᄌ: "킂",
    크ᄎ: "킃",
    크ᄏ: "킄",
    크ᄐ: "킅",
    크ᄑ: "킆",
    크ᄒ: "킇",
    크ᅵ: "킈",
    크ᅵᄀ: "킉",
    크ᅵᄁ: "킊",
    크ᅵᄀᄉ: "킋",
    크ᅵᄂ: "킌",
    크ᅵᄂᄌ: "킍",
    크ᅵᄂᄒ: "킎",
    크ᅵᄃ: "킏",
    크ᅵᄅ: "킐",
    크ᅵᄅᄀ: "킑",
    크ᅵᄅᄆ: "킒",
    크ᅵᄅᄇ: "킓",
    크ᅵᄅᄉ: "킔",
    크ᅵᄅᄐ: "킕",
    크ᅵᄅᄑ: "킖",
    크ᅵᄅᄒ: "킗",
    크ᅵᄆ: "킘",
    크ᅵᄇ: "킙",
    크ᅵᄇᄉ: "킚",
    크ᅵᄉ: "킛",
    크ᅵᄊ: "킜",
    크ᅵᄋ: "킝",
    크ᅵᄌ: "킞",
    크ᅵᄎ: "킟",
    크ᅵᄏ: "킠",
    크ᅵᄐ: "킡",
    크ᅵᄑ: "킢",
    크ᅵᄒ: "킣",
    키: "키",
    키ᄀ: "킥",
    키ᄁ: "킦",
    키ᄀᄉ: "킧",
    키ᄂ: "킨",
    키ᄂᄌ: "킩",
    키ᄂᄒ: "킪",
    키ᄃ: "킫",
    키ᄅ: "킬",
    키ᄅᄀ: "킭",
    키ᄅᄆ: "킮",
    키ᄅᄇ: "킯",
    키ᄅᄉ: "킰",
    키ᄅᄐ: "킱",
    키ᄅᄑ: "킲",
    키ᄅᄒ: "킳",
    키ᄆ: "킴",
    키ᄇ: "킵",
    키ᄇᄉ: "킶",
    키ᄉ: "킷",
    키ᄊ: "킸",
    키ᄋ: "킹",
    키ᄌ: "킺",
    키ᄎ: "킻",
    키ᄏ: "킼",
    키ᄐ: "킽",
    키ᄑ: "킾",
    키ᄒ: "킿",
    타: "타",
    타ᄀ: "탁",
    타ᄁ: "탂",
    타ᄀᄉ: "탃",
    타ᄂ: "탄",
    타ᄂᄌ: "탅",
    타ᄂᄒ: "탆",
    타ᄃ: "탇",
    타ᄅ: "탈",
    타ᄅᄀ: "탉",
    타ᄅᄆ: "탊",
    타ᄅᄇ: "탋",
    타ᄅᄉ: "탌",
    타ᄅᄐ: "탍",
    타ᄅᄑ: "탎",
    타ᄅᄒ: "탏",
    타ᄆ: "탐",
    타ᄇ: "탑",
    타ᄇᄉ: "탒",
    타ᄉ: "탓",
    타ᄊ: "탔",
    타ᄋ: "탕",
    타ᄌ: "탖",
    타ᄎ: "탗",
    타ᄏ: "탘",
    타ᄐ: "탙",
    타ᄑ: "탚",
    타ᄒ: "탛",
    태: "태",
    태ᄀ: "택",
    태ᄁ: "탞",
    태ᄀᄉ: "탟",
    태ᄂ: "탠",
    태ᄂᄌ: "탡",
    태ᄂᄒ: "탢",
    태ᄃ: "탣",
    태ᄅ: "탤",
    태ᄅᄀ: "탥",
    태ᄅᄆ: "탦",
    태ᄅᄇ: "탧",
    태ᄅᄉ: "탨",
    태ᄅᄐ: "탩",
    태ᄅᄑ: "탪",
    태ᄅᄒ: "탫",
    태ᄆ: "탬",
    태ᄇ: "탭",
    태ᄇᄉ: "탮",
    태ᄉ: "탯",
    태ᄊ: "탰",
    태ᄋ: "탱",
    태ᄌ: "탲",
    태ᄎ: "탳",
    태ᄏ: "탴",
    태ᄐ: "탵",
    태ᄑ: "탶",
    태ᄒ: "탷",
    탸: "탸",
    탸ᄀ: "탹",
    탸ᄁ: "탺",
    탸ᄀᄉ: "탻",
    탸ᄂ: "탼",
    탸ᄂᄌ: "탽",
    탸ᄂᄒ: "탾",
    탸ᄃ: "탿",
    탸ᄅ: "턀",
    탸ᄅᄀ: "턁",
    탸ᄅᄆ: "턂",
    탸ᄅᄇ: "턃",
    탸ᄅᄉ: "턄",
    탸ᄅᄐ: "턅",
    탸ᄅᄑ: "턆",
    탸ᄅᄒ: "턇",
    탸ᄆ: "턈",
    탸ᄇ: "턉",
    탸ᄇᄉ: "턊",
    탸ᄉ: "턋",
    탸ᄊ: "턌",
    탸ᄋ: "턍",
    탸ᄌ: "턎",
    탸ᄎ: "턏",
    탸ᄏ: "턐",
    탸ᄐ: "턑",
    탸ᄑ: "턒",
    탸ᄒ: "턓",
    턔: "턔",
    턔ᄀ: "턕",
    턔ᄁ: "턖",
    턔ᄀᄉ: "턗",
    턔ᄂ: "턘",
    턔ᄂᄌ: "턙",
    턔ᄂᄒ: "턚",
    턔ᄃ: "턛",
    턔ᄅ: "턜",
    턔ᄅᄀ: "턝",
    턔ᄅᄆ: "턞",
    턔ᄅᄇ: "턟",
    턔ᄅᄉ: "턠",
    턔ᄅᄐ: "턡",
    턔ᄅᄑ: "턢",
    턔ᄅᄒ: "턣",
    턔ᄆ: "턤",
    턔ᄇ: "턥",
    턔ᄇᄉ: "턦",
    턔ᄉ: "턧",
    턔ᄊ: "턨",
    턔ᄋ: "턩",
    턔ᄌ: "턪",
    턔ᄎ: "턫",
    턔ᄏ: "턬",
    턔ᄐ: "턭",
    턔ᄑ: "턮",
    턔ᄒ: "턯",
    터: "터",
    터ᄀ: "턱",
    터ᄁ: "턲",
    터ᄀᄉ: "턳",
    터ᄂ: "턴",
    터ᄂᄌ: "턵",
    터ᄂᄒ: "턶",
    터ᄃ: "턷",
    터ᄅ: "털",
    터ᄅᄀ: "턹",
    터ᄅᄆ: "턺",
    터ᄅᄇ: "턻",
    터ᄅᄉ: "턼",
    터ᄅᄐ: "턽",
    터ᄅᄑ: "턾",
    터ᄅᄒ: "턿",
    터ᄆ: "텀",
    터ᄇ: "텁",
    터ᄇᄉ: "텂",
    터ᄉ: "텃",
    터ᄊ: "텄",
    터ᄋ: "텅",
    터ᄌ: "텆",
    터ᄎ: "텇",
    터ᄏ: "텈",
    터ᄐ: "텉",
    터ᄑ: "텊",
    터ᄒ: "텋",
    테: "테",
    테ᄀ: "텍",
    테ᄁ: "텎",
    테ᄀᄉ: "텏",
    테ᄂ: "텐",
    테ᄂᄌ: "텑",
    테ᄂᄒ: "텒",
    테ᄃ: "텓",
    테ᄅ: "텔",
    테ᄅᄀ: "텕",
    테ᄅᄆ: "텖",
    테ᄅᄇ: "텗",
    테ᄅᄉ: "텘",
    테ᄅᄐ: "텙",
    테ᄅᄑ: "텚",
    테ᄅᄒ: "텛",
    테ᄆ: "템",
    테ᄇ: "텝",
    테ᄇᄉ: "텞",
    테ᄉ: "텟",
    테ᄊ: "텠",
    테ᄋ: "텡",
    테ᄌ: "텢",
    테ᄎ: "텣",
    테ᄏ: "텤",
    테ᄐ: "텥",
    테ᄑ: "텦",
    테ᄒ: "텧",
    텨: "텨",
    텨ᄀ: "텩",
    텨ᄁ: "텪",
    텨ᄀᄉ: "텫",
    텨ᄂ: "텬",
    텨ᄂᄌ: "텭",
    텨ᄂᄒ: "텮",
    텨ᄃ: "텯",
    텨ᄅ: "텰",
    텨ᄅᄀ: "텱",
    텨ᄅᄆ: "텲",
    텨ᄅᄇ: "텳",
    텨ᄅᄉ: "텴",
    텨ᄅᄐ: "텵",
    텨ᄅᄑ: "텶",
    텨ᄅᄒ: "텷",
    텨ᄆ: "텸",
    텨ᄇ: "텹",
    텨ᄇᄉ: "텺",
    텨ᄉ: "텻",
    텨ᄊ: "텼",
    텨ᄋ: "텽",
    텨ᄌ: "텾",
    텨ᄎ: "텿",
    텨ᄏ: "톀",
    텨ᄐ: "톁",
    텨ᄑ: "톂",
    텨ᄒ: "톃",
    톄: "톄",
    톄ᄀ: "톅",
    톄ᄁ: "톆",
    톄ᄀᄉ: "톇",
    톄ᄂ: "톈",
    톄ᄂᄌ: "톉",
    톄ᄂᄒ: "톊",
    톄ᄃ: "톋",
    톄ᄅ: "톌",
    톄ᄅᄀ: "톍",
    톄ᄅᄆ: "톎",
    톄ᄅᄇ: "톏",
    톄ᄅᄉ: "톐",
    톄ᄅᄐ: "톑",
    톄ᄅᄑ: "톒",
    톄ᄅᄒ: "톓",
    톄ᄆ: "톔",
    톄ᄇ: "톕",
    톄ᄇᄉ: "톖",
    톄ᄉ: "톗",
    톄ᄊ: "톘",
    톄ᄋ: "톙",
    톄ᄌ: "톚",
    톄ᄎ: "톛",
    톄ᄏ: "톜",
    톄ᄐ: "톝",
    톄ᄑ: "톞",
    톄ᄒ: "톟",
    토: "토",
    토ᄀ: "톡",
    토ᄁ: "톢",
    토ᄀᄉ: "톣",
    토ᄂ: "톤",
    토ᄂᄌ: "톥",
    토ᄂᄒ: "톦",
    토ᄃ: "톧",
    토ᄅ: "톨",
    토ᄅᄀ: "톩",
    토ᄅᄆ: "톪",
    토ᄅᄇ: "톫",
    토ᄅᄉ: "톬",
    토ᄅᄐ: "톭",
    토ᄅᄑ: "톮",
    토ᄅᄒ: "톯",
    토ᄆ: "톰",
    토ᄇ: "톱",
    토ᄇᄉ: "톲",
    토ᄉ: "톳",
    토ᄊ: "톴",
    토ᄋ: "통",
    토ᄌ: "톶",
    토ᄎ: "톷",
    토ᄏ: "톸",
    토ᄐ: "톹",
    토ᄑ: "톺",
    토ᄒ: "톻",
    토ᅡ: "톼",
    토ᅡᄀ: "톽",
    토ᅡᄁ: "톾",
    토ᅡᄀᄉ: "톿",
    토ᅡᄂ: "퇀",
    토ᅡᄂᄌ: "퇁",
    토ᅡᄂᄒ: "퇂",
    토ᅡᄃ: "퇃",
    토ᅡᄅ: "퇄",
    토ᅡᄅᄀ: "퇅",
    토ᅡᄅᄆ: "퇆",
    토ᅡᄅᄇ: "퇇",
    토ᅡᄅᄉ: "퇈",
    토ᅡᄅᄐ: "퇉",
    토ᅡᄅᄑ: "퇊",
    토ᅡᄅᄒ: "퇋",
    토ᅡᄆ: "퇌",
    토ᅡᄇ: "퇍",
    토ᅡᄇᄉ: "퇎",
    토ᅡᄉ: "퇏",
    토ᅡᄊ: "퇐",
    토ᅡᄋ: "퇑",
    토ᅡᄌ: "퇒",
    토ᅡᄎ: "퇓",
    토ᅡᄏ: "퇔",
    토ᅡᄐ: "퇕",
    토ᅡᄑ: "퇖",
    토ᅡᄒ: "퇗",
    토ᅢ: "퇘",
    토ᅢᄀ: "퇙",
    토ᅢᄁ: "퇚",
    토ᅢᄀᄉ: "퇛",
    토ᅢᄂ: "퇜",
    토ᅢᄂᄌ: "퇝",
    토ᅢᄂᄒ: "퇞",
    토ᅢᄃ: "퇟",
    토ᅢᄅ: "퇠",
    토ᅢᄅᄀ: "퇡",
    토ᅢᄅᄆ: "퇢",
    토ᅢᄅᄇ: "퇣",
    토ᅢᄅᄉ: "퇤",
    토ᅢᄅᄐ: "퇥",
    토ᅢᄅᄑ: "퇦",
    토ᅢᄅᄒ: "퇧",
    토ᅢᄆ: "퇨",
    토ᅢᄇ: "퇩",
    토ᅢᄇᄉ: "퇪",
    토ᅢᄉ: "퇫",
    토ᅢᄊ: "퇬",
    토ᅢᄋ: "퇭",
    토ᅢᄌ: "퇮",
    토ᅢᄎ: "퇯",
    토ᅢᄏ: "퇰",
    토ᅢᄐ: "퇱",
    토ᅢᄑ: "퇲",
    토ᅢᄒ: "퇳",
    토ᅵ: "퇴",
    토ᅵᄀ: "퇵",
    토ᅵᄁ: "퇶",
    토ᅵᄀᄉ: "퇷",
    토ᅵᄂ: "퇸",
    토ᅵᄂᄌ: "퇹",
    토ᅵᄂᄒ: "퇺",
    토ᅵᄃ: "퇻",
    토ᅵᄅ: "퇼",
    토ᅵᄅᄀ: "퇽",
    토ᅵᄅᄆ: "퇾",
    토ᅵᄅᄇ: "퇿",
    토ᅵᄅᄉ: "툀",
    토ᅵᄅᄐ: "툁",
    토ᅵᄅᄑ: "툂",
    토ᅵᄅᄒ: "툃",
    토ᅵᄆ: "툄",
    토ᅵᄇ: "툅",
    토ᅵᄇᄉ: "툆",
    토ᅵᄉ: "툇",
    토ᅵᄊ: "툈",
    토ᅵᄋ: "툉",
    토ᅵᄌ: "툊",
    토ᅵᄎ: "툋",
    토ᅵᄏ: "툌",
    토ᅵᄐ: "툍",
    토ᅵᄑ: "툎",
    토ᅵᄒ: "툏",
    툐: "툐",
    툐ᄀ: "툑",
    툐ᄁ: "툒",
    툐ᄀᄉ: "툓",
    툐ᄂ: "툔",
    툐ᄂᄌ: "툕",
    툐ᄂᄒ: "툖",
    툐ᄃ: "툗",
    툐ᄅ: "툘",
    툐ᄅᄀ: "툙",
    툐ᄅᄆ: "툚",
    툐ᄅᄇ: "툛",
    툐ᄅᄉ: "툜",
    툐ᄅᄐ: "툝",
    툐ᄅᄑ: "툞",
    툐ᄅᄒ: "툟",
    툐ᄆ: "툠",
    툐ᄇ: "툡",
    툐ᄇᄉ: "툢",
    툐ᄉ: "툣",
    툐ᄊ: "툤",
    툐ᄋ: "툥",
    툐ᄌ: "툦",
    툐ᄎ: "툧",
    툐ᄏ: "툨",
    툐ᄐ: "툩",
    툐ᄑ: "툪",
    툐ᄒ: "툫",
    투: "투",
    투ᄀ: "툭",
    투ᄁ: "툮",
    투ᄀᄉ: "툯",
    투ᄂ: "툰",
    투ᄂᄌ: "툱",
    투ᄂᄒ: "툲",
    투ᄃ: "툳",
    투ᄅ: "툴",
    투ᄅᄀ: "툵",
    투ᄅᄆ: "툶",
    투ᄅᄇ: "툷",
    투ᄅᄉ: "툸",
    투ᄅᄐ: "툹",
    투ᄅᄑ: "툺",
    투ᄅᄒ: "툻",
    투ᄆ: "툼",
    투ᄇ: "툽",
    투ᄇᄉ: "툾",
    투ᄉ: "툿",
    투ᄊ: "퉀",
    투ᄋ: "퉁",
    투ᄌ: "퉂",
    투ᄎ: "퉃",
    투ᄏ: "퉄",
    투ᄐ: "퉅",
    투ᄑ: "퉆",
    투ᄒ: "퉇",
    투ᅥ: "퉈",
    투ᅥᄀ: "퉉",
    투ᅥᄁ: "퉊",
    투ᅥᄀᄉ: "퉋",
    투ᅥᄂ: "퉌",
    투ᅥᄂᄌ: "퉍",
    투ᅥᄂᄒ: "퉎",
    투ᅥᄃ: "퉏",
    투ᅥᄅ: "퉐",
    투ᅥᄅᄀ: "퉑",
    투ᅥᄅᄆ: "퉒",
    투ᅥᄅᄇ: "퉓",
    투ᅥᄅᄉ: "퉔",
    투ᅥᄅᄐ: "퉕",
    투ᅥᄅᄑ: "퉖",
    투ᅥᄅᄒ: "퉗",
    투ᅥᄆ: "퉘",
    투ᅥᄇ: "퉙",
    투ᅥᄇᄉ: "퉚",
    투ᅥᄉ: "퉛",
    투ᅥᄊ: "퉜",
    투ᅥᄋ: "퉝",
    투ᅥᄌ: "퉞",
    투ᅥᄎ: "퉟",
    투ᅥᄏ: "퉠",
    투ᅥᄐ: "퉡",
    투ᅥᄑ: "퉢",
    투ᅥᄒ: "퉣",
    투ᅦ: "퉤",
    투ᅦᄀ: "퉥",
    투ᅦᄁ: "퉦",
    투ᅦᄀᄉ: "퉧",
    투ᅦᄂ: "퉨",
    투ᅦᄂᄌ: "퉩",
    투ᅦᄂᄒ: "퉪",
    투ᅦᄃ: "퉫",
    투ᅦᄅ: "퉬",
    투ᅦᄅᄀ: "퉭",
    투ᅦᄅᄆ: "퉮",
    투ᅦᄅᄇ: "퉯",
    투ᅦᄅᄉ: "퉰",
    투ᅦᄅᄐ: "퉱",
    투ᅦᄅᄑ: "퉲",
    투ᅦᄅᄒ: "퉳",
    투ᅦᄆ: "퉴",
    투ᅦᄇ: "퉵",
    투ᅦᄇᄉ: "퉶",
    투ᅦᄉ: "퉷",
    투ᅦᄊ: "퉸",
    투ᅦᄋ: "퉹",
    투ᅦᄌ: "퉺",
    투ᅦᄎ: "퉻",
    투ᅦᄏ: "퉼",
    투ᅦᄐ: "퉽",
    투ᅦᄑ: "퉾",
    투ᅦᄒ: "퉿",
    투ᅵ: "튀",
    투ᅵᄀ: "튁",
    투ᅵᄁ: "튂",
    투ᅵᄀᄉ: "튃",
    투ᅵᄂ: "튄",
    투ᅵᄂᄌ: "튅",
    투ᅵᄂᄒ: "튆",
    투ᅵᄃ: "튇",
    투ᅵᄅ: "튈",
    투ᅵᄅᄀ: "튉",
    투ᅵᄅᄆ: "튊",
    투ᅵᄅᄇ: "튋",
    투ᅵᄅᄉ: "튌",
    투ᅵᄅᄐ: "튍",
    투ᅵᄅᄑ: "튎",
    투ᅵᄅᄒ: "튏",
    투ᅵᄆ: "튐",
    투ᅵᄇ: "튑",
    투ᅵᄇᄉ: "튒",
    투ᅵᄉ: "튓",
    투ᅵᄊ: "튔",
    투ᅵᄋ: "튕",
    투ᅵᄌ: "튖",
    투ᅵᄎ: "튗",
    투ᅵᄏ: "튘",
    투ᅵᄐ: "튙",
    투ᅵᄑ: "튚",
    투ᅵᄒ: "튛",
    튜: "튜",
    튜ᄀ: "튝",
    튜ᄁ: "튞",
    튜ᄀᄉ: "튟",
    튜ᄂ: "튠",
    튜ᄂᄌ: "튡",
    튜ᄂᄒ: "튢",
    튜ᄃ: "튣",
    튜ᄅ: "튤",
    튜ᄅᄀ: "튥",
    튜ᄅᄆ: "튦",
    튜ᄅᄇ: "튧",
    튜ᄅᄉ: "튨",
    튜ᄅᄐ: "튩",
    튜ᄅᄑ: "튪",
    튜ᄅᄒ: "튫",
    튜ᄆ: "튬",
    튜ᄇ: "튭",
    튜ᄇᄉ: "튮",
    튜ᄉ: "튯",
    튜ᄊ: "튰",
    튜ᄋ: "튱",
    튜ᄌ: "튲",
    튜ᄎ: "튳",
    튜ᄏ: "튴",
    튜ᄐ: "튵",
    튜ᄑ: "튶",
    튜ᄒ: "튷",
    트: "트",
    트ᄀ: "특",
    트ᄁ: "튺",
    트ᄀᄉ: "튻",
    트ᄂ: "튼",
    트ᄂᄌ: "튽",
    트ᄂᄒ: "튾",
    트ᄃ: "튿",
    트ᄅ: "틀",
    트ᄅᄀ: "틁",
    트ᄅᄆ: "틂",
    트ᄅᄇ: "틃",
    트ᄅᄉ: "틄",
    트ᄅᄐ: "틅",
    트ᄅᄑ: "틆",
    트ᄅᄒ: "틇",
    트ᄆ: "틈",
    트ᄇ: "틉",
    트ᄇᄉ: "틊",
    트ᄉ: "틋",
    트ᄊ: "틌",
    트ᄋ: "틍",
    트ᄌ: "틎",
    트ᄎ: "틏",
    트ᄏ: "틐",
    트ᄐ: "틑",
    트ᄑ: "틒",
    트ᄒ: "틓",
    트ᅵ: "틔",
    트ᅵᄀ: "틕",
    트ᅵᄁ: "틖",
    트ᅵᄀᄉ: "틗",
    트ᅵᄂ: "틘",
    트ᅵᄂᄌ: "틙",
    트ᅵᄂᄒ: "틚",
    트ᅵᄃ: "틛",
    트ᅵᄅ: "틜",
    트ᅵᄅᄀ: "틝",
    트ᅵᄅᄆ: "틞",
    트ᅵᄅᄇ: "틟",
    트ᅵᄅᄉ: "틠",
    트ᅵᄅᄐ: "틡",
    트ᅵᄅᄑ: "틢",
    트ᅵᄅᄒ: "틣",
    트ᅵᄆ: "틤",
    트ᅵᄇ: "틥",
    트ᅵᄇᄉ: "틦",
    트ᅵᄉ: "틧",
    트ᅵᄊ: "틨",
    트ᅵᄋ: "틩",
    트ᅵᄌ: "틪",
    트ᅵᄎ: "틫",
    트ᅵᄏ: "틬",
    트ᅵᄐ: "틭",
    트ᅵᄑ: "틮",
    트ᅵᄒ: "틯",
    티: "티",
    티ᄀ: "틱",
    티ᄁ: "틲",
    티ᄀᄉ: "틳",
    티ᄂ: "틴",
    티ᄂᄌ: "틵",
    티ᄂᄒ: "틶",
    티ᄃ: "틷",
    티ᄅ: "틸",
    티ᄅᄀ: "틹",
    티ᄅᄆ: "틺",
    티ᄅᄇ: "틻",
    티ᄅᄉ: "틼",
    티ᄅᄐ: "틽",
    티ᄅᄑ: "틾",
    티ᄅᄒ: "틿",
    티ᄆ: "팀",
    티ᄇ: "팁",
    티ᄇᄉ: "팂",
    티ᄉ: "팃",
    티ᄊ: "팄",
    티ᄋ: "팅",
    티ᄌ: "팆",
    티ᄎ: "팇",
    티ᄏ: "팈",
    티ᄐ: "팉",
    티ᄑ: "팊",
    티ᄒ: "팋",
    파: "파",
    파ᄀ: "팍",
    파ᄁ: "팎",
    파ᄀᄉ: "팏",
    파ᄂ: "판",
    파ᄂᄌ: "팑",
    파ᄂᄒ: "팒",
    파ᄃ: "팓",
    파ᄅ: "팔",
    파ᄅᄀ: "팕",
    파ᄅᄆ: "팖",
    파ᄅᄇ: "팗",
    파ᄅᄉ: "팘",
    파ᄅᄐ: "팙",
    파ᄅᄑ: "팚",
    파ᄅᄒ: "팛",
    파ᄆ: "팜",
    파ᄇ: "팝",
    파ᄇᄉ: "팞",
    파ᄉ: "팟",
    파ᄊ: "팠",
    파ᄋ: "팡",
    파ᄌ: "팢",
    파ᄎ: "팣",
    파ᄏ: "팤",
    파ᄐ: "팥",
    파ᄑ: "팦",
    파ᄒ: "팧",
    패: "패",
    패ᄀ: "팩",
    패ᄁ: "팪",
    패ᄀᄉ: "팫",
    패ᄂ: "팬",
    패ᄂᄌ: "팭",
    패ᄂᄒ: "팮",
    패ᄃ: "팯",
    패ᄅ: "팰",
    패ᄅᄀ: "팱",
    패ᄅᄆ: "팲",
    패ᄅᄇ: "팳",
    패ᄅᄉ: "팴",
    패ᄅᄐ: "팵",
    패ᄅᄑ: "팶",
    패ᄅᄒ: "팷",
    패ᄆ: "팸",
    패ᄇ: "팹",
    패ᄇᄉ: "팺",
    패ᄉ: "팻",
    패ᄊ: "팼",
    패ᄋ: "팽",
    패ᄌ: "팾",
    패ᄎ: "팿",
    패ᄏ: "퍀",
    패ᄐ: "퍁",
    패ᄑ: "퍂",
    패ᄒ: "퍃",
    퍄: "퍄",
    퍄ᄀ: "퍅",
    퍄ᄁ: "퍆",
    퍄ᄀᄉ: "퍇",
    퍄ᄂ: "퍈",
    퍄ᄂᄌ: "퍉",
    퍄ᄂᄒ: "퍊",
    퍄ᄃ: "퍋",
    퍄ᄅ: "퍌",
    퍄ᄅᄀ: "퍍",
    퍄ᄅᄆ: "퍎",
    퍄ᄅᄇ: "퍏",
    퍄ᄅᄉ: "퍐",
    퍄ᄅᄐ: "퍑",
    퍄ᄅᄑ: "퍒",
    퍄ᄅᄒ: "퍓",
    퍄ᄆ: "퍔",
    퍄ᄇ: "퍕",
    퍄ᄇᄉ: "퍖",
    퍄ᄉ: "퍗",
    퍄ᄊ: "퍘",
    퍄ᄋ: "퍙",
    퍄ᄌ: "퍚",
    퍄ᄎ: "퍛",
    퍄ᄏ: "퍜",
    퍄ᄐ: "퍝",
    퍄ᄑ: "퍞",
    퍄ᄒ: "퍟",
    퍠: "퍠",
    퍠ᄀ: "퍡",
    퍠ᄁ: "퍢",
    퍠ᄀᄉ: "퍣",
    퍠ᄂ: "퍤",
    퍠ᄂᄌ: "퍥",
    퍠ᄂᄒ: "퍦",
    퍠ᄃ: "퍧",
    퍠ᄅ: "퍨",
    퍠ᄅᄀ: "퍩",
    퍠ᄅᄆ: "퍪",
    퍠ᄅᄇ: "퍫",
    퍠ᄅᄉ: "퍬",
    퍠ᄅᄐ: "퍭",
    퍠ᄅᄑ: "퍮",
    퍠ᄅᄒ: "퍯",
    퍠ᄆ: "퍰",
    퍠ᄇ: "퍱",
    퍠ᄇᄉ: "퍲",
    퍠ᄉ: "퍳",
    퍠ᄊ: "퍴",
    퍠ᄋ: "퍵",
    퍠ᄌ: "퍶",
    퍠ᄎ: "퍷",
    퍠ᄏ: "퍸",
    퍠ᄐ: "퍹",
    퍠ᄑ: "퍺",
    퍠ᄒ: "퍻",
    퍼: "퍼",
    퍼ᄀ: "퍽",
    퍼ᄁ: "퍾",
    퍼ᄀᄉ: "퍿",
    퍼ᄂ: "펀",
    퍼ᄂᄌ: "펁",
    퍼ᄂᄒ: "펂",
    퍼ᄃ: "펃",
    퍼ᄅ: "펄",
    퍼ᄅᄀ: "펅",
    퍼ᄅᄆ: "펆",
    퍼ᄅᄇ: "펇",
    퍼ᄅᄉ: "펈",
    퍼ᄅᄐ: "펉",
    퍼ᄅᄑ: "펊",
    퍼ᄅᄒ: "펋",
    퍼ᄆ: "펌",
    퍼ᄇ: "펍",
    퍼ᄇᄉ: "펎",
    퍼ᄉ: "펏",
    퍼ᄊ: "펐",
    퍼ᄋ: "펑",
    퍼ᄌ: "펒",
    퍼ᄎ: "펓",
    퍼ᄏ: "펔",
    퍼ᄐ: "펕",
    퍼ᄑ: "펖",
    퍼ᄒ: "펗",
    페: "페",
    페ᄀ: "펙",
    페ᄁ: "펚",
    페ᄀᄉ: "펛",
    페ᄂ: "펜",
    페ᄂᄌ: "펝",
    페ᄂᄒ: "펞",
    페ᄃ: "펟",
    페ᄅ: "펠",
    페ᄅᄀ: "펡",
    페ᄅᄆ: "펢",
    페ᄅᄇ: "펣",
    페ᄅᄉ: "펤",
    페ᄅᄐ: "펥",
    페ᄅᄑ: "펦",
    페ᄅᄒ: "펧",
    페ᄆ: "펨",
    페ᄇ: "펩",
    페ᄇᄉ: "펪",
    페ᄉ: "펫",
    페ᄊ: "펬",
    페ᄋ: "펭",
    페ᄌ: "펮",
    페ᄎ: "펯",
    페ᄏ: "펰",
    페ᄐ: "펱",
    페ᄑ: "펲",
    페ᄒ: "펳",
    펴: "펴",
    펴ᄀ: "펵",
    펴ᄁ: "펶",
    펴ᄀᄉ: "펷",
    펴ᄂ: "편",
    펴ᄂᄌ: "펹",
    펴ᄂᄒ: "펺",
    펴ᄃ: "펻",
    펴ᄅ: "펼",
    펴ᄅᄀ: "펽",
    펴ᄅᄆ: "펾",
    펴ᄅᄇ: "펿",
    펴ᄅᄉ: "폀",
    펴ᄅᄐ: "폁",
    펴ᄅᄑ: "폂",
    펴ᄅᄒ: "폃",
    펴ᄆ: "폄",
    펴ᄇ: "폅",
    펴ᄇᄉ: "폆",
    펴ᄉ: "폇",
    펴ᄊ: "폈",
    펴ᄋ: "평",
    펴ᄌ: "폊",
    펴ᄎ: "폋",
    펴ᄏ: "폌",
    펴ᄐ: "폍",
    펴ᄑ: "폎",
    펴ᄒ: "폏",
    폐: "폐",
    폐ᄀ: "폑",
    폐ᄁ: "폒",
    폐ᄀᄉ: "폓",
    폐ᄂ: "폔",
    폐ᄂᄌ: "폕",
    폐ᄂᄒ: "폖",
    폐ᄃ: "폗",
    폐ᄅ: "폘",
    폐ᄅᄀ: "폙",
    폐ᄅᄆ: "폚",
    폐ᄅᄇ: "폛",
    폐ᄅᄉ: "폜",
    폐ᄅᄐ: "폝",
    폐ᄅᄑ: "폞",
    폐ᄅᄒ: "폟",
    폐ᄆ: "폠",
    폐ᄇ: "폡",
    폐ᄇᄉ: "폢",
    폐ᄉ: "폣",
    폐ᄊ: "폤",
    폐ᄋ: "폥",
    폐ᄌ: "폦",
    폐ᄎ: "폧",
    폐ᄏ: "폨",
    폐ᄐ: "폩",
    폐ᄑ: "폪",
    폐ᄒ: "폫",
    포: "포",
    포ᄀ: "폭",
    포ᄁ: "폮",
    포ᄀᄉ: "폯",
    포ᄂ: "폰",
    포ᄂᄌ: "폱",
    포ᄂᄒ: "폲",
    포ᄃ: "폳",
    포ᄅ: "폴",
    포ᄅᄀ: "폵",
    포ᄅᄆ: "폶",
    포ᄅᄇ: "폷",
    포ᄅᄉ: "폸",
    포ᄅᄐ: "폹",
    포ᄅᄑ: "폺",
    포ᄅᄒ: "폻",
    포ᄆ: "폼",
    포ᄇ: "폽",
    포ᄇᄉ: "폾",
    포ᄉ: "폿",
    포ᄊ: "퐀",
    포ᄋ: "퐁",
    포ᄌ: "퐂",
    포ᄎ: "퐃",
    포ᄏ: "퐄",
    포ᄐ: "퐅",
    포ᄑ: "퐆",
    포ᄒ: "퐇",
    포ᅡ: "퐈",
    포ᅡᄀ: "퐉",
    포ᅡᄁ: "퐊",
    포ᅡᄀᄉ: "퐋",
    포ᅡᄂ: "퐌",
    포ᅡᄂᄌ: "퐍",
    포ᅡᄂᄒ: "퐎",
    포ᅡᄃ: "퐏",
    포ᅡᄅ: "퐐",
    포ᅡᄅᄀ: "퐑",
    포ᅡᄅᄆ: "퐒",
    포ᅡᄅᄇ: "퐓",
    포ᅡᄅᄉ: "퐔",
    포ᅡᄅᄐ: "퐕",
    포ᅡᄅᄑ: "퐖",
    포ᅡᄅᄒ: "퐗",
    포ᅡᄆ: "퐘",
    포ᅡᄇ: "퐙",
    포ᅡᄇᄉ: "퐚",
    포ᅡᄉ: "퐛",
    포ᅡᄊ: "퐜",
    포ᅡᄋ: "퐝",
    포ᅡᄌ: "퐞",
    포ᅡᄎ: "퐟",
    포ᅡᄏ: "퐠",
    포ᅡᄐ: "퐡",
    포ᅡᄑ: "퐢",
    포ᅡᄒ: "퐣",
    포ᅢ: "퐤",
    포ᅢᄀ: "퐥",
    포ᅢᄁ: "퐦",
    포ᅢᄀᄉ: "퐧",
    포ᅢᄂ: "퐨",
    포ᅢᄂᄌ: "퐩",
    포ᅢᄂᄒ: "퐪",
    포ᅢᄃ: "퐫",
    포ᅢᄅ: "퐬",
    포ᅢᄅᄀ: "퐭",
    포ᅢᄅᄆ: "퐮",
    포ᅢᄅᄇ: "퐯",
    포ᅢᄅᄉ: "퐰",
    포ᅢᄅᄐ: "퐱",
    포ᅢᄅᄑ: "퐲",
    포ᅢᄅᄒ: "퐳",
    포ᅢᄆ: "퐴",
    포ᅢᄇ: "퐵",
    포ᅢᄇᄉ: "퐶",
    포ᅢᄉ: "퐷",
    포ᅢᄊ: "퐸",
    포ᅢᄋ: "퐹",
    포ᅢᄌ: "퐺",
    포ᅢᄎ: "퐻",
    포ᅢᄏ: "퐼",
    포ᅢᄐ: "퐽",
    포ᅢᄑ: "퐾",
    포ᅢᄒ: "퐿",
    포ᅵ: "푀",
    포ᅵᄀ: "푁",
    포ᅵᄁ: "푂",
    포ᅵᄀᄉ: "푃",
    포ᅵᄂ: "푄",
    포ᅵᄂᄌ: "푅",
    포ᅵᄂᄒ: "푆",
    포ᅵᄃ: "푇",
    포ᅵᄅ: "푈",
    포ᅵᄅᄀ: "푉",
    포ᅵᄅᄆ: "푊",
    포ᅵᄅᄇ: "푋",
    포ᅵᄅᄉ: "푌",
    포ᅵᄅᄐ: "푍",
    포ᅵᄅᄑ: "푎",
    포ᅵᄅᄒ: "푏",
    포ᅵᄆ: "푐",
    포ᅵᄇ: "푑",
    포ᅵᄇᄉ: "푒",
    포ᅵᄉ: "푓",
    포ᅵᄊ: "푔",
    포ᅵᄋ: "푕",
    포ᅵᄌ: "푖",
    포ᅵᄎ: "푗",
    포ᅵᄏ: "푘",
    포ᅵᄐ: "푙",
    포ᅵᄑ: "푚",
    포ᅵᄒ: "푛",
    표: "표",
    표ᄀ: "푝",
    표ᄁ: "푞",
    표ᄀᄉ: "푟",
    표ᄂ: "푠",
    표ᄂᄌ: "푡",
    표ᄂᄒ: "푢",
    표ᄃ: "푣",
    표ᄅ: "푤",
    표ᄅᄀ: "푥",
    표ᄅᄆ: "푦",
    표ᄅᄇ: "푧",
    표ᄅᄉ: "푨",
    표ᄅᄐ: "푩",
    표ᄅᄑ: "푪",
    표ᄅᄒ: "푫",
    표ᄆ: "푬",
    표ᄇ: "푭",
    표ᄇᄉ: "푮",
    표ᄉ: "푯",
    표ᄊ: "푰",
    표ᄋ: "푱",
    표ᄌ: "푲",
    표ᄎ: "푳",
    표ᄏ: "푴",
    표ᄐ: "푵",
    표ᄑ: "푶",
    표ᄒ: "푷",
    푸: "푸",
    푸ᄀ: "푹",
    푸ᄁ: "푺",
    푸ᄀᄉ: "푻",
    푸ᄂ: "푼",
    푸ᄂᄌ: "푽",
    푸ᄂᄒ: "푾",
    푸ᄃ: "푿",
    푸ᄅ: "풀",
    푸ᄅᄀ: "풁",
    푸ᄅᄆ: "풂",
    푸ᄅᄇ: "풃",
    푸ᄅᄉ: "풄",
    푸ᄅᄐ: "풅",
    푸ᄅᄑ: "풆",
    푸ᄅᄒ: "풇",
    푸ᄆ: "품",
    푸ᄇ: "풉",
    푸ᄇᄉ: "풊",
    푸ᄉ: "풋",
    푸ᄊ: "풌",
    푸ᄋ: "풍",
    푸ᄌ: "풎",
    푸ᄎ: "풏",
    푸ᄏ: "풐",
    푸ᄐ: "풑",
    푸ᄑ: "풒",
    푸ᄒ: "풓",
    푸ᅥ: "풔",
    푸ᅥᄀ: "풕",
    푸ᅥᄁ: "풖",
    푸ᅥᄀᄉ: "풗",
    푸ᅥᄂ: "풘",
    푸ᅥᄂᄌ: "풙",
    푸ᅥᄂᄒ: "풚",
    푸ᅥᄃ: "풛",
    푸ᅥᄅ: "풜",
    푸ᅥᄅᄀ: "풝",
    푸ᅥᄅᄆ: "풞",
    푸ᅥᄅᄇ: "풟",
    푸ᅥᄅᄉ: "풠",
    푸ᅥᄅᄐ: "풡",
    푸ᅥᄅᄑ: "풢",
    푸ᅥᄅᄒ: "풣",
    푸ᅥᄆ: "풤",
    푸ᅥᄇ: "풥",
    푸ᅥᄇᄉ: "풦",
    푸ᅥᄉ: "풧",
    푸ᅥᄊ: "풨",
    푸ᅥᄋ: "풩",
    푸ᅥᄌ: "풪",
    푸ᅥᄎ: "풫",
    푸ᅥᄏ: "풬",
    푸ᅥᄐ: "풭",
    푸ᅥᄑ: "풮",
    푸ᅥᄒ: "풯",
    푸ᅦ: "풰",
    푸ᅦᄀ: "풱",
    푸ᅦᄁ: "풲",
    푸ᅦᄀᄉ: "풳",
    푸ᅦᄂ: "풴",
    푸ᅦᄂᄌ: "풵",
    푸ᅦᄂᄒ: "풶",
    푸ᅦᄃ: "풷",
    푸ᅦᄅ: "풸",
    푸ᅦᄅᄀ: "풹",
    푸ᅦᄅᄆ: "풺",
    푸ᅦᄅᄇ: "풻",
    푸ᅦᄅᄉ: "풼",
    푸ᅦᄅᄐ: "풽",
    푸ᅦᄅᄑ: "풾",
    푸ᅦᄅᄒ: "풿",
    푸ᅦᄆ: "퓀",
    푸ᅦᄇ: "퓁",
    푸ᅦᄇᄉ: "퓂",
    푸ᅦᄉ: "퓃",
    푸ᅦᄊ: "퓄",
    푸ᅦᄋ: "퓅",
    푸ᅦᄌ: "퓆",
    푸ᅦᄎ: "퓇",
    푸ᅦᄏ: "퓈",
    푸ᅦᄐ: "퓉",
    푸ᅦᄑ: "퓊",
    푸ᅦᄒ: "퓋",
    푸ᅵ: "퓌",
    푸ᅵᄀ: "퓍",
    푸ᅵᄁ: "퓎",
    푸ᅵᄀᄉ: "퓏",
    푸ᅵᄂ: "퓐",
    푸ᅵᄂᄌ: "퓑",
    푸ᅵᄂᄒ: "퓒",
    푸ᅵᄃ: "퓓",
    푸ᅵᄅ: "퓔",
    푸ᅵᄅᄀ: "퓕",
    푸ᅵᄅᄆ: "퓖",
    푸ᅵᄅᄇ: "퓗",
    푸ᅵᄅᄉ: "퓘",
    푸ᅵᄅᄐ: "퓙",
    푸ᅵᄅᄑ: "퓚",
    푸ᅵᄅᄒ: "퓛",
    푸ᅵᄆ: "퓜",
    푸ᅵᄇ: "퓝",
    푸ᅵᄇᄉ: "퓞",
    푸ᅵᄉ: "퓟",
    푸ᅵᄊ: "퓠",
    푸ᅵᄋ: "퓡",
    푸ᅵᄌ: "퓢",
    푸ᅵᄎ: "퓣",
    푸ᅵᄏ: "퓤",
    푸ᅵᄐ: "퓥",
    푸ᅵᄑ: "퓦",
    푸ᅵᄒ: "퓧",
    퓨: "퓨",
    퓨ᄀ: "퓩",
    퓨ᄁ: "퓪",
    퓨ᄀᄉ: "퓫",
    퓨ᄂ: "퓬",
    퓨ᄂᄌ: "퓭",
    퓨ᄂᄒ: "퓮",
    퓨ᄃ: "퓯",
    퓨ᄅ: "퓰",
    퓨ᄅᄀ: "퓱",
    퓨ᄅᄆ: "퓲",
    퓨ᄅᄇ: "퓳",
    퓨ᄅᄉ: "퓴",
    퓨ᄅᄐ: "퓵",
    퓨ᄅᄑ: "퓶",
    퓨ᄅᄒ: "퓷",
    퓨ᄆ: "퓸",
    퓨ᄇ: "퓹",
    퓨ᄇᄉ: "퓺",
    퓨ᄉ: "퓻",
    퓨ᄊ: "퓼",
    퓨ᄋ: "퓽",
    퓨ᄌ: "퓾",
    퓨ᄎ: "퓿",
    퓨ᄏ: "픀",
    퓨ᄐ: "픁",
    퓨ᄑ: "픂",
    퓨ᄒ: "픃",
    프: "프",
    프ᄀ: "픅",
    프ᄁ: "픆",
    프ᄀᄉ: "픇",
    프ᄂ: "픈",
    프ᄂᄌ: "픉",
    프ᄂᄒ: "픊",
    프ᄃ: "픋",
    프ᄅ: "플",
    프ᄅᄀ: "픍",
    프ᄅᄆ: "픎",
    프ᄅᄇ: "픏",
    프ᄅᄉ: "픐",
    프ᄅᄐ: "픑",
    프ᄅᄑ: "픒",
    프ᄅᄒ: "픓",
    프ᄆ: "픔",
    프ᄇ: "픕",
    프ᄇᄉ: "픖",
    프ᄉ: "픗",
    프ᄊ: "픘",
    프ᄋ: "픙",
    프ᄌ: "픚",
    프ᄎ: "픛",
    프ᄏ: "픜",
    프ᄐ: "픝",
    프ᄑ: "픞",
    프ᄒ: "픟",
    프ᅵ: "픠",
    프ᅵᄀ: "픡",
    프ᅵᄁ: "픢",
    프ᅵᄀᄉ: "픣",
    프ᅵᄂ: "픤",
    프ᅵᄂᄌ: "픥",
    프ᅵᄂᄒ: "픦",
    프ᅵᄃ: "픧",
    프ᅵᄅ: "픨",
    프ᅵᄅᄀ: "픩",
    프ᅵᄅᄆ: "픪",
    프ᅵᄅᄇ: "픫",
    프ᅵᄅᄉ: "픬",
    프ᅵᄅᄐ: "픭",
    프ᅵᄅᄑ: "픮",
    프ᅵᄅᄒ: "픯",
    프ᅵᄆ: "픰",
    프ᅵᄇ: "픱",
    프ᅵᄇᄉ: "픲",
    프ᅵᄉ: "픳",
    프ᅵᄊ: "픴",
    프ᅵᄋ: "픵",
    프ᅵᄌ: "픶",
    프ᅵᄎ: "픷",
    프ᅵᄏ: "픸",
    프ᅵᄐ: "픹",
    프ᅵᄑ: "픺",
    프ᅵᄒ: "픻",
    피: "피",
    피ᄀ: "픽",
    피ᄁ: "픾",
    피ᄀᄉ: "픿",
    피ᄂ: "핀",
    피ᄂᄌ: "핁",
    피ᄂᄒ: "핂",
    피ᄃ: "핃",
    피ᄅ: "필",
    피ᄅᄀ: "핅",
    피ᄅᄆ: "핆",
    피ᄅᄇ: "핇",
    피ᄅᄉ: "핈",
    피ᄅᄐ: "핉",
    피ᄅᄑ: "핊",
    피ᄅᄒ: "핋",
    피ᄆ: "핌",
    피ᄇ: "핍",
    피ᄇᄉ: "핎",
    피ᄉ: "핏",
    피ᄊ: "핐",
    피ᄋ: "핑",
    피ᄌ: "핒",
    피ᄎ: "핓",
    피ᄏ: "핔",
    피ᄐ: "핕",
    피ᄑ: "핖",
    피ᄒ: "핗",
    하: "하",
    하ᄀ: "학",
    하ᄁ: "핚",
    하ᄀᄉ: "핛",
    하ᄂ: "한",
    하ᄂᄌ: "핝",
    하ᄂᄒ: "핞",
    하ᄃ: "핟",
    하ᄅ: "할",
    하ᄅᄀ: "핡",
    하ᄅᄆ: "핢",
    하ᄅᄇ: "핣",
    하ᄅᄉ: "핤",
    하ᄅᄐ: "핥",
    하ᄅᄑ: "핦",
    하ᄅᄒ: "핧",
    하ᄆ: "함",
    하ᄇ: "합",
    하ᄇᄉ: "핪",
    하ᄉ: "핫",
    하ᄊ: "핬",
    하ᄋ: "항",
    하ᄌ: "핮",
    하ᄎ: "핯",
    하ᄏ: "핰",
    하ᄐ: "핱",
    하ᄑ: "핲",
    하ᄒ: "핳",
    해: "해",
    해ᄀ: "핵",
    해ᄁ: "핶",
    해ᄀᄉ: "핷",
    해ᄂ: "핸",
    해ᄂᄌ: "핹",
    해ᄂᄒ: "핺",
    해ᄃ: "핻",
    해ᄅ: "핼",
    해ᄅᄀ: "핽",
    해ᄅᄆ: "핾",
    해ᄅᄇ: "핿",
    해ᄅᄉ: "햀",
    해ᄅᄐ: "햁",
    해ᄅᄑ: "햂",
    해ᄅᄒ: "햃",
    해ᄆ: "햄",
    해ᄇ: "햅",
    해ᄇᄉ: "햆",
    해ᄉ: "햇",
    해ᄊ: "했",
    해ᄋ: "행",
    해ᄌ: "햊",
    해ᄎ: "햋",
    해ᄏ: "햌",
    해ᄐ: "햍",
    해ᄑ: "햎",
    해ᄒ: "햏",
    햐: "햐",
    햐ᄀ: "햑",
    햐ᄁ: "햒",
    햐ᄀᄉ: "햓",
    햐ᄂ: "햔",
    햐ᄂᄌ: "햕",
    햐ᄂᄒ: "햖",
    햐ᄃ: "햗",
    햐ᄅ: "햘",
    햐ᄅᄀ: "햙",
    햐ᄅᄆ: "햚",
    햐ᄅᄇ: "햛",
    햐ᄅᄉ: "햜",
    햐ᄅᄐ: "햝",
    햐ᄅᄑ: "햞",
    햐ᄅᄒ: "햟",
    햐ᄆ: "햠",
    햐ᄇ: "햡",
    햐ᄇᄉ: "햢",
    햐ᄉ: "햣",
    햐ᄊ: "햤",
    햐ᄋ: "향",
    햐ᄌ: "햦",
    햐ᄎ: "햧",
    햐ᄏ: "햨",
    햐ᄐ: "햩",
    햐ᄑ: "햪",
    햐ᄒ: "햫",
    햬: "햬",
    햬ᄀ: "햭",
    햬ᄁ: "햮",
    햬ᄀᄉ: "햯",
    햬ᄂ: "햰",
    햬ᄂᄌ: "햱",
    햬ᄂᄒ: "햲",
    햬ᄃ: "햳",
    햬ᄅ: "햴",
    햬ᄅᄀ: "햵",
    햬ᄅᄆ: "햶",
    햬ᄅᄇ: "햷",
    햬ᄅᄉ: "햸",
    햬ᄅᄐ: "햹",
    햬ᄅᄑ: "햺",
    햬ᄅᄒ: "햻",
    햬ᄆ: "햼",
    햬ᄇ: "햽",
    햬ᄇᄉ: "햾",
    햬ᄉ: "햿",
    햬ᄊ: "헀",
    햬ᄋ: "헁",
    햬ᄌ: "헂",
    햬ᄎ: "헃",
    햬ᄏ: "헄",
    햬ᄐ: "헅",
    햬ᄑ: "헆",
    햬ᄒ: "헇",
    허: "허",
    허ᄀ: "헉",
    허ᄁ: "헊",
    허ᄀᄉ: "헋",
    허ᄂ: "헌",
    허ᄂᄌ: "헍",
    허ᄂᄒ: "헎",
    허ᄃ: "헏",
    허ᄅ: "헐",
    허ᄅᄀ: "헑",
    허ᄅᄆ: "헒",
    허ᄅᄇ: "헓",
    허ᄅᄉ: "헔",
    허ᄅᄐ: "헕",
    허ᄅᄑ: "헖",
    허ᄅᄒ: "헗",
    허ᄆ: "험",
    허ᄇ: "헙",
    허ᄇᄉ: "헚",
    허ᄉ: "헛",
    허ᄊ: "헜",
    허ᄋ: "헝",
    허ᄌ: "헞",
    허ᄎ: "헟",
    허ᄏ: "헠",
    허ᄐ: "헡",
    허ᄑ: "헢",
    허ᄒ: "헣",
    헤: "헤",
    헤ᄀ: "헥",
    헤ᄁ: "헦",
    헤ᄀᄉ: "헧",
    헤ᄂ: "헨",
    헤ᄂᄌ: "헩",
    헤ᄂᄒ: "헪",
    헤ᄃ: "헫",
    헤ᄅ: "헬",
    헤ᄅᄀ: "헭",
    헤ᄅᄆ: "헮",
    헤ᄅᄇ: "헯",
    헤ᄅᄉ: "헰",
    헤ᄅᄐ: "헱",
    헤ᄅᄑ: "헲",
    헤ᄅᄒ: "헳",
    헤ᄆ: "헴",
    헤ᄇ: "헵",
    헤ᄇᄉ: "헶",
    헤ᄉ: "헷",
    헤ᄊ: "헸",
    헤ᄋ: "헹",
    헤ᄌ: "헺",
    헤ᄎ: "헻",
    헤ᄏ: "헼",
    헤ᄐ: "헽",
    헤ᄑ: "헾",
    헤ᄒ: "헿",
    혀: "혀",
    혀ᄀ: "혁",
    혀ᄁ: "혂",
    혀ᄀᄉ: "혃",
    혀ᄂ: "현",
    혀ᄂᄌ: "혅",
    혀ᄂᄒ: "혆",
    혀ᄃ: "혇",
    혀ᄅ: "혈",
    혀ᄅᄀ: "혉",
    혀ᄅᄆ: "혊",
    혀ᄅᄇ: "혋",
    혀ᄅᄉ: "혌",
    혀ᄅᄐ: "혍",
    혀ᄅᄑ: "혎",
    혀ᄅᄒ: "혏",
    혀ᄆ: "혐",
    혀ᄇ: "협",
    혀ᄇᄉ: "혒",
    혀ᄉ: "혓",
    혀ᄊ: "혔",
    혀ᄋ: "형",
    혀ᄌ: "혖",
    혀ᄎ: "혗",
    혀ᄏ: "혘",
    혀ᄐ: "혙",
    혀ᄑ: "혚",
    혀ᄒ: "혛",
    혜: "혜",
    혜ᄀ: "혝",
    혜ᄁ: "혞",
    혜ᄀᄉ: "혟",
    혜ᄂ: "혠",
    혜ᄂᄌ: "혡",
    혜ᄂᄒ: "혢",
    혜ᄃ: "혣",
    혜ᄅ: "혤",
    혜ᄅᄀ: "혥",
    혜ᄅᄆ: "혦",
    혜ᄅᄇ: "혧",
    혜ᄅᄉ: "혨",
    혜ᄅᄐ: "혩",
    혜ᄅᄑ: "혪",
    혜ᄅᄒ: "혫",
    혜ᄆ: "혬",
    혜ᄇ: "혭",
    혜ᄇᄉ: "혮",
    혜ᄉ: "혯",
    혜ᄊ: "혰",
    혜ᄋ: "혱",
    혜ᄌ: "혲",
    혜ᄎ: "혳",
    혜ᄏ: "혴",
    혜ᄐ: "혵",
    혜ᄑ: "혶",
    혜ᄒ: "혷",
    호: "호",
    호ᄀ: "혹",
    호ᄁ: "혺",
    호ᄀᄉ: "혻",
    호ᄂ: "혼",
    호ᄂᄌ: "혽",
    호ᄂᄒ: "혾",
    호ᄃ: "혿",
    호ᄅ: "홀",
    호ᄅᄀ: "홁",
    호ᄅᄆ: "홂",
    호ᄅᄇ: "홃",
    호ᄅᄉ: "홄",
    호ᄅᄐ: "홅",
    호ᄅᄑ: "홆",
    호ᄅᄒ: "홇",
    호ᄆ: "홈",
    호ᄇ: "홉",
    호ᄇᄉ: "홊",
    호ᄉ: "홋",
    호ᄊ: "홌",
    호ᄋ: "홍",
    호ᄌ: "홎",
    호ᄎ: "홏",
    호ᄏ: "홐",
    호ᄐ: "홑",
    호ᄑ: "홒",
    호ᄒ: "홓",
    호ᅡ: "화",
    호ᅡᄀ: "확",
    호ᅡᄁ: "홖",
    호ᅡᄀᄉ: "홗",
    호ᅡᄂ: "환",
    호ᅡᄂᄌ: "홙",
    호ᅡᄂᄒ: "홚",
    호ᅡᄃ: "홛",
    호ᅡᄅ: "활",
    호ᅡᄅᄀ: "홝",
    호ᅡᄅᄆ: "홞",
    호ᅡᄅᄇ: "홟",
    호ᅡᄅᄉ: "홠",
    호ᅡᄅᄐ: "홡",
    호ᅡᄅᄑ: "홢",
    호ᅡᄅᄒ: "홣",
    호ᅡᄆ: "홤",
    호ᅡᄇ: "홥",
    호ᅡᄇᄉ: "홦",
    호ᅡᄉ: "홧",
    호ᅡᄊ: "홨",
    호ᅡᄋ: "황",
    호ᅡᄌ: "홪",
    호ᅡᄎ: "홫",
    호ᅡᄏ: "홬",
    호ᅡᄐ: "홭",
    호ᅡᄑ: "홮",
    호ᅡᄒ: "홯",
    호ᅢ: "홰",
    호ᅢᄀ: "홱",
    호ᅢᄁ: "홲",
    호ᅢᄀᄉ: "홳",
    호ᅢᄂ: "홴",
    호ᅢᄂᄌ: "홵",
    호ᅢᄂᄒ: "홶",
    호ᅢᄃ: "홷",
    호ᅢᄅ: "홸",
    호ᅢᄅᄀ: "홹",
    호ᅢᄅᄆ: "홺",
    호ᅢᄅᄇ: "홻",
    호ᅢᄅᄉ: "홼",
    호ᅢᄅᄐ: "홽",
    호ᅢᄅᄑ: "홾",
    호ᅢᄅᄒ: "홿",
    호ᅢᄆ: "횀",
    호ᅢᄇ: "횁",
    호ᅢᄇᄉ: "횂",
    호ᅢᄉ: "횃",
    호ᅢᄊ: "횄",
    호ᅢᄋ: "횅",
    호ᅢᄌ: "횆",
    호ᅢᄎ: "횇",
    호ᅢᄏ: "횈",
    호ᅢᄐ: "횉",
    호ᅢᄑ: "횊",
    호ᅢᄒ: "횋",
    호ᅵ: "회",
    호ᅵᄀ: "획",
    호ᅵᄁ: "횎",
    호ᅵᄀᄉ: "횏",
    호ᅵᄂ: "횐",
    호ᅵᄂᄌ: "횑",
    호ᅵᄂᄒ: "횒",
    호ᅵᄃ: "횓",
    호ᅵᄅ: "횔",
    호ᅵᄅᄀ: "횕",
    호ᅵᄅᄆ: "횖",
    호ᅵᄅᄇ: "횗",
    호ᅵᄅᄉ: "횘",
    호ᅵᄅᄐ: "횙",
    호ᅵᄅᄑ: "횚",
    호ᅵᄅᄒ: "횛",
    호ᅵᄆ: "횜",
    호ᅵᄇ: "횝",
    호ᅵᄇᄉ: "횞",
    호ᅵᄉ: "횟",
    호ᅵᄊ: "횠",
    호ᅵᄋ: "횡",
    호ᅵᄌ: "횢",
    호ᅵᄎ: "횣",
    호ᅵᄏ: "횤",
    호ᅵᄐ: "횥",
    호ᅵᄑ: "횦",
    호ᅵᄒ: "횧",
    효: "효",
    효ᄀ: "횩",
    효ᄁ: "횪",
    효ᄀᄉ: "횫",
    효ᄂ: "횬",
    효ᄂᄌ: "횭",
    효ᄂᄒ: "횮",
    효ᄃ: "횯",
    효ᄅ: "횰",
    효ᄅᄀ: "횱",
    효ᄅᄆ: "횲",
    효ᄅᄇ: "횳",
    효ᄅᄉ: "횴",
    효ᄅᄐ: "횵",
    효ᄅᄑ: "횶",
    효ᄅᄒ: "횷",
    효ᄆ: "횸",
    효ᄇ: "횹",
    효ᄇᄉ: "횺",
    효ᄉ: "횻",
    효ᄊ: "횼",
    효ᄋ: "횽",
    효ᄌ: "횾",
    효ᄎ: "횿",
    효ᄏ: "훀",
    효ᄐ: "훁",
    효ᄑ: "훂",
    효ᄒ: "훃",
    후: "후",
    후ᄀ: "훅",
    후ᄁ: "훆",
    후ᄀᄉ: "훇",
    후ᄂ: "훈",
    후ᄂᄌ: "훉",
    후ᄂᄒ: "훊",
    후ᄃ: "훋",
    후ᄅ: "훌",
    후ᄅᄀ: "훍",
    후ᄅᄆ: "훎",
    후ᄅᄇ: "훏",
    후ᄅᄉ: "훐",
    후ᄅᄐ: "훑",
    후ᄅᄑ: "훒",
    후ᄅᄒ: "훓",
    후ᄆ: "훔",
    후ᄇ: "훕",
    후ᄇᄉ: "훖",
    후ᄉ: "훗",
    후ᄊ: "훘",
    후ᄋ: "훙",
    후ᄌ: "훚",
    후ᄎ: "훛",
    후ᄏ: "훜",
    후ᄐ: "훝",
    후ᄑ: "훞",
    후ᄒ: "훟",
    후ᅥ: "훠",
    후ᅥᄀ: "훡",
    후ᅥᄁ: "훢",
    후ᅥᄀᄉ: "훣",
    후ᅥᄂ: "훤",
    후ᅥᄂᄌ: "훥",
    후ᅥᄂᄒ: "훦",
    후ᅥᄃ: "훧",
    후ᅥᄅ: "훨",
    후ᅥᄅᄀ: "훩",
    후ᅥᄅᄆ: "훪",
    후ᅥᄅᄇ: "훫",
    후ᅥᄅᄉ: "훬",
    후ᅥᄅᄐ: "훭",
    후ᅥᄅᄑ: "훮",
    후ᅥᄅᄒ: "훯",
    후ᅥᄆ: "훰",
    후ᅥᄇ: "훱",
    후ᅥᄇᄉ: "훲",
    후ᅥᄉ: "훳",
    후ᅥᄊ: "훴",
    후ᅥᄋ: "훵",
    후ᅥᄌ: "훶",
    후ᅥᄎ: "훷",
    후ᅥᄏ: "훸",
    후ᅥᄐ: "훹",
    후ᅥᄑ: "훺",
    후ᅥᄒ: "훻",
    후ᅦ: "훼",
    후ᅦᄀ: "훽",
    후ᅦᄁ: "훾",
    후ᅦᄀᄉ: "훿",
    후ᅦᄂ: "휀",
    후ᅦᄂᄌ: "휁",
    후ᅦᄂᄒ: "휂",
    후ᅦᄃ: "휃",
    후ᅦᄅ: "휄",
    후ᅦᄅᄀ: "휅",
    후ᅦᄅᄆ: "휆",
    후ᅦᄅᄇ: "휇",
    후ᅦᄅᄉ: "휈",
    후ᅦᄅᄐ: "휉",
    후ᅦᄅᄑ: "휊",
    후ᅦᄅᄒ: "휋",
    후ᅦᄆ: "휌",
    후ᅦᄇ: "휍",
    후ᅦᄇᄉ: "휎",
    후ᅦᄉ: "휏",
    후ᅦᄊ: "휐",
    후ᅦᄋ: "휑",
    후ᅦᄌ: "휒",
    후ᅦᄎ: "휓",
    후ᅦᄏ: "휔",
    후ᅦᄐ: "휕",
    후ᅦᄑ: "휖",
    후ᅦᄒ: "휗",
    후ᅵ: "휘",
    후ᅵᄀ: "휙",
    후ᅵᄁ: "휚",
    후ᅵᄀᄉ: "휛",
    후ᅵᄂ: "휜",
    후ᅵᄂᄌ: "휝",
    후ᅵᄂᄒ: "휞",
    후ᅵᄃ: "휟",
    후ᅵᄅ: "휠",
    후ᅵᄅᄀ: "휡",
    후ᅵᄅᄆ: "휢",
    후ᅵᄅᄇ: "휣",
    후ᅵᄅᄉ: "휤",
    후ᅵᄅᄐ: "휥",
    후ᅵᄅᄑ: "휦",
    후ᅵᄅᄒ: "휧",
    후ᅵᄆ: "휨",
    후ᅵᄇ: "휩",
    후ᅵᄇᄉ: "휪",
    후ᅵᄉ: "휫",
    후ᅵᄊ: "휬",
    후ᅵᄋ: "휭",
    후ᅵᄌ: "휮",
    후ᅵᄎ: "휯",
    후ᅵᄏ: "휰",
    후ᅵᄐ: "휱",
    후ᅵᄑ: "휲",
    후ᅵᄒ: "휳",
    휴: "휴",
    휴ᄀ: "휵",
    휴ᄁ: "휶",
    휴ᄀᄉ: "휷",
    휴ᄂ: "휸",
    휴ᄂᄌ: "휹",
    휴ᄂᄒ: "휺",
    휴ᄃ: "휻",
    휴ᄅ: "휼",
    휴ᄅᄀ: "휽",
    휴ᄅᄆ: "휾",
    휴ᄅᄇ: "휿",
    휴ᄅᄉ: "흀",
    휴ᄅᄐ: "흁",
    휴ᄅᄑ: "흂",
    휴ᄅᄒ: "흃",
    휴ᄆ: "흄",
    휴ᄇ: "흅",
    휴ᄇᄉ: "흆",
    휴ᄉ: "흇",
    휴ᄊ: "흈",
    휴ᄋ: "흉",
    휴ᄌ: "흊",
    휴ᄎ: "흋",
    휴ᄏ: "흌",
    휴ᄐ: "흍",
    휴ᄑ: "흎",
    휴ᄒ: "흏",
    흐: "흐",
    흐ᄀ: "흑",
    흐ᄁ: "흒",
    흐ᄀᄉ: "흓",
    흐ᄂ: "흔",
    흐ᄂᄌ: "흕",
    흐ᄂᄒ: "흖",
    흐ᄃ: "흗",
    흐ᄅ: "흘",
    흐ᄅᄀ: "흙",
    흐ᄅᄆ: "흚",
    흐ᄅᄇ: "흛",
    흐ᄅᄉ: "흜",
    흐ᄅᄐ: "흝",
    흐ᄅᄑ: "흞",
    흐ᄅᄒ: "흟",
    흐ᄆ: "흠",
    흐ᄇ: "흡",
    흐ᄇᄉ: "흢",
    흐ᄉ: "흣",
    흐ᄊ: "흤",
    흐ᄋ: "흥",
    흐ᄌ: "흦",
    흐ᄎ: "흧",
    흐ᄏ: "흨",
    흐ᄐ: "흩",
    흐ᄑ: "흪",
    흐ᄒ: "흫",
    흐ᅵ: "희",
    흐ᅵᄀ: "흭",
    흐ᅵᄁ: "흮",
    흐ᅵᄀᄉ: "흯",
    흐ᅵᄂ: "흰",
    흐ᅵᄂᄌ: "흱",
    흐ᅵᄂᄒ: "흲",
    흐ᅵᄃ: "흳",
    흐ᅵᄅ: "흴",
    흐ᅵᄅᄀ: "흵",
    흐ᅵᄅᄆ: "흶",
    흐ᅵᄅᄇ: "흷",
    흐ᅵᄅᄉ: "흸",
    흐ᅵᄅᄐ: "흹",
    흐ᅵᄅᄑ: "흺",
    흐ᅵᄅᄒ: "흻",
    흐ᅵᄆ: "흼",
    흐ᅵᄇ: "흽",
    흐ᅵᄇᄉ: "흾",
    흐ᅵᄉ: "흿",
    흐ᅵᄊ: "힀",
    흐ᅵᄋ: "힁",
    흐ᅵᄌ: "힂",
    흐ᅵᄎ: "힃",
    흐ᅵᄏ: "힄",
    흐ᅵᄐ: "힅",
    흐ᅵᄑ: "힆",
    흐ᅵᄒ: "힇",
    히: "히",
    히ᄀ: "힉",
    히ᄁ: "힊",
    히ᄀᄉ: "힋",
    히ᄂ: "힌",
    히ᄂᄌ: "힍",
    히ᄂᄒ: "힎",
    히ᄃ: "힏",
    히ᄅ: "힐",
    히ᄅᄀ: "힑",
    히ᄅᄆ: "힒",
    히ᄅᄇ: "힓",
    히ᄅᄉ: "힔",
    히ᄅᄐ: "힕",
    히ᄅᄑ: "힖",
    히ᄅᄒ: "힗",
    히ᄆ: "힘",
    히ᄇ: "힙",
    히ᄇᄉ: "힚",
    히ᄉ: "힛",
    히ᄊ: "힜",
    히ᄋ: "힝",
    히ᄌ: "힞",
    히ᄎ: "힟",
    히ᄏ: "힠",
    히ᄐ: "힡",
    히ᄑ: "힢",
    히ᄒ: "힣",
  },
};
