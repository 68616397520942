import { useTranslate } from "app/hooks/lang";
import { Button } from "../ui/button.component";
import styles from "./error-fallback.module.scss";

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const t = useTranslate();

  return (
    <div data-navigate-row className={styles.container}>
      <h2>{t('fallback.error')}</h2>
      <Button data-navigate-item onClick={resetErrorBoundary}>
      {t('fallback.button')}
      </Button>
    </div>
  );
};