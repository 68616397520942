import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Category } from "services-hooks/types";
import { AppState } from "../store";

export interface RadioState {
  activeSection: Category[];
  activeIndex: number;
}

const initialState: RadioState = {
  activeSection: [],
  activeIndex: 0,
};

export const radioSlice = createSlice({
  name: "radio",
  initialState,
  reducers: {
    selectRadio: (
      state,
      payload: PayloadAction<{
        section: Category[];
        activeIndex: number;
      }>
    ) => {
      state.activeSection = payload.payload?.section;
      state.activeIndex = payload.payload?.activeIndex;
    },
    reset: () => initialState,
  },
});
export const radioActions = radioSlice.actions;
export const selectRadioState = (state: AppState) => state.radio;
export default radioSlice.reducer;
